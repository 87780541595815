export const GET_START = '[DEVICEMANAGER] GET_START';
export const GET_END = '[DEVICEMANAGER] GET_END';
export const ERROR = '[DEVICEMANAGER] ERROR';
export const CLEAR = '[DEVICEMANAGER] CLEAR';


export function GetStart(payload) {
    return { type: GET_START, payload }
}

export function GetEnd(payload) {
    return { type: GET_END, payload }
}

export function Clear() {
    return { type: CLEAR }
}
