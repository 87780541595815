import React, {Component, useEffect, useState} from 'react';
import './role.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import {connect, useDispatch, useSelector} from "react-redux";

import {LoadingComponent} from "../../components/loading/loading";
import {Table} from "antd";
import {columns} from "./columns";
import {ListRole} from "./components/list-role/list-role";
import {SectionComponent} from "../../components/description-section/section";
import AddRole from "./add-role";
import {GetStart} from "./store/role.actions";

export const RolePage = () => {
    const dispatch = useDispatch();
    useEffect(() => { dispatch(GetStart()) } , [])

    const {active, roles, loading} = useSelector(state => {
        const role = state.roleReducer;
        return {
            active: role.active,
            roles: role.roles,
            loading: role.loading
        };
    });

    return(
        <section id="role-main">
            <LoadingComponent loading={loading}/>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className="body">
                    <ListRole roles={roles} active={active}/>
                </div>
                <div style={{flex: 1, position: 'relative'}}>
                        {

                            active >=0 && roles.length > 0? <Table
                                scroll={{ y: window.innerHeight - 67 }}
                                bordered
                                className={`table-report ${!roles[active].edit && 'opactiy'}`}
                                dataSource={roles[active].authorizations}
                                columns={columns(roles[active].edit, dispatch)}
                                pagination={false} size="small"
                            /> :
                            <SectionComponent img={"/img/shield.svg"} description="Choisissez un rôle pour continuer"/>
                        }
                    </div>
            </div>
            <AddRole/>
        </section>
    );
};
