import * as vehicleActions from './vehicles.actions';
import {LIMIT} from "../../env";
import {GET_SENSOR_ACCESSORY} from "./vehicles.actions";


const INITIAL_STATE = {
    addVehicle: {
        steps:[
            {current: 'Traceur & groupe', next: 'Information de base'},
            {current: 'Information de base', next: 'Fin'},
        ],
        step: 0,
        data: {},
        commands: [],
        loading: false
    },
    stats: {connected: 0, disconnected: 0, withGps: 0, inGrp: 0, total: 0},
    vehicles: [],
    filters: {
        data: [
            {filter: 'all', name: 'Tous'},
            {filter: 'connected', name: 'Connecté'},
            {filter: 'disconnected', name: 'Non connecté'}],
        params: {skip: 0, limit: LIMIT, filter: 'all'}
    },
    loading: false,
    companyError: null
}


export function vehiclesReducer(state = INITIAL_STATE, action){
    switch (action.type) {
        case vehicleActions.GET_START:
            const params = {...state.filters.params, ...action.payload};
            return {
                ...state, loading: true,
                filters: {
                    ...state.filters,
                    params
                }
            }

        case vehicleActions.GET_END:
            return Object.assign({}, state, {
                vehicles: action.payload.list,
                stats: action.payload.stats,
                total: action.payload.total,
                loading: false
            });

        case vehicleActions.GET_START_ACCESSORY:
            return {
                ...state,
                addVehicle: INITIAL_STATE.addVehicle,
                loading: true
            }

        case vehicleActions.GET_END_ACCESSORY:
            const {_id, active, activated, available, accessory, accessoryUpdated}=action.payload;
            const data = {active, available, accessory, _id};
            data.disable = [];
            data.available = data.available.map(el => {
                const active = activated && activated.length>0 &&
                    activated.find(element => element.id === el.id || (element.type && element.type === el.id));
                if(active) {
                    el.icon = active.icon;
                    el.active = !active.type ? active.sensor : active.type;
                    if(el.group) { data.disable.push(el.group);}
                }
                return el;
            });
            if(accessoryUpdated) {data.accessoryUpdated=accessoryUpdated;}
            return {
                ...state,
                addVehicle: {
                    ...state.addVehicle,
                    data
                },

                loading: false
            }

        case vehicleActions.ADD_START_ACCESSORY:
        case vehicleActions.DELETE_START:
            return {
                ...state, loading: true
            }

        case vehicleActions.ADD_END_ACCESSORY:{
            const {response} = action.payload;
            const isArr = Array.isArray(action.payload.pin);
            let pin = !isArr ? action.payload.pin : action.payload.pin[0];
            if(response.modifiedCount) {
                let available;
                if(!isArr) {
                    available = state.addVehicle.data.available.findIndex(el => el.id === pin.id);
                } else {
                    available = state.addVehicle.data.available.findIndex(el =>
                        el.type === pin.type && el.type === 'input');
                }

                if(available !== -1) {
                    const pinTmp = state.addVehicle.data.available[available];
                    let disable = state.addVehicle.data.disable;

                    if(pin.sensor) {
                        pinTmp.active =  isArr ? pin.type : pin.sensor;
                        pinTmp.icon =  pin.icon;
                        if(pinTmp.group) {
                            const grp = disable.indexOf(pinTmp.group);
                            if(grp ===-1) {
                                disable.push(pinTmp.group);
                            }
                        }
                    } else {
                        delete pinTmp.active;
                        delete pinTmp.icon;
                        if(pinTmp.group) {
                            const grp = disable.indexOf(pinTmp.group);
                            if(grp !==-1) {
                                disable.splice(grp, 1);
                            }
                        }
                    }
                    const list = [
                        ...state.addVehicle.data.available.slice(0, available),
                        pinTmp,
                        ...state.addVehicle.data.available.slice(available + 1)
                    ];

                    return {
                        ...state,
                        loading: false,
                        addVehicle: {
                            ...state.addVehicle,
                            data:{
                                ...state.addVehicle.data,
                                available: list,
                                disable
                            }
                        }
                    }
                }
            }
            return {
                ...state,
                loading: false
            }
        }




        case vehicleActions.TOOGLE_ACCESSORY:{
            const type = action.payload;
            const accessory = state.addVehicle.data.accessory ? [...state.addVehicle.data.accessory] : [];
            const index = accessory.indexOf(type);
            if( index=== -1) {
                accessory.push(type)
            } else {
                accessory.splice(index, 1);
            }
            return {
                ...state,
                addVehicle: {
                    ...state.addVehicle,
                    data: {
                        ...state.addVehicle.data,
                        accessory
                    }
                },

                loading: false
            }
        }



        case vehicleActions.SAVE_START:
            return {
                ...state,
                addVehicle: {
                    ...state.addVehicle,
                    loading: true
                }
            };

        case vehicleActions.SAVE_END:
            const vehicle = action.payload;
            let {connected, disconnected, withGps, inGrp, total} = state.stats;

            const findVehicle = state.vehicles.findIndex(el => el._id === vehicle.id);
            if(findVehicle === -1) {
                state.vehicles.unshift(vehicle);
                vehicle.group && inGrp++;
                vehicle.tracer.online ? connected++ : disconnected++;
                total++;
                withGps++;
            } else {
                state.vehicles[findVehicle] = {...state.vehicles[findVehicle], ...action.payload};
            }
            return {
                ...state,
                addVehicle: INITIAL_STATE.addVehicle,
                vehicles: state.vehicles,
                stats: {
                    ...state.stats,
                    total,
                    inGrp,
                    connected,
                    disconnected,
                    withGps
                }
            };

        case vehicleActions.TOOGLE_STEP:
            if(state.addVehicle.step) {
                return {
                    ...state,
                    addVehicle: {
                        ...state.addVehicle,
                        step: false,
                        data: INITIAL_STATE.addVehicle.data
                    }
                }
            }
            return {
                ...state,
                addVehicle: {
                    ...state.addVehicle,
                    step: 1
                }
            }

        case vehicleActions.SET_STEP_DATA:
            return {
                ...state,
                addVehicle: {
                    ...state.addVehicle,
                    step: action.payload.step,
                    data: {
                        ...state.addVehicle.data,
                        ...action.payload.data
                    }
                }
            }

        case vehicleActions.SAVE_COMMANDS_START:
            return {
                ...state,
                addVehicle: {
                    ...state.addVehicle,
                    loading: true
                }
            }
        case vehicleActions.SAVE_COMMANDS_END:
            let commands = [];
            if(!action.payload.delete) {
                const ids = new Set(state.addVehicle.commands.map(d => d._id));
                commands = [...state.addVehicle.commands, ...action.payload.commands.filter(d => !ids.has(d._id))];
            } else {
                const findCommand = state.addVehicle.commands.findIndex(el => el._id === action.payload.commands[0]);
                if(findCommand !== -1) {
                    state.addVehicle.commands.splice(findCommand, 1);
                    commands = state.addVehicle.commands;
                }
            }


            return {
                ...state,
                addVehicle: {
                    ...state.addVehicle,
                    commands,
                    loading: false
                }
            }

        case vehicleActions.SET_DATE:
            return {
                ...state,
                addVehicle: {
                    ...state.addVehicle,
                    data: {
                        ...state.addVehicle.data,
                        subscription: action.payload
                    }
                }
            }

        case vehicleActions.SET_STEP:
            return {
                ...state,
                addVehicle: {
                    ...state.addVehicle,
                    step: action.payload.step
                }
            }

        case vehicleActions.TOOGLE_ACTIVE:
            return {
                ...state,
                addVehicle: {
                    ...state.addVehicle,
                    data: {
                        ...state.addVehicle.data,
                        active: action.payload
                    }
                }
            }




        case vehicleActions.DELETE_END:
            const {response, id} = action.payload;
            if(response.deletedCount) {
                const findv = state.vehicles.findIndex(el => el._id === id);
                if(findv !== -1) {
                    let {total} = state.stats;
                    total--;
                    state.vehicles.splice(findv, 1);
                    return {
                        ...state,
                        loading: false,
                        companies: state.companies,
                        stats: {
                            ...state.stats,
                            total
                        }
                    }
                }
            }
            return { ...state, loading: false }

        case vehicleActions.STEP_COMMAND_START:
            return {
                ...state,
                loading: true,
                addVehicle: {
                    ...state.addVehicle,
                    vehicle: action.payload
                }
            }

        case vehicleActions.STEP_COMMAND_END:
            return {
                ...state,
                loading: false,
                addVehicle: {
                    ...state.addVehicle,
                    step: 3,
                    commands: action.payload.response.commands
                }
            }



        case vehicleActions.ERROR:
            return Object.assign({}, state, {
                loading: false
            })

        case vehicleActions.CLEAR_DATA:
            if(action.payload.all) {
                return INITIAL_STATE
            }
            return {
                ...state,
                loading: false,
                addVehicle: INITIAL_STATE.addVehicle
            }

        default:
            return state;
    }
}
