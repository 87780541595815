import React, {Component} from 'react';
import {connect} from "react-redux";

import PerfectScrollbar from "react-perfect-scrollbar";
import './vehicle-history.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { SiGooglecalendar } from "react-icons/all";
import {getDate, getMonth} from "../../../../utils/moment-parser";
import DatePicker from "antd/lib/date-picker";
import {ClearHistory, GetHistoryStart, SetTimeLine} from "../../../../store/history/history.actions";
import HistoryInfoComponent from "./history-infoComponent/historyInfoComponent";
import HistoryDetailsInfo from "./history-details-info/historyDetailsInfo";
import {LoadingComponent} from "../../../../components/loading/loading";
import moment from 'moment-timezone';

class VehicleHistory extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 212}px`, swiper: null };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        const from = moment().tz(this.props.timezone).set({hour:0,minute:0,second:0,millisecond:0});
        const to = moment().tz(this.props.timezone).set({hour:23,minute:59,second:59,millisecond:0});
        this.props.dispatch(SetTimeLine({from: from}))
        this.props.dispatch(GetHistoryStart({_id: this.props._id, from: from.toDate(), to: to.toDate()}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.timeLine && prevProps.timeLine.active !== this.props.timeLine.active && this.props.timeLine.active) {
            this.state.swiper.slideTo(15 - this.props.timeLine.active);
        }

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        this.props.dispatch(ClearHistory());
    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 212}px` });
    }

    selectDay(ev) {
        if (ev.active) { return; }
       this.props.dispatch(GetHistoryStart({
            _id: this.props._id, from: ev.from.toDate(), to:  ev.to.toDate(), index: ev.index
       }));
    }

    disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment();
    }

    getSwiper() {
        if(this.props.timeLine && this.props.timeLine.times.length > 0)
            return (
                <Swiper
                    initialSlide={14}
                    spaceBetween={5}
                    slidesPerView={5}
                    rebuildOnUpdate={true}
                    onSwiper={(swiper => this.setState({swiper: swiper}))}
                >
                    {
                        this.props.timeLine.times.map((el, i) => {
                            const classHistory = el.index !== this.props.timeLine.active ? 'swiper-item' : 'swiper-item-active';
                            return (
                                <SwiperSlide key={i} onClick={() => this.selectDay(el)}>
                                    <div className={classHistory} >
                                        <label className="swiper-month">{getMonth(el.from)}</label>
                                        <div className="swiper-day">
                                            <label>{getDate(el.from)}</label>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })
                    }
                </Swiper>
                );
        return <></>;

    }

    onChange(date, dateString) {
        const from = moment(date).tz(this.props.timezone).set({hour:0,minute:0,second:0,millisecond:0});
        const to = moment(date).tz(this.props.timezone).set({hour:23,minute:59,second:59,millisecond:0});

        this.props.dispatch(SetTimeLine({from}))
        this.props.dispatch(GetHistoryStart({_id: this.props._id, from: from.toDate(), to: to.toDate()}));
    }

    render() {
        /*
        *

        *
        * */
            return (
                <div style={{display: 'flex', flexDirection: 'column', width: '270px', marginLeft: '10px', position: 'relative'}}>
                    <LoadingComponent loading={this.props.loading} left='0px' top='0px'/>
                    <div className="header-history">
                        <div className='header-timeline'>
                            {this.getSwiper()}
                            <div className='btn-calendar-history'>
                                <SiGooglecalendar />
                                <DatePicker style={{position: 'absolute', height: '100%', opacity: 0}}
                                            allowClear={false}
                                            disabledDate={this.disabledDate.bind(this)}
                                            onChange={this.onChange.bind(this)}/>
                            </div>
                        </div>
                        <HistoryInfoComponent/>
                    </div>

                        <HistoryDetailsInfo height={this.state.height}/>
                </div>

            );
    }
}

const VehicleHistoryStateToProps = (state) => {
    return {
        timezone: state.authReducer.user.timezone,
        _id: state.historyReducer._id,
        timeLine: state.historyReducer.timeLine,
        trips: state.historyReducer.trips,
        loading: state.historyReducer.loading ||state.historyReducer.traject.loading
    }
}
export default connect(VehicleHistoryStateToProps)(VehicleHistory);






