import React, {Component} from "react";
import {connect} from "react-redux";
import './timeline.css';
// import {SetActive} from "../../store/history/history.actions";
import moment from 'moment-timezone';
import {SetActiveEnd, SetActiveStart} from "../../store/history/history.actions";
const d3 = require('d3');


class D3Timeline extends Component{
    constructor(props) {
        super(props);
        this.init = this.init.bind(this);
        this.drawV2 = this.drawV2.bind(this);
    }
    componentDidMount() {
        this.init();
        this.drawV2();
    }

    init() {
        // data
        this.items = this.props.history.trips;
        const date = this.items[0].start.toDate();
        this.timezone = this.items[0].start._z.name;
        this.dates = [];
        this.f = 20;
        for (let i = 0; i < 13; i++) {
            if (i !== 12) {
                const hour = i * 2;
                const dateTimezone = moment(date).tz(this.timezone)
                    .set({hour, minute: 0, second: 0, millisecond: 0});
                this.dates.push(dateTimezone.toDate());
            } else {
                const d = moment(this.dates[0]).tz(this.timezone);
                d.add(1, 'd');
                this.dates.push(d.toDate());
            }
        }
        this.timeBegin=  this.dates[0];
        this.timeEnd= this.dates[this.dates.length - 1];
    }

    drawV2() {
        const laneLength = 1;
        const f = 20;
        const m = [20, 15, 15, 15]; // top right bottom left
        const h = 210 - m[0] - m[2];
        this.miniHeight = laneLength * 10 + 50;
         this.mainHeight = h - this.miniHeight - 50;
        const lane = 0;
        this.x = d3.scaleLinear()
            .domain([this.timeBegin, this.timeEnd])
            .range([0, this.props.w]);
        this.x1 = d3.scaleLinear()
            .domain([this.timeBegin, this.timeEnd])
            .range([0, this.props.w]);
        this.y1 = d3.scaleLinear()
            .domain([0, laneLength])
            .range([0, this.mainHeight]);
        this.y2 = d3.scaleLinear()
            .domain([0, laneLength])
            .range([0, this.miniHeight]);
        this.x1Axis = d3.axisBottom(this.x1)
            .tickFormat((d) => {
                const date = moment(d).tz(this.timezone);
                return date.format('HH:mm')
            })
            .tickValues(this.dates)
            .tickPadding(1);

        this.chart = d3.select('.timeLine')
            .append('svg')
            .attr('width', this.props.w + m[1] + m[3])
            .attr('height', h + m[0] + m[2])
            .attr('class', 'chart');

        this.chart.append('defs').append('clipPath')
            .attr('id', 'clip')
            .append('rect')
            .attr('width', this.props.w)
            .attr('height', this.mainHeight);

        const main = this.chart.append('g')
            .attr('transform', `translate(${m[3]}, ${-20})`)
            .attr('width', this.w)
            .attr('height', this.mainHeight)
            .attr('class', 'main');
        this.mini = this.chart.append('g')
            .attr('transform', `translate(${m[3]}, ${this.mainHeight - 25})`)
            .attr('width', this.props.w)
            .attr('height', this.miniHeight)
            .attr('class', 'mini');
        this.focus = main.append('g')
            .attr('class', 'focus');
        this.focus.append('g')
            .attr('class', 'x1 axis axisRed')
            .attr('transform', 'translate(' + 0 + ',' + (this.mainHeight + 5) + ')')
            .call(this.x1Axis);

        this.itemRects = main.append('g')
            .attr('clip-path', 'url(#clip)');

        // mini item rects
        this.mini.append('g').selectAll('miniItems')
            .data(this.items)
            .enter().append('rect')
            .attr('class', d => d.on ? 'on' : 'off')
            .attr('x', (d) => this.x(d.start))
            .attr('y', (d) => this.y2(0 + .5) - 5 + this.f)
            .attr('width', (d) => this.x(d.end) - this.x(d.start))
            .attr('height', 10);

        // brush
        this.brush = d3.brushX()
            .extent([[0, 0], [this.props.w, this.miniHeight]])
            .on('brush', () => {
                    this.display();
                }
            );

        this.mini.append('g')
            .attr('class', 'x brush')
            .call(this.brush)
            .selectAll('rect')
            .attr('y', 21)
            .attr('transform', 'translate(' + 0 + ',' + (this.miniHeight - 28) + ')');

       this.display();
    }


    display() {
        let data;
        if (d3.event) {
            data = d3.event.selection.map(this.x.invert);
            this.x1Axis = d3.axisBottom(this.x1)
                .tickFormat(d3.timeFormat('%H:%M'))
                .tickPadding(1);
        }
        if (this.x1) {
            let rects;
            const minExtent = !d3.event ? this.x1.domain()[0] : data[0];
            const maxExtent = !d3.event ? this.x1.domain()[1] : data[1];

            const visItems = this.items.filter(d => d.start < maxExtent && d.end > minExtent);
            this.mini.select('.brush')
                .call(this.brush.extent([[0, 0], [this.props.w, this.miniHeight - 30]]));

            this.x1.domain([minExtent, maxExtent]);

            // @ts-ignore
            this.focus.select('.x1.axis')
                .call(this.x1Axis);


            // update main item rects
            rects = this.itemRects.selectAll('rect')
                .data(visItems, d => d.start.tz(this.items[0].start._z.name).toDate())
                .attr('x', d => this.x1(d.start.tz(this.items[0].start._z.name).toDate()))
                .attr('width', d => this.x1(d.end.tz(this.items[0].start._z.name).toDate()) - this.x1(d.start.tz(this.items[0].start._z.name).toDate()));

            rects.enter().append('rect')
                .attr('class', d => d.on ? 'on' : 'off')
                .attr('x', d => this.x1(d.start.tz(this.items[0].start._z.name).toDate()))
                .attr('y', d => this.y1(0) + 10 + this.f)
                .attr('width', d => this.x1(d.end.tz(this.items[0].start._z.name).toDate()) - this.x1(d.start.tz(this.items[0].start._z.name).toDate()))
                .attr('height', d =>  0.8 * this.y1(1))
                .on('click', (el, index) => {
                    if(el.on) {
                        this.props.dispatch(SetActiveStart({index, data: el}));
                    } else {
                        this.props.dispatch(SetActiveEnd({index, traject: el.posEnd, on: el.on}));
                    }
                });


            rects.exit().remove();
        }

    }

    render() {
        return( <div  className="timeLine"/> );
    }
}

const MapStateToProps = (state) => {
    return { history: state.historyReducer }
}
export default connect(MapStateToProps)(D3Timeline);
