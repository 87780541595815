import React from "react"
import {useSelector} from "react-redux";
import '../generate-report.css';
import {MainReportStep1} from "./main-step-1";
import {MainReportStep2} from "./main-step-2";

export const MainReportSteps = () => {

    const {step} = useSelector(state => {
        const reports = state.reportsReducer;
        return {
            steps: reports.addReport.steps,
            step : reports.addReport.step
        };
    });

    switch (step) {
        case 1:
            return <MainReportStep1/>;
        case 2:
            return <MainReportStep2/>;
        default:
            return <></>
    }
}
