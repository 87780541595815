import {Button} from "../../../../components/widgets/form/button/button";
import React from "react";
import {BiMailSend} from "react-icons/all";
import './general-info.css'
import {useForm} from "react-hook-form";
import {SetStepData, ToogleActive} from "../../../../store/maintenance/maintenance.actions";
import { validateText} from "../../../../validation";
import {useDispatch, useSelector} from "react-redux";

export const InfoGeneralCompoment = ({validate}) => {
    const dispatch = useDispatch();
    const onChange = (dates, dateStrings) => { }

    const { step, data } = useSelector(state => {
        return {
            data: state.maintenanceReducer.addMaintenance.data,
            step: state.maintenanceReducer.addMaintenance.step,
        }
    });

    const { register, handleSubmit, setError, errors, watch  } = useForm(
        { mode: "onChange",
            defaultValues: {
                name: data.name,
                description: data.description
            }
        },
    );

    const onSubmit = async(data) => {
        dispatch(SetStepData({data, step: step+1}));
    };



    const sName = !errors.name ? 'input-form' : 'input-form input-form-error';
    const sDescription = !errors.description ? 'input-form' : 'input-form input-form-error';
    const toogleAdd = () => dispatch(ToogleActive());

    return(
        <div className="step1-maintenance">
            <form className="form-maintenance" onSubmit={handleSubmit(onSubmit)}>
                <div className={sName}>
                    <input  name="name" placeholder="Nom du service" ref={register(validateText(true))} />
                    <BiMailSend/>
                    {errors.name && <p className="error-input">{errors.name.message}</p>}
                </div>
                <div className={sDescription}>
                    <input name="description" placeholder="Description" ref={register(validateText(true))}/>
                    <BiMailSend/>
                    {errors.description && <p className="error-input">{errors.description.message}</p>}
                </div>
                <div className="actions">
                    <Button type='btn-accent' name='Annuler' press={toogleAdd}/>
                    <Button type='btn-primary' name='Suivant'/>
                </div>
            </form>
        </div>
    );
}
