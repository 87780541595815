import React, { Component }  from 'react';
import './header-action.css';

export class HeaderAction extends Component {

    click() {
        if(this.props.press && this.props.value && !this.props.active) {
            this.props.press(this.props.value);
        }
    }

    render() {
        const ICON = this.props.icon;
        const styleBtn = !this.props.active ? 'action-header' : 'action-header action-header-active';
        return (
            <div className={styleBtn} onClick={this.click.bind(this)}>
                <ICON/>
            </div>
        );
    }
}