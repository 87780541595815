import React, {useState} from 'react';
import './step1.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useForm } from "react-hook-form";
import {Button} from "../../../../components/widgets/form/button/button";
import {useDispatch, useSelector} from "react-redux";
import {SaveDriverEnd, SaveDriverStart, SetStepData, ToogleStep} from "../../../../store/driver/drivers.actions";
import { Select } from 'antd';



import {AiOutlineInfoCircle} from "react-icons/ai";
import {FaRegAddressCard, FiPhone} from "react-icons/all";
import {fetchOneIdentification} from "../../driversService";

const { Option } = Select;

export default function Step1() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {token, driver} = useSelector(state => {
        return {
            token: state.authReducer.token,
            timezone: state.authReducer.user.timezone,
            driver: state.driversReducer.addDriver.data
        }
    });
    const { register, handleSubmit, setError, errors, formState  } = useForm(
        {
            mode: "onChange",
            defaultValues: {
                id: driver.id,
                name: driver.name,
                phone: driver.phone,
                identification: driver.identification,

            }
        },
    );

    const onSubmit = async(data) => {
        setLoading(true);

        if(!data.phone) { delete data.phone; }
        if(!data.identification) {
            delete data.identification;
            dispatch(SaveDriverStart(data));
        } else if(data.identification !== driver.identification){
            const exist = await fetchOneIdentification(data.identification, token);
            if(exist) {
                setError('identification', {message: 'Cet identifiant éxiste déjà !', type:'isUnique'});
            } else {
                dispatch(SaveDriverStart(data));
            }
        } else {
            dispatch(SaveDriverStart(data));
        }


        setLoading(false);
    };

    const onChange = (description) => { dispatch(SetStepData({step: 1, data: {description}})); }
    const previous = () => { dispatch(ToogleStep()); };


    const styleName = !errors.name ? 'input-form' : 'input-form input-form-error';
    const styleIdentification = !errors.identification ? 'input-form' : 'input-form input-form-error';
    const stylePhone = !errors.phone ? 'input-form' : 'input-form input-form-error';

    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={styleIdentification}>
                    <input
                        name="identification" placeholder="Identifiant (RFID/IBUTTON)"
                        ref={register({
                            minLength: {value: 16, message: 'La taille doit être égale à : 16'},
                            maxLength: {value: 16, message: 'La taille doit être égale à : 16'},
                        })}
                    />
                    <FaRegAddressCard/>
                    {errors.identification && <p className="error-input">{errors.identification.message}</p>}
                </div>
                <div className={styleName}>
                    <input
                        name="id" hidden={true}
                        ref={register}
                    />
                    <input
                        name="name" placeholder="Nom complet du conducteur"
                        ref={register(
                            {
                                required: {value: true, message: 'Ce champs est obligatoire'},
                                maxLength: {value: 20, message: 'La taille maximum est: 20'},
                            }
                        )}
                    />
                    <AiOutlineInfoCircle/>
                    {errors.name && <p className="error-input">{errors.name.message}</p>}
                </div>
                <div className={stylePhone}>
                    <input
                        name="phone" placeholder="Numèros du téléphone"
                        ref={register(
                            {
                                maxLength: {value: 20, message: 'La taille maximum est: 20'},
                            }
                        )}
                    />
                    <FiPhone/>
                    {errors.phone && <p className="error-input">{errors.phone.message}</p>}
                </div>

            </div>

            <div className="actions">
                <Button type='btn-accent' press={previous} name='Fermer'/>
                <Button type='btn-primary' name='Terminer' loading={loading}/>
            </div>
        </form>
    );
}
