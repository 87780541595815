import React, { Component }  from 'react';
import './action-popover.css';
import {AiOutlineEdit} from "react-icons/ai";

export class ActionPopover extends Component {

    constructor(props) {
        super(props);
    }


    click() {
        if(this.props.press && this.props.value) {
            this.props.press(this.props.value);
        }
    }


    render() {
        const ICON = this.props.icon;
        return(
            <div className="action-popover" onClick={this.click.bind(this)}>
                <ICON />
                <label className="action-label">{this.props.label}</label>
            </div>
        );

    }
}