import * as adminActions from './admin.actions';


const INITIAL_STATE = {
    io: {
        list: []
    },
    loading: false,
    companyError: null
}


export function adminReducer(state = INITIAL_STATE, action){
    switch (action.type) {

        case adminActions.GET_START_IOS:
        case adminActions.REMOVE_START_IO:
            return {
                ...state, loading: true,
            }

        case adminActions.GET_END_IOS:{
            const list =  action.payload;
            return {
                ...state,
                loading: false,
                io: { list, active: list.length > 0 ? list[0].active : false }
            }
        }


        case adminActions.REMOVE_END_IO:
            if(action.payload.res.matchedCount) {
                const list = state.io.list.filter(el => el.io !== action.payload.io);
                return {
                    ...state,
                    loading: false,
                    io: { list }
                }
            }
            return {
                ...state,
                loading: false
            };


        case adminActions.ERROR:
            return{
               ...state,
                loading: false
            }

        case adminActions.CLEAR_DATA:
            return INITIAL_STATE
        default:
            return state;
    }
}
