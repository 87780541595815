import {BASE_URL} from "../../../env";
const axios = require('axios');

export function fetchData(payload, token) {
    const url = `${BASE_URL}/api/vehicle/${payload.id}/devicemanager?from=${payload.from.getTime()}&to=${payload.to.getTime()}&limit=10000&skip=0`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}



