import './maintenance.css';
import React, {useEffect, useState} from 'react';
import {AiOutlinePlus} from "react-icons/ai";
import PerfectScrollbar from 'react-perfect-scrollbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import {useDispatch, useSelector} from "react-redux";
import {HeaderAction} from "../../components/header/header-action/header-action";
import {LoadingComponent} from "../../components/loading/loading";

import { Popover, Table} from "antd";
import moment from "moment-timezone";
import {AddMaintenanceCompoment} from "./add-maintenance";
import {DeleteStart, GetStart, SetUpdate, ToogleActive} from "../../store/maintenance/maintenance.actions";
import {AiOutlineDelete, AiOutlineEdit, AiOutlineReload, VscDebugAlt2} from "react-icons/all";
import {FiMoreVertical} from "react-icons/fi";
import {ActionPopover} from "../../components/widgets/action-popover/action-popover";
import {ConfirmationDialog} from "../../components/confirmation-dialog/confirmation-dialog";
import {confirmAlert} from "react-confirm-alert";



export const MaintenancePage = ({match}) => {
    const {id} = match.params
    const dispatch = useDispatch();

    const {timezone, dataSource, km, loading, show} = useSelector(state => {
        return {
            show: state.maintenanceReducer.addMaintenance.show,
            km: state.maintenanceReducer.km,
            dataSource: state.maintenanceReducer.maintenances,
            timezone: state.authReducer.user.timezone,
            loading: state.maintenanceReducer.loading
        }
    });


    const toogleAdd = () => dispatch(ToogleActive());
    const reload = () => dispatch(GetStart({id}));

    useEffect(()=> {
        dispatch(GetStart({id}))
    }, [dispatch, id]);
    const deleteMaintenance = (idMaintenance) => {
        dispatch(DeleteStart({id: idMaintenance, vehicleId:id}))
    };

    const preDelete = (action) => {
        const options = {
            customUI: ({ onClose }) =>
                <ConfirmationDialog
                    close={onClose}
                    action={deleteMaintenance}
                    id={action.id}
                    keyword={action.name}
                    ActionName={"Supprimer"}/>,
            closeOnEscape: false,
            closeOnClickOutside: false
        };
        confirmAlert(options);
    };
    const update = (maintenance) => dispatch(SetUpdate(maintenance));

    const content = (maintenance) =>{
        return <>
            <ActionPopover icon={AiOutlineEdit} label="Modifier" press={()=>update(maintenance)} value={{action: 'edit'}}/>
            <ActionPopover icon={AiOutlineDelete} press={preDelete} label="Supprimer" value={{action: 'del', name: maintenance.name, id: maintenance._id}}/>
        </>
    }
    const columns = [
        {
            title: '#',
            dataIndex: 'actions',
            key: 'actions',
            width: 40,
            render: (text, maitenance) => {
                return(
                    <div className="more-options">
                        <Popover placement="rightTop" content={()=>content(maitenance)} text={text} trigger="hover">
                            <FiMoreVertical />
                        </Popover>
                    </div>

                )
            }
        },
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Etat',
            dataIndex: 'address',
            key: 'address',
            render: (text, maitenance) => {
                const render = [];

                if(maitenance.km) {
                    const maintainKm = maitenance.km.lastServiceMielage + maitenance.km.intervalKm;
                    const result = maintainKm - km;
                    if(result < 0) {
                        render.push(<label style={{color: 'red'}}>Expiré depuis {maintainKm.toFixed(2)} km</label>)
                    } else {
                        render.push(<label style={{color: 'green'}}>Expire dans {result.toFixed(2)} km</label>)
                    }
                }

                if(maitenance.day) {
                    const now = moment.tz(timezone).set({hour:0,minute:0,second:0,millisecond:0});
                    const expiration = moment(maitenance.day.lastServiceDay).tz(timezone).set({hour:0,minute:0,second:0,millisecond:0}).add(maitenance.day.intervalDay, 'days');
                    const result = expiration.diff(now, 'days');
                    if(result < 0) {
                        render.push(<label style={{color: 'red'}}>{maitenance.km ? ', ': ''}Expiré depuis {result *-1} Jours</label>)
                    } else {
                        render.push(<label style={{color: 'green'}}>{maitenance.km ? ', ': ''}Expire dans {result} Jours</label>)
                    }
                }

                return(<> {render} </>)
            },
        }


    ];


    const [height, setHeight] = `${window.innerHeight - 176}px`;


    return (<section id="maitenance">
        <LoadingComponent loading={loading}/>
        <div className="header-section">
            {
                !show &&
                    <>
                        <div className="filter">

                        </div>
                        <div className="action-right">
                            <HeaderAction
                                value={{action: 'reload'}}
                                press={reload}
                                icon={AiOutlineReload}
                            />
                            <HeaderAction
                                value={{action: 'add'}}
                                press={toogleAdd}
                                icon={AiOutlinePlus}
                            />
                        </div>
                    </>
            }
        </div>
        <div style={{display: 'flex'}}>
            <PerfectScrollbar className="scrollbar-container-vehicle animmation-scroll" style={{ height }}>
                {
                    !show ?
                        <div className="body">
                            <Table scroll={{ y: window.innerHeight - 170 }}
                                   className="table-report"
                                   dataSource={dataSource}
                                   columns={columns}
                                   pagination={false} size="small"
                            />
                        </div>
                        :
                    <AddMaintenanceCompoment id={id}/>
                }
            </PerfectScrollbar>
        </div>
    </section>);

}
