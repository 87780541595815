import React, {PureComponent} from 'react';
import {GoogleLayer} from "react-leaflet-google-v2";

export class GoogleSatellite extends PureComponent {

    constructor(props) { super(props); }

    render() {
        return (
            <GoogleLayer googlekey={this.props.googlekey}  maptype={'SATELLITE'}/>
        );
    }
}