const findRole = (name, where) => {
    return where.findIndex(r => r === name) !== -1;
}

export const canShowRole = (userType, roles) => {
    return userType === 'admin' || findRole('role:wt', roles) || findRole('role:dt', roles)
}

export const canManageRole = (userType, roles) => {
    return userType === 'admin' || findRole('role:wt', roles);
}

export const canDeleteRole = (userType, roles) => {
    return userType === 'admin' || findRole('role:dt', roles)
}

export const isLocation = (location) => !!location;

