const findRole = (name, where) => {
    return where.findIndex(r => r === name) !== -1;
}

export const canShowGrp = (userType, roles) => {
    return userType === 'admin' || findRole('group:rd', roles)
        || findRole('group:wt', roles)|| findRole('group:dt', roles)
}

export const canManageGrp = (userType, roles) => {
    return userType === 'admin' || findRole('group:wt', roles);
}

export const canDeleteGrp = (userType, roles) => {
    return userType === 'admin' || findRole('group:dt', roles)
}
