import React, {Component, useState} from 'react';
import './role.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useForm } from "react-hook-form";
import {Button} from "../../../components/widgets/form/button/button";
import {useDispatch, useSelector} from "react-redux";
import {SideBar} from "../../../components/sideBar/sideBar";
import {FaShieldAlt} from "react-icons/all";
import {CanDelete, SaveStart, SetAdd} from "../store/role.actions";
import {LoadingComponent} from "../../../components/loading/loading";
import {Checkbox} from "../../../components/widgets/form/checkbox/checkbox";

function checkTracer(tracer, vehicle) {
    if(vehicle.id && (!tracer || vehicle.tracer && vehicle.tracer === tracer)){
        return false;
    }
    return true;
}
export default function AddRole(props) {
    const dispatch = useDispatch();
    const {loading, active, canDelete} = useSelector(state => state.roleReducer.add);
    const { register, handleSubmit, setError, errors, formState  } = useForm({ mode: "onChange"});

    if(active) {
        const onSubmit = async(data) => {
            dispatch(SaveStart(data));
        };
        const previous = () => {
            dispatch(SetAdd(false));
        };
        const styleName = !errors.name ? 'input-form' : 'input-form input-form-error';

        return (
            <SideBar
                height={window.innerHeight}
                countSteps={1}
                step={1}
                stepsText={[{current: 'Ajouter un nouveau rôle', next: 'Terminer'}]}
            >
                <LoadingComponent loading={loading}/>
                <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <div className={styleName}>
                            <input
                                name="name" placeholder="Nom du rôle"
                                ref={register(
                                    {
                                        required: {value: true, message: 'Ce champs est obligatoire'},
                                        minLength: {value: 4, message: 'La taille minimum est: 4'}
                                    }
                                )}
                            />
                            <FaShieldAlt/>
                            {errors.name && <p className="error-input">{errors.name.message}</p>}
                        </div>
                        <Checkbox name="canDelete" press={()=>dispatch(CanDelete())} checked={canDelete}/>

                    </div>

                    <div className="actions">
                        <Button type='btn-accent' press={previous} name='Fermer'/>
                        <Button type='btn-primary' name='Terminer' loading={loading}/>
                    </div>
                </form>
            </SideBar>
        );
    }
    return <></>

}
