import React, {useEffect, useRef} from 'react';
import ApexCharts from "apexcharts";

export const ReactApexChart = ({id, series, options}) => {
    const chart = useRef(null);
    const idApex = `apex-${id}`;

    useEffect(() => {
        chart.current = new ApexCharts(document.querySelector(`#${idApex}`), options);
        chart.current .render()
    }, []);

    useEffect(()=> {
        if(chart.current) {
            chart.current.updateSeries(series);
            /*chart.current.updateOptions({ annotations: {
                        yaxis: [
                            {
                                y: new Date('2021-07-01').getTime(),
                                borderColor: '#00E396',
                                label: {
                                    borderColor: '#00E396',
                                    style: {
                                        color: '#fff',
                                        background: '#00E396'
                                    },
                                    text: 'Y-axis annotation on 8800'
                                }
                            }
                        ]
                    }})*/
        }
    }, [series]);

    return ( <div id={idApex}></div> );

}

