import './group.css';
import React, { Component }  from 'react';
import {confirmAlert} from "react-confirm-alert";
import {AiOutlinePlus, AiOutlineReload, AiOutlineSearch} from "react-icons/ai";
import PerfectScrollbar from 'react-perfect-scrollbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import {connect} from "react-redux";
import {GetVehiclesStart, DeleteVehicleStart} from "../../store/vehicles/vehicles.actions";
import {HeaderAction} from "../../components/header/header-action/header-action";
import {SearchAction} from "../../components/header/search-action/search-action";
import {LoadingComponent} from "../../components/loading/loading";
import {Paginator} from "../../components/pagination/paginator/Paginator";
import {SideBar} from "../../components/sideBar/sideBar";
import Group from "./add-group/group";
import {BlankComponent} from "../../components/blank/blank";
import {ConfirmationDialog} from "../../components/confirmation-dialog/confirmation-dialog";
import {GroupInfo} from "../../components/group/group-info/group-info";
import {GetGroupsStart, ToogleStep, SetStepData, deleteGroupStart} from "../../store/group/group.actions";
import {GetCommandsStart} from "../../store/commands/command.actions";
import {AccountInfo} from "../../components/account/account-info";


class GroupsPage extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 176}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    componentDidMount() {
        this.props.dispatch(GetGroupsStart({}));
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 176}px` });
    }

    findGroupById(id){
        return this.props.groups.find(el => id === el._id);
    }

    deleteAction(id) {
        this.props.dispatch(deleteGroupStart(id));
    }
    /*
     * Events
     * */

    groupAction(action) {
        switch (action.action) {
            case 'reload':
                this.props.dispatch(GetGroupsStart({}))
                break;
            case 'add':
                this.props.dispatch(ToogleStep());
                break;
            case 'del':
                const options = {
                    customUI: ({ onClose }) =>
                        <ConfirmationDialog
                            close={onClose}
                            action={this.deleteAction.bind(this)}
                            id={action.id}
                            keyword={action.name}
                            ActionName={"Supprimer"}/>,
                    closeOnEscape: false,
                    closeOnClickOutside: false
                };

                confirmAlert(options);
                break;
            case 'edit':
                const group = this.findGroupById(action.id);
                if(group) {
                    this.props.dispatch(SetStepData({
                        step: 1,
                        data: {
                            id: group._id,
                            name: group.name
                        }
                    }));
                }
                break;
        }
    }

    filterAction(action) {
        if (action.key === 'name') {
            this.props.dispatch(GetGroupsStart({
                name: action.value,
                skip: 0
            }));
        }
    }

    paginate(skip, limit) {
        this.props.dispatch(GetGroupsStart({skip: skip}));
    }

    render() {
        const params= this.props.filters.params;
        return (
            <section id="groups">
                <LoadingComponent loading={this.props.loading}/>
                <div className="header-section">
                    <div className="action-right">
                        <HeaderAction
                            value={{action: 'reload'}}
                            press={this.groupAction.bind(this)}
                            icon={AiOutlineReload}
                        />
                        <HeaderAction
                            value={{action: 'add'}}
                            press={this.groupAction.bind(this)}
                            icon={AiOutlinePlus}
                        />
                        <SearchAction
                            icon={AiOutlineSearch}
                            indentifier="name"
                            onChange={this.filterAction.bind(this)}
                        />
                    </div>

                </div>
                <div style={{display: 'flex'}}>
                    <PerfectScrollbar className="scrollbar-container-group animmation-scroll" style={{ height: this.state.height }}>
                        <div className="body">
                            {
                                this.props.groups && this.props.groups.length > 0  ? this.props.groups.map((group, i) => {
                                    return <GroupInfo
                                        key={i}
                                        group={group}
                                        press={this.groupAction.bind(this)}
                                    />
                                }) : !this.props.loading && <BlankComponent/>
                            }
                        </div>

                        <Paginator paginate={this.paginate.bind(this)} limit={params.limit} skip={params.skip} total={this.props.total}/>

                    </PerfectScrollbar>
                    {
                        this.props.addGroup.step ?
                            <SideBar
                                height={window.innerHeight}
                                countSteps={1}
                            step={this.props.addGroup.step}
                            stepsText={this.props.addGroup.steps}
                        >
                            { this.props.addGroup.step === 1 && <Group
                                data={this.props.addGroup.data}
                            /> }
                        </SideBar> : <></>
                    }
                </div>
            </section>
        );
    }
}

const groupsStateToProps = (state) => {
    return {
        groups: state.groupReducer.groups,
        filters: state.groupReducer.filters,
        loading: state.groupReducer.loading,
        addGroup: state.groupReducer.addGroup,
        total: state.groupReducer.total
    }
}
export default connect(groupsStateToProps)(GroupsPage);