import React, {Component, useEffect, useRef, useState} from 'react';
import "react-perfect-scrollbar/dist/css/styles.css";
import ApexCharts from "apexcharts";
import {ReactApexChart} from "../components/apex-chart";

export const SignalGraph = ({data}) => {

    const [series, setSeries] = useState([]);
    const [options] = useState({
        series: [{ data: [] }],
        chart: {
            id: 'signal',
            group: 'social',
            type: 'line',
            height: 160,
            animations: {
                enabled: false,},
            dataLabels: {
                enabled: false
            }
        },
        xaxis: {  type: 'datetime', },
        yaxis: {
            title: {
                text: 'Signal',
            },
        },
        stroke: {
            width: 2
        },
    });

    useEffect(()=> {
        setSeries([{
            data: data.map(info => [new Date(info.gpsDate), info.signalGSM !== undefined ? info.signalGSM : null])
        }]);
    }, [data]);

    return <ReactApexChart id="signal" series={series} options={options}/>;

}

