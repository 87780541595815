import React, {PureComponent} from "react";
import "./alert.css"

export class Alert extends PureComponent{
    constructor(props) {
        super(props);
    }
    render() {
        const ICON = this.props.icon;
        return(
            <>
                { this.props.msg &&
                    <div className={`alert alert-${this.props.type}`}>
                        {ICON && <ICON/>}
                        <label>
                            {this.props.msg}
                        </label>
                    </div>
                }
            </>
            );


    }
}