import React, { Component }  from 'react';
import './dashborad.css';
import {StatNumber} from "../../components/widgets/statsNumber/statNumber";

export class DashboardPage extends Component {
    render() {
        return (
            <section className="dashboard">
                <div className="row">
                    <StatNumber
                        img="/img/dashboard/km.svg"
                        name="Distance parcourus"
                        value={123.3}
                        unit="km"
                    />
                    <StatNumber
                        img="/img/dashboard/trips.svg"
                        name="Nombre de trajets"
                        value={25}
                        unit="Trajets"
                    />
                    <StatNumber
                        img="/img/dashboard/speed.svg"
                        name="Vitesse moyenne"
                        value={101}
                        unit="km"
                    />
                    <StatNumber
                        img="/img/dashboard/cars.svg"
                        name="Nombre de véhicules"
                        value={12}
                    />
                </div>

            </section>
        );
    }
}