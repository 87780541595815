import React, {Component} from "react";
import './confirmation-dialog.css'
import {Button} from "../widgets/form/button/button";

export class ConfirmationDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {disable: true};
    }

    componentDidMount() { }

    onClose() {
        this.props.close && this.props.close();
    }

    delete() {
        if(this.props.action) {
            this.props.id && this.props.action(this.props.id);
            this.props.close && this.props.close();
        }

    }

    keyUp(event) {
        this.setState({disable : event.target.value !== this.props.keyword});
    }

    render() {
        return (
            <div className="confirmation">
                <div className="title">
                    <h2>Confirmation</h2>
                </div>
                <div className="content" style={{fontWeight: 'lighter'}}>
                    <p> Pour confirmer, merci d'écrire le nom
                        <span style={{color: "#f44336", fontWeight: 600, marginLeft: 3}}>
                            {this.props.keyword}
                        </span>
                    </p>
                    <input type="text" onKeyUp={this.keyUp.bind(this)}/>
                </div>
                <div className="footer">
                    <Button name="Annuler" type="btn-accent" press={this.onClose.bind(this)}/>
                    <Button name={this.props.ActionName} press={this.delete.bind(this)} disabled={this.state.disable} type="btn-primary"/>
                </div>
            </div>
        );
    }
}