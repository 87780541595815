import React from 'react';
import { Marker as LeafletMarker } from 'leaflet';
import { LeafletProvider, withLeaflet, MapLayer } from 'react-leaflet';
import './lib/drift-marker';
import 'leaflet-rotatedmarker';

class RotatedMarker extends MapLayer {
    static defaultProps = {
        rotationOrigin: 'center',
    };

    createLeafletElement(props) {
        const el = new LeafletMarker(props.position, this.getOptions(props));
        this.contextValue = { ...props.leaflet, popupContainer: el };
        return el;
    }

    updateLeafletElement(fromProps, toProps) {
        if (toProps.position !== fromProps.position) {
            // const duration = duration;
            this.leafletElement.slideTo(toProps.position, {
                duration: this.props.duration,
                keepAtCenter: this.props.keepAtCenter || false
            })
        }
        if (toProps.icon !== fromProps.icon) {
            this.leafletElement.setIcon(toProps.icon);
        }
        if (toProps.zIndexOffset !== fromProps.zIndexOffset) {
            this.leafletElement.setZIndexOffset(toProps.zIndexOffset);
        }
        if (toProps.opacity !== fromProps.opacity) {
            this.leafletElement.setOpacity(toProps.opacity);
        }
        if (toProps.draggable !== fromProps.draggable) {
            if (toProps.draggable === true) {
                this.leafletElement.dragging.enable();
            } else {
                this.leafletElement.dragging.disable();
            }
        }
        if (toProps.rotationAngle !== fromProps.rotationAngle) {
            this.leafletElement.setRotationAngle(toProps.rotationAngle);
        }
        if (toProps.rotationOrigin !== fromProps.rotationOrigin) {
            this.leafletElement.setRotationOrigin(toProps.rotationOrigin);
        }
    }

    render() {
        const { children } = this.props;
        return children == null || this.contextValue == null ? null : (
            <LeafletProvider value={this.contextValue}>{children}</LeafletProvider>
        );
    }
}

export default withLeaflet(RotatedMarker);