export const GET_START = '[ALARMS_MAP] GET_START';
export const GET_END = '[ALARMS_MAP] GET_END';

export const DELETE_END = '[ALARMS_MAP] DELETE_END';
export const DELETE_START = '[ALARMS_MAP] DELETE_START';

export const SAVE_START = '[ALARMS_MAP] SAVE_START';
export const SAVE_END = '[ALARMS_MAP] SAVE_END';

export const GET_UPDATE_START = '[ALARMS_MAP] GET_UPDATE_START';
export const GET_UPDATE_END = '[ALARMS_MAP] GET_UPDATE_END';

export const GET_ALARM_START = '[ALARMS_MAP] GET_ALARM_START';
export const GET_ALARM_END = '[ALARMS_MAP] GET_ALARM_END';

export const SET_TIMES = '[ALARMS_MAP] SET_TIMES';

export const TOOGLE_STEP = '[ALARMS_MAP] TOOGLE_STEP';
export const SET_STEPS = '[ALARMS_MAP] SET_STEPS';
export const SET_STEP_DATA = '[ALARMS_MAP] SET_STEP_DATA';
export const RESET_STEP = '[GROUP] RESET_STEP';

export const SET_ACTIVE_CHOICE = '[ALARMS_MAP] SET_ACTIVE_CHOICE';

export const SELECT_VEHICLE = '[ALARMS_MAP] SELECT_VEHICLE';
export const SELECT_GROUP = '[ALARMS_MAP] SELECT_GROUP';
export const SELECT_ZONE = '[ALARMS_MAP] SELECT_ZONE';

export const CLEAR_ALARMS = '[ALARMS_MAP] CLEAR_ALARMS';

export const ERROR = '[ALARMS_MAP] ERROR';

export const SET_ACTIVE = '[ALARMS_MAP] SET_ACTIVE';

export function ResetStep() {
    return { type: RESET_STEP }
}

export function SelectVehicleAlarm(payload){
    return { type: SELECT_VEHICLE, payload }
}

export function SelectGroupAlarm(payload){
    return { type: SELECT_GROUP, payload }
}

export function GetAlarmsStart(payload) {
    return { type: GET_START, payload }
}

export function GetAlarmsEnd(payload) {
    return { type: GET_END, payload }
}

export function GetAlarmStart(payload) {
    return { type: GET_ALARM_START, payload }
}

export function GetAlarmEnd(payload) {
    return { type: GET_ALARM_END, payload }
}

export function SetTimes(payload) {
    return { type: SET_TIMES, payload }
}

export function Error(msg) {
    return { type: ERROR, msg }
}

export function SetActive(payload) {
    return { type: SET_ACTIVE, payload }
}

export function ToogleStep() {
    return { type: TOOGLE_STEP }
}

export function SetSteps() {
    return { type: SET_STEPS }
}

export function SetActiveChoice(payload) {
    return { type: SET_ACTIVE_CHOICE, payload }
}

export function SetStepData(payload) {
    return { type: SET_STEP_DATA, payload }
}

export function SelectZone(payload) {
    return { type: SELECT_ZONE, payload }
}

export function SaveStart() {
    return { type: SAVE_START }
}

export function SaveEnd(payload) {
    return { type: SAVE_END, payload }
}

export function DeleteStart(payload) {
    return { type: DELETE_START, payload }
}

export function DeleteEnd(payload) {
    return { type: DELETE_END, payload }
}

export function GetAlarmUpdateStart(payload) {
    return { type: GET_UPDATE_START, payload }
}

export function GetAlarmUpdateEnd(payload) {
    return { type: GET_UPDATE_END, payload }
}

export function ClearAlarms() {
    return { type: CLEAR_ALARMS }
}



