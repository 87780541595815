import React, {PureComponent} from "react";
import {MdTrackChanges} from "react-icons/md";
import './event-type.css'
import {
    MdPanTool,
    AiOutlineQuestionCircle,
    IoMdFlashOff,
    IoMdSpeedometer,
    FaCarCrash,
    GiNinjaMask
} from "react-icons/all";

export class AlarmTypeInfo extends PureComponent {

    constructor(props) { super(props); }
    press() {
        if(this.props.press) {
            this.props.press(this.props.alarm);
        }
    }

    componentDidMount() { }

    getIcon() {
        switch (this.props.alarm.icon) {
            case 'speed':
                return IoMdSpeedometer;
            case 'pan_tool':
                return MdPanTool;
            case 'track_changes':
                return MdTrackChanges;
            case 'security':
                return FaCarCrash;
            case 'flash_off':
                return IoMdFlashOff;
            case 'GiNinjaMask':
                return GiNinjaMask
            default:
                return AiOutlineQuestionCircle;
        }
    }

    render() {
        const alarm = this.props.alarm;
        const alwaysActive = alarm.active ? 'always' : '';

        const ICON = this.getIcon();
        if(alarm){
            const classEvent = this.props.active._id !== this.props.alarm._id ? `alarm-type ${alwaysActive}`: 'alarm-type alarm-type-active'
            return(
                <div className={classEvent} onClick={!alarm.active && this.press.bind(this)}>
                    <ICON/>
                    <div className="description-event">
                        <span className="name-type">{alarm.name} {alarm.active && <span>(activé)</span>}</span>
                        <span className="description">{alarm.description}</span>
                    </div>
                </div>
            );
        }
       return <></>

    }
}

