export const GET_START = '[BLACKLIST] GET_START';
export const GET_END = '[BLACKLIST] GET_END';
export const ERROR = '[BLACKLIST] ERROR';

export const TOOGLE_STEP = '[BLACKLIST] TOOGLE_STEP';

export const TOOGLE_ACTIVE = '[BLACKLIST] TOOGLE_ACTIVE';

export const SET_STEP_DATA = '[BLACKLIST] SET_STEP_DATA';
export const SET_STEP = '[BLACKLIST] SET_STEP';
export const SET_DATE = '[BLACKLIST] SET_DATE';

export const SAVE_START = '[BLACKLIST] SAVE_START';
export const SAVE_END = '[BLACKLIST] SAVE_END';

export const DELETE_START = '[BLACKLIST] DELETE_START';
export const DELETE_END = '[BLACKLIST] DELETE_END';





export function SaveBlackListStart(payload) {
    return { type: SAVE_START, payload }
}

export function SaveBlackListEnd(payload) {
    return { type: SAVE_END, payload }
}

export function GetBlackListsStart(payload) {
    return { type: GET_START, payload }
}

export function ToogleStep() {
    return { type: TOOGLE_STEP }
}

export function ToogleActive(payload) {
    return { type: TOOGLE_ACTIVE, payload }
}

export function SetStepData(payload) {
    return { type: SET_STEP_DATA, payload }
}

export function getBlackListsEnd(payload) {
    return { type: GET_END, payload }
}

export function deleteBlackListStart(payload) {
    return { type: DELETE_START, payload }
}

export function deleteBlackListEnd(payload) {
    return { type: DELETE_END, payload }
}



export function Error(msg) {
    return { type: ERROR, msg }
}