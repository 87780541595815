import * as actions from "./maintenance.actions";
import {catchError, map, switchMap} from 'rxjs/operators'
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {NotificationManager} from "react-notifications";
import {AutoLogout} from "../auth/auth.actions";
import {
    addMaintenance,
    deleteMaintenance,
    getMaintenance,
    updateMaintenance
} from "../../pages/maintenance/maintenanceService";
import {GetStart} from "./maintenance.actions";


export const SaveMaintenanceEpic = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_START),
    switchMap((action) => {
        const {id, emails} =action.payload
        const body = { ...state$.value.maintenanceReducer.addMaintenance.data };
        body.emails = emails;

        if(body._id) {
            return from(updateMaintenance(id, body, state$.value.authReducer.token)).pipe(
                switchMap(res => {
                    NotificationManager.success(body.name + ' est modifié avec succès', 'Modification', 2000);
                    return of(actions.SaveMaintenanceEnd({}), GetStart({id}));
                }),
                catchError(err => {
                    let msg = 'une erreur s\'est produite !';
                    if (err.response.status === 401) {
                        return of(AutoLogout(), Error(''));
                    }
                    return of(Error(msg))
                })
            )
        }
        return from(addMaintenance(id, body, state$.value.authReducer.token)).pipe(
            switchMap(res => {
                NotificationManager.success(body.name + ' est créé avec succès', 'Création', 2000);
                return of(actions.SaveMaintenanceEnd({}), GetStart({id}));
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )

    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const GetMaintenanceEpic = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        const {id} =action.payload
        return from(getMaintenance(id, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.GetEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const DeleteMaitenanceEpic = (action$, state$) => action$.pipe(
    ofType(actions.DELETE_START),
    switchMap((action) => {
        const {id, vehicleId} = action.payload;
        return from(deleteMaintenance(vehicleId, id, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('la main est supprimée avec succès', 'Suppression', 2000);
                return actions.DeleteEnd({response: res.data, id});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, 'Suppression', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Suppression', 2000);
        return of(Error('Problem unknow'))
    })
);
