import React, {Component, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import './step1.css';
import {AiOutlineUser, BiMailSend} from "react-icons/all";
import {Button} from "../../../../components/widgets/form/button/button";
import {useForm} from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from 'moment-timezone';
import {DatePicker} from "antd";
import {ResetStep, SaveStart, SetStepData} from "../../../../store/reports/report.actions";
import isAlpha from "validator/es/lib/isAlpha";



export default function Step1Alarm(props) {
    const dispatch = useDispatch();
    const {token, timezone, active, step, activeStep, data} = useSelector(state => {
        return {
            token: state.authReducer.token,
            timezone: state.authReducer.user.timezone,
            active: state.reportsReducer.active,
            step: state.reportsReducer.addReport.step,
            activeStep: state.reportsReducer.addReport.step,
            data: state.reportsReducer.addReport.data
        }
    });
    moment.tz.setDefault(props.timezone);

    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setError, errors, watch  } = useForm(
        { mode: "onChange",
            defaultValues: {
                id: active._id,
                format: 'xls',
                name: data.name,
            }
        },
    );

    const onSubmit = async(dat) => {
        if(data.start && data.end) {
            dispatch(SaveStart(dat));
        }
    };

    const previous = () => {
        if(activeStep === 1 && step === 1) {
            dispatch(ResetStep());
        } else {
            dispatch(SetStepData({data: {}, step: 1}));
        }
    };


    function onChange(dates, dateStrings) {
        dispatch(SetStepData({data: {
            start: dates[0].set({hour:0,minute:0,second:0,millisecond:0}).toDate().getTime(),
                end: dates[1].set({hour:23,minute:59,second:59,millisecond:59}).toDate().getTime()
            }, step}));
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }


    const { RangePicker } = DatePicker;
    return(
        <PerfectScrollbar style={{ height: `${window.innerHeight - 210}px`}}>
            <div className="step1-report">
                <form className="form-alarm" onSubmit={handleSubmit(onSubmit)}>
                    <h4>Période</h4>
                    <RangePicker
                        className={'period'}
                        ranges={{
                            'Aujourd\'hui': [moment().set({hour:0,minute:0,second:0,millisecond:0}), moment()],
                            'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'La semaine dernière': [moment().subtract(6, 'days'), moment()],
                            'Le mois dernier': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            'Ce mois-ci': [moment().startOf('month'), moment()],

                        }}
                        format="YYYY/MM/DD"
                        disabledDate={disabledDate}
                        onChange={onChange}
                    />
                    <div className="actions">
                        <Button type='btn-accent'  name='Précédent' press={previous}/>
                        <Button type='btn-primary' name='Términer'/>
                    </div>
                </form>
                <div className="description">
                    <img src="/img/report/report.svg"/>
                </div>
            </div>
        </PerfectScrollbar>
    )
}
