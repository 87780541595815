import * as authActions from './auth.actions';

const INITIAL_STATE = {
    expiresIn: 0,
    token: null,
    user: null,
    loading: false,
    timeOut: null,
    authError: null
}


export function authReducer(state = INITIAL_STATE, action){
    switch (action.type) {
        case authActions.LOGIN_START:
            return Object.assign({}, state, {
                loading: true,
                authError: INITIAL_STATE.authError
            })

        case authActions.LOGIN_END:
            return Object.assign({}, state, {
                loading: false,
                token: action.payload.token,
                expiresIn: action.payload.expiresIn,
                user: action.payload.user
            })

        case authActions.LOGOUT_START:
            return Object.assign({}, state, {
                loading: true,
            })
        case authActions.SET_TIMEOUT:
            return Object.assign({}, state, {
                timeOut: action.payload
            })

        case authActions.AUTO_LOGOUT:
            return Object.assign({}, state, {
                token: false,
                loading:false
            })

        case authActions.LOGOUT_END:
            return INITIAL_STATE;


        case authActions.ERROR:
            return Object.assign({}, state, {
                user: INITIAL_STATE.user,
                authError: action.payload,
                loading: false
            })


        default:
            return state;
    }
}
