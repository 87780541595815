import React, {useState} from 'react';
import './step1.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useForm } from "react-hook-form";
import {Button} from "../../../../components/widgets/form/button/button";
import {useDispatch, useSelector} from "react-redux";
import {SaveBlackListStart, SetStepData, ToogleStep} from "../../../../store/blacklist/blacklist.actions";
import { Select } from 'antd';



import {AiOutlineInfoCircle} from "react-icons/ai";
import {FaRegAddressCard} from "react-icons/all";
import {fetchOneIdentification} from "../../blackListService";

const { Option } = Select;

export default function Step1() {
    // console.log(moment.tz.names())
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [errDescription, setErrDescription] = useState(false);
    const {token, blacklist, timezone} = useSelector(state => {
        return {
            token: state.authReducer.token,
            timezone: state.authReducer.user.timezone,
            blacklist: state.blacklistReducer.addblacklist.data
        }
    });
    const { register, handleSubmit, setError, errors, formState  } = useForm(
        { mode: "onChange" },
    );

    const onSubmit = async(data) => {
        setLoading(true);
        setErrDescription(false);
        const errDescription = !blacklist.description;
        const exist = await fetchOneIdentification(data.identification, token);
        if(exist) {
            setError('identification', {message: 'Cet identifiant éxiste déjà !', type:'isUnique'});
        }
        if(errDescription) {
            setErrDescription(true);
        }

        if(!exist && !errDescription) {
            dispatch(SaveBlackListStart(data));
        }

        setLoading(false);
    };

    const onChange = (description) => { dispatch(SetStepData({step: 1, data: {description}})); }
    const previous = () => { dispatch(ToogleStep()); };


    const styleName = !errors.name ? 'input-form' : 'input-form input-form-error';
    const styleIdentification = !errors.identification ? 'input-form' : 'input-form input-form-error';

    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={styleIdentification}>
                    <input
                        name="identification" placeholder="Identifiant d'utilisateur"
                        ref={register({
                            required: {value: true, message: 'Ce champs est obligatoire'},
                            minLength: {value: 4, message: 'La taille minimum est: 4'},
                            maxLength: {value: 20, message: 'La taille maximum est: 20'},
                        })}
                    />
                    <FaRegAddressCard/>
                    {errors.identification && <p className="error-input">{errors.identification.message}</p>}
                </div>
                <div className={styleName}>
                    <input
                        name="name" placeholder="Nom complet du fraudeur"
                        ref={register(
                            {
                                required: {value: true, message: 'Ce champs est obligatoire'},
                                maxLength: {value: 20, message: 'La taille maximum est: 20'},
                            }
                        )}
                    />
                    <AiOutlineInfoCircle/>
                    {errors.name && <p className="error-input">{errors.name.message}</p>}
                </div>

                    <Select
                        showSearch
                        size={"large"}
                        style={{ width: '92%', fontSize:'12px'}}
                        placeholder="Choix du dommage"
                        optionFilterProp="children"
                        onChange={onChange}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="Accident">Accident</Option>
                        <Option value="Vol">Vol</Option>
                        <Option value="Dégradations intérieur">Dégradations intérieur</Option>
                        <Option value="Dégradations extérieur">Dégradations extérieur</Option>
                        <Option value="Rayures">Rayures</Option>
                        <Option value="Panne">Panne</Option>
                        <Option value="Retard">Retard</Option>
                        <Option value="Contravention">Contravention</Option>
                    </Select>
                    {errDescription && <p style={{fontSize: '10px', color: '#E45D61'}}>Ce champs est obligatoire</p>}

            </div>

            <div className="actions">
                <Button type='btn-accent' press={previous} name='Fermer'/>
                <Button type='btn-primary' name='Terminer' loading={loading}/>
            </div>
        </form>
    );
}
