import React, {Component, useState} from 'react';
import './step1.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import {Button} from "../../../../components/widgets/form/button/button";
import {useDispatch} from "react-redux";
import {FaCircle, FaDrawPolygon, FaMapMarkerAlt, MdTimeline} from "react-icons/all";
import {SetStep, SetTypeZone, ToogleStep} from "../../../../store/zones/zones.actions";


export default function Step1(props) {
    const dispatch = useDispatch();
    const chooseZone = (data) => { dispatch(SetTypeZone(data)); };
    const handleSubmit = () => {
        dispatch(SetStep(2));
    };

    const previous = () => { dispatch(ToogleStep()); };


    return (
        <div className="form-aside">
            <div className="options-zone">
                <div
                    className={props.data.type === 'POI' ? 'option-zone option-zone-active' : 'option-zone' }
                    onClick={() => chooseZone('POI')}
                >
                    <FaMapMarkerAlt/>
                    <label>POI</label>
                </div>
                <div className={props.data.type === 'Point' ? 'option-zone option-zone-active' : 'option-zone' } onClick={() => chooseZone('Point')}>
                    <FaCircle/>
                    <label>Cércle</label>
                </div>
                <div className={props.data.type === 'Polygon' ? 'option-zone option-zone-active' : 'option-zone' }
                    onClick={() => chooseZone('Polygon')}
                >
                    <FaDrawPolygon/>
                    <label>Polygone</label>
                </div>
                <div
                    className={props.data.type === 'LineString' ? 'option-zone option-zone-active' : 'option-zone' }
                    onClick={() => chooseZone('LineString')}
                >
                    <MdTimeline/>
                    <label>Itinéraire</label>
                </div>
            </div>
            <div className="actions">
                <Button type='btn-accent' press={previous} name='Fermer'/>
                <Button type='btn-primary' name='Suivant' disabled={!props.data.type} press={handleSubmit}/>
            </div>
        </div>
    );
}
