import React, { Component }  from 'react';
import './company-info.css';
import {GoLocation} from "react-icons/go";
import {BiCalendar} from "react-icons/bi";
import {AiOutlineCar, AiOutlineEdit, AiOutlineDelete, AiOutlinePlus} from "react-icons/ai";
import {FiUsers} from "react-icons/fi";
import {BiBox} from "react-icons/bi"
import CountUp from "react-countup";
import "react-perfect-scrollbar/dist/css/styles.css";
import {BiPhone} from "react-icons/bi";
import {HeaderAction} from "../header/header-action/header-action";
import {IoIosNotifications, IoMdLogIn} from "react-icons/io";

export class CompaniesInfo extends Component {

    constructor(props) { super(props); }

    componentDidMount() { }

    render() {
        const img = this.props.active ? '/img/companies/enabled.svg' : '/img/companies/disabled.svg';
        const dateDeFin = 'Lundi 08 Août 2020 à 20:30';
        const styleCmp = !this.props.current ? 'card-company-info' : 'card-company-info current-company';
        return (
            <div className={styleCmp}>
                <img className="status" src={img}/>
                <div style={{flex: '1 1 50%', marginLeft: '3em'}}>
                    <div className="main">
                        <div>
                            <h4 className="name">{this.props.name}</h4>
                            <div className="description">
                                <div className="descWidget">
                                    <BiPhone />
                                    <label>{this.props.phone}</label>
                                </div>
                                <div className="descWidget">
                                    <BiCalendar />
                                    <label>{dateDeFin}</label>
                                </div>

                                {
                                    this.props.address && <div className="descWidget">
                                        <GoLocation />
                                        <label>{this.props.address}</label>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="action">
                            <HeaderAction icon={AiOutlineEdit} press={this.props.press} value={{action: 'edit', id: this.props.id}}/>
                            <HeaderAction icon={AiOutlineDelete} press={this.props.press} value={{action: 'del', name: this.props.name, id: this.props.id}}/>
                            {
                                !this.props.current && <HeaderAction icon={IoMdLogIn} press={this.props.press} value={{action: 'login', id: this.props.id}}/>
                            }
                        </div>

                    </div>
                    <div className="details">
                        <div className="widget-value">
                            <AiOutlineCar />
                            <div className="info">
                                <label className="type">véhicules</label>
                                <CountUp delay={1} end={this.props.vehicles} duration={2}/>
                            </div>
                        </div>
                        <div className="widget-value">
                            <FiUsers />
                            <div className="info">
                                <label className="type">utilisateurs</label>
                                <CountUp delay={1} end={this.props.users} duration={2}/>
                            </div>
                        </div>
                        <div className="widget-value">
                            <BiBox />
                            <div className="info">
                                <label className="type">groupes</label>
                                <CountUp delay={1} end={this.props.groups} duration={2}/>
                            </div>
                        </div>
                        <div className="widget-value">
                            <IoIosNotifications />
                            <div className="info">
                                <label className="type">alarmes</label>
                                <CountUp delay={1} end={this.props.alarms} duration={2}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}