import {BASE_URL} from "../../env";
const axios = require('axios');

export async function addMaintenance(id, payload, token) {
    const body = {name: payload.name, description: payload.description};
    if(payload.km && payload.km.active) {
        body.km = {
            lastServiceMielage: payload.km.lastServiceMielage,
            intervalKm: payload.km.intervalKm,
        };
    }
    if(payload.emails ) { body.emails = payload.emails; }
    if(payload.day && payload.day.active) {
        body.day = {intervalDay: payload.day.intervalDay, lastServiceDay: parseInt(payload.day.lastServiceDay.format('x'))}
    }

    const url = `${BASE_URL}/api/vehicle/${id}/maintenance`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.post(url, body, config);
}

export async function updateMaintenance(id, payload, token) {
    const body = {name: payload.name, description: payload.description};
    if(payload.km && payload.km.active) {
        body.km = {
            lastServiceMielage: payload.km.lastServiceMielage,
            intervalKm: payload.km.intervalKm,
        };
    }
    if(payload.emails ) { body.emails = payload.emails; }
    if(payload.day && payload.day.active) {
        body.day = {intervalDay: payload.day.intervalDay, lastServiceDay: parseInt(payload.day.lastServiceDay.format('x'))}
    }

    const url = `${BASE_URL}/api/vehicle/${id}/maintenance/${payload._id}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.patch(url, body, config);
}

export async function getMaintenance(id, token) {
    const url = `${BASE_URL}/api/vehicle/${id}/maintenance`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}


export async function deleteMaintenance(vehicleId, payload, token) {
    const url = `${BASE_URL}/api/vehicle/${vehicleId}/maintenance/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.delete(url, config);
}




