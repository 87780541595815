import React, {Component, useState} from 'react';
import './step2.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useForm } from "react-hook-form";
import {Button} from "../../../../components/widgets/form/button/button";
import {useDispatch, useSelector} from "react-redux";
import {
    SaveVehicleEnd,
    SaveVehicleStart,
    SetStepData,
    ToogleActive,
    ToogleStep
} from "../../../../store/vehicles/vehicles.actions";
import {FaBoxOpen} from "react-icons/fa";
import {AiOutlineFieldNumber, AiOutlineInfoCircle} from "react-icons/ai";
import {IoLogoModelS} from "react-icons/io";
import {BsCardChecklist, IoMdSpeedometer} from "react-icons/all";
import {SetStep} from "../../../../store/vehicles/vehicles.actions";

export default function Step2(props) {
    const dispatch = useDispatch();
    const {token, loading} = useSelector(state => {
        return{
            token: state.authReducer.token,
            loading: state.vehiclesReducer.addVehicle.loading,

        }
    });
    const { register, handleSubmit, setError, errors, formState  } = useForm(
        { mode: "onChange",
            defaultValues: {
                name: props.data.name,
                plate: props.data.plate,
                vin: props.data.vin,
                model: props.data.model,
                type: props.data.type,
            }
        },
    );

    const onSubmit = async(data) => {
        dispatch(SaveVehicleStart(data));
    };

    const previous = () => { dispatch(SetStep({step: 1})); };


    const styleName = !errors.name ? 'input-form' : 'input-form input-form-error';

    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={styleName}>
                    <input
                        name="name" placeholder="Nom"
                        ref={register(
                            {
                                required: {value: true, message: 'Ce champs est obligatoire'},
                                minLength: {value: 4, message: 'La taille minimum est: 4'}
                            }
                        )}
                    />
                    <AiOutlineInfoCircle/>
                    {errors.name && <p className="error-input">{errors.name.message}</p>}
                </div>
                <div className='input-form'>
                    <input name="plate" placeholder="Matricule"
                           ref={register()}
                    />
                    <AiOutlineFieldNumber/>
                </div>
                <div className='input-form'>
                    <input name="vin" placeholder="VIN"
                           ref={register()}
                    />
                    <BsCardChecklist/>
                </div>
                <div className='input-form'>
                    <input name="model" placeholder="Modèle"
                           ref={register()}
                    />
                    <IoLogoModelS/>
                </div>
                <div className='input-form'>
                    <input name="type" placeholder="Type"
                           ref={register()}
                    />
                    <FaBoxOpen/>
                </div>


            </div>

            <div className="actions">
                <Button type='btn-accent' press={previous} name='Précédent'/>
                <Button type='btn-primary' name='Terminer' loading={loading}/>
            </div>
        </form>
    );
}
