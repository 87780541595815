import React from "react";
import './header.css';
import {Avatar} from "../components/avatar/avatar";
import {RiMenuFoldFill, RiMenuUnfoldFill} from "react-icons/ri";
import {GiGps} from "react-icons/gi";
import {BsBuilding} from "react-icons/bs";
import {NavLink} from "react-router-dom";
import {FaUserAltSlash, FaUserShield, GoPrimitiveDot} from "react-icons/all";
import { useSelector} from "react-redux";
import {canShowRole, isLocation} from "../common/role";



export const Header = (props) => {

    const {type, location, name, role, on, off} = useSelector(state => {
        const { user } = state.authReducer;
        return {
            location:  user && user.company.location,
            name: user?.name,
            type: user.type,
            role: user?.role,
            on: state.vehiclesMapReducer.stats.on.size,
            off: state.vehiclesMapReducer.stats.off.size,
        };
    });

    const onPress = (value) => {
        props.onPress && props.onPress(value);
    }

    const findRole = (name) => {
        return role.findIndex(r => r === name) !== -1;
    }

    return(
        <header>
            <div className="leftMenu">
                <div onClick={() => onPress({action: 'menuSize'})} className="toogleMenu">
                    {
                        props.sizeMenu === 'full' ? <RiMenuFoldFill className="icon"/>
                            : <RiMenuUnfoldFill className="icon"/>
                    }
                </div>
                {
                    /*
                    <div className="stats">
                        <div className="stat">
                            <label>{on}</label>
                            <div className="description">
                                <GoPrimitiveDot color="rgb(59, 206, 133)"/><label>En marche</label>
                            </div>
                        </div>
                        <div className="stat">
                            <label>{off}</label>
                            <div className="description">
                                <GoPrimitiveDot color="red"/><label>En Arrêt</label>
                            </div>
                        </div>


                    </div>*/
                }

                {
                    type === 'admin' && <>
                        <NavLink className="subMenu" activeClassName='activeSubMenu' to="/companies">
                            <BsBuilding className="icon"/>
                            <span>Entreprises</span>
                        </NavLink>
                        <NavLink className="subMenu" activeClassName='activeSubMenu' to="/tracers">
                            <GiGps className="icon"/>
                            <span>Traceurs</span>
                        </NavLink>

                    </>
                }

                {
                    canShowRole(type, role) && <NavLink className="subMenu" activeClassName='activeSubMenu' to="/role">
                        <FaUserShield className="icon"/>
                        <span>Rôles</span>
                    </NavLink>
                }
                {
                    isLocation(location) && <NavLink className="subMenu" activeClassName='activeSubMenu' to="/blacklist">
                        <FaUserAltSlash className="icon"/>
                        <span>Liste noire</span>
                    </NavLink>
                }
            </div>
            <Avatar img="/img/avatar.svg" name={name} press={onPress}/>
        </header>
    )
}
