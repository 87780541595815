import React, { Component }  from 'react';

export class NotFound extends Component {
    render() {
        return (
            <div>
                <p>NotFound Page</p>
            </div>
        );
    }
}