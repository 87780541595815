import React from "react"
import {Steps} from "antd";
import {useSelector} from "react-redux";
import '../generate-report.css';
import {MainReportSteps} from "./steps-report";

export const StepsReport = () => {

    const {steps, step} = useSelector(state => {
        const reports = state.reportsReducer;
        return {
            steps: reports.addReport.steps,
            step : reports.addReport.step
        };
    });

    const {Step} = Steps;
    return(
        <div className="add-report">
            <Steps size={'small'} className="stepper-report" current={0}>
                { steps.map(step => {
                    const ICON = step.icon;
                    return <Step title={step.name} icon={<ICON/>}/>
                }) }
            </Steps>
            <MainReportSteps/>
        </div>
    )
}
