const findRole = (name, where) => {
    return where.findIndex(r => r === name) !== -1;
}

export const canShowDriver = (userType, roles) => {
    return userType === 'admin' || findRole('driver:rd', roles) || findRole('driver:wt', roles) || findRole('driver:dt', roles)
}

export const canManageDriver = (userType, roles) => {
    return userType === 'admin' || findRole('driver:wt', roles);
}

export const canDeleteDriver = (userType, roles) => {
    return userType === 'admin' || findRole('driver:dt', roles)
}
