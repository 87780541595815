import React, {useState} from 'react';
import './step2.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useForm } from "react-hook-form";
import {Button} from "../../../../components/widgets/form/button/button";
import {useDispatch, useSelector} from "react-redux";
import {
    SaveAccountStart,
    SetStep,
    ToogleActive, UpdatePasswordStart
} from "../../../../store/accounts/accounts.actions";
import {RiLockPasswordLine} from "react-icons/ri";
import {CgPassword} from "react-icons/cg";

export default function UpdatePassword() {
    const dispatch = useDispatch();
    const {token, account, loading} = useSelector(state => {
        return {
            token: state.authReducer.token,
            account: state.accountsReducer.addAccount.data,
            loading: state.accountsReducer.addAccount.loading,
        }
    });
    const { register, handleSubmit, setError, errors, watch  } = useForm(
        { mode: "onChange",
            defaultValues: {
                username: account.username
            }
        },
    );

    const onSubmit = async(data) => {
        dispatch(UpdatePasswordStart(data));
    };

    const previous = () => { dispatch(SetStep({step: 0})); };

    const styleOld = !errors.oldPassword ? 'input-form' : 'input-form input-form-error';
    const styleNew = !errors.password ? 'input-form' : 'input-form input-form-error';
    const styleConfirm = !errors.confirmPassword ? 'input-form' : 'input-form input-form-error';

    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <input
                    type="text"
                    hidden="hidden"
                    name="username"
                    ref={register()}
                />
                {
                    account.type === 'admin' && <div className={styleOld}>
                        <input
                            type="password"
                            name="oldPassword" placeholder="Ancien mot de passe"
                            ref={register(
                                {
                                    required: {value: true, message: 'Ce champs est obligatoire'}
                                }

                            )}
                        />
                        <RiLockPasswordLine/>
                        {errors.oldPassword && <p className="error-input">{errors.oldPassword.message}</p>}
                    </div>
                }
                <div className={styleNew}>
                    <input
                        type="password"
                        name="password"
                        placeholder="Nouveau mot de passe"
                        ref={register(
                            {
                                required: {value: true, message: 'Ce champs est obligatoire'},
                                minLength: {value: 5, message: 'La taille minimum est: 5'},
                                validate: {
                                    hasNumber: (input) => ( /\d/.test(input)) || "Ce champs doit contenir un nombre",
                                    hasUpper: (input) => (/[A-Z]/.test(input)) || "Ce champs doit contenir une lettre en majuscule",
                                    hasLower: (input) => (/[a-z]/.test(input)) || "Ce champs doit contenir une lettre en miniscule",
                                }
                            }

                        )}
                    />
                    <RiLockPasswordLine/>
                    {errors.password && <p className="error-input">{errors.password.message}</p>}
                </div>
                <div className={styleConfirm}>
                                <input
                                    type="password"
                                    name="confirmPassword" placeholder="Confirmation du mot de passe"
                                    ref={register(
                                        {
                                            validate: {
                                                isNumber: (input) => (input === watch('password')) || "Merci de renseigner le même mot de passe"
                                            }
                                        }

                                    )}
                                />
                                <CgPassword/>
                                {errors.confirmPassword && <p className="error-input">{errors.confirmPassword.message}</p>}
                            </div>
            </div>

            <div className="actions">
                <Button type='btn-accent' press={previous} name='Fermer'/>
                <Button type='btn-primary' name='Suivant' loading={loading}/>
            </div>
        </form>
    );
}
