import {BASE_URL} from "../../env";
const axios = require('axios');

export function fetchZones(payload, token) {
    let params = '';
    for (let param in payload) {
        params+=`${param}=${payload[param]}&`
    }
    const url = `${BASE_URL}/api/zone/details?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export function fetchZone(payload, token) {
    const url = `${BASE_URL}/api/zone/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export function deleteZone(payload, token) {
    const url = `${BASE_URL}/api/zone/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.delete(url, config);
}

export function saveZone(payload, token) {
    const url = `${BASE_URL}/api/zone`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.post(url, payload, config);
}
export function updateZone(payload, token) {
    const url = `${BASE_URL}/api/zone`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.patch(url, payload, config);
}
