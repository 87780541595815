import React, { Component }  from 'react';
import {connect} from "react-redux";

import PerfectScrollbar from "react-perfect-scrollbar";
import './vehicle-commands-map.css';
import {
    Clear
} from "../../../../store/details/details.actions";
import {AiOutlinePoweroff, AiOutlineSend} from "react-icons/all";
import {LoadingComponent} from "../../../../components/loading/loading";
import {sendCommand} from "../../vehicleService";
import { notification, Popconfirm } from 'antd';


class VehicleCommandsMap extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 108}px`, loadind: false };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        this.props.dispatch(Clear());
    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 108}px` });
    }

    sendCommand(el){
        this.setState({loading: true});
        const send = sendCommand({commandID: el._id, vehicleID: this.props.command}, this.props.token)
        send.then(el => {
            notification.success({
                duration: 3,
                message: `Succès`,
                description:
                    'Commande prise en compte',
                placement: 'bottomLeft',
            });
        }).catch(err => {
            notification.success({
                duration: 3,
                message: `Erreur`,
                description:
                    'Commande non prise en compte',
                placement: 'bottomLeft',
            });
        }).finally(() => this.setState({loading: false}));
    }


    render() {
        const vehicle = this.props.vehicles.find(el => el._id === this.props.command);
        if(vehicle)
            return (
                <PerfectScrollbar className="items-commands-vehicle" style={{ height: this.state.height, flex: 1, zIndex: 992}}>
                    <LoadingComponent loading={this.state.loading}/>

                    {
                        vehicle.commands.map((el, i) => {
                            let description, name, ICON = null;
                            switch (el.name) {
                                case 'start':
                                    name = "Start";
                                    ICON = AiOutlinePoweroff;
                                    description = 'Authorisé le démarrage du véhicule';
                                    break;
                                case 'stop':
                                    name = "Stop"
                                    description = 'Authorisé le démarrage du véhicule';
                                    ICON = AiOutlinePoweroff;
                                    break;
                                default:
                                    name = el.name;
                                    description = null;
                                    ICON = AiOutlineSend;
                            }
                            return(
                                <Popconfirm
                                    key={i}
                                    title="Vous êtes sûr？"
                                    okText="Oui"
                                    cancelText="Non"
                                    placement="right"
                                    onConfirm={() => this.sendCommand(el)}>
                                    <div className="more-info">
                                        <div className="item-command-vehicle">

                                            <div className="item-info-vehicle-detail">
                                                <label>{name}</label>
                                                {
                                                    description && <label>{description}</label>
                                                }
                                            </div>
                                            <div className={`icon-info icon-info-${el.name}`}>
                                                <ICON/>
                                            </div>
                                        </div>
                                    </div>
                                </Popconfirm>

                            );
                        })
                    }

                </PerfectScrollbar>
            );
        return <></>
    }
}

const VehicleDetailsStateToProps = (state) => {
    return {
        vehicles: state.vehiclesMapReducer.vehicles.list,
        command: state.vehiclesMapReducer.command,
        token: state.authReducer.token
    }
}
export default connect(VehicleDetailsStateToProps)(VehicleCommandsMap);