import {Button} from "../../../../components/widgets/form/button/button";
import React, {useEffect, useState} from "react";
import {BiMailSend, GiPathDistance} from "react-icons/all";
import './interval.css'
import {useForm} from "react-hook-form";
import {resetInput, validateDay, validateKm} from "../../../../validation";
import moment from "moment-timezone";
import {Checkbox, DatePicker} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {SetStepData} from "../../../../store/maintenance/maintenance.actions";

export const IntervalCompoment = () => {

    const dispatch = useDispatch();
    const { checkedKm, checkedDay, timezone, step, km, day } = useSelector(state => { return {
        step: state.maintenanceReducer.addMaintenance.step,
        km: state.maintenanceReducer.addMaintenance.data.km,
        day: state.maintenanceReducer.addMaintenance.data.day,
        checkedDay: state.maintenanceReducer.addMaintenance.data.day.active,
        checkedKm: state.maintenanceReducer.addMaintenance.data.km.active,
        timezone: state.authReducer.user.timezone,
    } });


    const onChangeCheckBox = (e) =>   dispatch(SetStepData({data: {km: {...km, ...{active: e.target.checked}}}, step}));
    const onChangeDay = (e) => dispatch(SetStepData({data: {day: {...day, ...{active: e.target.checked}}}, step}));

    const back = () => dispatch(SetStepData({data: {}, step: step - 1}));

    const actualMielage = km && km.actualMielage;
    const lastServiceMielage = km && (km.lastServiceMielage || km.actualMielage.toFixed(0));
    const intervalKm = km && km.intervalKm;

    const lastServiceDay = day && day.lastServiceDay && moment(day.lastServiceDay);
    const intervalDay = day && day.intervalDay;

    const { register, handleSubmit, setError, errors, reset  } = useForm(
        { mode: "onChange",
            defaultValues: {
                actualMielage, lastServiceMielage, intervalKm,
                intervalDay: intervalDay
            }
        },
    );

    useEffect(() => {
        resetInput(reset, {actualMielage, lastServiceMielage, intervalDay, intervalKm });
        }, [checkedKm, checkedDay, reset, actualMielage, lastServiceMielage, intervalDay, intervalKm]
    );

    const onSubmit = async(data) => {
        const result = {};
        if(checkedKm) { result.km = {active: checkedKm, actualMielage, lastServiceMielage: parseInt(data.lastServiceMielage, 10), intervalKm: parseInt(data.intervalKm, 10)}; }
        if(checkedDay) { result.day = {active: checkedDay, intervalDay: parseInt(data.intervalDay, 10), lastServiceDay}; }
        if (checkedDay && !lastServiceDay){
            return;
        }
        if(checkedDay || checkedKm) {
            dispatch(SetStepData({data: result, step: step+1}));
        }

    };

    const sintervalKm = !errors.intervalKm ? 'input-form' : 'input-form input-form-error';
    const sLastServiceMielage = !errors.lastServiceMielage ? 'input-form' : 'input-form input-form-error';
    const sActualMielage = !errors.actualMielage ? 'input-form' : 'input-form input-form-error';
    const sintervalDay = !errors.intervalDay ? 'input-form' : 'input-form input-form-error';

    const onChange = (dates, dateStrings) => {
        const lastServiceDaysNumber = parseInt(moment.tz(dates, timezone).format('x'), 10);
        dispatch(SetStepData({data: {km, day: {active: checkedDay, lastServiceDay: lastServiceDaysNumber, intervalDay}}, step}));
    }



    const styleKm = {flex:1, display: 'flex', flexDirection: 'row', marginTop: '12px'};
    const styleDay = {...styleKm};
    if(!checkedKm) {styleKm.opacity = .4} else {styleKm.opacity = 1}
    if(!checkedDay) {styleDay.opacity = .4} else {styleDay.opacity = 1}

    return(
        <div className="interval-maintenance">
            <form className="form-maintenance" onSubmit={handleSubmit(onSubmit)}>
                <h4>
                    <Checkbox
                        checked={checkedKm}
                        onChange={onChangeCheckBox}
                    >Kilométrage</Checkbox>
                </h4>

                <div style={styleKm}>
                    <div>
                        <label style={{fontSize: '10px'}}>Intérvalle de kilométre</label>
                        <div style={{margin: '0.4em 0px 1.1em 0px'}} className={sintervalKm}>
                            <input  name="intervalKm" type="number" placeholder="Intérvalle de kilométre" ref={register(validateKm(checkedKm))} disabled={!checkedKm} />
                            <BiMailSend/>
                            {errors.intervalKm && <p className="error-input">{errors.intervalKm.message}</p>}
                        </div>
                    </div>

                    <div style={{flex:1, margin: '0px 12px'}}>
                        <label style={{fontSize: '10px'}}>Dérnière utilisation du service</label>
                        <div style={{margin: '0.4em 0px 1.1em 0px'}} className={sLastServiceMielage}>
                            <input name="lastServiceMielage" type="number" placeholder="Dérnière utilisation du service" ref={register(validateKm(checkedKm))} disabled={!checkedKm}/>
                            <BiMailSend/>
                            {errors.lastServiceMielage && <p className="error-input">{errors.lastServiceMielage.message}</p>}
                        </div>
                    </div>

                    <div style={{flex:1}}>
                        <label style={{fontSize: '10px'}}>kilométrage actuel</label>
                        <div style={{margin: '0.4em 0px 1.1em 0px'}} className={sActualMielage}>
                            <input name="actualMielage" placeholder="kilométrage actuel" ref={register()} disabled={true}/>
                            <GiPathDistance/>
                            {errors.actualMielage && <p className="error-input">{errors.actualMielage.message}</p>}
                        </div>
                    </div>

                </div>

                <h4>
                    <Checkbox
                        checked={checkedDay}
                        onChange={onChangeDay}
                    >Jours intérval</Checkbox>
                </h4>
                <div style={styleDay}>
                    <div style={{flex:1}}>
                        <label style={{fontSize: '10px'}}>Intérvalle en nombre de jours</label>
                        <div style={{margin: '0.4em 0px 1.1em 0px'}} className={sintervalDay}>
                            <input  name="intervalDay" type="number" placeholder="Intérvalle en nombre de jours" ref={register(validateDay(checkedDay))} disabled={!checkedDay}/>
                            <BiMailSend/>
                            {errors.intervalDay && <p className="error-input">{errors.intervalDay.message}</p>}
                        </div>
                    </div>

                    <div style={{flex:1, marginLeft: '12px'}}>
                        <label style={{fontSize: '10px'}}>Dernier service</label>

                            <DatePicker
                                style={{margin: '0.4em 0px 1.1em 0px'}}
                                className={'period'}
                                format="YYYY-MM-DD HH:mm:ss"
                                defaultValue={lastServiceDay}
                                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss').tz(timezone) }}
                                onChange={onChange}
                                disabled={!checkedDay}
                            />

                    </div>
                </div>

                <div className="actions">
                    <Button type='btn-accent' name='Précédent' press={back}/>
                    <Button type='btn-primary' name='Suivant'/>
                </div>
            </form>
        </div>
    );
}
