import {BASE_URL} from "../../../../env";
const axios = require('axios');

export function fetchHistoy(payload, token) {
    const params = `?from=${payload.from.getTime()}&to=${payload.to.getTime()}`;
    const url = `${BASE_URL}/api/vehicle/${payload._id}/history${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export function fetchTraject(vehicle, payload, token) {
    let filter = '';
    const params = `/${payload.data.id}`;
    if(payload.data.sliced) {
        filter = `?start=${payload.data.start.toDate().getTime()}&end=${payload.data.end.toDate().getTime()}`
    }
    const url = `${BASE_URL}/api/vehicle/${vehicle}/traject${params}${filter}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}
