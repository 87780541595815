import {BASE_URL} from "../../env";

const axios = require('axios');

export function fetchGroups(payload, token) {
    let params = '';
    for (let param in payload) {
        params+=`${param}=${payload[param]}&`
    }
    const url = `${BASE_URL}/api/group/all?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}


export function AddGroup(payload, token) {
    const url = `${BASE_URL}/api/group`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.post(url, {name: payload.name}, config);
}


export function UpdateGroup(payload, token) {
    const url = `${BASE_URL}/api/group`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.patch(url, {_id: payload.id, name: payload.name}, config);
}


export async function deleteGroup(payload, token) {
    const url = `${BASE_URL}/api/group/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.delete(url, config);
}

