import './App.css';
import 'react-notifications/lib/notifications.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import React, {Component} from "react";
import {Provider} from "react-redux";
import AppReducer from './store'
import {applyMiddleware, createStore} from "redux";
import {createEpicMiddleware} from "redux-observable";
import Routes from "./routes";
import history from "./services/history";
import {Router} from "react-router-dom";
import rootEpics from './store/epics/index'

class App extends Component{
  constructor(props) {
    super(props);
      this.epicMiddleware = createEpicMiddleware();
      this.store = createStore(AppReducer, applyMiddleware(this.epicMiddleware));
      this.epicMiddleware.run(rootEpics);
  }
render() {
    return (
        <Provider store={this.store}>
            <Router history={history}>
                <Routes/>
            </Router>
        </Provider>
    );
}
}

export default App;
