import React, {PureComponent} from "react";
import Route from "./Route";
import {Switch} from "react-router-dom";
import {DashboardPage} from "../pages/dashboard/dashboardPage";
import LoginPage from "../pages/login/loginPage";
import ReportsPage from "../pages/reports/reportsPage";
import {connect} from "react-redux";
import MapPagePage from "../pages/map/mapPage";
import history from "../services/history";
import {ActiveMenu} from "../store/main.actions";
import CompaniesPage from "../pages/companies/companiesPage";
import {UsersPage} from "../pages/accounts/usersPage";
import {NotFound} from "../pages/notfound/notFoundPage";
import VehiclePage from "../pages/vehicles/vehiclePage";
import {getAuthToCookies} from "../pages/login/loginService";
import {LoginEnd} from "../store/auth/auth.actions";
import GroupsPage from "../pages/groups/groupPage";
import CommandsPage from "../pages/commands/commandPage";
import ZonesPage from "../pages/zones/zonesPage";
import AlarmsPage from "../pages/alarms/alarmsPage";
import BlackListPage from "../pages/blacklist/blackListPage";
import DriversPage from "../pages/drivers/driversPage";
import {DeviceManagerPage} from "../pages/admin/device-manager/device-manager";
import AccessoryPage from "../pages/admin/accessory/accessory";
import IoPage from "../pages/admin/io/io";
import TracersPage from "../pages/tracer/tracerPage";
import {MaintenancePage} from "../pages/maintenance/maintenancePage";
import {ReportsBisPage} from "../pages/reoprtsBis";
import {RolePage} from "../pages/role/rolePage";

class Routes extends PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const auth = getAuthToCookies();
        if(auth) { this.props.dispatch(LoginEnd(auth)); }
        this.props.dispatch(ActiveMenu(history.location.pathname));
        this.unregisterHistoryListener = history.listen((location, action) => {
            this.props.dispatch(ActiveMenu(location.pathname));
        });
    }

    componentWillUnmount() {
        this.unregisterHistoryListener();
    }

    render() {
        return (
                <Switch>
                    <Route path="/" exact component={LoginPage}  signed={this.props.signed}/>
                    <Route path="/map" component={MapPagePage} isPrivate signed={this.props.signed}/>
                    <Route path="/dashboard" component={DashboardPage} isPrivate signed={this.props.signed}/>
                    <Route path="/zones" component={ZonesPage} isPrivate signed={this.props.signed}/>
                    <Route path="/reports" component={ReportsBisPage} isPrivate signed={this.props.signed}/>
                    <Route path="/role" component={RolePage} isPrivate signed={this.props.signed}/>
                    <Route path="/alarms" component={AlarmsPage} isPrivate signed={this.props.signed}/>
                    <Route path="/companies" component={CompaniesPage} isPrivate signed={this.props.signed}/>
                    <Route path="/tracers" component={TracersPage} isPrivate signed={this.props.signed}/>
                    <Route path="/users" component={UsersPage} isPrivate signed={this.props.signed}/>
                    <Route path="/groups" component={GroupsPage} isPrivate signed={this.props.signed}/>
                    <Route path="/drivers" component={DriversPage} isPrivate signed={this.props.signed}/>
                    <Route path="/vehicles/:id/manage" component={DeviceManagerPage} isPrivate signed={this.props.signed}/>
                    <Route path="/maintenance/:id" component={MaintenancePage} isPrivate signed={this.props.signed}/>
                    <Route path="/vehicles/:id/accessory" component={AccessoryPage} isPrivate signed={this.props.signed}/>
                    <Route path="/vehicles/:id/io" component={IoPage} isPrivate signed={this.props.signed}/>
                    <Route path="/vehicles" component={VehiclePage} isPrivate signed={this.props.signed}/>
                    <Route path="/commands" component={CommandsPage} isPrivate signed={this.props.signed}/>
                    <Route path="/blacklist" component={BlackListPage} isPrivate signed={this.props.signed}/>

                    {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
                    <Route component={NotFound}  />
                </Switch>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        signed: state.authReducer.token !== null
    }
}
export default connect(mapStateToProps)(Routes);
