import React, {useEffect, useState} from 'react';
import './step2.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useForm } from "react-hook-form";
import {Button} from "../../../../components/widgets/form/button/button";
import {fetchGroupByUName, fetchTracerByUid} from "../../../vehicles/vehicleService";
import {useDispatch, useSelector} from "react-redux";
import {
    SaveAccountStart,
    SetStep,
    ToogleActive
} from "../../../../store/accounts/accounts.actions";
import {GiGps} from "react-icons/gi";
import {Checkbox} from "../../../../components/widgets/form/checkbox/checkbox";
import {RiLockPasswordLine} from "react-icons/ri";
import {CgPassword} from "react-icons/cg";
import isNumeric from "validator/lib/isNumeric";
import {SaveVehicleStart} from "../../../../store/vehicles/vehicles.actions";
import {Form, Select} from "antd";
import {fetchRolesForUser} from "../../../role/roleService";
const { Option } = Select;

export default function Step2() {
    const dispatch = useDispatch();
    const {token, account} = useSelector(state => {
        return {
            token: state.authReducer.token,
            account: state.accountsReducer.addAccount.data
        }
    });
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const { register, handleSubmit, setError, errors, watch, setValue  } = useForm(
        { mode: "onChange",
            defaultValues: {
                type: account.type,
                role: account?.role?._id
            }
        },
    );

    const onSubmit = async(data) => {
        dispatch(SaveAccountStart(data));
    };

    const previous = () => { dispatch(SetStep({step: 1})); };

    const toggleActive = (value) => {
        dispatch(ToogleActive(value));
    }

    const styleType = !errors.name ? 'input-form' : 'input-form input-form-error';
    const styleRole = !errors.role ? 'input-form-role' : 'input-form-role input-form-error-role';
    const stylePassword = !errors.password ? 'input-form' : 'input-form input-form-error';
    const styleConfPassword = !errors.confirmPassword ? 'input-form' : 'input-form input-form-error';


    useEffect(() => {
        register({ name: 'role' }, {  required: {value: true, message: 'Ce champs est obligatoire'} });
        initRole();
    }, []);

    const initRole = async () => {
        const {status, data} = await fetchRolesForUser(token);
        setRoles(data);
    }


    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={styleType}>
                    <select name="type"
                            ref={register({
                                required: {value: true, message: 'Ce champs est obligatoire'},
                            })}>
                        <option value="user">Utilsateur</option>
                        <option value="admin">Administrateur</option>
                    </select>
                    <GiGps/>
                    {errors.type && <p className="error-input">{errors.type.message}</p>}
                </div>
                <div className={styleRole}>
                    <Form.Item style={{margin: '0px 25px 1.9em 0px'}}>
                        <Select
                            defaultValue={account?.role?._id}
                            onChange={(e) => { setValue('role', e) }}
                            placeholder="Choisir un rôle"
                            allowClear
                        >
                            { roles.map(r => <Option value={r._id}>{r.name}</Option>) }
                        </Select>
                    </Form.Item>
                    {errors.role && <p className="error-input">{errors.role.message}</p>}
                </div>

                {
                    !account.id &&
                        <>
                            <div className={stylePassword}>
                                <input
                                    name="password" placeholder="Mot de passe"
                                    ref={register(
                                        {
                                            required: {value: true, message: 'Ce champs est obligatoire'},
                                            minLength: {value: 5, message: 'La taille minimum est: 5'},
                                            validate: {
                                                hasNumber: (input) => ( /\d/.test(input)) || "Ce champs doit contenir un nombre",
                                                hasUpper: (input) => (/[A-Z]/.test(input)) || "Ce champs doit contenir une lettre en majuscule",
                                                hasLower: (input) => (/[a-z]/.test(input)) || "Ce champs doit contenir une lettre en miniscule",
                                            }
                                        }

                                    )}
                                />
                                <RiLockPasswordLine/>
                                {errors.password && <p className="error-input">{errors.password.message}</p>}
                            </div>

                            <div className={styleConfPassword}>
                                <input
                                    name="confirmPassword" placeholder="Mot de passe"
                                    ref={register(
                                        {
                                            validate: {
                                                isNumber: (input) => (input === watch('password')) || "Merci de renseigner le même mot de passe"
                                            }
                                        }

                                    )}
                                />
                                <CgPassword/>
                                {errors.confirmPassword && <p className="error-input">{errors.confirmPassword.message}</p>}
                            </div>
                        </>
                }
                <Checkbox name="active" press={toggleActive} checked={account.active}/>
            </div>

            <div className="actions">
                <Button type='btn-accent' press={previous} name='Précédent'/>
                <Button type='btn-primary' name='Suivant' loading={loading}/>
            </div>
        </form>
    );
}
