import React, {PureComponent} from 'react';
import './choice-item.css';
import {AiOutlineCheck, BsBuilding} from "react-icons/all";

export class ChoiceItem extends PureComponent {


    constructor(props) {
        super(props);
    }

    componentDidMount() { }

    press() {
        if(this.props.press){
            this.props.press();
        }
    }

    render() {
        const ICON = this.props.icon;
        const css = !this.props.active ? 'choice-item' : 'choice-item choice-item-active';
        return (
            <div className={css} onClick={this.props.press}>
                { this.props.active ? <AiOutlineCheck/> : <ICON/> }
                <span>{this.props.description}</span>
            </div>
        );
    }
}




