import React, {Component} from 'react';
import {connect} from "react-redux";

import PerfectScrollbar from "react-perfect-scrollbar";
import './vehicle-details-map.css';
import {KmStats} from "../../../../components/vehicle/stats/km/km";
import {
    Clear,
    GetAlarmsStart,
    GetKMStart,
    GetSpeedStart, GetTripsStart
} from "../../../../store/details/details.actions";
import {Speed} from "../../../../components/vehicle/stats/speed/speed";
import {GiRoad, IoIosNotificationsOutline} from "react-icons/all";
import {LoadingComponent} from "../../../../components/loading/loading";
import moment from "moment-timezone";


class VehicleDetailsMap extends Component {

    constructor(props) {
        super(props);
        this.state = {height: `${window.innerHeight - 108}px`};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {

        this.fetchDetails();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.id !== this.props.id) {
            this.fetchDetails();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        this.props.dispatch(Clear());
    }

    updateWindowDimensions() {
        this.setState({height: `${window.innerHeight - 108}px`});
    }


    fetchDetails() {
        // const index = this.props.route.params.vehicle.i;
        const from = moment().tz(this.props.timezone).set({hour: 0, minute: 0, second: 0, millisecond: 0});
        const to = moment().tz(this.props.timezone).set({hour: 23, minute: 59, second: 59, millisecond: 0});
        const fromm = moment().tz(this.props.timezone).subtract(7, 'days').set({hour: 23, minute: 59, second: 59, millisecond: 0});


        this.props.dispatch(GetSpeedStart({from: from.toDate(), to: to.toDate(), _id: this.props.id}));
        this.props.dispatch(GetAlarmsStart({from: from.toDate(), to: to.toDate(), _id: this.props.id}));
        this.props.dispatch(GetTripsStart({from: from.toDate(), to: to.toDate(), _id: this.props.id}));
        this.props.dispatch(GetKMStart({from: fromm.toDate(), to: to.toDate(), _id: this.props.id}));
    }

    render() {
        const vehicle = this.props.vehicles.find(el => el._id === this.props.id);
        if (vehicle)
            return (
                <PerfectScrollbar className="items-details-vehicle"
                                  style={{height: this.state.height, flex: 1, zIndex: 992}}>
                    <Speed
                        speed={vehicle.dataVehicle.speed || 0}
                        min={this.props.details.speed.min}
                        max={this.props.details.speed.max}
                    />
                    <KmStats data={this.props.details.kms} lKms={this.props.details.lKms}
                             unitSpeed={vehicle.unitSpeed}/>
                    <div className="more-info">
                        <div className="item-info-vehicle">
                            <LoadingComponent loading={this.props.details.lTrips} left="0px" top="0px"
                                              background={'rgba(255, 255, 255, 0.7)'}/>

                            <div className="item-info-vehicle-detail">
                                <label>Trajets</label>
                                <label>{this.props.details.trips}</label>
                            </div>
                            <div className='icon-info'>
                                <GiRoad/>
                            </div>
                        </div>
                        <div className="item-info-vehicle">
                            <LoadingComponent loading={this.props.details.lAlarms} left="0px" top="0px"
                                              background={'rgba(255, 255, 255, 0.7)'}/>
                            <div className="item-info-vehicle-detail">
                                <label>Alarmes</label>
                                <label>{this.props.details.alarms}</label>
                            </div>
                            <div className='icon-info'>
                                <IoIosNotificationsOutline/>
                            </div>
                        </div>

                    </div>
                </PerfectScrollbar>
            );
        return <></>
    }
}

const VehicleDetailsStateToProps = (state) => {
    return {
        timezone: state.authReducer.user.timezone,
        details: state.detailsReducer,
        vehicles: state.vehiclesMapReducer.vehicles.list,
        id: state.vehiclesMapReducer.details,
    }
}
export default connect(VehicleDetailsStateToProps)(VehicleDetailsMap);
