import React, { Component }  from 'react';
import './companies.css';
import { AiOutlinePlus, AiOutlineSearch} from "react-icons/ai";
import CountUp from "react-countup";
import {HeaderAction} from "../../components/header/header-action/header-action";
import {SearchAction} from "../../components/header/search-action/search-action";
import {ShapeDetails} from "../../components/widgets/shape-details/shape-details";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from 'react-perfect-scrollbar'
import {BsCheckAll} from "react-icons/bs";
import {AiOutlineClose, AiOutlineReload} from "react-icons/ai";
import {BiCalendarExclamation} from "react-icons/bi";
import {CompaniesInfo} from "../../components/company/company-info";
import {FilterAction} from "../../components/header/filter-action/filter-action";
import {SideBar} from "../../components/sideBar/sideBar";
import Step1 from "./add-company/step1/step1";
import Step2 from "./add-company/step2/step2";
import {
    deleteCompanyStart,
    GetCompaniesStart,
    SetStep,
    SetStepData, SwitchCompanyStart,
    ToogleStep
} from "../../store/comapny/company.actions";
import {connect} from "react-redux";
import {ShapeElementInfo} from "../../components/widgets/shape-element-info/shape-element-info";
import {LoadingComponent} from "../../components/loading/loading";
import {Paginator} from "../../components/pagination/paginator/Paginator";
import { confirmAlert } from 'react-confirm-alert';
import {BlankComponent} from "../../components/blank/blank";
import {ConfirmationDialog} from "../../components/confirmation-dialog/confirmation-dialog";
import Step3 from "./add-company/step3/step3";
import Step0 from "./add-company/step0/step0";
class CompaniesPage extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 176}px`};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(GetCompaniesStart({}));
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 176}px` });
    }

    findCompanyById(id){
        return this.props.companies.find(el => id === el._id);
    }

    /*
     * Events
     * */

    deleteAction(id) {
        this.props.dispatch(deleteCompanyStart(id));
    }


    companyAction(action) {
        switch (action.action) {
            case 'reload':
                this.props.dispatch(GetCompaniesStart({}))
                break;
            case 'login':
                const update = {company: {id: action.id}};
                this.props.dispatch(SwitchCompanyStart({update}))
                break;
            case 'add':
                this.props.dispatch(ToogleStep());
                break;
            case 'del':
                const options = {
                    customUI: ({ onClose }) =>
                        <ConfirmationDialog
                            close={onClose}
                            action={this.deleteAction.bind(this)}
                            id={action.id}
                            keyword={action.name}
                            ActionName={"Supprimer"}/>,
                    closeOnEscape: false,
                    closeOnClickOutside: false
                };
                confirmAlert(options);
                break;
            case 'edit':
                const company = this.findCompanyById(action.id);
                if(company) {
                    this.props.dispatch(SetStepData({
                        step: 1,
                        data: {
                            id: company._id,
                            name: company.name,
                            email: company.email,
                            address: company.address,
                            phone: company.phone,
                            active: company.active,
                            subscription: company.subscription,
                            type: company.type,
                            mapBox: company.mapBox || '',
                            googleMaps: company.googleMaps || ''
                        }
                    }));
                }
                break;
        }
    }

    filterAction(action) {
        switch (action.key) {
            case 'name':
                this.props.dispatch(GetCompaniesStart({
                    name: action.value,
                    skip: 0
                }))
                break;

            case 'filter':
                this.props.dispatch(GetCompaniesStart({
                    filter: action.value,
                    skip: 0
                }))
                break;
        };
    }

    paginate(skip, limit) {
        this.props.dispatch(GetCompaniesStart({skip: skip}));
    }

    render() {
        const filters= this.props.filters.data;
        const params= this.props.filters.params;
        return (
            <section id="companies">
                <LoadingComponent loading={this.props.loading}/>
                <div className="header-section">
                    <div className="filter">
                        {
                            filters && filters.map((el, i)=> {
                               return <FilterAction
                                   key={i}
                                   active={el.filter === params.filter}
                                   label={el.name}
                                   value={el.filter}
                                   press={this.filterAction.bind(this)}
                               />
                            })
                        }
                    </div>
                    <div className="action-right">

                        <HeaderAction
                            value={{action: 'reload'}}
                            press={this.companyAction.bind(this)}
                            icon={AiOutlineReload}
                        />
                        <HeaderAction
                            value={{action: 'add'}}
                            press={this.companyAction.bind(this)}
                            icon={AiOutlinePlus}
                        />
                        <SearchAction
                            icon={AiOutlineSearch}
                            indentifier="name"
                            onChange={this.filterAction.bind(this)}
                        />
                    </div>

                </div>
                <div style={{display: 'flex'}}>
                    <PerfectScrollbar className="scrollbar-container animmation-scroll" style={{ height: this.state.height }}>
                        {
                            this.props.companies.length > 0  ? this.props.companies.map((company, i) => {
                               return <CompaniesInfo
                                   key={i}
                                   id={company._id}
                                   active={company.active}
                                   name={company.name}
                                   phone={company.phone}
                                   address={company.address}
                                   vehicles={company.vehicles}
                                   groups={company.groups}
                                   users={company.accounts}
                                   alarms={company.alarms}
                                   current={company._id === this.props.companyId && this.props.companyId}
                                   press={this.companyAction.bind(this)}
                               />
                            }) : !this.props.loading && <BlankComponent/>
                        }
                        <Paginator paginate={this.paginate.bind(this)} limit={params.limit} skip={params.skip} total={this.props.total}/>
                    </PerfectScrollbar>
                    <ShapeDetails>
                        <div className="circle-chart">
                            <div className="chart-value">
                                <CountUp className="value" delay={1} end={this.props.stats.total} duration={2}/>
                                <span className="description">Entreprises</span>
                            </div>
                        </div>
                        <div className="description">
                            <ShapeElementInfo
                                name="Activé"
                                icon={BsCheckAll}
                                value={this.props.stats.active}
                                first
                            />
                            <ShapeElementInfo
                                name="Désactivé"
                                icon={AiOutlineClose}
                                value={this.props.stats.deactive}
                            />
                            <ShapeElementInfo
                                name="Fin d'abonnement"
                                icon={BiCalendarExclamation}
                                value={this.props.stats.endSubscription}
                            />
                        </div>
                    </ShapeDetails>

                    {
                        this.props.addCompany.step ?
                            <SideBar
                                height={window.innerHeight}
                                countSteps={this.props.addCompany.steps.length}
                            step={this.props.addCompany.step}
                            stepsText={this.props.addCompany.steps}
                        >
                            {
                                this.props.addCompany.step === 1 && <Step0
                                    data={this.props.addCompany.data}/>
                            }
                            {
                                this.props.addCompany.step === 2 && <Step1
                                data={this.props.addCompany.data}/>
                            }
                            {
                                this.props.addCompany.step === 3 && <Step2
                                data={this.props.addCompany.data}/>
                            }
                            {
                                this.props.addCompany.step === 4 && <Step3
                                data={this.props.addCompany.data}/>
                            }

                        </SideBar> : <></>
                    }
                </div>
            </section>
        );
    }
}

const companiesStateToProps = (state) => {
    return {
        companyId: state.authReducer.user && state.authReducer.user.company._id,
        companies: state.companyReducer.companies,
        total: state.companyReducer.total,
        filters: state.companyReducer.filters,
        stats: state.companyReducer.stats,
        loading: state.companyReducer.loading,
        addCompany: state.companyReducer.addCompany,
    }
}
export default connect(companiesStateToProps)(CompaniesPage);