import React, {Component} from "react";
import {AiOutlineSearch} from "react-icons/ai";
import PerfectScrollbar from "react-perfect-scrollbar";
import {BlankComponent} from "../../components/blank/blank";
import {Paginator} from "../../components/pagination/paginator/Paginator";
import {connect} from "react-redux";
import './tracer.css';
import {LoadingComponent} from "../../components/loading/loading";
import { ClearReports} from "../../store/reports/report.actions";
import {GetTracersStart, SetActive} from "../../store/tracers/tracers.actions";
import {SectionComponent} from "../../components/description-section/section";
import ListTracersPage from "./list-tracers/list-tracers";

class TracersPage extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 210}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        // this.props.dispatch(GetTracersStart({}));
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        this.props.dispatch(ClearReports());

    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 210}px` });
    }

    changed(e) {
        if(e.keyCode === 13) {
            this.props.dispatch(GetTracersStart({
                name: `${e.target.value}.*`
            }));
        }
    }

    paginate(skip, limit) {
        this.props.dispatch(GetTracersStart({skip: skip}));
    }

    activeTracer(_id) { this.props.dispatch(SetActive(_id)); }

    accountAction() {

    }

    filterAction() {

    }
    render() {
        const params= this.props.filters.params;
        const { active } = this.props.tracers;
        return (
            <section id="tracer-section" style={{flex: !active ? 1 : 'unset'}}>
                <div className="tracer">
                    <LoadingComponent loading={this.props.loading}/>
                    <div className="action-search-alarm">
                        <input type="text" placeholder="Chercher..."/>
                        <AiOutlineSearch/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                        <div style={{padding:'0px 3px', marginBottom: '12px', display: 'flex', flexWrap: 'wrap',
                            alignContent: 'flex-start' }}>
                            {
                                this.props.tracers.list.length > 0 ?this.props.tracers.list.map((el, i) => {
                                    const classCss =  el._id !== this.props.active ? "tracer-comp" : "tracer-comp tracer-comp-active";
                                    return (
                                        <div className={classCss} onClick={() => this.activeTracer(el._id)}>
                                            <img src={el.img}/>
                                           <span>{el.name}</span>
                                        </div>
                                    );

                                }): <BlankComponent/>
                            }
                        </div>
                        <Paginator
                            noMargin={true}
                            paginate={this.paginate.bind(this)}
                            limit={params.limit}
                            skip={params.skip}
                            total={this.props.tracers.total}/>
                    </div>
                </div>
                {
                    <div className="content">
                        { this.props.active ? <ListTracersPage id={this.props.active}></ListTracersPage> :
                            <SectionComponent img={"/img/tracer/tracer.svg"} description="Choisissez un type de traceur pour continuer"/>
                        }
                    </div>
                }
            </section>
        );
    }
}

const VehiclesStateToProps = (state) => {
    return {
        filters: state.tracersReducer.filters,
        tracers: state.tracersReducer.tracers,
        loading: state.tracersReducer.loading || state.tracersReducer.tracer.loading,
        active: state.tracersReducer.active,
        tracer: state.tracersReducer.tracer
    }
}
export default connect(VehiclesStateToProps)(TracersPage);
