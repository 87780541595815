import {BASE_URL} from "../../env";
const axios = require('axios');

export function fetchCompanies(payload, token) {
    let params = '';
    for (let param in payload) {
        params+=`${param}=${payload[param]}&`
    }
    const url = `${BASE_URL}/api/company/all?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}


export async function fetchCompanyByName(name, token) {
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    const url =`${BASE_URL}/api/company?projection[name]=1&filter[name]=${name}&projection[_id]=0`;
    try{
        const res = await axios.get(url, config);
        return !!res.data;
    }catch (e) { return false; }
}

export async function addCompany(payload, token) {
    const url = `${BASE_URL}/api/company`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.post(url, payload, config);
}

export async function updateCompany(payload, token) {
    const url = `${BASE_URL}/api/company`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.put(url, payload, config);
}

export async function deleteCompany(payload, token) {
    const url = `${BASE_URL}/api/company/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.delete(url, config);
}