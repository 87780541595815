const findRole = (name, where) => {
    return where.findIndex(r => r === name) !== -1;
}

export const canShowReport = (userType, roles) => {
    return userType === 'admin' || findRole('report:rd', roles) || findRole('report:wt', roles) || findRole('report:dt', roles)
}

export const canManageReport = (userType, roles) => {
    return userType === 'admin' || findRole('report:wt', roles);
}

export const canDeleteReport = (userType, roles) => {
    return userType === 'admin' || findRole('report:dt', roles)
}
