import React, {PureComponent} from "react";
import {MdTrackChanges} from "react-icons/md";
import './report-type.css'
import {
    IoIosWater,
    AiOutlineQuestionCircle,
    GiCaptainHatProfile,
    IoIosNotificationsOutline, MdTimeline, BsCardChecklist, GiRoad, BiGasPump,
} from "react-icons/all";

export class ReportTypeInfo extends PureComponent {

    constructor(props) { super(props); }
    press() {
        if(this.props.press && this.props.enable) {
            this.props.press(this.props.report);
        }
    }

    componentDidMount() { }

    getIcon() {
        switch (this.props.report.icon) {
            case 'notifications':
                return IoIosNotificationsOutline;
            case 'timeline':
                return MdTimeline;
            case 'fact_check':
                return BsCardChecklist;
            case 'theaters':
                return GiRoad;
            case 'FaGasPump':
                return BiGasPump;
            case 'GiCaptainHatProfile':
                return GiCaptainHatProfile
            case 'IoWater':
                return IoIosWater
            default:
                return AiOutlineQuestionCircle;
        }
    }

    render() {
        const report = this.props.report;
        const ICON = this.getIcon();
        if(report){
                let classEvent = 'report-type';
                if(this.props.active._id === this.props.report._id) {
                    classEvent+=' report-type-active';
                } else if(!this.props.enable) {
                    classEvent+=' disabled-report';
                }
            return(
                <div className={classEvent} onClick={this.press.bind(this)}>
                    <ICON/>
                    <div className="description-event">
                        <span className="name-type">{report.name}</span>
                        <span className="description">{report.description}</span>
                    </div>
                </div>
            );
        }
       return <></>

    }
}

