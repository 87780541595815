import * as actions from "./details.actions";
import {catchError, map, switchMap} from 'rxjs/operators'
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {Error, GetAlarmsEnd, GetKMEnd, GetSpeedEnd, GetSpeedsEnd, GetTripsEnd} from "./details.actions";
import {fetchKm, fetchSpeed, fetchCountAlarms, fetchCountTrips} from "../../pages/vehicles/vehicleService";
import {AutoLogout} from "../auth/auth.actions";

export const FetchSpeed = (action$, state$) => action$.pipe(
    ofType(actions.GET_START_SPEED),
    switchMap( action => {
        return from(fetchSpeed(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                return GetSpeedEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const FetchAlarms = (action$, state$) => action$.pipe(
    ofType(actions.GET_START_ALARMS),
    switchMap((action) => {
        return from(fetchCountAlarms(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                return GetAlarmsEnd(res.data.alarms);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const FetchTrips = (action$, state$) => action$.pipe(
    ofType(actions.GET_START_TRIPS),
    switchMap((action) => {
        return from(fetchCountTrips(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                return GetTripsEnd(res.data.trips);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const FetchKm = (action$, state$) => action$.pipe(
    ofType(actions.GET_START_KM),
    switchMap((action) => {
        return from(fetchKm(action.payload, state$.value.authReducer.token)).pipe(
            map(res =>{
                const maximum = Math.max.apply(Math, res.data.map((o) => o.max));
                let min, minStr, maxStr;
                let today = undefined;
                const day = new Date().getDay();
                return {
                    stats: res.data.map(
                        (o) => {
                            const m = (o.max/maximum) || 0;
                            if(maximum === o.max){ maxStr = o._id -1; }
                            if(!min || min > o.max ) { min = o.max; minStr = o._id -1; }
                            return {d: o._id - 1, value: o.maxV, max: m}
                        },
                    ),
                    max: maxStr,
                    min: minStr,
                    today: res.data[6].max
                };
            }),
            map(res => {

                return GetKMEnd(res);
            }),
            catchError(err => {
                console.log(err)
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        console.log(err)
        return of(Error('Problem unknow'))
    })
);

