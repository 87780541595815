import {AiOutlineDoubleLeft, AiOutlineDoubleRight, FaPlus} from "react-icons/all";
import {Button as BTN} from "antd";
import {SetShowListReportType} from "../../../../store/reports/report.actions";
import PerfectScrollbar from "react-perfect-scrollbar";
import {BlankComponent} from "../../../../components/blank/blank";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RoleInfo} from "../role-info";
import {SetActive, SetAdd} from "../../store/role.actions";
import {canDeleteRole, canManageRole} from "../../../../common/role";


export const ListRole = ({roles, active}) => {
    const {type, roleAuthorization} = useSelector(state => {
        const { user } = state.authReducer;
        return {
            type: user.type,
            roleAuthorization: user?.role
        }
    });
    const dispatch = useDispatch();
    const [show, setShow] = useState(true);
    return(
        <>
            <div className={`role ${!show ? 'role-hide' : ''}`}>
                <div className="header">
                    <label className="title">Rôles</label>
                    <AiOutlineDoubleLeft className="icon-close" onClick={()=>setShow(false)}/>
                </div>
                <BTN className="addReport" type="dashed" onClick={()=>dispatch(SetAdd(true))}>
                    <FaPlus className="icon-addRole"/>
                    <span>Créer un nouveau rôle</span>
                </BTN>
                <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                    <PerfectScrollbar style={{ height: `${window.innerHeight - 190}px`, padding:'0px 3px', marginBottom: '12px' }}>
                        {
                            Array.isArray(roles) && roles.length > 0 ?
                                roles.map((role, index) => {
                                    return <RoleInfo key={index} index={index} role={role} active={active === index}
                                     canDelete={canDeleteRole(type, roleAuthorization)}
                                     canManage={canManageRole(type, roleAuthorization)}
                                    />
                                })
                                : <BlankComponent/>
                        }
                    </PerfectScrollbar>
                </div>
            </div>
            {
                !show &&  <div className="mini-report" onClick={()=>setShow(true)}>
                    <div className="text">
                        <AiOutlineDoubleRight className="icon-close" />
                        <span className="title">Rôles</span>
                    </div>
                </div>
            }
        </>

    );
}
