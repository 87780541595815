import * as actions from "./drivers.actions";
import {catchError, map, switchMap} from 'rxjs/operators'
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {AutoLogout} from "../auth/auth.actions";
import {Error} from "../comapny/company.actions";
import {NotificationManager} from "react-notifications";
import {addDriver, deleteDriver, fetchDriver, updateDriver} from "../../pages/drivers/driversService";



export const FetchDriverEpic = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        const params = {...state$.value.driversReducer.filters.params, ...action.payload};
        return from(fetchDriver(params, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.getDriversEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const SaveDriverEpic = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_START),
    switchMap((action) => {

        delete action.payload._id;
        if(action.payload.id) {
            return from(updateDriver(action.payload, state$.value.authReducer.token)).pipe(
                map(res => {
                    NotificationManager.success('Le conducteur est modifié avec succès', 'Modification', 2000);
                    const updateDriver = {
                        ...{
                            id: action.payload.id,
                        },
                        ...action.payload
                    }
                    return actions.SaveDriverEnd(updateDriver);
                }),
                catchError(err => {
                    let msg = 'une erreur s\'est produite !';
                    if (err.response.status === 401) {
                        return of(AutoLogout(), Error(''));
                    }
                    return of(Error(msg))
                })
            )
        }
        const driver = {...state$.value.driversReducer.addDriver.data, ...action.payload};
        delete driver.id;
        return from(addDriver(driver, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('Le conducteur est créé avec succès', 'Création', 2000);
                const newAccount = {
                    ...{
                        _id: res.data.insertedId,
                        id: res.data.insertedId,
                    },
                    ...driver
                }
                return actions.SaveDriverEnd(newAccount);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const DeleteDriverEpic = (action$, state$) => action$.pipe(
    ofType(actions.DELETE_START),
    switchMap((action) => {
        return from(deleteDriver(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('le compte est supprimée avec succès', 'Suppression', 2000);
                return actions.deleteDriverEnd({response: res.data, id: action.payload});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, 'Suppression', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Suppression', 2000);
        return of(Error('Problem unknow'))
    })
);
