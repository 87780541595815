import React, { Component }  from 'react';
import './loading.css'
import {ImSpinner10} from "react-icons/im";

export class LoadingComponent extends Component {

    constructor(props) { super(props); }

    render() {
        let st = {};
        if(this.props.left) { st.marginLeft = this.props.left }
        if(this.props.top) { st.marginTop = this.props.top }
        if(this.props.background) { st.backgroundColor = this.props.background }
        return (
            <>
                {
                    this.props.loading &&
                        <div className="loading-container" style={st}>
                            <div className="loading-item">
                                <ImSpinner10/>
                                <span>Chargement...</span>
                            </div>

                        </div>
                }
            </>
    );
    }
}