
export function getMonth(momentDate) {
    switch (momentDate.month()) {
        case 0:
            return 'Jan';
        case 1:
            return 'Fév';
        case 2:
            return 'Mar';
        case 3:
            return 'Avr';
        case 4:
            return 'Mai';
        case 5:
            return 'Jui';
        case 6:
            return 'Juil';
        case 7:
            return 'Aoû';
        case 8:
            return 'Sep';
        case 9:
            return 'Oct';
        case 10:
            return 'Nov';
        case 11:
            return 'Déc';
    }
}


export function getDate(momentDate) {
    return momentDate.date();
}