export const ACTIVE_MENU = '[MAIN] ACTIVE_MENU';
export const SET_SIZE_MENU = '[MAIN] SET_SIZE_MENU';
export const MIN_SIZE_MENU = '[MAIN] MIN_SIZE_MENU';

export function ActiveMenu(payload) {
    return { type: ACTIVE_MENU, payload }
}

export function SetSizeMenu() {
    return { type: SET_SIZE_MENU }
}

export function MinSizeMenu() {
    return { type: MIN_SIZE_MENU }
}

