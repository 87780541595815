import React, { Component }  from 'react';
import './login.css';
import {Button} from "../../components/widgets/form/button/button";
import {AiOutlineUser, BsInfoCircle, RiLockPasswordLine} from "react-icons/all";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {LoginStart} from "../../store/auth/auth.actions";
import {Alert} from "../../components/alert/alert";
export default function  LoginPage(props) {
    const dispatch = useDispatch();
    const {loading, authError} = useSelector(state => {
        return {
            loading: state.authReducer.loading,
            authError: state.authReducer.authError
        }
    })
    const { register, handleSubmit, setError, errors, formState  } = useForm(
        { mode: "onChange",
            defaultValues: { username: "", password: "" }
        },
    );



    const onSubmit = async(data) => {
        dispatch(LoginStart(data));
    };

    const styleUsername = !errors.username ? 'input-form'  : 'input-form input-form-error';
    const stylePassword = !errors.password ? 'input-form' : 'input-form input-form-error';
    const styleBackground = props.noBackground ? '' : 'login-background';
        return (
            <div id="loginPage" className={styleBackground}>
                <div className="main">
                    <div className="part2">
                        <img src="/img/logo.svg" className="logo"/>
                        <label className="company-name">Telematics services</label>
                        <div className="title">
                            <h4>Connexion</h4>
                            <div className="description">
                                Bienvenue, veuillez vous connecter à votre compte.
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {
                                props.notification && !authError &&
                                    <Alert icon={BsInfoCircle} msg={props.notification} type="info"/>
                            }
                            <Alert msg={authError} type="danger"/>
                            <div className={styleUsername}>
                                <AiOutlineUser/>
                                <input name="username" type="text" placeholder="Nom d'utilisateur"
                                   ref={register(
                                       {required: {value: true, message: 'Ce champs est obligatoire'}}
                                   )}/>
                                {errors.username && <p className="error-input">{errors.username.message}</p>}
                            </div>
                            <div className={stylePassword} style={{marginBottom: "18px"}}>
                                <RiLockPasswordLine/>
                                <input name="password" type="password" placeholder="Mot de passe"
                                    ref={register(
                                        {required: {value: true, message: 'Ce champs est obligatoire'}}
                                )}/>
                                {errors.password && <p className="error-input">{errors.password.message}</p>}
                            </div>
                            <Button name="Se connecter" type="btn-primary" loading={loading}/>
                        </form>
                    </div>
                    <div className="part1"></div>
                </div>
            </div>
        );
}
