import React, {Component} from "react";
import {connect} from "react-redux";
import {
    DeleteStart,
    GetAlarmsStart,
    GetAlarmStart,
    GetAlarmUpdateStart,
    SetActive,
    ToogleStep
} from "../../../store/alarms/alarms.actions";
import {AlarmInfo} from "../../../components/alarms/alarm-info/alarm-info";
import PerfectScrollbar from "react-perfect-scrollbar";
import {LoadingComponent} from "../../../components/loading/loading";
import {BlankComponent} from "../../../components/blank/blank";
import {HeaderAction} from "../../../components/header/header-action/header-action";
import {AiOutlinePlus, AiOutlineReload, AiOutlineSearch} from "react-icons/ai";
import {SearchAction} from "../../../components/header/search-action/search-action";
import {DeleteAccountStart, GetAccountsStart} from "../../../store/accounts/accounts.actions";
import {Paginator} from "../../../components/pagination/paginator/Paginator";
import AddAlarmPage from "../add-alarm/add-alarm";
import {ConfirmationDialog} from "../../../components/confirmation-dialog/confirmation-dialog";
import {confirmAlert} from "react-confirm-alert";

class ListAlarmsPage extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 210}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(GetAlarmStart({}));
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.id !== this.props.id) {
            this.props.dispatch(GetAlarmStart({}));
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        // this.props.dispatch(ClearVehicles());

    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 210}px` });
    }

    changed(e) {
        if(e.keyCode === 13) {
            this.props.dispatch(GetAlarmStart({
                name: `${e.target.value}.*`,
                skip: 0
            }));
        }
    }

    paginate(skip, limit) { this.props.dispatch(GetAlarmStart({skip: skip}));}

    alarmAction(action) {
        switch (action.action) {
            case 'add':
                this.props.dispatch(ToogleStep());
                break;
            case 'edit':
                this.props.dispatch(GetAlarmUpdateStart(action.id));
                break;
            case 'reload':
                this.props.dispatch(GetAlarmStart({}));
                break;
            case 'del':
                const options = {
                    customUI: ({ onClose }) =>
                        <ConfirmationDialog
                            close={onClose}
                            action={this.deleteAction.bind(this)}
                            id={action.id}
                            keyword={action.name}
                            ActionName={"Supprimer"}/>,
                    closeOnEscape: false,
                    closeOnClickOutside: false
                };
                confirmAlert(options);
                break;

        }
    }

    deleteAction(id) {
        this.props.dispatch(DeleteStart(id));
    }


    filterAction(action) {
        switch (action.key) {
            case 'name':
                this.props.dispatch(GetAlarmStart({
                    name: action.value,
                    skip: 0
                }))
                break;

            case 'filter':
                this.props.dispatch(GetAlarmStart({
                    filter: action.value,
                    skip: 0
                }))
                break;
        };
    }

    render() {
        const params= this.props.filters.params;
        if(this.props.step === -1)
            return (
                <>
                    <div className="header-section">
                        <div className="action-right">
                            <HeaderAction
                                value={{action: 'reload'}}
                                press={this.alarmAction.bind(this)}
                                icon={AiOutlineReload}
                            />
                            <HeaderAction
                                value={{action: 'add'}}
                                press={this.alarmAction.bind(this)}
                                icon={AiOutlinePlus}
                            />
                            <SearchAction
                                icon={AiOutlineSearch}
                                indentifier="name"
                                onChange={this.filterAction.bind(this)}
                            />
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                    <PerfectScrollbar className="scrollbar-container-alarm" style={{ height: this.state.height }}>
                        {
                            this.props.alarms && this.props.alarms.length > 0 ?
                                this.props.alarms.map((alarm, i) =>  <AlarmInfo key={i} alarm={alarm} press={this.alarmAction.bind(this)}/>) :
                            <BlankComponent/>
                        }
                    </PerfectScrollbar>
                    <Paginator
                        noMargin={true}
                        paginate={this.paginate.bind(this)}
                        limit={params.limit}
                        skip={params.skip}
                        total={this.props.total}/>
                </div>
                </>
            );
        else
            return <AddAlarmPage/>;
    }
}

const VehiclesStateToProps = (state) => {
    return {
        alarms: state.alarmsReducer.alarm.list,
        filters: state.alarmsReducer.alarm.filters,
        total: state.alarmsReducer.alarm.total,
        id: state.alarmsReducer.active._id,
        step: state.alarmsReducer.addAlarm.step,
    }
}
export default connect(VehiclesStateToProps)(ListAlarmsPage);