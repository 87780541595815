import React, {PureComponent, useState} from 'react';
import './manage-command.css';
import {Button} from "../../../../components/widgets/form/button/button";
import {connect, useDispatch, useSelector} from "react-redux";
import {SaveCommandsStart, ToogleStep} from "../../../../store/vehicles/vehicles.actions";
import {Popconfirm, Select, Button as AButton, Spin} from "antd";
import {AiOutlineDelete, AiOutlineSend} from "react-icons/all";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import {fetchCommands as commandsService} from "../../../commands/commandService";
import debounce from 'lodash/debounce';
import PerfectScrollbar from "react-perfect-scrollbar";
import {GetGroupsStart} from "../../../../store/group/group.actions";

class ManageCommand extends PureComponent{

    constructor(props) {
        super(props);
        this.state = {loading: false, data: [], values: [], height: `${window.innerHeight - 340}px`};
        this.fetchCommands = debounce(this.fetchCommands, 800);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
    }

    updateWindowDimensions() {
        this.setState({ height: `${window.innerHeight - 340}px`})
    }
    saveCommands() {
        const vehicle = this.props.vehicle;
        this.props.dispatch(SaveCommandsStart({vehicle, commands: this.state.values}));
        this.setState({
            data: [],
            loading: false,
            values:[]
        })
    }

    deleteCommand(command){
        const vehicle = this.props.vehicle;
        this.props.dispatch(SaveCommandsStart({
            vehicle, commands: [command._id], delete: true}));
    }

    fetchCommands(value){
        this.setState({loading: true});
        const payload = {name: `${value}.*`, filter: 'all', limit: 10, skip: 0};
        const commands = commandsService(payload, this.props.token);
        commands.then(res => {
            this.setState({data: res.data.list, loading: false});
        })
    }
    handleChange(value){
        const values = value.map(el => {
            return { _id: el.key, command: el.label[2], name: el.label[0],
                label: `${el.label.join('')}`, key: el.key }
        });
        this.setState({
            data: [],
            loading: false,
            values
        });
    };
    previous(){ this.props.dispatch(ToogleStep()); }
    render() {
        const { loading, data, values } = this.state;
        return (
            <div className="form-aside">
                <div>
                    <div className="add-commands-list">
                        <Select
                            value={values}
                            mode="multiple"
                            labelInValue
                            placeholder="Selectionner une commande"
                            filterOption={false}
                            notFoundContent={this.state.loading ? <Spin size="small" /> : null}
                            onSearch={this.fetchCommands.bind(this)}
                            onChange={this.handleChange.bind(this)}
                            style={{ width: '100%', marginRight: 10 }}
                        >
                            {data.map(d => (
                                <Select.Option  key={d._id}>{d.name}-{d.command}</Select.Option>
                            ))}
                        </Select>
                        <AButton
                            type="primary"
                            loading={this.props.loading}
                            onClick={this.saveCommands.bind(this)}
                            icon={<PlusOutlined />}
                            disabled={this.state.values.length === 0}/>
                    </div>

                    <PerfectScrollbar style={{ height: this.state.height }}>
                        {
                            this.props.commands && this.props.commands.map((el, i) => {
                                return(
                                    <div key={i} className="command-vehicle">
                                        <div className="info-command">
                                            <AiOutlineSend />
                                            <div className="label-command">
                                                <label>{el.name}</label>
                                                <label>{el.command}</label>
                                            </div>
                                        </div>
                                        <Popconfirm
                                            title="Vous êtes sûr？"
                                            okText="Oui"
                                            cancelText="Non"
                                            placement="left"
                                            onConfirm={() => this.deleteCommand(el)}>
                                            <AiOutlineDelete/>
                                        </Popconfirm>
                                    </div>
                                )
                            })
                        }
                    </PerfectScrollbar>



                </div>
                <div className="actions">
                    <Button type='btn-accent' press={this.previous.bind(this)} name='Fermer'/>
                </div>
            </div>
        );
    }
}
const vehiclesStateToProps = (state) => {
    return {
        vehicle: state.vehiclesReducer.addVehicle.vehicle,
        commands: state.vehiclesReducer.addVehicle.commands,
        loading: state.vehiclesReducer.addVehicle.loading,
        token: state.authReducer.token,

    }
}
export default connect(vehiclesStateToProps)(ManageCommand);
