import React, {Component, createRef} from 'react';
import './speedometer.css'
import CountUp from "react-countup";

export class Speedometer extends Component {

    constructor(props) {
        super(props);
        this.pathRef = createRef();
        this.state = {pathSize: 0}
    }

    componentDidMount() {
        if(this.pathRef.current) {
            this.setState({pathSize: this.pathRef.current.getTotalLength()});
        }
    }



    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.pathRef.current && prevState.pathSize !== this.pathRef.current.getTotalLength()) {
            this.setState({pathSize: this.pathRef.current.getTotalLength()});
        }

    }


    render() {
        let newSDA = (Number(this.props.percentage)*this.state.pathSize) / 100;
        let gap = this.state.pathSize - newSDA
        return (
            <div id="speedometer">
              <div className="svg_container">
                    <svg version="1.1" id="Layer_1" x="0px" y="0px"
                         viewBox="0 0 496.8 350">
                        <path strokeLinecap="round" id="gauge-path" d="M135,318.6c-31-29.8-50.3-71.7-50.3-118c0-90.4,73.3-163.7,163.7-163.7s163.7,73.3,163.7,163.7
            c0,46.4-19.3,88.3-50.3,118"/>
                        <defs>
                            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="64.6974" y1="156.8583" x2="432.0974" y2="156.8583" gradientTransform="matrix(1 0 0 -1 0 331.8583)">
                                <stop  offset="0%" className="stop1" />
                                <stop  offset="70%" className="stop1" />
                                <stop  offset="100%" className="stop2"/>
                            </linearGradient>
                        </defs>

                        <path
                            ref={this.pathRef}
                            stroke="url(#SVGID_1_)"
                            strokeDasharray={`${this.state.pathSize*this.props.percentage/100}, ${this.state.pathSize}`}
                            strokeLinecap="round" id="gauge" d="M135,318.6c-31-29.8-50.3-71.7-50.3-118c0-90.4,73.3-163.7,163.7-163.7s163.7,73.3,163.7,163.7
            c0,46.4-19.3,88.3-50.3,118"/>
                    </svg>
                  <div className="detail-speed">
                      <label>
                          <CountUp preserveValue={true} delay={0} end={this.props.percentage} duration={2}/>
                      </label>
                      <label>
                          km/h
                      </label>
                  </div>
                  <div className="label-speed">
                      <label>Vitesse </label>
                      <label>actuel</label>
                  </div>
                </div>

            </div>

        );
    }
}