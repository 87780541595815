export function fitBounds(map, vehicles) {
    if (map != null) {

        const result = vehicles.map(vehicle =>  [ vehicle.dataVehicle.location.coordinates[1], vehicle.dataVehicle.location.coordinates[0]]);
        if(result.length>0)  map.leafletElement.fitBounds(result);
    }
}

export function fitBoundsPositions(map, positions) {
    if (map != null) {
        map.leafletElement.fitBounds(positions);
    }
}

export function changeView(map, latlng, zoom) {
    if (map != null) {
        map.leafletElement.panTo(latlng, {animate: false})
        map.leafletElement.setView(latlng, zoom);
    }
}

export function zoomIn(map) {
    if (map != null) { map.leafletElement.zoomIn(); }
}

export function zoomOut(map) {
    if (map != null) { map.leafletElement.zoomOut(); }
}

/**
 * InitZoneOptions: on every zone creation
 */
export function initZoneOptions(zone, color= '#1976D2', fillOpacity= .4) {
    zone.setStyle({color, opacity: 1, fillColor: color, fillOpacity});
}

/**
 * Start new zone (Circle, polygon or polyline)
 */
export function startNewZone(map, zoneInfo) {
    switch (zoneInfo.id) {
        case 'Polygon':
            // @ts-ignore
            this.zone = map.editTools.startPolygon();
            this.initZoneOptions();
            this.zone.enableEdit();
            this.zoneLayer.addLayer(this.zone);
            break;

        case 'Point':
            // @ts-ignore
            this.zone = map.editTools.startCircle();
            this.initZoneOptions();
            this.zone.enableEdit();
            this.circleDrag();
            this.zoneLayer.addLayer(this.zone);
            break;

        case 'LineString':
            // @ts-ignore
            this.zone = map.editTools.startPolyline();
            this.initZoneOptions();
            this.zone.enableEdit();
            this.eventDblClickLine(map);
            break;
    }
}
