import React, { Component }  from 'react';
import './zone-info.css';
import {FiPhone, FiMoreVertical} from "react-icons/fi";
import { Popover } from 'antd';
import {ActionPopover} from "../widgets/action-popover/action-popover";
import {AiOutlineEdit, AiOutlineDelete, AiOutlineMail, AiOutlineUser} from "react-icons/ai";
import {FaDrawPolygon, FaCircle} from "react-icons/fa";
import {MdTimeline} from "react-icons/md";
import {BiShowAlt, FaMapMarkerAlt} from "react-icons/all";

export class ZoneInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {visible: false};
    }

    componentDidMount() { }

    type() {
        const {location,radius} = this.props.zone;
        switch(location.type) {
            case 'Point':
                return this.props.zone.poi ? `POI (${radius} m)` : `Cércle (${radius} m)`;
            case 'Polygon':
                return 'Polygone';
            case 'LineString':
                return `Itinéraire (${radius} m)`;
            default:
                return this.props.zone.location.type;
        }
    }

    Icon(active) {
        const color = !active ? this.props.zone.color  : 'white';
        switch(this.props.zone.location.type) {
            case 'Point':
                return this.props.zone.poi ? <FaMapMarkerAlt color={color}/>  : <FaCircle color={color}/>;
            case 'Polygon':
                return <FaDrawPolygon color={color}/>;
            case 'LineString':
                return <MdTimeline color={color}/>;
            default:
                return <></>;
        }
    }

    activate(){
        if(this.props.press){
            this.props.press({
                action: 'select',
                _id: this.props.zone._id
            });
        }

    }


    render() {
        const active = this.props.active && (this.props.active._id === this.props.zone._id || this.props.active._id === this.props.zone.id);
        const text = <span>Title</span>;
        const content = (
            <>
                <ActionPopover icon={BiShowAlt} label="Afficher" press={this.props.press} value={{action: 'show', id: this.props.zone._id ||this.props.zone.id}}/>
                <ActionPopover icon={AiOutlineEdit} label="Modifier" press={this.props.press} value={{action: 'edit', id: this.props.zone._id ||this.props.zone.id}}/>
                <ActionPopover icon={AiOutlineDelete} press={this.props.press} label="Supprimer" value={{action: 'del', name: this.props.zone.name, id: this.props.zone._id ||this.props.zone.id}}/>
            </>
        );
        const pointer =  !this.props.disableMenu ? 'main' : 'main main-pointer';
        return (
            <div className={active? "card-zone-info card-zone-info-active" : "card-zone-info"}>
                {
                    !this.props.disableMenu &&  <div className="more-options">
                        <Popover
                            placement="rightTop"
                            content={content}
                            text={text} trigger="click"
                        >
                            <FiMoreVertical />
                        </Popover>
                    </div>
                }
                <div className={pointer} onClick={this.activate.bind(this)}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                        <div className="avatar-zones">
                            {this.Icon(active)}
                        </div>

                        <h5 className="name">{this.props.zone.name}</h5>
                        <div className="description">
                            <div className="descWidget">
                                <label>{this.type()}</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
