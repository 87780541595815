import React, { Component }  from 'react';
import './zones.css';
import {FilterAction} from "../../../../components/header/filter-action/filter-action";
import {HeaderAction} from "../../../../components/header/header-action/header-action";
import {AiOutlinePlus, AiOutlineReload, AiOutlineSearch} from "react-icons/ai";
import {SearchAction} from "../../../../components/header/search-action/search-action";
import {connect} from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import {BlankComponent} from "../../../../components/blank/blank";
import {Paginator} from "../../../../components/pagination/paginator/Paginator";
import {ZoneInfo} from "../../../../components/zone/zone-info";
import {GetZoneOneStart, GetZoneStart, StartDelete, StartZoneEdit, ToogleStep} from "../../../../store/zones/zones.actions";
import {Button} from "../../../../components/widgets/form/button/button";
import {SaveStart, SelectZone, SetStepData} from "../../../../store/alarms/alarms.actions";
import {LoadingComponent} from "../../../../components/loading/loading";


class ZonesStep extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 286}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(GetZoneStart({}));
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 286}px` });
    }

    deleteAction(id) {
        this.props.dispatch(StartDelete(id))
    }

    zoneAction(action) {
        if (action.action === 'reload') { this.props.dispatch(GetZoneStart({})); }
        else if(action.action === 'select') {
            this.props.dispatch(SelectZone(action._id));
        }
    }

    filterAction(action) {
        switch (action.key) {
            case 'name':
                this.props.dispatch(GetZoneStart({
                    name: action.value,
                    skip: 0
                }));
                break;

            case 'filter':
                this.props.dispatch(GetZoneStart({
                    filter: action.value,
                    skip: 0
                }))
                break;
        };
    }
    paginate(skip, limit) {
        this.props.dispatch(GetZoneStart({skip: skip}));
    }

    cancel() {  this.props.dispatch(SetStepData({step: this.props.step-1, data: {} })); }
    save() {
        const required = this.props.active && this.props.active.icon === 'track_changes'
            && ((this.props.activeZone && this.props.activeZone.length === 0) || !this.props.activeZone);
        if(!required) { this.props.dispatch(SaveStart()); }
    }
    render() {
        const filters= this.props.filters.data;
        const params= this.props.filters.params;
        const required = this.props.active && this.props.active.icon === 'track_changes'
            && ((this.props.activeZone && this.props.activeZone.length === 0) || !this.props.activeZone);
        return (
            <section id="zones-step">
                <LoadingComponent loading={this.props.loading}/>
                <div className="header-section">
                    <div className="filter">
                        {
                            filters && filters.map((el, i)=> {
                                return <FilterAction
                                    key={i}
                                    active={el.filter === params.filter}
                                    label={el.name}
                                    value={el.filter}
                                    press={this.filterAction.bind(this)}
                                />
                            })
                        }
                    </div>
                    <div className="action-right">
                        <HeaderAction
                            value={{action: 'reload'}}
                            press={this.zoneAction.bind(this)}
                            icon={AiOutlineReload}
                        />
                        <SearchAction
                            icon={AiOutlineSearch}
                            indentifier="name"
                            onChange={this.filterAction.bind(this)}
                        />
                    </div>

                </div>
                <div style={{display: 'flex'}}>
                    <PerfectScrollbar className="scrollbar-container-zone" style={{ height: this.state.height }}>
                        <div>
                            <div className="body">
                                {
                                    this.props.zones.length > 0  ? this.props.zones.map((zone,i) => {
                                        return <ZoneInfo
                                            key={i}
                                            active={this.props.activeZone && this.props.activeZone.indexOf(zone._id) !== -1}
                                            zone={zone}
                                            press={this.zoneAction.bind(this)}
                                            disableMenu={true}
                                        />
                                    }) : !this.props.loading && <BlankComponent/>
                                }
                            </div>
                            <Paginator paginate={this.paginate.bind(this)} limit={params.limit} skip={params.skip} total={this.props.total}/>
                        </div>

                        <div className="actions">
                            <Button type='btn-accent'  name='Précédent' press={this.cancel.bind(this)}/>
                            <Button type='btn-primary' name='Terminer' disabled={required } press={this.save.bind(this)}/>
                        </div>
                    </PerfectScrollbar>
                </div>
            </section>
        );
    }
}

const zonesStateToProps = (state) => {
    return {
        editTools: state.vehiclesMapReducer.editRef,
        zones: state.zonesReducer.zones,
        loading: state.zonesReducer.loading || state.zonesReducer.addZone.loading,
        filters: state.zonesReducer.filters,
        total: state.zonesReducer.total,
        addZone: state.zonesReducer.addZone,

        step: state.alarmsReducer.addAlarm.step,
        activeZone: state.alarmsReducer.addAlarm.zones,
        active:  state.alarmsReducer.active

    }
}
export default connect(zonesStateToProps)(ZonesStep);