import * as alarmsActions from "./alarms.actions";
import {LIMIT} from "../../env";
import {AiOutlineCalendar, AiOutlineCar, BiBox, GiRadarSweep, IoIosNotificationsOutline} from "react-icons/all";
import {Steps} from "antd";
import React from "react";
import {SELECT_VEHICLE, SET_STEP_DATA} from "./alarms.actions";
import * as accountActions from "../accounts/accounts.actions";

const INITIAL_STATE = {
    alarms: { list: [], total:0 },
    active: false,
    filters: { params: {skip: 0, limit: LIMIT} },
    loading: false,

    alarm: {
        list: [],
        total:0,
        filters: { params: {skip: 0, limit: LIMIT} },
        loading: false,
    },

    addAlarm: {
        active: -1,
        steps:[],
        step: -1,
        data: {},
        loading: false
    },
}

export function alarmsReducer(state = INITIAL_STATE, action){
    switch (action.type) {
        case alarmsActions.GET_START:
            const params = {...state.filters.params, ...action.payload};
            return Object.assign({}, state, {
                loading: true,
                filters: { ...state.filters, params }
            });

        case alarmsActions.GET_END:
            return{
                ...state,
                loading: false,
                new: false,
                addAlarm: INITIAL_STATE.addAlarm,
                active: INITIAL_STATE.active,
                alarms: {
                    ...state.alarms,
                    list: action.payload,
                    total: action.payload.length
                }
            }

        case alarmsActions.ERROR:
            return Object.assign({}, state, {
                loading: false
            })


        case alarmsActions.SET_ACTIVE:
            return {
                ...state,
                active: action.payload
            };

        case alarmsActions.GET_ALARM_START:
            const paramAlarm = {...state.alarm.filters.params, ...action.payload};
            return {
                ...state,
                alarm:{
                    ...state.alarm,
                    loading: true,
                    filters: { ...state.filters, params: paramAlarm }
                }
            }

        case alarmsActions.GET_ALARM_END:
            return {
                ...state,
                alarm:{
                    ...state.alarm,
                    list: action.payload.list,
                    total: action.payload.total,
                    loading: false,
                },
                addAlarm: INITIAL_STATE.addAlarm,
            }

        case alarmsActions.SET_TIMES:
            return {
                ...state,
                addAlarm:{
                    ...state.addAlarm,
                    times: action.payload
                }
            }

        case alarmsActions.SET_STEPS:
            return {
                ...state,
                addAlarm:{
                    ...state.addAlarm,
                    step: 1
                }
            }

        case alarmsActions.SET_ACTIVE_CHOICE:
            let steps = INITIAL_STATE.addAlarm.steps;
            switch (action.payload) {
                case 0:
                    steps= [
                        {icon: IoIosNotificationsOutline, name: 'Notification'},
                        {icon: AiOutlineCalendar, name: 'Planification'},
                        {icon: GiRadarSweep, name: 'Zones géographiques'}
                    ];
                    if(state.active.zone === false) {
                        delete steps.splice(2, 1);
                    }
                    break;
                case 1:
                    steps= [
                        {icon: BiBox, name: 'Groupes'},
                        {icon: IoIosNotificationsOutline, name: 'Notification'},
                        {icon: AiOutlineCalendar, name: 'Planification'},
                        {icon: GiRadarSweep, name: 'Zones géographiques'}
                    ];
                    if(state.active.zone === false) {
                        delete steps.splice(3, 1);
                    }
                    break;
                case 2:
                    steps= [
                        {icon: AiOutlineCar, name: 'Véhicules'},
                        {icon: IoIosNotificationsOutline, name: 'Notification'},
                        {icon: AiOutlineCalendar, name: 'Planification'},
                        {icon: GiRadarSweep, name: 'Zones géographiques'}
                    ];
                    if(state.active.zone === false) {
                        delete steps.splice(3, 1);
                    }
                    break
            }
            return {
                ...state,
                addAlarm:{
                    ...state.addAlarm,
                    active: action.payload,
                    steps
                }
            }

        case alarmsActions.TOOGLE_STEP:
            if(state.addAlarm.step !== -1) {
                return {
                    ...state,
                    addAlarm: INITIAL_STATE.addAlarm
                }
            }
            return {
                ...state,
                addAlarm: {
                    ...state.addAlarm,
                    step: 0
                }
            }

        case alarmsActions.SELECT_VEHICLE:
            return {
                ...state,
                addAlarm: {
                    ...state.addAlarm,
                    vehicle: action.payload
                }
            }

        case alarmsActions.SELECT_GROUP:
            return {
                ...state,
                addAlarm: {
                    ...state.addAlarm,
                    group: action.payload
                }
            }

        case alarmsActions.SELECT_ZONE:
            const id = action.payload;
            let zones = state.addAlarm.zones || [];
            zones = [...zones];
            const i = zones.indexOf(id);
            if(i === -1){
                zones.push(id);
            } else {
                zones.splice(i, 1);
            }
            return {
                ...state,
                addAlarm: {
                    ...state.addAlarm,
                    zones
                }
            }




        case alarmsActions.RESET_STEP:
            return {
                ...state,
                addAlarm: {
                    ...state.addAlarm,
                    step: state.addAlarm.data._id ? -1: 0,
                    steps: INITIAL_STATE.addAlarm.steps ,
                    data: INITIAL_STATE.addAlarm.data
                }
            }

        case alarmsActions.SET_STEP_DATA:
            const dataa = {...state.addAlarm.data, ...action.payload.data};
            return {
                ...state,
                addAlarm: {
                    ...state.addAlarm,
                    step: action.payload.step,
                    data: dataa
                }
            }


        case alarmsActions.SAVE_END:
            const { data, res} = action.payload;
            if(res.insertedCount) {
                const list = [...state.alarm.list];
                const _id = res.insertedId;
                list.push({...{_id}, ...data});
                return {
                    ...state,
                    loading: false,
                    addAlarm: INITIAL_STATE.addAlarm,
                    alarm:{
                        ...state.alarm,
                        list
                    }
                }
            } else if(res.modifiedCount) {
                const findAlarm = state.alarm.list.findIndex(el => el._id === data._id);
                if(findAlarm !== -1) {
                    const list = [...state.alarm.list];
                    list[findAlarm] = ({...list[findAlarm], ...data});
                    return {
                        ...state,
                        loading: false,
                        addAlarm: INITIAL_STATE.addAlarm,
                        alarm:{
                            ...state.alarm,
                            list
                        }
                    }
                }
            }
            return {
                ...state,
                loading: false,
                addAlarm: INITIAL_STATE.addAlarm,
            };

        case alarmsActions.DELETE_END:
            const response = action.payload.response;
            if(response.deletedCount) {
                const listAlarm = [...state.alarm.list];
                const find = listAlarm.findIndex(el => el._id === action.payload.id);
                if(find !== -1) {
                    listAlarm.splice(find, 1);
                    return {
                        ...state,
                        loading: false,
                        addAlarm: INITIAL_STATE.addAlarm,
                        alarm: {
                            ...state.alarm,
                            list: listAlarm,
                            total: state.alarm.total - 1
                        }
                    }
                }


            }
            return { ...state, loading: false }

        case alarmsActions.SAVE_START:
        case alarmsActions.DELETE_START:
        case alarmsActions.GET_UPDATE_START:
            return {
                ...state,
                loading: true
            }
        case alarmsActions.GET_UPDATE_END:
            const stepss = getSteps(action.payload, state.active.zone);

            let alarm = {_id: action.payload._id,};
            alarm= {...alarm, ...action.payload.config}

            return {
                ...state,
                loading: false,
                addAlarm: {
                    ...state.addAlarm,
                    ...stepss.res,
                    step: 1,
                    steps: stepss.steps,
                    data: alarm,
                    zones: action.payload.zones,
                    times: action.payload.times
                }
            }
        case alarmsActions.CLEAR_ALARMS:
            return INITIAL_STATE;

        default:
            return state;
    }
}

function getSteps(payload, hasZone) {
    const res = {}
    res.active = 0;

    const steps= [
        {icon: IoIosNotificationsOutline, name: 'Notification'},
        {icon: AiOutlineCalendar, name: 'Planification'},
        {icon: GiRadarSweep, name: 'Zones géographiques'}
    ];

    if(hasZone === false) { delete steps.splice(2, 1); }

    const vehicles = payload.vehicles && payload.vehicles.length > 0;
    const groups = payload.groups && payload.groups.length > 0;

    if(groups) {
        res.steps.unshift({icon: BiBox, name: 'Groupes'});
        res.active=1;
        res.group = payload.groups[0];
    } else if(vehicles) {
        steps.unshift({icon: AiOutlineCar, name: 'Véhicules'});
        res.active=2;
        res.vehicle = payload.vehicles[0];
    }


    return {steps, res};
}