import React, { Component }  from 'react';
import './button.css';
import {AiOutlineLoading} from "react-icons/ai";

export class Button extends Component {

    constructor(props) {
        super(props);
    }

    click(e) {
      e.preventDefault();
      if(this.props.press) { this.props.press(); }
    }

    render() {
        if(!this.props.loading && !this.props.disabled) {
            if(this.props.press) {
                return (
                    <button onClick={this.click.bind(this)} className={`btn ${this.props.type}`}>{this.props.name}</button>
                );
            }
            return (
                <button className={`btn ${this.props.type}`}>{this.props.name}</button>
            );
        }
        return (<button className="btn disabled-btn" disabled>
            {
                this.props.disabled ? this.props.name : <AiOutlineLoading/>
            }

        </button>);

    }
}