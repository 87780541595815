import React, {PureComponent} from 'react';
import './paginator-report.css'
import {BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronLeft, BsChevronRight} from "react-icons/all";

export class PaginatorReport extends PureComponent {

    constructor(props) {
        super(props);
        this.state = { page: this.getPage()}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({ page: this.getPage()});
        }
    }

    getPage() {
        const pageIndex = Math.ceil(this.props.skip / this.props.limitFix);
        return pageIndex+1;
    }

    next() {
        const skip = this.props.skip + this.props.limitFix;
        let limit = skip + this.props.limitFix;
        limit = limit < this.props.total ? limit : this.props.total
        if(this.props.paginate && skip <= this.props.total){
            this.props.paginate(skip, limit);
        }
    }


    back() {
        let skip = this.props.skip - this.props.limitFix ;
        let limit = this.props.limit - this.props.limitFix ;
        skip = skip >= 0 ? skip : 0;
        limit = limit > 0 ? limit : this.props.limitFix; // bug a regler
        if(this.props.paginate) {
            this.props.paginate(skip, limit);
        }
    }

    backToFirst() {
        const limit = this.props.total > this.props.limit ? this.props.limit : this.props.total;
        if(this.props.paginate) {
            this.props.paginate(0, limit);
        }
    }

    goToEnd() {
        const skip = this.props.total > this.props.limitFix ? this.props.total - this.props.limitFix : 0;
        if(this.props.paginate) {
            this.props.paginate(skip, this.props.total);
        }
    }

    render() {
        const skip = this.props.skip === 0 ? 1 : this.props.skip;
        const limit = this.props.limit > this.props.total ? this.props.total : this.props.limit;

        return (
            <div className="paginator-report-data" >

                {
                    this.props.fullSize && <button onClick={this.backToFirst.bind(this)}>
                        <BsChevronDoubleLeft/>
                    </button>
                }
                <button onClick={this.back.bind(this)}>
                    <BsChevronLeft className="pageAction"/>
                </button>

                <label className="pages">page {this.state.page}/{Math.ceil(this.props.total / this.props.limitFix)}</label>
                <button onClick={this.next.bind(this)}>
                    <BsChevronRight className="pageAction"/>
                </button>
                <span className="report-count">Rapports de {skip} à {limit} sur {
                    this.props.total
                }</span>
                {
                    this.props.fullSize && <button onClick={this.goToEnd.bind(this)}>
                        <BsChevronDoubleRight/>
                    </button>
                }

            </div>
        );
    }
}
