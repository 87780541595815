import * as actions from "./vehicles.actions";
import {catchError, map, switchMap} from 'rxjs/operators'
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {
    addAccessory,
    addVehicle, fetchAccessory,
    fetchVehicles,
    getCommandVehicle, getSensorVehicle, manageCommandVehicle,
    updateVehicle
} from "../../pages/vehicles/vehicleService";
import {NotificationManager} from "react-notifications";
import {AutoLogout} from "../auth/auth.actions";
import {Error} from "../vehicles/vehicles.actions";
import {deleteVehicle} from "../../pages/vehicles/vehicleService";
import {notification} from "antd";


const openNotificationWithIcon = type => {
    notification[type]({
        message: "Pas d'accessoire!",
        description:
            'Pas d\'accessoire trouvé pour ce boitier',
    });
};

export const FetchVehiclesEpic = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        const params = {...state$.value.vehiclesReducer.filters.params, ...action.payload};
        return from(fetchVehicles(params, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.getVehiclesEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const FetchAccessoryStart = (action$, state$) => action$.pipe(
    ofType(actions.GET_START_ACCESSORY),
    switchMap((action) => {
        return from(fetchAccessory(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                if(!res.data.available || res.data.available.length === 0) {
                    openNotificationWithIcon('info');
                    return actions.Error('');
                }
                return actions.GetAccessoryEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const SaveVehicleEpic = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_START),
    switchMap((action) => {
        const vehicle = {...state$.value.vehiclesReducer.addVehicle.data, ...action.payload};
        if(vehicle.id) {
            let path = '';
            if(vehicle.accessory) {
                path = '/accessory';
                delete vehicle.available;
                delete vehicle.accessoryUpdated;
            }
            return from(updateVehicle(vehicle, state$.value.authReducer.token, path)).pipe(
                map(res => {
                    NotificationManager.success('le vehicule est modifié avec succès', 'Modification' , 2000);
                    if(path === '/accessory') {
                        return actions.Error('');

                    }
                    res.data.id = res.data._id;
                    return actions.SaveVehicleEnd(res.data);
                }),
                catchError(err => {
                    let msg = 'une erreur s\'est produite !';
                    if (err.response.status === 401) {
                        return of(AutoLogout(), Error(''));
                    }
                    return of(Error(msg))
                })
            )
        }
        delete vehicle.id;
        return from(addVehicle(vehicle, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('Le véhicule est créé avec succès', 'Création', 2000);
                const newVehicle = {
                    ...{
                        _id: res.data.insertedId,
                        id: res.data.insertedId,
                    },
                    ...vehicle,
                    ...{
                       tracer: res.data.tracer
                    }

                }
                return actions.SaveVehicleEnd(newVehicle);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const DeleteVehicleEpic = (action$, state$) => action$.pipe(
    ofType(actions.DELETE_START),
    switchMap((action) => {
        return from(deleteVehicle(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('le véhicule est supprimée avec succès', 'Suppression', 2000);
                return actions.DeleteVehicleEnd({response: res.data, id: action.payload});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, 'Suppression', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Suppression', 2000);
        return of(Error('Problem unknow'))
    })
);

export const GetCommandsEpic = (action$, state$) => action$.pipe(
    ofType(actions.STEP_COMMAND_START),
    switchMap((action) => {
        return from(getCommandVehicle(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.StepCommandEnd({response: res.data[0], id: action.payload});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, '', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Suppression', 2000);
        return of(Error('Problem unknow'))
    })
);


export const UpdateCommandsEpic = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_COMMANDS_START),
    switchMap((action) => {
        const vehicleId =  state$.value.vehiclesReducer.addVehicle.vehicle;
        let commands = action.payload.commands;
        if(!action.payload.delete) {
            commands = action.payload.commands.map(el =>{ return el._id })
        }
        return from(manageCommandVehicle({vehicleId, commands, delete: action.payload.delete}, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.SaveCommandsEnd(action.payload);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, '', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Modification', 2000);
        return of(Error('Problem unknow'))
    })
);

export const AddAccessory = (action$, state$) => action$.pipe(
    ofType(actions.ADD_START_ACCESSORY),
    switchMap((action) => {
        const _id =  state$.value.vehiclesReducer.addVehicle.data._id;
        return from(addAccessory(_id, action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.AddAccessoryEnd({pin: action.payload, response: res.data});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, '', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Modification', 2000);
        return of(Error('Problem unknow'))
    })
);