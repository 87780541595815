import React, { Component }  from 'react';
import './group-info.css';
import {AiOutlineEdit, AiOutlineDelete, AiOutlineCar} from "react-icons/ai";
import {BiBox} from "react-icons/bi"
import "react-perfect-scrollbar/dist/css/styles.css";
import {HeaderAction} from "../../header/header-action/header-action";
import CountUp from "react-countup";
import {FiUsers} from "react-icons/fi";

export class GroupInfo extends Component {

    constructor(props) { super(props); }

    componentDidMount() { }

    render() {
        return (
            <div className="card-group">
                <div>
                    <div className="main">
                        <div style={{display: 'flex', alignItems:'center'}}>
                            <div>
                                <h4 className="name">{this.props.group.name}</h4>
                            </div>
                        </div>
                        <div className="action">
                            <HeaderAction icon={AiOutlineEdit} press={this.props.press} value={{action: 'edit', id: this.props.group._id}}/>
                            <HeaderAction icon={AiOutlineDelete} press={this.props.press} value={{action: 'del', name: this.props.group.name, id: this.props.group._id}}/>
                        </div>

                    </div>
                    <div className="details">
                        <div className="widget-value">
                            <AiOutlineCar />
                            <div className="info">
                                <label className="type">véhicules</label>
                                <CountUp delay={1} end={this.props.group.vehicles} duration={2}/>
                            </div>
                        </div>
                        <div className="widget-value">
                            <FiUsers />
                            <div className="info">
                                <label className="type">utilisateurs</label>
                                <CountUp delay={1} end={this.props.group.accounts} duration={2}/>
                            </div>
                        </div>
                        <div className="widget-value">
                            <BiBox />
                            <div className="info">
                                <label className="type">alarmes</label>
                                <CountUp delay={1} end={this.props.group.alarms} duration={2}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}