import React, {Component} from 'react';
import './off-traject.css';
import 'swiper/swiper.scss';
import {
    FaParking, GiButtonFinger, GiCaptainHatProfile,
    GiPathDistance,
    MdTimer
} from "react-icons/all";
import {dateDiff, getHours, getMinutes, getTimeHours, getTimeMinutes, UNITTIME} from "../../../utils/date";
import {Address, Adress} from "../../adress/adress";
import moment from "moment-timezone";
import {Tooltip} from "antd";


export class OffTraject extends Component {

    constructor(props) {
        super(props);
    }

    pointing() {
        const {pointing} = this.props.data;
        const pointings = [];
        if(pointing && pointing.length > 0) {
            for(let i=0;i<pointing.length;i++) {
                const {name, dateTime} = pointing[i];
                pointings.push(
                    <Tooltip placement="right" title={`Pointage: ${dateTime}`}>
                        <div className="history-timeline-data-info">
                            <GiButtonFinger/>
                            <label>{ name }</label>
                        </div>
                    </Tooltip>

                );
            }
        }
        return pointings;
    }


    render() {
        const {data, active, index, onPress} = this.props;
        const classItem = active === index ? 'history-timeline-item parking parking-active' : 'history-timeline-item parking';


        const diff = moment.utc(data.duration ?? 0);

        return (
            <div className={classItem} onClick={() => onPress(index, data.posEnd, false)}>
                <div className="history-timeline-time history-timeline-time-off">
                    <label>{data.start && data.start.format('HH')}:{data.start && data.start.format('mm')}</label>
                    <FaParking/>
                </div>
                <div className="history-timeline-line"/>
                <div className="history-timeline-data">
                    <label className="type">En arrêt</label>
                    <div className="history-timeline-data-info first">
                        <MdTimer/>
                        <label>{diff.format("HH")}h {diff.format("mm")}min {diff.format("ss")}sec</label>
                    </div>
                    <div className="history-timeline-data-info">
                        <GiPathDistance/>
                            <Address classItem={'adress-history'} coordinates={data.posEnd} index={index}/>
                    </div>
                    { this.pointing() }
                </div>
            </div>
        );
    }
}
