import React, {PureComponent} from "react";
import {MdTrackChanges} from "react-icons/md";
import './event-info.css'
import {
    MdPanTool,
    AiOutlineQuestionCircle,
    IoMdFlashOff,
    IoMdSpeedometer,
    FaCarCrash,
    GiNinjaMask, FaMapMarkerAlt
} from "react-icons/all";
import moment from "moment-timezone";

export class EventMapInfo extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {ICON: AiOutlineQuestionCircle, name: '', zone: '', date: ''}
    }
    press() {
        if(this.props.press) {
            this.props.press(this.props.event);
        }
    }

    componentDidMount() { }

    formatData() {

            const {vehicle, zone, type, date, poi } = this.props.event;
            const data = {vehicle, date: new Date(date)};
            if(zone) { data.zone = `(${zone})`; }
            switch (type) {
                case 'speed':
                    return {...{ name: 'Excès de vitesse', ICON: IoMdSpeedometer }, ...data};
                case 'inZone':
                    return !poi ? {...{ name: 'Détéction entrée de zone',  ICON: MdTrackChanges }, ...data}:  {...{ name: 'Détéction entrée au POI',  ICON: FaMapMarkerAlt }, ...data};
                case 'outZone':
                    return !poi ? {...{ name: 'Détéction sortie de zone',  ICON: MdTrackChanges }, ...data}:  {...{ name: 'Détéction sortie du POI',  ICON: FaMapMarkerAlt }, ...data};
                case 'notAuthorized':
                    return {...{  name: 'Démarage non autorisé', ICON: MdPanTool }, ...data};
                case 'noElectricity':
                    return {...{ name:  'Traceur débranché', ICON: IoMdFlashOff }, ...data};
                case 'crash':
                    return {...{ name:  'Accident de voiture', ICON: FaCarCrash }, ...data};
                case 'towing':
                    return {...{ name:  'Détéction dépannage', ICON: AiOutlineQuestionCircle }, ...data};
                case 'jamming':
                    return {...{ name:  'Détection de brouillage réseau', ICON: GiNinjaMask }, ...data};
                default:
                    return {...{ name:  'Non Connue', ICON: AiOutlineQuestionCircle }, ...data};
            }


    }


    render() {
        if( this.props.event){
            const {name, ICON, zone, vehicle, date} = this.formatData();
            const classEvent = !this.props.active ? 'event-info' : 'event-info event-info-active'
            return(
                <div className={classEvent} onClick={this.press.bind(this)}>
                    <ICON/>
                    <div className="description-event">
                        <span className="zone-type">{name} <span className="vehicle-event">{zone}</span></span>
                        <span className="vehicle-event">{vehicle}</span>
                        <span className="time-event">{moment(date).tz(this.props.timezone).format('DD/MM/YYYY HH:mm:ss')}</span>
                    </div>
                </div>
            );
        }
        return <></>

    }
}
