import React, {Component, useEffect, useState} from 'react';
import {getAddress, getAdress} from "./adress.service";
import {Tooltip} from "antd";


export const Address = ({coordinates, classItem}) => {
    const [address, setAddress] = useState('...');
    useEffect(()=> {
        if(coordinates) {
            async function anyNameFunction() {
                const address = await getAddress(coordinates[0], coordinates[1]);
                setAddress(address);
            }

            anyNameFunction()//
        }
    }, [coordinates])
    return (
        <Tooltip placement="right" title={address}>
            <label className={classItem}>{address}</label>
        </Tooltip>
    );
}

