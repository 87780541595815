import {BASE_URL} from "../../env";
const axios = require('axios');

export function fetchAlarmsType(payload, token) {
    let params = `skip=${payload.skip}&limit=${payload.limit}`;
    if (payload.name) {
        params+=`&filter[item][name]=${payload.name}`
    }
    const url = `${BASE_URL}/api/alarms?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export function fetchAlarms(payload, token) {
    let params = `skip=${payload.skip}&limit=${payload.limit}&id=${payload.id}`;
    if (payload.name) {
        params+=`&filter[name]=${payload.name}`
    }
    const url = `${BASE_URL}/api/alarm/all?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export function SaveAlarm(payload, token) {
    const url = `${BASE_URL}/api/alarm`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    if(payload.config._id) { return axios.patch(url, payload, config); }
    return axios.post(url, payload, config);

}

export function deleteAlarm(payload, token) {
    const url = `${BASE_URL}/api/alarm/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.delete(url, config);
}

export function getAlarm(payload, token) {
    const url = `${BASE_URL}/api/alarm/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}


