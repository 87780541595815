import React, {Component} from 'react';
import './vehicles.css';
import {FilterAction} from "../../../../components/header/filter-action/filter-action";
import {HeaderAction} from "../../../../components/header/header-action/header-action";
import {AiOutlinePlus, AiOutlineReload, AiOutlineSearch} from "react-icons/ai";
import {SearchAction} from "../../../../components/header/search-action/search-action";
import {connect} from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import {BlankComponent} from "../../../../components/blank/blank";
import {Paginator} from "../../../../components/pagination/paginator/Paginator";
import {GetVehiclesStart} from "../../../../store/vehicles/vehicles.actions";
import {Button} from "../../../../components/widgets/form/button/button";
import {LoadingComponent} from "../../../../components/loading/loading";
import {VehicleInfoAlarm} from "../../../../components/vehicle/vehicle-info-alarm/vehicle-info-alarm";
import {SelectVehicleReport, SetStepData, ResetStep} from "../../../../store/reports/report.actions";


class VehiclesPage extends Component {

    constructor(props) {
        super(props);
        this.state = {height: `${window.innerHeight - 213}px`};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(GetVehiclesStart({}));
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({height: `${window.innerHeight - 213}px`});
    }

    zoneAction(action) {
        if (action.action === 'reload') {
            this.props.dispatch(GetVehiclesStart({}));
        }
    }

    filterAction(action) {
        switch (action.key) {
            case 'name':
                this.props.dispatch(GetVehiclesStart({
                    name: action.value,
                    skip: 0
                }))
                break;

            case 'filter':
                this.props.dispatch(GetVehiclesStart({
                    filter: action.value,
                    skip: 0
                }))
                break;
        }
        ;
    }

    vehicleAction(action) {
        switch (action.action) {
            case 'reload':
                this.props.dispatch(GetVehiclesStart({}))
                break;
            case 'select':
                this.props.dispatch(SelectVehicleReport(action.value));
                break;
        }
    }

    paginate(skip, limit) {  this.props.dispatch(GetVehiclesStart({skip: skip})); }
    cancel() {  this.props.dispatch(ResetStep()); }
    next() { this.props.dispatch(SetStepData({step: this.props.step+1 })); }

    render() {
        const filters = this.props.filters.data;
        const params = this.props.filters.params;
        const hasVehicle = this.props.vehicles.length > 0;
        return (
            <PerfectScrollbar style={{height: this.state.height}}>
                <LoadingComponent loading={this.props.loading}/>
                <div id="vehicles-step">
                    <div className="header-section">
                        <div className="filter">
                            {
                                filters && filters.map((el, i) => {
                                    return <FilterAction
                                        key={i}
                                        active={el.filter === params.filter}
                                        label={el.name}
                                        value={el.filter}
                                        press={this.filterAction.bind(this)}
                                    />
                                })
                            }
                        </div>
                        <div className="action-right">
                            <HeaderAction
                                value={{action: 'reload'}}
                                press={this.zoneAction.bind(this)}
                                icon={AiOutlineReload}
                            />
                            <SearchAction
                                icon={AiOutlineSearch}
                                indentifier="name"
                                onChange={this.filterAction.bind(this)}
                            />
                        </div>

                    </div>
                    <div className="vehicles-list">
                        <div className="body">
                            {
                                hasVehicle ? this.props.vehicles.map((vehicle, i) => {
                                    return <VehicleInfoAlarm
                                        active={this.props.vehicle === vehicle._id}
                                        key={i}
                                        vehicle={vehicle}
                                        press={this.vehicleAction.bind(this)}
                                    />
                                }) : !this.props.loading && <BlankComponent/>
                            }
                        </div>
                        <Paginator paginate={this.paginate.bind(this)} limit={params.limit} skip={params.skip}
                                   total={this.props.total}/>

                        {
                            hasVehicle && <div className="actions">
                                <Button type='btn-accent' name='Annuler' press={this.cancel.bind(this)}/>
                                <Button type='btn-primary' name='Suivant' press={this.next.bind(this)} disabled={!this.props.vehicle}/>
                            </div>
                        }

                    </div>
                </div>
            </PerfectScrollbar>

        );
    }
}

const vehiclesStateToProps = (state) => {
    return {
        vehicles: state.vehiclesReducer.vehicles,
        filters: state.vehiclesReducer.filters,
        loading: state.vehiclesReducer.loading,
        total: state.vehiclesReducer.total,

        vehicle: state.reportsReducer.addReport.vehicle,
        step: state.reportsReducer.addReport.step,
    }
}
export default connect(vehiclesStateToProps)(VehiclesPage);
