import React, {memo, useState} from 'react';
import './vehicle-map-info.css';
import {
    AiOutlineInfoCircle, AiOutlineSend,
    BiBeenHere,
    BiCurrentLocation, BsClockHistory, BsTools,
    FaCar, FaGasPump,
    FaKey, FaMapMarkerAlt,
    FaParking, FaStop,
    FiWifi,
    FiWifiOff, GiCaptainHatProfile,
    GiSatelliteCommunication,
    IoMdSpeedometer, MdTimeline, VscGraph
} from "react-icons/all";
import {VehicleTimer} from "../vehicle-timer/vehicle-timer";
import {getSignal, getSignalName, km, speed} from "../../../utils/parser";
import {Popover} from "antd";
import {ActionPopover} from "../../widgets/action-popover/action-popover";
import {Address} from "../../adress/adress";
import {NavLink} from "react-router-dom";


const size = 158.5;
const vehicleOnline = '/img/vehicles/car.png';
const vehicleOffline = '/img/vehicles/car-offline.png';
const VehicleMapInfo = (props) => {
    const [visible, setVisible] = useState(false);

    const press = (value) => {
        if(props.press && value) {
            props.press(value);
            setVisible(false);
        }
    }

    const handleVisibleChange = visible => {
        setVisible(visible);
    };

    const getImg = () => {
        const {vehicle} = props;
        if(vehicle.dataVehicle) {
            const {ingintion} = vehicle.dataVehicle;
            if(vehicle.online) {
                return ingintion ? (<div className="border">
                        <img className="logo" src={vehicleOnline}/>
                        <div className="borderSmall"></div></div>)
                    :
                    (<div className="borderOff">
                        <img className="logo" src={vehicleOffline}/>
                        <div className="borderSmallOff"></div>
                    </div>);
            } else {
                return (<div className="borderOffline">
                    <img className="logo" src={vehicleOffline}/>
                    <div className="borderSmallOffline"></div>
                </div>);
            }
        }
    }

    const text = <span>Title</span>;
    let content;

    if (props.vehicle.dataVehicle && props.vehicle.dataVehicle.location && props.vehicle.dataVehicle.location.coordinates) {
        const lat = props.vehicle.dataVehicle.location.coordinates[1];
        const lng = props.vehicle.dataVehicle.location.coordinates[0];
        content = (
            <>
                <ActionPopover icon={BiCurrentLocation} label="Localiser véhicule" press={press}
                               value={{action: 'locate', id: props.vehicle._id,
                                   position: [lat, lng]}}/>

                {
                    props.vehicle.uid !== props.trackVehicle ?
                        <ActionPopover icon={BiBeenHere} label="Suivre véhicule" press={press}
                                       value={{action: 'track', uid: props.vehicle.uid}}/>
                        :
                        <ActionPopover icon={FaStop} label="Arrêter le suivie" press={press}
                                       value={{action: 'track', uid: false}}/>
                }
                <div style={{position: 'absolute', left: '10px', width: '93%', borderTop: '1px solid rgba(132, 132, 132, 0.2)'}}/>
                <ActionPopover icon={AiOutlineInfoCircle} label="Afficher informations véhicules" press={press} value={{action: 'info', uid: props.vehicle.uid}}/>
                <ActionPopover icon={BsClockHistory} label="Afficher l'historique" press={press} value={{action: 'history', _id: props.vehicle._id}}/>
                <ActionPopover icon={VscGraph} label="Afficher détail véhicule" press={press} value={{action: 'detail', _id: props.vehicle._id}}/>
                {
                    props.hasMaintenance && <>
                        <div style={{position: 'absolute', left: '10px', width: '93%', borderTop: '1px solid rgba(132, 132, 132, 0.2)'}}/>
                        <NavLink activeClassName='active'  to={`/maintenance/${props.vehicle._id}`} className="item">
                            <div className="action-popover">
                                <BsTools />
                                <label className="action-label">Maintenance</label>
                            </div>
                        </NavLink>
                    </>
                }


                {

                    props.vehicle.commands.length > 0 &&
                    <>
                        <div style={{position: 'absolute', left: '10px', width: '93%', borderTop: '1px solid rgba(132, 132, 132, 0.2)'}}/>
                        <ActionPopover icon={AiOutlineSend} label="Envoyer une commande" press={press} value={{action: 'command', _id: props.vehicle._id}}/>
                    </>



                }
            </>
        )
    } else {
        content = <></>;
    }

    return (
        <Popover
            placement="rightTop"
            content={content}
            text={text}
            trigger="click"
            visible={visible}
            onVisibleChange={handleVisibleChange}
        >
            <div className="vehicle-info">
                <div className="container-vehicle">
                    <div style={{display: "flex", alignItems: "center", marginRight: 12, flexDirection: 'column'}}>

                        {getImg()}
                        {
                            props.vehicle.dataVehicle && <VehicleTimer date={props.vehicle.dataVehicle.serverDate ?? props.vehicle.dataVehicle.gpsDate} />
                        }

                    </div>
                    <div className="basic-info">
                        <div style={{display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "space-between"}}>
                            <label className="name-vehicle"> {props.vehicle.name} </label>
                            <div style={{display: "flex", flexDirection: "row", width: 60,  justifyContent: "space-between", marginTop: 1.6, alignItems: "center"
                            }}>
                                { props.vehicle.dataVehicle && props.vehicle.dataVehicle.ingintion ?
                                    <FaKey style={{ color: 'rgba(1, 147, 10, 1)'}} size={14}/> :
                                    <FaKey style={{ color: 'gray'}} size={14} />
                                }

                                { props.vehicle.dataVehicle &&  props.vehicle.dataVehicle.movement ?
                                    <FaCar style={{color: 'rgba(1, 147, 10, 1)'}} size={14} /> :
                                    <FaParking style={{color: '#1976d2'}} size={14} />
                                }


                                { props.vehicle.dataVehicle &&  props.vehicle.online ?
                                    <FiWifi style={{ color: 'rgba(1, 147, 10, 1)'}} size={15}/> :
                                    <FiWifiOff style={{ color: 'gray'}} size={15}/>
                                }

                            </div>
                        </div>

                        { props.vehicle.dataVehicle && props.vehicle.dataVehicle.fuel ?
                            <div className="adress">
                                <FaGasPump size={12} />
                                <label className="size12">{ props.vehicle.dataVehicle.fuel } L</label>
                            </div>
                            : <></>
                        }
                        { props.vehicle.dataVehicle &&
                        <div className="adress">
                            <MdTimeline size={12} />
                            <label className="size12">{props.vehicle.dataVehicle.km ? props.vehicle.dataVehicle.km.toFixed(2) : '-'} km</label>
                        </div>
                        }

                        {
                            props.vehicle.dataVehicle && props.vehicle.dataVehicle.ingintion && props.vehicle.driver  &&
                            <div className="adress">
                                <GiCaptainHatProfile size={12} />
                                <label className="size12">{props.vehicle.driver}</label>
                            </div>
                        }
                        {
                            props.vehicle.dataVehicle  &&
                            <div className="adress">
                                <FaMapMarkerAlt name="map-marker-alt" size={12} />
                                <Address classItem={'size12'} coordinates={props.vehicle?.dataVehicle?.location?.coordinates} index={props.index}/>
                            </div>
                        }


                    </div>
                </div>
                {
                    props.vehicle.dataVehicle && <div className="containerDetails">
                        <div className="item">
                            <label className="value">{props.vehicle.dataVehicle.speed != null ? speed(props.vehicle.dataVehicle.speed) : '-'} km/h</label>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <IoMdSpeedometer style={{color: 'gray'}} size={14} />
                                <label style={{fontSize: 10, marginLeft: 3, color: 'gray'}}>Vitesse</label>
                            </div>
                        </div>
                        <div style={{height: 20, borderRight: "1px solid rgba(132, 132, 132, 0.2)"}}/>

                        <div className="item">
                            <label className="value">{ props.vehicle.dataVehicle.satelite != null ? props.vehicle.dataVehicle.satelite : '-' }</label>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <GiSatelliteCommunication size={14} color="gray" />
                                <label style={{fontSize: 10, marginLeft: 3, color: 'gray'}}>Satellites</label>
                            </div>
                        </div>

                        <div style={{height: 20, borderRight: "1px solid rgba(132, 132, 132, 0.2)"}}/>
                        <div className="item">
                            <label className="value">{ props.vehicle.dataVehicle.signalGSM != null ? getSignalName(props.vehicle.dataVehicle.signalGSM) : '-'}</label>
                            <div style={{display: "flex", flexDirection: "row", color: "gray"}}>
                                {getSignal(props.vehicle.dataVehicle.signalGSM)}
                                <label style={{fontSize: 10, marginLeft: 3, color: 'gray'}}>Signale</label>
                            </div>
                        </div>

                    </div>
                }

            </div>
        </Popover>

    );
}

export default memo(VehicleMapInfo);
