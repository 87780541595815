import {ofType} from "redux-observable";
import * as actions from "./auth.actions";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {from, of} from "rxjs";
import {deleteCookies, logIn, saveDataToCookies} from "../../pages/login/loginService";

export const LogInEpic = (action$, state$) => action$.pipe(
    ofType(actions.LOGIN_START),
    switchMap((action) => {
        return from(logIn(action.payload)).pipe(
            tap(payload => {
                saveDataToCookies(payload.data);
            }),
            map(res => {
                return actions.LoginEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    msg = 'Votre nom d\'utilisateur ou mot de passe est incorrect';
                }
                return of(actions.Error(msg));
            })
        )
    }),
    catchError(err => {
        return of(actions.Error('Problem unknow'))
    })
);

export const LogOutEpic = (action$, state$) => action$.pipe(
    ofType(actions.LOGOUT_END),
    map((action) => {
        deleteCookies();
        return actions.Error('');
    }),
    catchError(err => {
        return of(actions.Error('Problem unknow'))
    })
);

