import React, {PureComponent} from "react";
import './logo.css';

export class Logo extends PureComponent{
    constructor(props) { super(props); }
    render() {
        const logoClass = this.props.hideText ? 'logo' : 'logo short-logo';
        return (
            <div className={logoClass}>
                <img src={this.props.logo}/>
                {
                    this.props.hideText && <label>
                        <span className="secondary">{this.props.primary}</span>
                        <span className="primary">{this.props.secondary}</span>
                    </label>
                }

            </div>
        );
    }
}

