import * as actions from "./accounts.actions";
import {catchError, map, switchMap} from 'rxjs/operators'
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {addAccount, fetchAccounts, updateAccount, updatePassword} from "../../pages/accounts/accountService";
import {NotificationManager} from "react-notifications";
import {AutoLogout} from "../auth/auth.actions";
import {Error} from "../accounts/accounts.actions";
import {deleteAccount} from "../../pages/accounts/accountService";
import {UpdatePasswordEnd} from "./accounts.actions";


export const FetchAccountsEpic = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        const params = {...state$.value.accountsReducer.filters.params, ...action.payload};
        return from(fetchAccounts(params, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.getAccountsEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const SaveAccountEpic = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_START),
    switchMap((action) => {
        const account = {...state$.value.accountsReducer.addAccount.data, ...action.payload};
        const active = state$.value.accountsReducer.addAccount.data.active ? "activateAccount" : "";
        if(account.id) {
            delete account.groups;
            return from(updateAccount(account, state$.value.authReducer.token)).pipe(
                map(res => {
                    NotificationManager.success('le compte est modifiée avec succès', 'Modification' , 2000);
                    account.id = res.data._id;
                    return actions.SaveAccountEnd(account);
                }),
                catchError(err => {
                    let msg = 'une erreur s\'est produite !';
                    if (err.response.status === 401) {
                        return of(AutoLogout(), Error(''));
                    }
                    return of(Error(msg))
                })
            )
        }
        account.choices = [active];
        delete account.id;
        delete account.active;
        delete account.confirmPassword;
        return from(addAccount(account, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('Le compte est créé avec succès', 'Création', 2000);
                const newAccount = {
                    ...{
                        _id: res.data.insertedId,
                        id: res.data.insertedId,
                    },
                    ...account,
                }
                return actions.SaveAccountEnd(newAccount);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const DeleteAccountEpic = (action$, state$) => action$.pipe(
    ofType(actions.DELETE_START),
    switchMap((action) => {
        return from(deleteAccount(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('le compte est supprimée avec succès', 'Suppression', 2000);
                return actions.DeleteAccountEnd({response: res.data, id: action.payload});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, 'Suppression', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Suppression', 2000);
        return of(Error('Problem unknow'))
    })
);


export const UpdatePasswordEpic = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_PASSWORD_START),
    switchMap((action) => {
        return from(updatePassword(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('le mot de passe est modifié avec succès', 'Suppression', 2000);
                return UpdatePasswordEnd({});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, 'Modification', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Modification', 2000);
        return of(Error('Problem unknow'))
    })
);
