import React, {useState} from 'react';
import './update-grp.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useForm } from "react-hook-form";
import {Button} from "../../../../components/widgets/form/button/button";
import {fetchGroupByUName} from "../../../vehicles/vehicleService";
import {useDispatch, useSelector} from "react-redux";
import {FaBoxOpen} from "react-icons/fa";
import {SaveAccountStart, SetStep} from "../../../../store/accounts/accounts.actions";

export default function UpdateGrp(props) {
    const dispatch = useDispatch();
    const {token, group, id} = useSelector(state => {
        const grp = state.accountsReducer.addAccount.data.groups;
        return {
            token: state.authReducer.token,
            group: grp && grp.length > 0 ? grp[0] : '',
            id: state.accountsReducer.addAccount.data._id
        }
    });
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setError, errors, formState  } = useForm(
        { mode: "onChange",
            defaultValues: { group, id }
        },
    );

    const onSubmit = async(data) => {
        setLoading(true);
        const promises = [];

        if(data.group){ promises.push(fetchGroupByUName(data.group, token)); }
        const response = await Promise.all(promises);
        const errors = response.filter(el => el.error === true);
        if(errors.length>0) {
            response.forEach(el => {
                if(el.error){
                    setError(el.for, {message: el.msg, type:'isUnique'});
                }
            })
        }else {
            dispatch(SaveAccountStart(data));
        }


        setLoading(false);
    };

    const previous = () => { dispatch(SetStep({step: 0})); };

    const styleGroup = !errors.group ? 'input-form' : 'input-form input-form-error';

    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={styleGroup}>
                    <input name="group" placeholder="Groupe"
                           ref={register()}
                    />

                    <FaBoxOpen/>
                    {errors.group && <p className="error-input">{errors.group.message}</p>}
                </div>


            </div>
            <input name="id" hidden="hidden" ref={register()} />
            <div className="actions">
                <Button type='btn-accent' press={previous} name='Fermer'/>
                <Button type='btn-primary' name='Terminer' loading={loading}/>
            </div>
        </form>
    );
}
