import * as actions from "./zones.actions";
import * as L from 'leaflet';
import {catchError, map, switchMap} from 'rxjs/operators'
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {AutoLogout} from "../auth/auth.actions";
import {Error} from "./zones.actions";
import {deleteZone, fetchZone, fetchZones, saveZone, updateZone} from "../../pages/zones/zoneService";
import {initZoneOptions} from "../map/vehicles/leaflet-provider";
import '../../components/leaflet/lib/corridor';
import {NotificationManager} from "react-notifications";

export const FetchZonesEpic = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        const params = {...state$.value.zonesReducer.filters.params, ...action.payload};
        return from(fetchZones(params, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.getZoneEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const FetchZoneEpic = (action$, state$) => action$.pipe(
    ofType(actions.FETCH_ONE_START),
    switchMap((action) => {
        return from(fetchZone(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.GetZoneOneEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const EditStartZoneEpic = (action$, state$) => action$.pipe(
    ofType(actions.START_ZONE_EDIT),
    switchMap((action) => {
        return from(fetchZone(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.EndZoneEdit(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);


export const SetColorZone = (action$, state$) => action$.pipe(
    ofType(actions.SET_COLOR),
    map((action) => {
        const {color, zone}= state$.value.zonesReducer.addZone.data;
        initZoneOptions(zone, color);
        return actions.Error('');
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const SaveStartEpics = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_START),
    switchMap((action) => {
        const {color, zone, type, radius}= state$.value.zonesReducer.addZone.data;
        const location = zone.geometry;
        let data = {color, name: action.payload.name, location};
        if(type === 'LineString') { data.radius = parseInt(action.payload.radius, 10); }
        else if(type === 'Point') { data.radius = radius; }
        else if(type === 'POI') { data.radius = radius; data.poi = true;}
        if(action.payload.id) {
            data = {...data, ...{id: action.payload.id}};
            return from(updateZone(data, state$.value.authReducer.token)).pipe(
                map(res => {
                    NotificationManager.success('La zone est modifiée avec suuccés', 'Modification' , 2000);
                    return actions.SaveZoneEnd(data);
                }),
                catchError(err => {
                    let msg = 'une erreur s\'est produite !';
                    if (err.response.status === 401) {
                        return of(AutoLogout(), Error(''));
                    }
                    return of(Error(msg))
                })
            );
        }
        return from(saveZone(data, state$.value.authReducer.token)).pipe(
            map(res => {
                data = {...data, ...{id: res.data.insertedId}};
                NotificationManager.success('La zone est créée avec suuccés', 'Création' , 2000);
                return actions.SaveZoneEnd(data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        );
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);


export const DeleteStartEpics = (action$, state$) => action$.pipe(
    ofType(actions.DELETE_START),
    switchMap((action) => {
        return from(deleteZone(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                const data = {response: res.data, id: action.payload};
                NotificationManager.success('La zone est supprimé avec suuccés', 'Suppression' , 2000);
                return actions.EndDelete(data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        );
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const SaveEndEpics = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_END),
    map((action) => {
        const {editRef} = state$.value.vehiclesMapReducer;
        editRef.clearAll();
        return actions.Error('');
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

