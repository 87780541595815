import React, { Component }  from 'react';
import './vehicle-info.css';
import {AiOutlineEdit, AiOutlineDelete, AiOutlineInfoCircle} from "react-icons/ai";
import {BiBox} from "react-icons/bi"
import "react-perfect-scrollbar/dist/css/styles.css";
import {HeaderAction} from "../header/header-action/header-action";
import {MdWifiTethering} from "react-icons/md";
import {RiSimCard2Line} from "react-icons/ri";
import {GiGps} from "react-icons/gi";
import {
    AiOutlineDownload,
    AiOutlineSend,
    FaToolbox,
    GiMovementSensor,
    VscDebugAlt2
} from "react-icons/all";
import {ActionPopover} from "../widgets/action-popover/action-popover";
import {FiMoreVertical} from "react-icons/fi";
import {Popover} from "antd";

export class VehicleInfo extends Component {

    constructor(props) { super(props); }

    componentDidMount() { }

    status() {
        if(this.props.vehicle.tracer) {
            return this.props.vehicle.tracer.online ? "Connecté" : "Non connecté";
        }
        return '-'
    }
    render() {
        const tracer = this.props.vehicle.tracer;
        const text = <span>Title</span>;
        const content = (
            <>
                <ActionPopover icon={GiMovementSensor} label="Capteurs" press={this.props.press} value={{action: 'io', id: this.props.vehicle._id}}/>
                <ActionPopover icon={FaToolbox} label="Accessoires" press={this.props.press} value={{action: 'accessory', id: this.props.vehicle._id}}/>
                <ActionPopover icon={AiOutlineSend} label="Commandes" press={this.props.press} value={{action: 'command', id: this.props.vehicle._id}}/>
                <div style={{position: 'absolute', left: '10px', width: '93%', borderTop: '1px solid rgba(132, 132, 132, 0.2)'}}/>
                <ActionPopover icon={AiOutlineEdit} label="Modifier" press={this.props.press} value={{action: 'edit', id: this.props.vehicle._id}}/>
                <ActionPopover icon={AiOutlineDelete} label="Supprimer" press={this.props.press} value={{action: 'del', name: this.props.vehicle.name, id: this.props.vehicle._id}}/>
                <div style={{position: 'absolute', left: '10px', width: '93%', borderTop: '1px solid rgba(132, 132, 132, 0.2)'}}/>
                <ActionPopover icon={AiOutlineDownload} label="Fichier de configuration" press={this.props.press} value={{action: 'command', id: this.props.vehicle._id}}/>

            </>
        );
        return (
            <div className="card-vehicle-info">

                <div>
                    <div className="main">
                        <div style={{display: 'flex', alignItems:'center'}}>
                            <div>
                                <h4 className="name">{this.props.vehicle.name}</h4>
                                <div className="description">
                                    <div className="descWidget">
                                        <AiOutlineInfoCircle />
                                        <label>{tracer && tracer.uid ? tracer.uid : '-'}</label>
                                    </div>
                                    <div className="descWidget">
                                        <RiSimCard2Line />
                                        <label>{tracer && tracer.sim ? tracer.sim : '-'}</label>
                                    </div>

                                    <div className="descWidget">
                                        <GiGps />
                                        <label>{tracer && tracer.name ? tracer.name : '-'}</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="action">
                            <HeaderAction icon={VscDebugAlt2} press={this.props.press} value={{action: 'debug', id: this.props.vehicle._id}}/>
                            <Popover placement="rightTop" content={content} text={text} trigger="click">
                                <div className="action-header">
                                    <FiMoreVertical/>
                                </div>
                            </Popover>
                        </div>

                    </div>
                    <div className="details">
                        <div className="widget-value">
                            <BiBox />
                            <div className="info">
                                <label className="type">groupes</label>
                                <span>{this.props.vehicle.group ? this.props.vehicle.group : '-'}</span>
                            </div>
                        </div>
                        <div className="widget-value">
                            <MdWifiTethering />
                            <div className="info">
                                <label className="type">Etat</label>
                                <span style={{fontSize: '12px'}}>
                                    {this.status()}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}