import React, {Component} from 'react';
import {AiOutlineEdit, AiOutlineDelete} from "react-icons/ai";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./role-info.css"
import CountUp from "react-countup";
import {FiUsers} from "react-icons/fi";
import {HeaderAction} from "../../../components/header/header-action/header-action";
import {useDispatch} from "react-redux";
import {DeleteStart, SetActive, SetEdit, UpdateStart} from "../store/role.actions";
import {AiOutlineSave} from "react-icons/all";
import {ConfirmationDialog} from "../../../components/confirmation-dialog/confirmation-dialog";
import {confirmAlert} from "react-confirm-alert";

export const RoleInfo = ({index, role, active, canDelete, canManage}) => {

    const dispatch = useDispatch()
    const classname = active ? 'card-role card-role-active' : 'card-role';

    const deleteAction = (_id) => dispatch(DeleteStart(_id));
    const actionRole = (value) =>{
        if(value.action === 'edit') {
            dispatch(SetEdit({index, edit: true}));
        }
        else if(value.action === 'save') {
            dispatch(UpdateStart(index));
        } else if(value.action === 'del') {
            const options = {
                customUI: ({ onClose }) =>
                    <ConfirmationDialog
                        close={onClose}
                        action={()=> deleteAction(value.id)}
                        id={value.id}
                        keyword={value.name}
                        ActionName={"Supprimer"}/>,
                closeOnEscape: false,
                closeOnClickOutside: false
            };

            confirmAlert(options);
        }
    }
    return (
        <div className={classname} >
            <div>
                <div className="main">
                    <div style={{display: 'flex', alignItems: 'center'}} onClick={()=>dispatch(SetActive(index))}>
                        <div>
                            <h5 className="name">{role.name}</h5>
                        </div>
                    </div>
                    <div className="action">
                        {
                            canManage && (!role.edit ? <HeaderAction icon={AiOutlineEdit} press={actionRole}
                                                      value={{action: 'edit'}}/> :
                                <HeaderAction icon={AiOutlineSave} press={actionRole}
                                              value={{action: 'save'}}/>)
                        }

                        {
                            canDelete && <HeaderAction icon={AiOutlineDelete} press={actionRole}
                                          value={{action: 'del', name: role.name, id: role._id}}/>
                        }
                    </div>

                </div>
                <div className="details" onClick={()=>dispatch(SetActive(index))}>
                    <div className="widget-value">
                        <FiUsers/>
                        <div className="info">
                            <span className="type">utilisateurs</span>
                            <CountUp delay={1} end={role.users} duration={2}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
