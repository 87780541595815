import {combineEpics} from 'redux-observable';
import {DeleteCompanyEpic, FetchCompaniesEpic, SaveCompanyEpic, SwitchCompanyEpic} from "../comapny/company.epics";
import {
    AddAccessory,
    DeleteVehicleEpic, FetchAccessoryStart,
    FetchVehiclesEpic,
    GetCommandsEpic,
    SaveVehicleEpic,
    UpdateCommandsEpic
} from "../vehicles/vehicles.epics";
import {LogInEpic, LogOutEpic} from "../auth/auth.epics";
import {DeleteGroupEpic, FetchGroupsEpic, SaveGroupEpic} from "../group/groups.epics";
import {DeleteAccountEpic, FetchAccountsEpic, SaveAccountEpic, UpdatePasswordEpic} from "../accounts/accounts.epics";
import {
    FetchVehiclesMap,
    Locate, ShowAllEpic,
    VehicleData,
    VehiclesMapEnd,
    ZoomInEpic,
    ZoomOutEpic
} from "../map/vehicles/vehicles.epics";
import {FetchAlarms, FetchKm, FetchSpeed, FetchTrips} from "../details/details.epics";
import {DeleteCommandEpic, FetchCommandsEpic, SaveCommandEpic} from "../commands/command.epics";
import {FetchHistory, FetchTrajects} from "../history/history.epics";
import {
    DeleteStartEpics, EditStartZoneEpic,
    FetchZoneEpic,
    FetchZonesEpic,
    SaveEndEpics,
    SaveStartEpics,
    SetColorZone
} from "../zones/zones.epics";
import {FetchEventsMap} from "../map/events/events.epics";
import {
    DeleteAlarmEpic,
    FetchAlarmsType,
    FetchAlarmType,
    GetUpdateAlarmEpic,
    SaveAlarmsEpic
} from "../alarms/alarms.epics";
import {
    FetchReportsType,
    SaveReportsEpic,
    FetchReportType,
    StartDownloadEpic, SaveEndReportsEpic, FetchReportGenerated
} from "../reports/reports.epics";
import {DeleteBlackListEpic, FetchBlackListEpic, SaveBlackListEpic} from "../blacklist/blacklist.epics";
import {DeleteDriverEpic, FetchDriverEpic, SaveDriverEpic} from "../driver/drivers.epics";
import {DeleteIOSEpic, FetchIOSEpic} from "../admin/admin.epics";
import {CreateTracer, DeleteTracer, GetTracer, UpdateTracer} from "../tracers/tracers.epics";
import {FetchDataEpics} from "../deviceManager/deviceManager.epics";
import {DeleteMaitenanceEpic, GetMaintenanceEpic, SaveMaintenanceEpic} from "../maintenance/maintenance.epics";
import {DeleteRoleEpic, FetchRolesEpic, SaveRoleEpic, UpdateRolesEpic} from "../../pages/role/store/role.epics";
export default combineEpics(
    LogInEpic,
    LogOutEpic,

    FetchCompaniesEpic,
    SaveCompanyEpic,
    DeleteCompanyEpic,
    SwitchCompanyEpic,

    FetchVehiclesEpic,
    SaveVehicleEpic,
    DeleteVehicleEpic,
    UpdateCommandsEpic,
    FetchAccessoryStart,
    AddAccessory,

    FetchGroupsEpic,
    SaveGroupEpic,
    DeleteGroupEpic,

    FetchAccountsEpic,
    SaveAccountEpic,
    DeleteAccountEpic,
    UpdatePasswordEpic,

    FetchVehiclesMap,
    VehiclesMapEnd,
    Locate,
    VehicleData,

    ZoomOutEpic,
    ZoomInEpic,
    ShowAllEpic,

    FetchKm,
    FetchSpeed,
    FetchAlarms,
    FetchTrips,

    FetchCommandsEpic,
    DeleteCommandEpic,
    SaveCommandEpic,
    GetCommandsEpic,

    FetchHistory,
    FetchTrajects,

    FetchZonesEpic,
    // SetStepZone,
    SetColorZone,
    SaveStartEpics,
    SaveEndEpics,
    FetchZoneEpic,
    DeleteStartEpics,
    EditStartZoneEpic,

    FetchEventsMap,

    FetchAlarmsType,
    FetchAlarmType,
    SaveAlarmsEpic,
    DeleteAlarmEpic,
    GetUpdateAlarmEpic,

    FetchReportsType,
    FetchReportType,
    SaveReportsEpic,
    SaveEndReportsEpic,

    StartDownloadEpic,

    FetchBlackListEpic,
    SaveBlackListEpic,
    DeleteBlackListEpic,

    FetchDriverEpic,
    SaveDriverEpic,
    DeleteDriverEpic,

    FetchIOSEpic,
    DeleteIOSEpic,

    GetTracer,
    DeleteTracer,
    CreateTracer,
    UpdateTracer,
    FetchDataEpics,

    SaveMaintenanceEpic,
    GetMaintenanceEpic,
    DeleteMaitenanceEpic,

    FetchReportGenerated,

    SaveRoleEpic,
    FetchRolesEpic,
    UpdateRolesEpic,
    DeleteRoleEpic


);

