import * as groupActions from './group.actions';
import {LIMIT} from "../../env";


const INITIAL_STATE = {
    addGroup: {
        steps:[
            {current: 'Information de base', next: 'Fin d\'abonnement'},
        ],
        step: 0,
        data: {},
        loading: false
    },
    groups: [],
    total: 0,
    filters: {
        params: {skip: 0, limit: LIMIT}
    },
    loading: false,
    companyError: null
}


export function groupReducer(state = INITIAL_STATE, action){
    switch (action.type) {

        case groupActions.GET_START:
            const params = {...state.filters.params, ...action.payload};
            return {
                ...state, loading: true,
                filters: {
                    ...state.filters,
                    params
                }
            }

        case groupActions.GET_END:
            return Object.assign({}, state, {
                groups: action.payload.list,
                total: action.payload.total,
                loading: false
            });


        case groupActions.SAVE_START:
            return {
                ...state,
                addGroup: {
                    ...state.addGroup,
                    loading: true
                }
            };

        case groupActions.SAVE_END:
            const group = action.payload;
            const findGroup = state.groups.findIndex(el => el._id === group.id);

            if(findGroup === -1) {
                state.groups.unshift(group);
            } else {
                state.groups[findGroup] = {...state.groups[findGroup], ...group};
            }
            return {
                ...state,
                addGroup: INITIAL_STATE.addGroup,
                groups: state.groups,
            };

        case groupActions.TOOGLE_STEP:
            if(state.addGroup.step) {
                return {
                    ...state,
                    addGroup: {
                        ...state.addGroup,
                        step: false,
                        data: INITIAL_STATE.addGroup.data
                    }
                }
            }
            return {
                ...state,
                addGroup: {
                    ...state.addGroup,
                    step: 1
                }
            }

        case groupActions.SET_STEP_DATA:
            return {
                ...state,
                addGroup: {
                    ...state.addGroup,
                    step: action.payload.step,
                    data: {
                        ...state.addGroup.data,
                       ...action.payload.data
                    }
                }
            }

        case groupActions.SET_DATE:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    data: {
                        ...state.addCompany.data,
                        subscription: action.payload
                    }
                }
            }

        case groupActions.SET_STEP:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    step: action.payload.step
                }
            }

        case groupActions.TOOGLE_ACTIVE:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    data: {
                        ...state.addCompany.data,
                        active: action.payload
                    }
                }
            }

        case groupActions.DELETE_START:
            return {
                ...state,
                loading: true
            }

        case groupActions.DELETE_END:
            const {response, id} = action.payload;
            if(response.deletedCount) {
                const findGroup = state.groups.findIndex(el => el._id === id);
                if(findGroup !== -1) {
                    state.groups.splice(findGroup, 1);
                    return { ...state, loading: false, companies: state.groups }
                }
            }
            return { ...state, loading: false }

        case groupActions.ERROR:
            return Object.assign({}, state, {
                loading: false
            })

        default:
            return state;
    }
}