import './speed.css';
import React, { PureComponent }  from 'react';
import {Speedometer} from "../../../chart/circles/speedometer/speedometer";


export class Speed extends PureComponent{

    constructor(props) {
        super(props);
    }

    loadData() {
            return(
                <div id="speed">
                    <div style={{
                        display: 'flex',
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between"}}
                    >
                        <Speedometer percentage={this.props.speed}/>
                    </div>

                    <div>
                        <label className="label-important">Vitesse véhicule</label>
                        <div className="detail-first">
                            <label>Min:</label>
                            <label style={{marginLeft: 12, labelAlignVertical: "center"}}>
                                {
                                    (this.props.min || this.props.min === 0) ? `${this.props.min} km/h` : '-'
                                }
                            </label>
                        </div>
                        <div className="detail">
                            <label>Max:</label>
                            <label>
                                {
                                    (this.props.max || this.props.max === 0) ? `${this.props.max} km/h` : '-'
                                }
                            </label>
                        </div>
                        <div className="detail">
                            <label>Actuel:</label>
                            <label>
                                { this.props.speed } km/h
                            </label>
                        </div>
                    </div>
                </div>
            )


    }

    render() {
        return <>{ this.loadData() }</>;
    }
}