import React, { Component }  from 'react';
import './search-action.css';
import CountUp from "react-countup";
import {AiOutlinePlus} from "react-icons/ai";

export class SearchAction extends Component {
    constructor(props) {
        super(props);
    }
    changed(e) {
        if(e.keyCode === 13) {
            this.props.onChange &&  this.props.indentifier &&
            this.props.onChange({
                key: this.props.indentifier,
                value: `${e.target.value}.*`
            });
        }
    }
    render() {
        const ICON = this.props.icon
        return (
            <div className="action-search">
                <input type="text" placeholder="Chercher..." onKeyUp={this.changed.bind(this)}/>
                <ICON/>
            </div>
        );
    }
}