import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Button} from "../../../../components/widgets/form/button/button";
import {useForm} from "react-hook-form";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from 'moment-timezone';
import {DatePicker} from "antd";
import {ResetStep, SaveStart, SetStepData} from "../../../../store/reports/report.actions";
import {AiOutlineMail} from "react-icons/all";




export default function ChooseTimeRange({timezone}) {
    const dispatch = useDispatch();
    const {active, step, data} = useSelector(state => {
        const reports = state.reportsReducer;
        return {
            active: reports.active,
            step: reports.addReport.step,
            data: reports.addReport.data
        }
    });
    moment.tz.setDefault(timezone);

    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setError, errors, watch  } = useForm(
        { mode: "onChange",
            defaultValues: {
                id: active._id,
                format: 'xls',
                name: data.name,
            }
        },
    );

    const onSubmit = async(dat) => {
        if(data.start && data.end) {
            dispatch(SaveStart(dat));
        }
    };

    const previous = () => {
        if(step === 1) {
            dispatch(ResetStep());
        } else {
            dispatch(SetStepData({data: {}, step: 1}));
        }
    };


    function onChange(dates, dateStrings) {

        dispatch(SetStepData({data: {
                start: moment(dates[0].toDate().getTime()).tz(timezone).set({hour:0,minute:0,second:0,millisecond:0}).toDate().getTime(),
                end: moment(dates[1].toDate().getTime()).tz(timezone).set({hour:23,minute:59,second:59,millisecond:59}).toDate().getTime()
            }, step}));
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }


    const { RangePicker } = DatePicker;
    const styleName = !errors.name ? 'input-form' : 'input-form input-form-error';
    const validateName = {
        required: {value: true, message: 'Ce champs est obligatoire'},
        minLength: {value: 3, message: 'La taille minimum est: 3'},
        maxLength: {value: 35, message: 'La taille maximum est: 20'},
    }
    return(
        <PerfectScrollbar style={{ height: `${window.innerHeight - 210}px`}}>
            <div className="step1-report">
                <form className="form-alarm" onSubmit={handleSubmit(onSubmit)}>
                    <h4>Nom du rapport</h4>
                    <div className={styleName}>
                        <input
                            name="name" placeholder="Nom du rapport"
                            ref={register(validateName)}
                        />
                        <AiOutlineMail/>
                        {errors.name && <p className="error-input">{errors.name.message}</p>}
                    </div>
                    <h4>Période</h4>
                    <RangePicker
                        className={'period'}
                        ranges={{
                            'Aujourd\'hui': [moment().set({hour:0,minute:0,second:0,millisecond:0}), moment()],
                            'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'La semaine dernière': [moment().subtract(6, 'days'), moment()],
                            'Le mois dernier': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            'Ce mois-ci': [moment().startOf('month'), moment()],

                        }}
                        format="YYYY/MM/DD"
                        disabledDate={disabledDate}
                        onChange={onChange}
                    />
                    <div className="actions">
                        <Button type='btn-accent'  name='Précédent' press={previous}/>
                        <Button type='btn-primary' name='Términer'/>
                    </div>
                </form>
                <div className="description">
                    <img src="/img/report/report.svg"/>
                </div>
            </div>
        </PerfectScrollbar>
    )
}
