import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import {AiFillFileAdd, AiOutlineCar, BiBox, BsBuilding} from "react-icons/all";
import {ChoiceItem} from "../../../../components/choice-item/choice-item";
import {Button} from "../../../../components/widgets/form/button/button";
import {SetActive, SetActiveChoice, SetSteps} from "../../../../store/reports/report.actions";
export const InitReport = () => {

    const dispatch = useDispatch();
    const {choice} = useSelector(state => {
        const reports = state.reportsReducer.addReport;
        return { choice: reports.active };
    });

    const setChoice = (choice) => dispatch(SetActiveChoice(choice))
    return(
        <div className="content">
            <PerfectScrollbar style={{height: `${window.innerHeight - 90}px`}}>
                <div id="choice-item-alarm">
                    <div  className="header background-color-primary">
                        <div className="main-title">
                            <div className="title">
                                <AiFillFileAdd/>
                                <label >Création d'un rapport</label>
                            </div>
                        </div>
                    </div>
                    <div className="items-alarms">
                        <div className="items-alarms-choices">
                            <ChoiceItem
                                icon={BsBuilding}
                                description="Enterprise"
                                active={choice === 0}
                                press={() => setChoice(0)}
                            />
                            <ChoiceItem icon={BiBox} description="Groupes"
                                        active={choice === 1}
                                        press={() => setChoice(1)}
                            />
                            <ChoiceItem icon={AiOutlineCar} description="Véhicule" active={choice === 2}
                                        press={() => setChoice(2)}
                            />
                        </div>
                        <div className="description">
                            <img src="/img/alarms/choice.svg"/>
                        </div>
                    </div>
                    <div className="actions">
                        <Button type='btn-accent' name='Annuler' press={()=>dispatch(SetActive(false))}/>
                        <Button type='btn-primary' name='Suivant' disabled={choice === -1} press={()=> dispatch(SetSteps())}/>
                    </div>

                </div>
            </PerfectScrollbar>
        </div>
    );
}
