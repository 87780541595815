import React, { Component }  from 'react';
import {connect} from "react-redux";
import {
    AiOutlineClockCircle, FaGasPump, FaTemperatureHigh,
    FaWaveSquare,
    FiWifi,
    FiWifiOff, GiCaptainHatProfile, GiMountainCave,
    GiSatelliteCommunication, IoIosFlash,
    IoIosSpeedometer, MdNearMe, MdTimeline,
    MdVpnKey
} from "react-icons/all";
import {getSignal, getSignalName, km, speed} from "../../../../utils/parser";
import PerfectScrollbar from "react-perfect-scrollbar";
import './vehicle-info-map.css';
import moment from "moment-timezone";

class VehicleInfoMap extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 108}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 108}px` });
    }

    status(connected){
        if(connected) {
            return (
                <>
                    <div className="item-info-vehicle-detail">
                        <label>Status</label>
                        <label>Connecté</label>
                    </div>
                    <div className='icon-info'> <FiWifi/> </div>
                </>
            )
        }
        return (
            <>
                <div className="item-info-vehicle-detail">
                    <label>Status</label>
                    <label className="danger-info">Non connecté</label>
                </div>
                <div className='icon-info'> <FiWifiOff/> </div>
            </>
        )
    }
    time(date){
        return (
            <>
                <div className="item-info-vehicle-detail">
                    <label>Temps</label>
                    <label>{moment(date).tz(this.props.timezone).format('DD/MM/YYYY HH:mm:ss')}</label>
                </div>
                <div className='icon-info'>
                    <AiOutlineClockCircle/>
                </div>
            </>
        )
    }
    movement(movement){
        if(movement) { return <label>En mouvement</label>}
        return <label>Parking</label>
    }
    ingintion(ingintion){
        if(ingintion) { return <label>Oui</label>}
        return <label>Non</label>
    }
    render() {
        if(this.props.info) {
            const vehicle = this.props.vehicles.find(el => el.uid === this.props.info);
            if(vehicle && vehicle.dataVehicle && vehicle.dataVehicle.location && vehicle.dataVehicle.location.coordinates.length === 2){
                return (
                    <PerfectScrollbar className="items-info-vehicle" style={{ height: this.state.height, flex: 1, zIndex: 992}}>
                        <div className="item-info-vehicle">
                            { this.status(vehicle.online)}
                        </div>
                        <div className="item-info-vehicle">
                            { this.time(vehicle.dataVehicle.gpsDate)}
                        </div>
                        <div className="item-info-vehicle">
                            <div className="item-info-vehicle-detail">
                                <label>Etat</label>
                                {this.movement(vehicle.dataVehicle.movement)}
                            </div>
                            <div className='icon-info'>
                                <FaWaveSquare/>
                            </div>
                        </div>
                        <div className="item-info-vehicle">
                            <div className="item-info-vehicle-detail">
                                <label>Vitesse</label>
                                <label>{speed(vehicle.dataVehicle.speed)} km</label>
                            </div>
                            <div className='icon-info'>
                                <IoIosSpeedometer/>
                            </div>
                        </div>
                        <div className="item-info-vehicle">
                            <div className="item-info-vehicle-detail">
                                <label>Allumage</label>
                                {this.ingintion(vehicle.dataVehicle.ingintion)}
                            </div>
                            <div className='icon-info'>
                                <MdVpnKey/>
                            </div>
                        </div>
                        {
                            vehicle.dataVehicle.fuel && <div className="item-info-vehicle">
                                <div className="item-info-vehicle-detail">
                                    <label>Fuel</label>
                                    <label>{vehicle.dataVehicle.fuel} L</label>
                                </div>
                                <div className='icon-info'>
                                    <FaGasPump/>
                                </div>
                            </div>
                        }
                        {
                            vehicle.dataVehicle.engineTmp && <div className="item-info-vehicle">
                                <div className="item-info-vehicle-detail">
                                    <label>Température moteur</label>
                                    <label>{vehicle.dataVehicle.engineTmp} C°</label>
                                </div>
                                <div className='icon-info'>
                                    <FaTemperatureHigh/>
                                </div>
                            </div>
                        }

                        <div className="item-info-vehicle">
                            <div className="item-info-vehicle-detail">
                                <label>Signale</label>
                                <label className={vehicle.dataVehicle.signalGSM > 20 ? '' : 'danger-info' }>
                                    {getSignalName(vehicle.dataVehicle.signalGSM)}</label>
                            </div>
                            <div className='icon-info'>
                                {getSignal(vehicle.dataVehicle.signalGSM)}
                            </div>
                        </div>
                        <div className="item-info-vehicle">
                            <div className="item-info-vehicle-detail">
                                <label>Satellite</label>
                                <label className={vehicle.dataVehicle.satelite > 5 ? '' : 'danger-info' }>{vehicle.dataVehicle.satelite}</label>
                            </div>
                            <div className='icon-info'>
                                <GiSatelliteCommunication/>
                            </div>
                        </div>
                        <div className="item-info-vehicle">
                            <div className="item-info-vehicle-detail">
                                <label>Odométre</label>
                                <label>{vehicle.dataVehicle.km ? vehicle.dataVehicle.km.toFixed(2) : '-'} km</label>
                            </div>
                            <div className='icon-info'>
                                <MdTimeline/>
                            </div>
                        </div>

                        <div className="item-info-vehicle">
                            <div className="item-info-vehicle-detail">
                                <label>Voltage</label>
                                <label>{km(vehicle.dataVehicle.gpsV)} V</label>
                            </div>
                            <div className='icon-info'>
                                <IoIosFlash/>
                            </div>
                        </div>
                        <div className="item-info-vehicle">
                            <div className="item-info-vehicle-detail">
                                <label>Altitude</label>
                                <label>{vehicle.dataVehicle.altitude} m</label>
                            </div>
                            <div className='icon-info'>
                                <GiMountainCave/>
                            </div>
                        </div>
                        <div className="item-info-vehicle">
                            <div className="item-info-vehicle-detail">
                                <label>Direction</label>
                                <label>{vehicle.dataVehicle.angle}</label>
                            </div>
                            <div className='icon-info'>
                                <MdNearMe/>
                            </div>
                        </div>
                        <div className="item-info-vehicle">
                            <div className="item-info-vehicle-detail">
                                <label>Chauffeur</label>
                                <label>-</label>
                            </div>
                            <div className='icon-info'>
                                <GiCaptainHatProfile/>
                            </div>
                        </div>
                    </PerfectScrollbar>
                );
            }

        }
        return <></>

    }
}

const VehiclesStateToProps = (state) => {
    return {
        timezone: state.authReducer.user.timezone,
        info: state.vehiclesMapReducer.info,
        vehicles: state.vehiclesMapReducer.vehicles.list,
    }
}
export default connect(VehiclesStateToProps)(VehicleInfoMap);
