import {BASE_URL} from "../../env";
const axios = require('axios');

export function fetchReportsType(payload, token) {
    let params = `skip=${payload.skip}&limit=${payload.limit}`;
    if (payload.name) {
        params+=`&filter[item][name]=${payload.name}`
    }
    const url = `${BASE_URL}/api/reports?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export function fetchReports(payload, token) {
    let params = `skip=${payload.skip}&limit=${payload.limit}&type=${payload.id}`;
    if (payload.name) {
        params+=`&name=${payload.name}`
    }
    const url = `${BASE_URL}/api/report/all?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export function saveReport(filters, payload, type, token){
    let url;
    if (type !== 'poi') {
        url = `${BASE_URL}/api/report/${type}?skip=${filters.skip}&limit=${filters.limit}`;
    } else {
        url = `${BASE_URL}/api/report/zone?skip=${filters.skip}&limit=${filters.limit}&poi=1`;
    }

    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.post(url, payload, config);

}

export function deleteReport(payload, token) {
    const url = `${BASE_URL}/api/alarm/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.delete(url, config);
}

export function getReport(payload, token) {
    const url = `${BASE_URL}/api/alarm/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export function download(payload, token) {
    const url = `${BASE_URL}/api/report/download/${payload._id}`;
    const config = {
        responseType: 'blob',
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export function fetchGeneratedReports(payload, token) {
    const url = `${BASE_URL}/api/report`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}




