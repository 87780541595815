import React, {Component} from "react";
import {AiOutlineSearch} from "react-icons/ai";
import PerfectScrollbar from "react-perfect-scrollbar";
import {BlankComponent} from "../../components/blank/blank";
import {Paginator} from "../../components/pagination/paginator/Paginator";
import {connect} from "react-redux";
import './reports.css';
import {LoadingComponent} from "../../components/loading/loading";
import {GetReportsStart, SetActive, ClearReports} from "../../store/reports/report.actions";
import {ReportTypeInfo} from "../../components/reports/report-type/report-type";
import {SectionComponent} from "../../components/description-section/section";
import AddReportPage from "./add-alarm/add-report";

class ReportsPage extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 210}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.props.dispatch(GetReportsStart({}));
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        this.props.dispatch(ClearReports());

    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 210}px` });
    }

    changed(e) {
        if(e.keyCode === 13) {
            this.props.dispatch(GetReportsStart({
                name: `${e.target.value}.*`
            }));
        }
    }

    paginate(skip, limit) {
        this.props.dispatch(GetReportsStart({skip: skip}));
    }

    activeAlarm(alarm) { this.props.dispatch(SetActive(alarm)); }

    accountAction() {

    }

    filterAction() {

    }
    render() {
        const params= this.props.filters.params;
        const { total } = this.props.report;
        return (
            <section id="report-section" style={{flex: total === 0 ? 1 : 'unset'}}>
                <div className="alarm">
                    <LoadingComponent loading={this.props.loading}/>
                    <div className="action-search-alarm">
                        <input type="text" placeholder="Chercher..." onKeyUp={this.changed.bind(this)}/>
                        <AiOutlineSearch/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                        <PerfectScrollbar style={{ height: this.state.height, padding:'0px 3px', marginBottom: '12px' }}>

                            {
                                this.props.reports.list.length > 0 ?this.props.reports.list.map((el, i) => {
                                    return <ReportTypeInfo key={i}
                                                report={el}
                                                press={this.activeAlarm.bind(this)}
                                                active={this.props.active}
                                                enable={this.props.report.list.length === 0}
                                    />
                                }): <BlankComponent/>
                            }
                        </PerfectScrollbar>
                        <Paginator
                            noMargin={true}
                            paginate={this.paginate.bind(this)}
                            limit={params.limit}
                            skip={params.skip}
                            total={this.props.reports.total}/>
                    </div>
                </div>

                {
                    total === 0 && <div className="content">
                        { this.props.active ? <AddReportPage/>  :
                            <SectionComponent img={"/img/report/report.svg"} description="Choisissez un type de rapport pour continuer"/>
                        }
                    </div>
                }

            </section>
        );
    }
}

const VehiclesStateToProps = (state) => {
    return {
        filters: state.reportsReducer.filters,
        reports: state.reportsReducer.reports,
        loading: state.reportsReducer.loading || state.reportsReducer.report.loading,
        active: state.reportsReducer.active,
        report: state.reportsReducer.report
    }
}
export default connect(VehiclesStateToProps)(ReportsPage);
