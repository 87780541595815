import * as React from 'react';
import {Component} from 'react';
import {timeDifference} from "../../../utils/date";


export class VehicleTimer extends Component{

    constructor(props) {
        super(props);
        this.state = timeDifference(new Date(), new Date(this.props.date));
    }

    componentDidMount() {
        this.interval();
    }

    componentWillUnmount() {
        if(this._interval){clearInterval(this._interval)};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props) {
            this.interval();
        }
    }

    interval() {

        if(this._interval){ clearInterval(this._interval); }
        let interval = 5000;
        const timer = timeDifference(new Date(), new Date(this.props.date));
        this.setState(timer);
        interval = this.setTimeTimer(timer);
        this._interval = setInterval(() => {
            const timer = timeDifference(new Date(), new Date(this.props.date));
            if(interval === 0 && this._interval){
                clearInterval(this._interval);
                return;
            }

            if(timer.unit != this.state.unit) { this.interval(); }
            this.setState(timer);
        }, interval)
    }

    setTimeTimer(timer){
        let interval = 0;
        if(timer.unit === 'Secondes' || timer.unit === 'Seconde') {
            interval = 5000;
        } else if(timer.unit === 'Minutes' || timer.unit === 'Minute') {
            interval = 60000;
        }
        return interval;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }


    render() {
        const time = {
                fontSize: 9,
                fontWeight: "bold",
                color: this.state.unit === 'Secondes' || this.state.unit === 'Seconde' || this.state.unit === 'Minute'
                || (this.state.unit === 'Minutes' && this.state.time > 20) ? "rgba(1, 147, 10, 1)" : "#F0895C",
                marginRight: 2
        };
        const unity = {
                fontSize: 9,
                color: this.state.unit === 'Secondes' || this.state.unit === 'Seconde' || this.state.unit === 'Minute'
                || (this.state.unit === 'Minutes' && this.state.time > 20) ? "rgba(1, 147, 10, 1)" : "#F0895C",
            }

        return (
            <div className="timer">
                <label style={time}>{this.state.time}</label>
                <label style={unity}>{this.state.unit}</label>
            </div>
        );
    }
}


