export const GET_START = '[MAINTENANCE] GET_START';
export const GET_END = '[MAINTENANCE] GET_END';

export const SAVE_START = '[MAINTENANCE] SAVE_START';
export const SAVE_END = '[MAINTENANCE] SAVE_END';

export const DELETE_START = '[MAINTENANCE] DELETE_START';
export const DELETE_END = '[MAINTENANCE] DELETE_END';
export const SET_UPDATE = '[MAINTENANCE] SET_UPDATE';



export const GET_START_ACCESSORY = '[MAINTENANCE] GET_START_ACCESSORY';
export const GET_END_ACCESSORY = '[MAINTENANCE] GET_END_ACCESSORY';
export const TOOGLE_ACCESSORY = '[MAINTENANCE] TOOGLE_ACCESSORY';
export const ADD_START_ACCESSORY = '[MAINTENANCE] ADD_START_ACCESSORY';
export const ADD_END_ACCESSORY = '[MAINTENANCE] ADD_END_ACCESSORY';




export const ERROR = '[MAINTENANCE] ERROR';

export const TOOGLE_STEP = '[MAINTENANCE] TOOGLE_STEP';
export const TOOGLE_ACTIVE = '[MAINTENANCE] TOOGLE_ACTIVE';

export const SET_STEP_DATA = '[MAINTENANCE] SET_STEP_DATA';
export const SET_STEP = '[MAINTENANCE] SET_STEP';
export const SET_DATE = '[MAINTENANCE] SET_DATE';





export const STEP_COMMAND_START = '[MAINTENANCE] STEP_COMMAND_START';
export const STEP_COMMAND_END = '[MAINTENANCE] STEP_COMMAND_END';
export const SAVE_COMMANDS_START = '[MAINTENANCE] SAVE_COMMANDS_START';
export const SAVE_COMMANDS_END = '[MAINTENANCE] SAVE_COMMANDS_END';

export const CLEAR_DATA='[MAINTENANCE] CLEAR_DATA';



export function SetStepData(payload) {
    return { type: SET_STEP_DATA, payload }
}

export function SaveMaintenanceStart(payload) {
    return { type: SAVE_START, payload }
}

export function SaveMaintenanceEnd(payload) {
    return { type: SAVE_END, payload }
}

export function GetStart(payload) {
    return { type: GET_START, payload }
}

export function GetEnd(payload) {
    return { type: GET_END, payload }
}

export function DeleteStart(payload) {
    return { type: DELETE_START, payload }
}

export function DeleteEnd(payload) {
    return { type: DELETE_END, payload }
}


export function SetUpdate(payload) {
    return { type: SET_UPDATE, payload }
}













export function AddAccessoryStart(payload) {
    return { type: ADD_START_ACCESSORY, payload }
}

export function AddAccessoryEnd(payload) {
    return { type: ADD_END_ACCESSORY, payload }
}

export function StepCommandStart(payload) {
    return { type: STEP_COMMAND_START, payload }
}

export function StepCommandEnd(payload) {
    return { type: STEP_COMMAND_END, payload }
}



export function GetAccessoryStart(payload) {
    return { type: GET_START_ACCESSORY, payload }
}

export function GetAccessoryEnd(payload) {
    return { type: GET_END_ACCESSORY, payload }
}

export function ToogleAccessory(payload) {
    return { type: TOOGLE_ACCESSORY, payload }
}


export function ToogleStep() {
    return { type: TOOGLE_STEP }
}

export function ToogleActive(payload) {
    return { type: TOOGLE_ACTIVE, payload }
}



export function SetStep(payload) {
    return { type: SET_STEP, payload }
}



export function SaveCommandsStart(payload) {
    return { type: SAVE_COMMANDS_START, payload }
}

export function SaveCommandsEnd(payload) {
    return { type: SAVE_COMMANDS_END, payload }
}

export function ClearData(payload) {
    return { type: CLEAR_DATA, payload }
}

export function Error(msg) {
    return { type: ERROR, msg }
}
