export const GET_START = '[EVENTS_MAP] GET_START';
export const GET_END = '[EVENTS_MAP] GET_END';
export const PUSH_EVENTS = '[EVENTS_MAP] PUSH_EVENTS';


export const ERROR = '[EVENTS_MAP] ERROR';

export const SET_ACTIVE = '[EVENTS_MAP] SET_ACTIVE_LAYER';


export function GetEventsStart(payload) {
    return { type: GET_START, payload }
}

export function PushEvents(payload) {
    return { type: PUSH_EVENTS, payload }
}

export function GetEventsEnd(payload) {
    return { type: GET_END, payload }
}

export function Error(msg) {
    return { type: ERROR, msg }
}

export function SetActive(payload) {
    return { type: SET_ACTIVE, payload }
}

