import * as tracersActions from "./tracers.actions";
import {LIMIT} from "../../env";
import React from "react";

const INITIAL_STATE = {
    tracers: {list: [
            {name: 'FMB120', img: 'img/tracer/fmb120.png', _id: '5ea9f54a32feb3d839074206'},
            {name: 'FMB920', img: 'img/tracer/fmb920.png', _id: '5ea9f56a32feb3d839074207'},
            {name: 'FMB140', img: 'img/tracer/fmb140.png', _id: '5fd70bb248b416817951b138'}
        ], total: 3},
    active: false,
    filters: {params: {skip: 0, limit: LIMIT}},
    loading: false,

    tracer: {
        list: [],
        total: 0,
        loading: false,
        skip: 0,
        limit: LIMIT
    },

    addTracer: {
        active: false,
        data: {},
        loading: false
    },
    error: ''
}

export function tracersReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case tracersActions.GET_START:
            const params = {...state.filters.params, ...action.payload};
            return Object.assign({}, state, {
                loading: true,
                filters: {...state.filters, params}
            });

        case tracersActions.GET_END:
            return {
                ...state,
                loading: false,
                new: false,
                addTracer: INITIAL_STATE.addTracer,
                active: INITIAL_STATE.active,
                tracers: {
                    ...state.tracers,
                    list: action.payload,
                    total: action.payload.length
                }
            }

        case tracersActions.SET_ACTIVE:
            const active = action.payload;
            if(!active) {
                return {
                    ...state,
                    active: action.payload,
                    addTracer: INITIAL_STATE.addTracer
                }
            }
            return {
                ...state,
                active: action.payload
            };

        case tracersActions.GET_TRACER_START: {
            //const paramReport = {...state.report.filters.params, ...action.payload};
            let tracer = state.tracer
            if(action.payload.skip) {
                tracer = {...state.tracer, ...action.payload};
            } else {
                tracer.skip = 0;
            }

            return Object.assign({}, state, {
                loading: true,
                tracer
            });
        }


        case tracersActions.GET_TRACER_END:
            return {
                ...state,
                loading: false,
                addTracer: INITIAL_STATE.addTracer,
                tracer: {
                    ...state.tracer,
                    list: action.payload.list,
                    total: action.payload.total,
                }
            }

        case tracersActions.TOOGLE_MANAGE_TRACER:
            return {
                ...state,
                addTracer: {
                    ...state.addTracer,
                    data: INITIAL_STATE.addTracer.data,
                    active: !state.addTracer.active
                }
            }

        case tracersActions.DELETE_START:
            return {
                ...state,
                loading: true
            }
        case tracersActions.DELETE_END:
            const {response, id} = action.payload;
            if(response.deletedCount) {
                const findTracer = state.tracer.list.findIndex(el => el._id === id);
                if(findTracer !== -1) {
                    state.tracer.list.splice(findTracer, 1);
                    return { ...state, loading: false, tracer: {
                            ...state.tracer,
                            list: state.tracer.list
                        }
                    }
                }
            }
            return { ...state, loading: false }

        case tracersActions.SET_ADD_DATA:
            return {
                ...state,
                addTracer: {
                    ...state.addTracer,
                    active: true,
                    loading: false,
                    data: action.payload
                }
            }

        case tracersActions.CREATE_TRACER_START:
        case tracersActions.UPDATE_TRACER_START:
            return {
                ...state,
                addTracer: {
                    ...state.addTracer,
                    loading: true
                },
                error: ''
            }

        case tracersActions.CREATE_TRACER_END:
            const tracer = action.payload;
            const findTracer = state.tracer.list.findIndex(el => el._id === tracer._id);
            if(findTracer === -1) {
                state.tracer.list.unshift(tracer);
            } else {
                state.tracer.list[findTracer] = {...state.tracer.list[findTracer], ...tracer};
            }
            return {
                ...state,
                addTracer: INITIAL_STATE.addTracer,
                tracer: {
                    ...state.tracer,
                    list: state.tracer.list
                },
            };

        case tracersActions.ERROR:
            return {
                ...state,
                error: action.payload,
                addTracer: {
                    ...state.addTracer,
                    loading: false
                },
                loading: false
            }
        default:
            return state;
    }
}

