import React, {Component} from "react";
import {connect} from "react-redux";
import {Steps} from 'antd';
import {AiOutlineCalendar, BiBox, GiRadarSweep, IoIosNotificationsOutline} from "react-icons/all";
import './add-alarm.css';
import Step1Alarm from "./step1/step1";
import PlanningStep from "./planningStep/planning-step";
import ZonesStep from "./zone-choose/zonesPage";
import GroupsPage from "./group-choose/groupsPage";
import InitAlarm from "./init-alarm/init-alarm";
import VehiclePage from "./vehicle-choose/vehiclesPage";

class AddAlarmPage extends Component {

    constructor(props) {
        super(props);
        this.state = {height: `${window.innerHeight - 210}px`};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({height: `${window.innerHeight - 210}px`});
    }

    getStep1() {
        if(this.props.addActive === 0) {return <Step1Alarm/>;}
        else if(this.props.addActive === 1) {return <GroupsPage/>;}
        else {return <VehiclePage/>;}
    }

    getStep2() {
        if(this.props.addActive === 0) {return <PlanningStep/>;}
        else {return <Step1Alarm/>;}
    }

    getStep3() {
        if(this.props.addActive === 0) {return <ZonesStep/>;}
        else {return <PlanningStep/>;}
    }
    getStep4() {
        if(this.props.addActive !== 0) {return <ZonesStep/>;}
        else {return <></>;}
    }

    getStep() {
        const step = this.props.step;
        switch (step) {
            case 1:
                return this.getStep1();
            case 2:
                return this.getStep2();
            case 3:
                return this.getStep3();
            case 4:
                return this.getStep4();
            default:
                return <></>
        }
    }

    render() {
        const {Step} = Steps;
        if (this.props.step !== 0) {
            return (
                <div className="add-alarm">
                    <Steps size={'small'} className="stepper-alarm" current={this.props.step - 1}>
                        { this.props.steps.map(step => {
                            const ICON = step.icon;
                            return <Step title={step.name} icon={<ICON/>}/>
                        }) }
                    </Steps>
                    {this.getStep()}
                </div>
            );

        }
        return (
            <div className="add-alarm">
                <InitAlarm/>
            </div>
        )
    }
}

const VehiclesStateToProps = (state) => {
    return {
        active: state.alarmsReducer.active,
        addActive: state.alarmsReducer.addAlarm.active,
        step: state.alarmsReducer.addAlarm.step,
        steps: state.alarmsReducer.addAlarm.steps,
    }
}
export default connect(VehiclesStateToProps)(AddAlarmPage);