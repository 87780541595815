import {BASE_URL} from "../../env";
import Cookies from "universal-cookie";
const axios = require('axios');

const authKey = 'auth';

export async function logIn(payload) {
    const url = `${BASE_URL}/api/account/login`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    }
    return axios.post(url, payload, config);
}
export function saveDataToCookies(payload) {
    const cookies = new Cookies();
    cookies.set(
        authKey,
        JSON.stringify(payload),
        { path: '/', expires: new Date(payload.expiresIn) }
    );
}

export function deleteCookies() {
    const cookies = new Cookies();
    cookies.remove(authKey);
}



export function getAuthToCookies() {
    const cookies = new Cookies();
    return cookies.get(authKey);
}

export function remmoveAuthToCookies() {
    const cookies = new Cookies();
    return cookies.remove(authKey);
}