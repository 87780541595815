import * as companyActions from './company.actions';
import {LIMIT} from "../../env";


const INITIAL_STATE = {
    addCompany: {
        steps:[
            {current: 'Choix de formule', next: 'Information de base'},
            {current: 'Information de base', next: 'Fin d\'abonnement'},
            {current: 'Fin d\'abonnement', next: 'Maps'},
            {current: 'Clée d\'api Maps', next: 'Fin'},
        ],
        step: 0,
        data: {subscription: new Date().getTime(), active: true},
        loading: false
    },
    stats: {active: 0, deactive: 0, endSubscription: 0, total: 0},
    companies: [],
    total: 0,
    filters: {
        data: [
            {filter: 'all', name: 'Tous'},
            {filter: 'active', name: 'Activé'},
            {filter: 'deactive', name: 'Désactivé'},{filter: 'subscription', name: 'Fin d\'abonnement'}],
        params: {skip: 0, limit: LIMIT, filter: 'all'}
    },
    loading: false,
    companyError: null
}


export function companyReducer(state = INITIAL_STATE, action){
    switch (action.type) {

        case companyActions.SWITCH_START:
            return {
                ...state,
                loading: true
            }
        case companyActions.SWITCH_END:
            return {
                ...state,
                loading: false
            }

        case companyActions.GET_START:
            const params = {...state.filters.params, ...action.payload};
            return {
                ...state, loading: true,
                filters: {
                    ...state.filters,
                    params
                }
            }

        case companyActions.GET_END:
            return Object.assign({}, state, {
                companies: action.payload.list,
                stats: action.payload.stats,
                total: action.payload.total,
                loading: false
            });


        case companyActions.SAVE_START:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    loading: true
                }
            };
        case companyActions.SAVE_END:
            const company = action.payload;
            let {total, active, deactive} = state.stats;
            company.active = company.choices.indexOf('active') !== -1;

            const findCompany = state.companies.findIndex(el => el._id === company.id);
            if(findCompany === -1) {
                state.companies.unshift(company);
                total++;
                company.active ? active++ : deactive++;
            } else {
                state.companies[findCompany] = {...state.companies[findCompany], ...company};
                if(company.active){ active++; deactive--; }
                else { active--; deactive++; }
            }
            return {
                ...state,
                addCompany: INITIAL_STATE.addCompany,
                companies: state.companies,
                stats: {
                    ...state.stats,
                    total,
                    active,
                    deactive
                }
            };

        case companyActions.TOOGLE_STEP:
            if(state.addCompany.step) {
                return {
                    ...state,
                    addCompany: {
                        ...state.addCompany,
                        step: false,
                        data: INITIAL_STATE.addCompany.data
                    }
                }
            }
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    step: 1
                }
            }

        case companyActions.SET_STEP_DATA:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    step: action.payload.step,
                    data: {
                        ...state.addCompany.data,
                        ...action.payload.data
                    }
                }
            }

        case companyActions.SET_SUBSCRIPTION:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    data: {
                        ...state.addCompany.data,
                        type: action.payload
                    }
                }
            }

        case companyActions.SET_DATE:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    data: {
                        ...state.addCompany.data,
                        subscription: action.payload
                    }
                }
            }

        case companyActions.SET_STEP:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    step: action.payload.step
                }
            }

        case companyActions.TOOGLE_ACTIVE:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    data: {
                        ...state.addCompany.data,
                        active: action.payload
                    }
                }
            }

        case companyActions.TOOGLE_LOCATION:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    data: {
                        ...state.addCompany.data,
                        location: action.payload
                    }
                }
            }


        case companyActions.DELETE_START:
            return {
                ...state,
                loading: true
            }

        case companyActions.DELETE_END:
            const {response, id} = action.payload;
            if(response.deletedCount) {
                const findCompany = state.companies.findIndex(el => el._id === id);
                if(findCompany !== -1) {
                    let {total, active, deactive} = state.stats;
                    total--;
                    state.companies[findCompany].active ? active-- : deactive--;
                    state.companies.splice(findCompany, 1);
                    return {
                        ...state,
                        loading: false,
                        companies: state.companies,
                        stats: {
                            ...state.stats,
                            total,
                            active,
                            deactive
                        }
                    }
                }


            }
            return { ...state, loading: false }

        case companyActions.ERROR:
            return Object.assign({}, state, {
                loading: false
            })

        default:
            return state;
    }
}