import React, {useEffect, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {BlankComponent} from "../../components/blank/blank";
import {AiOutlineDoubleLeft, AiOutlineDoubleRight, FaPlus} from "react-icons/all";
import {Button as BTN, Progress} from "antd";
import './report.css';
import {ReportListPage} from "./list-report-type";
import {InitReport} from "./generate-report/init-report";
import {useDispatch, useSelector} from "react-redux";
import {GenerateReport} from "./generate-report";
import {GetGeneratedStart, SetShowListReportType} from "../../store/reports/report.actions";
import {GeneratedReportItem} from "./generated-report-item";

export const ReportsBisPage = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(true);
    const {active, generated, showListReportType} = useSelector(state => {
        const reports = state.reportsReducer;
        return {
            active: reports.active,
            generated: reports.generated,
            showListReportType: reports.showListReportType
        };
    });

    useEffect(() => { dispatch(GetGeneratedStart()); }, []);
    return (
        <section id="alarm-section">
            <div className="report-main">
                <div className={`report ${!show ? 'report-hide' : ''}`}>
                    <div className="header">
                        <label className="title">Rapports générés</label>
                        <AiOutlineDoubleLeft className="icon-close" onClick={()=>setShow(false)}/>
                    </div>
                    <BTN className="addReport" type="dashed" onClick={()=>dispatch(SetShowListReportType(true))}>
                        <FaPlus className="icon-addReport"/>
                        <span>Créer un rapport</span>
                    </BTN>
                    <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                        <PerfectScrollbar style={{ height: `${window.innerHeight - 210}px`, padding:'0px 3px', marginBottom: '12px' }}>
                            {
                                generated?.length > 0 ? generated.map(report =>
                                    <GeneratedReportItem report={report}/>)
                                    : <BlankComponent/>
                            }
                        </PerfectScrollbar>
                    </div>
                </div>
                <ReportListPage show={showListReportType} toogleShow={()=>dispatch(SetShowListReportType(!showListReportType))}/>
                {
                    !show &&  <div className="mini-report" onClick={()=>setShow(true)}>
                        <div className="text">
                            <AiOutlineDoubleRight className="icon-close" />
                            <span className="title">  Rapports générés</span>
                        </div>
                    </div>
                }
            </div>
            { active && <GenerateReport/> }

        </section>
    )
}

