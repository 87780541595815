import React, {Component, createRef} from 'react';
import {connect} from "react-redux";
import {fitBoundsPositions} from "../../../../store/map/vehicles/leaflet-provider";

import {GeoJSON, FeatureGroup, Circle, Map} from "react-leaflet";
import CorridorPolyline from "../../../../components/leaflet/corridor-polyline";

class ShowZone extends Component {

    constructor(props) {
        super(props);
        this.geoRef = createRef();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        // Show zone
        if (this.props.showZone && this.props.showZone !== prevProps.showZone) {
            const zone = this.geoRef.current.leafletElement;
            fitBoundsPositions(this.props.mapRef.current, zone.getBounds());
        }
    }

    showZone() {
        const zone = this.props.showZone;
        if (zone) {
            switch (zone.location.type) {
                case 'Point':
                    const coordinates = zone.location.coordinates;
                    return (<FeatureGroup ref={this.geoRef}>
                        <Circle center={[coordinates[1], coordinates[0]]} radius={zone.radius}
                                color={zone.color}/>
                    </FeatureGroup>)
                case 'LineString':
                    const data = {type: 'Feature', properties: {}, geometry: zone.location};
                    const positions = zone.location.coordinates.map(el => [el[1], el[0]]);
                    return (<FeatureGroup ref={this.geoRef}>
                        <GeoJSON data={data} style={{color: zone.color}}/>
                        <CorridorPolyline
                            positions={positions}
                            className={'corridor'}
                            color={zone.color} opacity={0.7} corridor={zone.radius}
                        />
                    </FeatureGroup>)

                case 'Polygon':
                    const dataa = {type: 'Feature', properties: {}, geometry: zone.location};
                    return (<FeatureGroup ref={this.geoRef}><GeoJSON data={dataa} style={{color: zone.color}}/></FeatureGroup>)
                    break;
            }
        }
        return <></>
    }




    render() {
        return (
            <>
                {this.props.showZone && this.showZone()}
                {
                    this.props.zone.latLngs ?
                        <CorridorPolyline
                            positions={this.props.zone.latLngs}
                            className={'corridor'}
                            color={this.props.zone.color}
                            opacity={0.7}
                            corridor={this.props.zone.radius}
                        />
                        : <></>
                }
            </>

        );
    }
}

const MapStateToProps = (state) => {
    return {
        showZone: state.zonesReducer.zone,
        zone: state.zonesReducer.addZone.data,
    }
}
export default connect(MapStateToProps)(ShowZone);