export const INIT_MAP='[VEHICLES_MAP] INIT_MAP';
export const GET_START = '[VEHICLES_MAP] GET_START';
export const GET_END = '[VEHICLES_MAP] GET_END';
export const CLEAR_VEHICLES = '[VEHICLES_MAP] CLEAR_VEHICLES';

export const ERROR = '[VEHICLES_MAP] ERROR';

export const SET_INFO = '[VEHICLES_MAP] SET_ACTIVE';
export const SET_CNX = '[VEHICLES_MAP] SET_CNX';
export const SET_DETAILS = '[VEHICLES_MAP] SET_DETAILS';
export const SET_DRIVER = '[VEHICLES_MAP] SET_DRIVER';


export const TOOGLE_VEHICLE = '[VEHICLES_MAP] TOOGLE_VEHICLE';
export const TOOGLE_CLUSTER = '[VEHICLES_MAP] TOOGLE_CLUSTER';
export const TOOGLE_TRAFFIC = '[VEHICLES_MAP] TOOGLE_TRAFFIC';

export const SET_VEHICLE_DATA = '[VEHICLES_MAP] SET_VEHICLE_DATA';
export const SET_COMMAND = '[VEHICLES_MAP] SET_COMMAND';

export const LOCATE = '[VEHICLES_MAP] LOCATE';
export const SET_TRACK_VEHICLE = '[VEHICLES_MAP] SET_TRACK_VEHICLE';

export const ZOOM_IN = '[VEHICLES_MAP] ZOOM_IN';
export const ZOOM_OUT = '[VEHICLES_MAP] ZOOM_OUT';
export const SHOW_ALL = '[VEHICLES_MAP] SHOW_ALL';

export const SET_ACTIVE_LAYER = '[VEHICLES_MAP] SET_ACTIVE_LAYER';

export const CHANGE_VIEW = '[VEHICLES_MAP] CHANGE_VIEW';


export function ClearVehicles() {
    return { type: CLEAR_VEHICLES }
}
export function ChangeView(payload) {
    return { type: CHANGE_VIEW, payload }
}
export function Locate(payload) {
    return { type: LOCATE, payload }
}

export function ZoomIn() {
    return { type: ZOOM_IN  }
}

export function ZoomOut(payload) {
    return { type: ZOOM_OUT }
}

export function ShowAll(payload) {
    return { type: SHOW_ALL }
}

export function TrackVehicle(payload) {
    return { type: SET_TRACK_VEHICLE, payload }
}

export function InitMap(payload) {
    return { type: INIT_MAP, payload }
}

export function SetVehicleData(payload) {
    // console.log(payload)
    return { type: SET_VEHICLE_DATA, payload }
}

export function SetDriver(payload) {
    return { type: SET_DRIVER, payload }
}

export function SetVehicleCnx(payload) {
    return { type: SET_CNX, payload }
}

export function GetVehiclesStart(payload) {
    return { type: GET_START, payload }
}

export function getVehiclesEnd(payload) {
    return { type: GET_END, payload }
}

export function Error(msg) {
    return { type: ERROR, msg }
}

export function SetInfo(payload) {
    return { type: SET_INFO, payload }
}



export function SetDetails(payload) {
    return { type: SET_DETAILS, payload }
}

export function SetActiveLayer(payload) {
    return { type: SET_ACTIVE_LAYER, payload }
}

export function SetCommand(payload) {
    return { type: SET_COMMAND, payload }
}


export function ToogleVehicle() {
    return { type: TOOGLE_VEHICLE }
}

export function ToogleCluster() {
    return { type: TOOGLE_CLUSTER }
}
