export const GET_START = '[ZONES] GET_START';
export const GET_END = '[ZONES] GET_END';
export const ERROR = '[ZONES] ERROR';

export const DELETE_START = '[ZONES] DELETE_START';
export const DELETE_END = '[ZONES] DELETE_END';

export const SAVE_START = '[ZONES] SAVE_START';
export const SAVE_END = '[ZONES] SAVE_END';

export const FETCH_ONE_START = '[ZONES] FETCH_ONE_START';
export const FETCH_ONE_END = '[ZONES] FETCH_ONE_END';

export const SET_COLOR = '[ZONES] SET_COLOR';
export const SET_RADIUS = '[ZONES] SET_RADIUS';

export const SET_STEP_DATA = '[ZONES] SET_STEP_DATA';
export const SET_STEP = '[ZONES] SET_STEP';
export const TOOGLE_STEP = '[ZONES] TOOGLE_STEP';

export const SET_TYPE_ZONE = '[ZONES] SET_TYPE_ZONE';
export const SET_CORRIDOR = '[ZONES] SET_CORRIDOR';

export const ZONE_DRAW = '[ZONES] ZONE_DRAW';

export const START_ZONE_EDIT = '[ZONES] START_ZONE_EDIT';
export const END_ZONE_EDIT = '[ZONES] END_ZONE_EDIT';

export const CLEAR_ZONE = '[ZONES] CLEAR_ZONE';


export function GetZoneOneStart(payload) {
    return { type: FETCH_ONE_START, payload }
}

export function GetZoneOneEnd(payload) {
    return { type: FETCH_ONE_END, payload }
}

export function StartDelete(payload) {
    return { type: DELETE_START, payload }
}

export function EndDelete(payload) {
    return { type: DELETE_END, payload }
}

export function GetZoneStart(payload) {
    return { type: GET_START, payload }
}

export function getZoneEnd(payload) {
    return { type: GET_END, payload }
}

export function SetStepData(payload) {
    return { type: SET_STEP_DATA, payload }
}
export function SetColor(payload) {
    return { type: SET_COLOR, payload }
}
export function SetTypeZone(payload) {
    return { type: SET_TYPE_ZONE, payload }
}

export function SetStep(payload) {
    return { type: SET_STEP, payload }
}

export function ToogleStep() {
    return { type: TOOGLE_STEP }
}

export function StartZoneDraw(payload) {
    return { type: ZONE_DRAW, payload }
}

export function StartZoneEdit(payload) {
    return { type: START_ZONE_EDIT, payload }
}

export function EndZoneEdit(payload) {
    return { type: END_ZONE_EDIT, payload }
}

export function SaveZoneStart(payload) {
    return { type: SAVE_START, payload }
}

export function SaveZoneEnd(payload) {
    return { type: SAVE_END, payload }
}

export function SetRadius(payload) {
    return { type: SET_RADIUS, payload }
}

export function SetCorridor(payload) {
    return { type: SET_CORRIDOR, payload }
}

export function ClearZone() {
    return { type: CLEAR_ZONE }
}

export function Error(msg) {
    return { type: ERROR, msg }
}