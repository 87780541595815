import React, {useEffect, useState} from 'react';
import './device-manager.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useDispatch, useSelector} from "react-redux";
import {Clear, GetStart} from "../../../store/deviceManager/deviceManager.actions";
import {KmGraph} from "./graph/km-graph";
import {FuelGraph} from "./graph/fuel-graph";
import {IngintionGraph} from "./graph/ingintion-graph";
import {VoltageGraph} from "./graph/voltage-graph";
import {SignalGraph} from "./graph/signal-graph";
import {DatePicker} from "antd";
import {ImStatsDots, SiGooglecalendar} from "react-icons/all";
import moment from "moment-timezone";
import {LoadingComponent} from "../../../components/loading/loading";
import {MinSizeMenu} from "../../../store/main.actions";
import {TimeGraph} from "./graph/time-graph";
export const DeviceManagerPage = ({match}) => {

    const [height, setHeight] =  useState(`${window.innerHeight - 215}px`);
    const dispatch = useDispatch();

    const { list, timezone, loading } = useSelector(state => {
        return {
            list: state.deviceManagerReducer.data.list,
            total: state.deviceManagerReducer.data.total,
            loading: state.deviceManagerReducer.data.loading,
            timezone: state.authReducer.user.timezone
        }
    });

    const disabledDate = (current) =>{
        return current && current > moment();
    }

    const selectDay = (date) =>{
        const {id} = match.params
        const from = moment(date).tz(timezone).set({hour:0,minute:0,second:0,millisecond:0});
        const to = moment(date).tz(timezone).set({hour:23,minute:59,second:59,millisecond:0});
        dispatch(GetStart({id, from: from.toDate(), to: to.toDate()}));
    }

    useEffect(() => {
        const {id} = match.params
        dispatch(MinSizeMenu());
        const from = moment().tz(timezone).set({hour:0,minute:0,second:0,millisecond:0});
        const to = moment().tz(timezone).set({hour:23,minute:59,second:59,millisecond:0});
        dispatch(GetStart({id, from: from.toDate(), to: to.toDate()}));
        return () => dispatch(Clear())
    }, []);

    useEffect(() => {
        setHeight(height);
    }, [height]);

    return (
        <section id="device-manager">
            <LoadingComponent loading={loading}/>
            <div  className="header background-color-primary">
                <div className="main-title">
                    <div className="title">
                        <ImStatsDots/>
                        <label>Gestionnaire de voiture ({list.length !== 0 ? `${list.length} données` : '-' })</label>
                    </div>
                </div>
                <div className="time">
                    <div className='btn-calendar-history'>
                        <SiGooglecalendar />
                        <DatePicker style={{position: 'absolute', height: '100%', opacity: 0}}
                                    allowClear={false}  disabledDate={disabledDate} onChange={selectDay} />
                    </div>
                </div>
            </div>

            <div className="items-device-manager">
                <PerfectScrollbar className="scrollbar" style={{ height, padding:'0px 3px', marginBottom: '12px'}}>
                    <div className="chartContainer">
                        <div style={{flex: 1}}>
                            <KmGraph data={list}/>
                        </div>
                        <div style={{flex: 1}}>
                            <IngintionGraph  data={list}/>
                        </div>

                    </div>

                    <div className="chartContainer">
                        <div style={{flex: 1}}>
                            <FuelGraph  data={list}/>
                        </div>

                        <div style={{flex: 1}}>
                            <TimeGraph  data={list}/>
                        </div>
                    </div>

                    <div className="chartContainer">
                        <div style={{flex: 1}}>
                            <VoltageGraph  data={list}/>
                        </div>
                        <div style={{flex: 1}}>
                            <SignalGraph  data={list}/>
                        </div>
                    </div>
                </PerfectScrollbar>
            </div>
        </section>
    );
}































/*
class DeviceManagerPage extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 176}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 176}px` });
    }

    back() { this.props.history.goBack();}

    render() {
        return (
            <section id="device-manager">
                <div  className="header background-color-primary">
                    <div className="main-title">
                        <div className="title">
                            <ImStatsDots/>
                            <label>Gestionnaire de voiture</label>
                        </div>
                    </div>
                </div>
                <div className="items-device-manager">
                    <div className="items-alarms-choices">
                        <div className="choice-item">
                            <BsTable/>
                            <span>Tableau</span></div>
                        <div className="choice-item">
                            <ImStatsDots/>
                            <span>Graphiques</span></div>
                    </div>
                    <div className="description">
                        <img src="/img/device-manager/device-manager.svg"/>
                    </div>
                </div>
            </section>
        );
    }
}

const companiesStateToProps = (state) => {
    return {

    }
}
export default connect(companiesStateToProps)(DeviceManagerPage);
*/
