import {Progress} from "antd";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment-timezone";
import {StartDownload} from "../../store/reports/report.actions";

export const GeneratedReportItem = ({report}) => {
    const {timezone} = useSelector(state => {
        return { timezone: state.authReducer.user.timezone, };
    });
    const {_id, createdAt, name} = report;

    const dispatch = useDispatch();
    const download = () => {
        if(report.progress === 100 && report._id) {
            dispatch(StartDownload({name, _id}))
        }
    }

    const progress = {padding: '9px 0px 9px 0px', display: 'flex', flexDirection: 'column', borderBottom: '1px solid rgb(163, 168, 166)', cursor: 'pointer'};
    if(!report || report.progress !== 100) {
        progress.cursor = 'not-allowed';
        progress.opacity = 0.7;
    }
    return(
        <div onClick={download} style={progress}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <span style={{fontSize: '13px', fontWeight: 400}}>{name}</span>
                {
                    report.hasOwnProperty('progress') ?
                        <Progress percent={report.progress ?? 0} steps={4} size="default" strokeColor="#52c41a" />
                        : <span style={{fontSize: '10px'}}>En attente...</span>
                }
            </div>
            <span style={{fontSize: '10px', color: 'rgba(0, 0, 0, 0.54)'}}>Date de création: {moment(createdAt).tz(timezone).format('DD/MM/YYYY à HH:mm:ss')}</span>
        </div>
    );
}
