
import './km.css';
import {km} from "../../../../utils/parser";
import React, { PureComponent }  from 'react';
import {Bar} from "../../../chart/bar/Bar";
import {LoadingComponent} from "../../../loading/loading";


export class KmStats extends PureComponent{

    days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    minDays = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];

    constructor(props) {
        super(props);
    }

    loadData() {
            return(
                <div id="odometre">
                    <LoadingComponent loading={this.props.lKms} left="-10px" top= "0px" background={'rgba(255, 255, 255, 0.7)'}/>
                    <div style={{
                        display: 'flex',
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between"}}
                    >
                        {
                            this.props.data && this.props.data.stats.map((el, i) => {
                                console.log(el.max, el.d)
                                return <Bar key={i} value={el.max} label={this.minDays[el.d]}/>
                            })
                        }
                    </div>

                    <div>
                        <label className="label-important">Odométre du jour</label>
                        <div className="detail-first">
                            <label>Aujourd'hui:</label>
                            <label style={{marginLeft: 12, labelAlignVertical: "center"}}>
                                {this.props.data.today === 0 || this.props.data.today  ? `${this.props.data.today.toFixed(2)} km` : '-'}

                            </label>
                        </div>
                        <div className="detail">
                            <label>Max:</label>
                            <label>
                                {this.props.data.max || this.props.data.max >= 0 ? this.days[this.props.data.max] : '-'}

                            </label>
                        </div>
                        <div className="detail">
                            <label>Min:</label>
                            <label>
                                {this.props.data.min || this.props.data.min >= 0 ? this.days[this.props.data.min] : '-'}
                            </label>
                        </div>
                    </div>
                </div>
            )


    }

    render() {
        return <>{ this.loadData() }</>;
    }
}