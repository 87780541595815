import React from 'react';
import { useForm } from "react-hook-form";
import isNumeric from "validator/lib/isNumeric";

import {AiOutlineInfoCircle} from "react-icons/ai";
import {Button} from "../../widgets/form/button/button";


export default function Coeff(props) {
    const { register, handleSubmit, errors  } = useForm(
        { mode: "onChange"},
    );

    const onSubmit = async(data) => {
        data.coeff = Number(data.coeff);
        if(props.select) {
            props.select({key: 'coeff', value: data.coeff})
        }
    };


    const styleCoeff = !errors.coeff ? 'input-form' : 'input-form input-form-error';
    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={styleCoeff}>
                    <input
                        name="coeff" placeholder="Coéfficient"
                        ref={register(
                            { required: {value: true, message: 'Ce champs est obligatoire'},
                                validate: {
                                    isNumber: (input) => isNumeric(input) || "Ce champs doit contenir que des nombres"
                                }}
                        )}
                    />
                    <AiOutlineInfoCircle/>
                    {errors.coeff && <p className="error-input">{errors.coeff.message}</p>}
                </div>

            </div>
            <Button name="Confirmer" type="btn-primary"/>
        </form>
    );
}
