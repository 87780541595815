import * as vehiclesActions from "./vehicles.actions";
import {LIMIT} from "../../../env";

const INITIAL_STATE = {
    activeTileLayer: {type: 'osm', url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'},
    map: false,
    trackVehicle: false,
    info: false,
    details: false,
    command: false,
    show: {
        hidden: false,
        isClustered: false,
        vehicles: true,
        traffic: false
    },
    polylines: new Map(),
    stats: {
        on: new Set([]),
        off: new Set([])
    },
    vehicles: {
        list: [],
        total:0,
    },
    events: {
        active: false,
        list: [],
        total:0,
    },
    active: 'vehicles',
    filters: {
        params: {skip: 0, limit: LIMIT}
    },
    loading: false
}

export function vehiclesMapReducer(state = INITIAL_STATE, action){
    switch (action.type) {
        case vehiclesActions.INIT_MAP:
            return {
                ...state,
                map: action.payload.map,
                editRef: action.payload.editRef
            }
            break
        case vehiclesActions.SET_TRACK_VEHICLE:
            return {
                ...state,
                trackVehicle: action.payload.uid
            }
            break
        case vehiclesActions.GET_START:
            const params = {...state.filters.params, ...action.payload};
            return Object.assign({}, state, {
                loading: true,
                filters: {
                    ...state.filters,
                    params
                }
            })
        case vehiclesActions.GET_END:
            let on = action.payload.stats.find((el) => el._id  === true);
            on = on ? on.vehicles : [];
            let off = action.payload.stats.find((el) => el._id  === false);
            off = off ? off.vehicles : [];
            return Object.assign({}, state, {
                vehicles: {list: action.payload.list, total: action.payload.total},
                stats: {on: new Set(on), off: new Set(off)},
                loading: false
            })
        case vehiclesActions.CLEAR_VEHICLES:
            return Object.assign({}, state, {
                vehicles: INITIAL_STATE.vehicles,
            })

        case vehiclesActions.ERROR:
            return Object.assign({}, state, {
                loading: false
            })

        case vehiclesActions.CHANGE_VIEW:
            return {
                ...state,
                active: action.payload
            }

        case vehiclesActions.SET_INFO:
            return Object.assign({}, state, {
                info: action.payload,
                details: false,
                command: false
            })

        case vehiclesActions.SET_DETAILS:
            return {
                ...state,
                details: action.payload,
                info: false,
                command: false
            }

        case vehiclesActions.SET_COMMAND:
            return {
                ...state,
                command: action.payload,
                details: false,
                info: false
            }

        case vehiclesActions.TOOGLE_VEHICLE:
            return {
                ...state,
                show: {
                    ...state.show,
                    hidden: !state.show.hidden
                }
            }

        case vehiclesActions.TOOGLE_CLUSTER:
            return {
                ...state,
                show: {
                    ...state.show,
                    isClustered: !state.show.isClustered
                }
            }

        case vehiclesActions.TOOGLE_TRAFFIC:
            return Object.assign({}, state, {
                show: Object.assign({}, state.show, {
                    traffic: !state.show.traffic
                })
            })

        case vehiclesActions.SET_DRIVER:{
            const index = state.vehicles.list.map(el => el.uid).indexOf(action.payload.uid);
            const driver = action.payload;
            if(index !== -1) {
                const vehicle = state.vehicles.list[index];
                const info = Object.assign({},
                    state.vehicles.list[index],
                    {online: vehicle.online, driver: driver.data.name, name: vehicle.name,
                        uid: vehicle.uid});

                const list = [
                    ...state.vehicles.list.slice(0, index),
                    info,
                    ...state.vehicles.list.slice(index + 1)
                ];

                return {
                    ...state,
                    vehicles: {
                        ...state.vehicles,
                        list
                    }
                }

            }
            return state;
        }


        case vehiclesActions.SET_VEHICLE_DATA:
            const index = state.vehicles.list.map(el => el.uid).indexOf(action.payload.uid);
            if(index !== -1) {
                // Start polylines
                const polylines = state.polylines;
                const data = [...state.polylines.get(action.payload.uid) || []] ;

                action.payload.dataVehicle.forEach((el) => {
                    const lat = el.location.coordinates[1];
                    const lng = el.location.coordinates[0];
                    data.push([lat, lng]);
                });
                if(data.length > 10) {
                    data.splice(0, 1);
                }
                polylines.set(action.payload.uid, data);
                // End polylines

                const size = action.payload.dataVehicle.length;

                const info = Object.assign({},
                    state.vehicles.list[index],
                    {online: true, name: state.vehicles.list[index].name,
                        uid: state.vehicles.list[index].uid,
                        dataVehicle:  action.payload.dataVehicle[size-1]});
                if(info.dataVehicle.ingintion === false) { delete info.driver};
                const list = [
                    ...state.vehicles.list.slice(0, index),
                    info,
                    ...state.vehicles.list.slice(index + 1)
                ];
                return Object.assign({}, state, {
                    stats: stats(state, action.payload.dataVehicle[size-1].movement, action.payload.uid),
                    vehicles: Object.assign({}, state.vehicles, { list }),
                    polylines
                });
            }
            return state;

        case vehiclesActions.SET_CNX:
            const indexx = state.vehicles.list.map(el => el.uid).indexOf(action.payload.uid);
            if(indexx !== -1) {
                const status = Object.assign({},
                    state.vehicles.list[indexx],
                    {online: action.payload.status});
                const listt = [
                    ...state.vehicles.list.slice(0, indexx),
                    status,
                    ...state.vehicles.list.slice(indexx + 1)
                ];
                return Object.assign({}, state, {
                    vehicles: Object.assign({}, state.vehicles, { list: listt })
                })
            }
            return state;


        case vehiclesActions.SET_ACTIVE_LAYER:
            return Object.assign({}, state, {
                activeTileLayer: action.payload
            });

        case vehiclesActions.SET_COMMAND:
            return Object.assign({}, state, {
                command: action.payload
            });

        default:
            return state;
    }
}

function stats(state, movement, uid) {

    const on = new Set([...state.stats.on]);
    const off = new Set([...state.stats.off]);

    if(movement) {
        on.add(uid);
        off.delete(uid);
    } else {
        on.delete(uid);
        off.add(uid);
    }
    return {on, off};
}
