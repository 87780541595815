import * as blacklistActions from './blacklist.actions';
import {LIMIT} from "../../env";


const INITIAL_STATE = {
    addblacklist: {
        steps:[
            {current: 'Information de base', next: 'Fin'},
        ],
        step: 0,
        data: {},
        loading: false
    },
    blacklist: [],
    total: 0,
    filters: {
        params: {skip: 0, limit: LIMIT}
    },
    loading: false,
    companyError: null
}


export function blacklistReducer(state = INITIAL_STATE, action){
    switch (action.type) {

        case blacklistActions.GET_START:
            const params = {...state.filters.params, ...action.payload};
            return {
                ...state, loading: true,
                filters: {
                    ...state.filters,
                    params
                }
            }

        case blacklistActions.GET_END:
            return Object.assign({}, state, {
                blacklist: action.payload.list,
                total: action.payload.total,
                loading: false
            });


        case blacklistActions.SAVE_START:
            return {
                ...state,
                addblacklist: {
                    ...state.addblacklist,
                    loading: true
                }
            };

        case blacklistActions.SAVE_END:
            const blacklist = action.payload;
            state.blacklist.unshift(blacklist);

            return {
                ...state,
                addblacklist: INITIAL_STATE.addblacklist,
                blacklist: state.blacklist,
            };

        case blacklistActions.TOOGLE_STEP:
            if(state.addblacklist.step) {
                return {
                    ...state,
                    addblacklist: {
                        ...state.addblacklist,
                        step: false,
                        data: INITIAL_STATE.addblacklist.data
                    }
                }
            }
            return {
                ...state,
                addblacklist: {
                    ...state.addblacklist,
                    step: 1
                }
            }

        case blacklistActions.SET_STEP_DATA:
            return {
                ...state,
                addblacklist: {
                    ...state.addblacklist,
                    step: action.payload.step,
                    data: {
                        ...state.addblacklist.data,
                       ...action.payload.data
                    }
                }
            }

        case blacklistActions.SET_DATE:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    data: {
                        ...state.addCompany.data,
                        subscription: action.payload
                    }
                }
            }

        case blacklistActions.SET_STEP:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    step: action.payload.step
                }
            }

        case blacklistActions.TOOGLE_ACTIVE:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    data: {
                        ...state.addCompany.data,
                        active: action.payload
                    }
                }
            }

        case blacklistActions.DELETE_START:
            return {
                ...state,
                loading: true
            }

        case blacklistActions.DELETE_END:
            const {response, id} = action.payload;
            if(response.deletedCount) {
                const findGroup = state.blacklist.findIndex(el => el._id === id);
                if(findGroup !== -1) {
                    state.blacklist.splice(findGroup, 1);
                    return { ...state, loading: false, blacklist: state.blacklist }
                }
            }
            return { ...state, loading: false }

        case blacklistActions.ERROR:
            return Object.assign({}, state, {
                loading: false
            })

        default:
            return state;
    }
}