export const GET_START_SPEED = '[DETAILS] GET_START_SPEED';
export const GET_END_SPEED = '[DETAILS] GET_END_SPEED';

export const GET_START_SPEEDS = '[DETAILS] GET_START_SPEEDS';
export const GET_END_SPEEDS = '[DETAILS] GET_END_SPEEDS';

export const GET_START_KM = '[DETAILS] GET_START_KM';
export const GET_END_KM = '[DETAILS] GET_END_KM';

export const GET_START_ALARMS = '[DETAILS] GET_START_ALARMS';
export const GET_END_ALARMS = '[DETAILS] GET_END_ALARMS';

export const GET_START_TRIPS = '[DETAILS] GET_START_TRIPS';
export const GET_END_TRIPS = '[DETAILS] GET_END_TRIPS';


export const ERROR = '[DETAILS] ERROR';

export const CLEAR = '[DETAILS] CLEAR';


export function GetSpeedStart(payload) {
    return { type: GET_START_SPEED, payload }
}
export function GetSpeedEnd(payload) {
    return { type: GET_END_SPEED, payload }
}

export function GetSpeedsStart(payload) {
    return { type: GET_START_SPEEDS, payload }
}
export function GetSpeedsEnd(payload) {
    return { type: GET_END_SPEEDS, payload }
}

export function GetKMStart(payload) {
    return { type: GET_START_KM, payload }
}
export function GetKMEnd(payload) {
    return { type: GET_END_KM, payload }
}

export function GetAlarmsStart(payload) {
    return { type: GET_START_ALARMS, payload }
}
export function GetAlarmsEnd(payload) {
    return { type: GET_END_ALARMS, payload }
}

export function GetTripsStart(payload) {
    return { type: GET_START_TRIPS, payload }
}
export function GetTripsEnd(payload) {
    return { type: GET_END_TRIPS, payload }
}

export function Clear() {
    return { type: CLEAR }
}





export function Error(msg) {
    return { type: ERROR, msg }
}
