import moment from "moment-timezone";

export function timeDifference(current, previous) {

    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const elapsed = current - previous;


    if (elapsed < msPerMinute) {
        const time = Math.round(elapsed/1000);
        return {time, unit: time > 1 ? 'Secondes' : 'Seconde'};
    }

    else if (elapsed < msPerHour) {
        const time = Math.round(elapsed/msPerMinute);
        return {time, unit: time > 1 ? 'Minutes' : 'Minute'}
    }

    else if (elapsed < msPerDay ) {
        const time = Math.round(elapsed/msPerHour);
        return {time, unit: time > 1 ? 'Heures' : 'Heure'}
    }

    else if (elapsed < msPerMonth) {
        const time = Math.round(elapsed/msPerDay);
        return {time, unit: time > 1 ? 'Jours' : 'Jour'}
    }

    else if (elapsed < msPerYear) {
        const time = Math.round(elapsed/msPerMonth);
        return {time, unit: time > 1 ? 'Mois' : 'Mois'}
    }

    else {
        const time = Math.round(elapsed/msPerYear);
        return {time, unit: time > 1 ? 'Années' : 'Année'}
    }
}

export function  getTimeHours(date) {
    const hours = date.getHours();
    return hours < 10 ? `0${hours}` : `${hours}`;
}

export function  getTimeMinutes(date) {
    const hours = date.getMinutes();
    return hours < 10 ? `0${hours}` : `${hours}`;
}

export function dateDiff(recentDate, date, unit) {
    const diffMs = recentDate.getTime() - date.getTime();
    switch (unit) {
        case UNITTIME.MINUTES:
            return Math.round(diffMs / 60000);
        case UNITTIME.DAYS:
            return Math.floor(diffMs /8.64e+7);
        case UNITTIME.HOURS:
            return Math.floor(diffMs /3.6e+6);
    }
}

export function  getHours(allMinutes) {
    const hours = Math.floor((allMinutes * 1.6667e-5) / 60).toFixed(0);
    return hours < 10 ? `0${hours}` : `${hours}`;
}

export function getMinutes(allMinutes) {
    const minutes = ((allMinutes * 1.6667e-5) % 60).toFixed(0);
    return minutes < 10 ? `0${minutes}` : `${minutes}`;
}

export function getSeconds(allSeconds) {
    const minutes = allSeconds % 3600;
    return minutes < 10 ? `0${allSeconds}` : `${allSeconds}`;
}

export function getMonth(date) {
    switch (date.getMonth()) {
        case 0:
            return 'Jan';
        case 1:
            return 'Fév';
        case 2:
            return 'Mar';
        case 3:
            return 'Avr';
        case 4:
            return 'Mai';
        case 5:
            return 'Jui';
        case 6:
            return 'Juil';
        case 7:
            return 'Aoû';
        case 8:
            return 'Sep';
        case 9:
            return 'Oct';
        case 10:
            return 'Nov';
        case 11:
            return 'Déc';
    }
}

export function getDateTime(dt) {
    return `${
        (dt.getMonth() + 1).toString().padStart(2, '0')}/${
        dt.getDate().toString().padStart(2, '0')}/${
        dt.getFullYear().toString().substr(-2)} ${
        dt.getHours().toString().padStart(2, '0')}:${
        dt.getMinutes().toString().padStart(2, '0')}:${
        dt.getSeconds().toString().padStart(2, '0')}`;
}

export function toTimezone(date, timezone) {
    if(!date || !timezone) {
        return '-';
    }
    return moment(date).tz(timezone).format('DD-MM-YYYY HH:mm:ss');
}

export const UNITTIME = {
    DAYS: 'DAYS',
    HOURS: 'HOURS',
    MINUTES: 'MINUTES'
}
