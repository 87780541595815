import React, { Component }  from 'react';
import './zones.css';
import {LoadingComponent} from "../../components/loading/loading";
import {FilterAction} from "../../components/header/filter-action/filter-action";
import {HeaderAction} from "../../components/header/header-action/header-action";
import {AiOutlineClose, AiOutlinePlus, AiOutlineReload, AiOutlineSearch} from "react-icons/ai";
import {SearchAction} from "../../components/header/search-action/search-action";
import {connect} from "react-redux";
import {ConfirmationDialog} from "../../components/confirmation-dialog/confirmation-dialog";
import {confirmAlert} from "react-confirm-alert";
import PerfectScrollbar from "react-perfect-scrollbar";
import {BlankComponent} from "../../components/blank/blank";
import {Paginator} from "../../components/pagination/paginator/Paginator";
import {ZoneInfo} from "../../components/zone/zone-info";
import {
    ClearZone,
    GetZoneOneStart,
    GetZoneStart,
    StartDelete,
    StartZoneEdit,
    ToogleStep
} from "../../store/zones/zones.actions";
import {SideBar} from "../../components/sideBar/sideBar";
import Step1 from "./add-zone/step1/step1";
import Step2 from "./add-zone/step2/step2";

class ZonesPage extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 176}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(GetZoneStart({}));
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        this.props.dispatch(ClearZone())

    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 176}px` });
    }

    deleteAction(id) {
        this.props.dispatch(StartDelete(id))
    }
    zoneAction(action) {
        switch (action.action) {
            case 'reload':
                this.props.dispatch(GetZoneStart({}));
                break;
            case 'add':
                this.props.dispatch(ToogleStep());
                break;
            case 'edit':
                this.props.dispatch(StartZoneEdit(action.id));
                break;
            case'show':
                this.props.dispatch(GetZoneOneStart(action.id));
                break;
            case 'del':
                const options = {
                    customUI: ({ onClose }) =>
                        <ConfirmationDialog
                            close={onClose}
                            action={this.deleteAction.bind(this)}
                            id={action.id}
                            keyword={action.name}
                            ActionName={"Supprimer"}/>,
                    closeOnEscape: false,
                    closeOnClickOutside: false
                };
                confirmAlert(options);
                break;
        }
    }
    filterAction(action) {
        switch (action.key) {
            case 'name':
                this.props.dispatch(GetZoneStart({
                    name: action.value,
                    skip: 0
                }))
                break;

            case 'filter':
                this.props.dispatch(GetZoneStart({
                    filter: action.value,
                    skip: 0
                }))
                break;
        };
    }
    paginate(skip, limit) {
        this.props.dispatch(GetZoneStart({skip: skip}));
    }

    render() {
        const filters= this.props.filters.data;
        const params= this.props.filters.params;
        return (
            <section id="zones">
                <LoadingComponent loading={this.props.loading}/>
                <div className="header-section">
                    <div className="filter">
                        {
                            filters && filters.map((el, i)=> {
                                return <FilterAction
                                    key={i}
                                    active={el.filter === params.filter}
                                    label={el.name}
                                    value={el.filter}
                                    press={this.filterAction.bind(this)}
                                />
                            })
                        }
                    </div>
                    <div className="action-right">
                        <HeaderAction
                            value={{action: 'reload'}}
                            press={this.zoneAction.bind(this)}
                            icon={AiOutlineReload}
                        />
                        <HeaderAction
                            value={{action: 'add'}}
                            press={this.zoneAction.bind(this)}
                            icon={AiOutlinePlus}
                        />
                        <SearchAction
                            icon={AiOutlineSearch}
                            indentifier="name"
                            onChange={this.filterAction.bind(this)}
                        />
                    </div>

                </div>
                <div style={{display: 'flex'}}>
                    <PerfectScrollbar className="scrollbar-container-zone" style={{ height: this.state.height }}>
                        <div className="body">
                            {
                                this.props.zones.length > 0  ? this.props.zones.map((zone,i) => {
                                    return <ZoneInfo
                                        key={i}
                                        active={this.props.zone}
                                        zone={zone}
                                        press={this.zoneAction.bind(this)}
                                    />
                                }) : !this.props.loading && <BlankComponent/>
                            }
                        </div>
                        <Paginator paginate={this.paginate.bind(this)} limit={params.limit} skip={params.skip} total={this.props.total}/>

                    </PerfectScrollbar>

                    {
                        this.props.addZone.step > 0 &&
                        <SideBar
                            height={window.innerHeight}
                            countSteps={this.props.addZone.steps.length}
                            step={this.props.addZone.step}
                            stepsText={this.props.addZone.steps}
                        >
                            {
                                this.props.addZone.step === 1 &&  !this.props.addZone.data.location && <Step1
                                    data={this.props.addZone.data}
                                />
                            }
                            {
                                this.props.addZone.step === 2 &&  <Step2
                                    data={this.props.addZone.data}
                                />
                            }
                            {
                                this.props.addZone.step === 1 && this.props.addZone.steps.length === 1 && <Step2
                                    data={this.props.addZone.data}
                                />
                            }
                        </SideBar>
                    }

                </div>
            </section>
        );
    }
}

const zonesStateToProps = (state) => {
    return {
        editTools: state.vehiclesMapReducer.editRef,
        zones: state.zonesReducer.zones,
        zone: state.zonesReducer.zone,
        loading: state.zonesReducer.loading || state.zonesReducer.addZone.loading,
        filters: state.zonesReducer.filters,
        total: state.zonesReducer.total,
        addZone: state.zonesReducer.addZone,

    }
}
export default connect(zonesStateToProps)(ZonesPage);
