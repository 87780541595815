import React, { Component }  from 'react';
import './circleProgressBar.css';
import "react-perfect-scrollbar/dist/css/styles.css";

export class CircleProgressBar extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div className="circle-progress">
                <svg viewBox="0 0 33.83098862 33.83098862" width={this.props.size}
                     height={this.props.size} xmlns="http://www.w3.org/2000/svg">
                    <circle className="background" stroke="#efefef" strokeWidth="2"
                            cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                    <circle className={`circle ${this.props.color}`} strokeWidth="2"
                            strokeDasharray={`${this.props.percent},100`} strokeLinecap="round"
                            cx="16.91549431" cy="16.91549431" r="15.91549431"></circle>
                </svg>
                {this.props.children}
            </div>

        );
    }
}