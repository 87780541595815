import {BASE_URL} from "../../../env";
const axios = require('axios');

export async function getIOActive(payload, token) {
    const url = `${BASE_URL}/api/vehicle/${payload}/io/active`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export async function deleteIo(vehicleId, payload, token) {
    const url = `${BASE_URL}/api/vehicle/${vehicleId}/io/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.delete(url, config);
}