import React, {useState} from 'react';
import './step1.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useForm } from "react-hook-form";
import {Button} from "../../../../components/widgets/form/button/button";
import {fetchGroupByUName, fetchTracerByUid} from "../../vehicleService";
import {useDispatch, useSelector} from "react-redux";
import {SetStepData, ToogleActive, ToogleStep} from "../../../../store/vehicles/vehicles.actions";
import {FaBoxOpen} from "react-icons/fa";
import {GiGps} from "react-icons/gi";
import {Checkbox} from "../../../../components/widgets/form/checkbox/checkbox";

function checkTracer(tracer, vehicle) {
    if(vehicle.id && (!tracer || vehicle.tracer && vehicle.tracer === tracer)){
        return false;
    }
    return true;
}
export default function Step1(props) {
    const dispatch = useDispatch();
    const {token, vehicle} = useSelector(state => {
        return {
            token: state.authReducer.token,
            vehicle: state.vehiclesReducer.addVehicle.data,
        }
    });
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setError, errors, formState  } = useForm(
        { mode: "onChange",
            defaultValues: {
                id: props.data.id,
                group: props.data.group,
                tracer: props.data.tracer,
                unitSpeed: props.data.unitSpeed,
            }
        },
    );

    const onSubmit = async(data) => {
        setLoading(true);
        const promises = [];
        const check = checkTracer(data.tracer, vehicle);
        if(check) {
            promises.push(fetchTracerByUid(data.tracer, token))
        }

        if(data.group){ promises.push(fetchGroupByUName(data.group, token)); }
        const response = await Promise.all(promises);
        const errors = response.filter(el => el.error === true);
        if(errors.length>0) {
            response.forEach(el => {
                if(el.error){
                    setError(el.for, {message: el.msg, type:'isUnique'});
                }
            })
        }else {
            dispatch(SetStepData({step: 2, data}));
        }


        setLoading(false);
    };

    const previous = () => { dispatch(ToogleStep()); };

    const toogleCheckbox = (value) => {
        dispatch(ToogleActive(value));
    };

    const styleTracer = !errors.tracer ? 'input-form' : 'input-form input-form-error';
    const styleGroup = !errors.group ? 'input-form' : 'input-form input-form-error';
    const styleUnit = !errors.unitSpeed ? 'input-form' : 'input-form input-form-error';
    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={styleTracer}>
                    <input
                        name="id" hidden={true}
                        ref={register}
                    />
                    <input
                        name="tracer" placeholder="Id du traceur"
                        ref={register(
                            {
                                required: {value: !vehicle.id, message: 'Ce champs est obligatoire'},
                                minLength: {value: 4, message: 'La taille minimum est: 4'}
                            }

                        )}
                    />
                    <GiGps/>
                    {errors.tracer && <p className="error-input">{errors.tracer.message}</p>}
                </div>
                <div className={styleGroup}>

                    <input name="group" placeholder="Groupe"
                           ref={register()}
                    />
                    {
                        /*
                                            <AutoComplete
                        active="hicham"
                        data={['hicham', 'belghiti']}/>
                        * */
                    }

                    <FaBoxOpen/>
                    {errors.group && <p className="error-input">{errors.group.message}</p>}
                </div>
                <Checkbox name="active" press={toogleCheckbox} checked={props.data.active}/>

            </div>

            <div className="actions">
                <Button type='btn-accent' press={previous} name='Fermer'/>
                <Button type='btn-primary' name='Suivant' loading={loading}/>
            </div>
        </form>
    );
}
