import isEmail from "validator/lib/isEmail";

export const validateText  = (required) =>  {
    const validation = {
        minLength: {value: 4, message: 'La taille minimum est: 4'},
        maxLength: {value: 35, message: 'La taille maximum est: 35'}
    }
    if(required) validation.required = {value: true, message: 'Ce champs est obligatoire'};
    return validation;
}


export const validateKm  = (required) =>  {
    const validation = {  }
    if(required) {
        validation.required = {value: true, message: 'Ce champs est obligatoire'};
        validation.min = {value: 1, message: 'Minimum est: 1 km'};
        validation.max = {value: 2000000, message: 'Maximum est: 2000000 km'};
    }
    return validation;
}

export const validateDay  = (required) =>  {
    const validation = {  }
    if(required) {
        validation.required = {value: true, message: 'Ce champs est obligatoire'};
        validation.min = {value: 1, message: 'Minimum est: 1 jours'};
        validation.max = {value: 1460, message: 'Maximum est: 1460 jours'};
    }
    return validation;
}

export const validateEmail = (required) => {
    const validation = {  }
    if(required) {
        validation.required = {
            value: true,
                message: 'Ce champs est obligatoire'
        };
    }

    validation.validate =  {
        isEmail: (input) => isEmail(input) || "Ce champs doit être un email : example@provider.com"
    }
    return validation;
}

export const resetInput = (reset, valuesToReset) => {
    reset(valuesToReset, {
        keepErrors: false, keepDirty: false, keepIsSubmitted: false,
        keepTouched: false, keepIsValid: false, keepSubmitCount: false,
    });
}
