import React, { Component }  from 'react';
import './shape-details.css';

export class ShapeDetails extends Component {
    render() {
        return (
            <div className="section-details">
                <div className="left"></div>
                <div className="center">
                    <div className="top">
                        <div className="border-top">
                            {this.props.children}
                        </div>
                    </div>
                </div>
                <div className="right"></div>
            </div>
        );
    }
}