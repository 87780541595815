import React, { Component }  from 'react';
import './checkbox.css';

export class Checkbox extends Component {

    constructor(props) {
        super(props);
    }

    click(e) {
        if(this.props.press)
            this.props.press(e.target.checked);
    }

    render() {
        return(
            <div className="toggles">
                <input
                    type="checkbox"
                    name={this.props.name}
                    id={this.props.name}
                    className="ios-toggle"
                    onChange={this.click.bind(this)}
                    checked={this.props.checked}
                />
                <label htmlFor={this.props.name} className="checkbox-label" data-off={`${this.props.name} désactivé`} data-on={`${this.props.name} activé`}></label>
            </div>
        );

    }
}