const findRole = (name, where) => {
    return where.findIndex(r => r === name) !== -1;
}

export const canShowAlarm = (userType, roles) => {
    return userType === 'admin' || findRole('alarm:rd', roles) || findRole('alarm:wt', roles) || findRole('alarm:dt', roles)
}

export const canManageAlarm = (userType, roles) => {
    return userType === 'admin' || findRole('alarm:wt', roles);
}

export const canDeleteAlarm = (userType, roles) => {
    return userType === 'admin' || findRole('alarm:dt', roles)
}
