export const GET_START = '[ROLE] GET_START';
export const GET_END = '[ROLE] GET_END';

export const SAVE_START = '[ROLE] SAVE_START';
export const SAVE_END = '[ROLE] SAVE_END';

export const UPDATE_START = '[ROLE] UPDATE_START';
export const UPDATE_END = '[ROLE] UPDATE_END';

export const DELETE_START = '[ROLE] DELETE_START';
export const DELETE_END = '[ROLE] DELETE_END';

export const ERROR = '[ROLE] ERROR';

export const SET_ACTIVE = '[ROLE] SET_ACTIVE';
export const SET_EDIT = '[ROLE] SET_EDIT';
export const SET_ADD = '[ROLE] SET_ADD';
export const SET_ROLE = '[ROLE] SET_ROLE';
export const CAN_DELETE = '[ROLE] CAN_DELETE';


export function CanDelete() { return { type: CAN_DELETE }; }
export function GetStart(payload) { return { type: GET_START, payload }; }
export function GetEnd(payload) { return { type: GET_END, payload }; }

export function SetActive(payload) { return { type: SET_ACTIVE, payload }; }
export function SetEdit(payload) { return { type: SET_EDIT, payload }; }
export function SetAdd(payload) { return { type: SET_ADD, payload }; }
export function SetRole(payload) { return { type: SET_ROLE, payload }; }

export function SaveStart(payload) { return { type: SAVE_START, payload }; }
export function UpdateStart(payload) { return { type: UPDATE_START, payload }; }
export function UpdateEnd(payload) { return { type: UPDATE_END, payload }; }

export function DeleteStart(payload) { return { type: DELETE_START, payload }; }
export function DeleteEnd(payload) { return { type: DELETE_END, payload }; }

export function SaveEnd(payload) { return { type: SAVE_END, payload }; }
export function Error(payload) { return { type: ERROR, payload }; }

