import React, { Component }  from 'react';
import './shape-element-info.css';
import {BsCheckAll} from "react-icons/bs";

export class ShapeElementInfo extends Component {
    render() {
        const ICON = this.props.icon;
        const classIcon = this.props.first ? "shape-item-detail-first" : "shape-item-detail";
        return (
            <div className="shape-element-info">
                <div className={classIcon}><ICON/></div>
                <div className="data">
                    <label className="value">{this.props.value}</label>
                    <label className="description">{this.props.name}</label>
                </div>
            </div>
        );
    }
}