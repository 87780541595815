import React, { Component }  from 'react';
import {connect} from "react-redux";
import './map.css'
import {MinSizeMenu} from "../../store/main.actions";
import {Button, Popover, Tooltip} from "antd";
import {HeaderAction} from "../../components/header/header-action/header-action";
import {
    AiOutlineCar,
    AiOutlineZoomIn,
    AiOutlineZoomOut,
    BiEraser, IoIosArrowBack,
    IoMdNotificationsOutline, IoMdRefresh,
    MdCenterFocusStrong,
    MdDeviceHub,
    MdLayers,
    MdNearMe,
} from "react-icons/all";
import {
    ChangeView,
    ClearPolylines, GetVehiclesStart, SetActiveLayer, SetCommand, SetDetails, SetInfo,
    ShowAll,
    ToogleCluster,
    ToogleVehicle,
    ZoomIn,
    ZoomOut
} from "../../store/map/vehicles/vehicles.actions";
import VehiclesMapPage from "./vehicles/vehicles";
import {LoadingComponent} from "../../components/loading/loading";
import VehicleInfoMap from "./vehicles/vehicle-info/vehicle-info-map";
import VehicleDetailsMap from "./vehicles/vehicle-details/vehicle-details-map";
import {Layer} from "../../components/map/layer/layer";
import VehicleCommandsMap from "./vehicles/vehicle-commands/vehicle-commands-map";
import VehicleHistory from "./vehicles/vehicle-history/vehicle-history";
import {ToogleHistory} from "../../store/history/history.actions";
import EventsMapPage from "./events/events";
import {Badge} from "antd";
import {GetEventsStart} from "../../store/map/events/events.actions";


class MapPagePage extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() { this.props.dispatch(MinSizeMenu()); }


    action(action) {
        switch (action) {
            case 'zoomIn':
                this.props.dispatch(ZoomIn());
                break;
            case 'zoomOut':
                this.props.dispatch(ZoomOut());
                break;
            case 'cluster':
                this.props.dispatch(ToogleCluster());
                break;
            case 'showAll':
                this.props.dispatch(ShowAll());
                break;
            case 'toogleShow':
                this.props.dispatch(ToogleVehicle());
                break;

        }
    }

    changeLayer(action){
        switch (action) {
            case 'streets-v9':
            case 'satellite-streets-v10':
                this.props.dispatch(
                    SetActiveLayer({
                        type: action,
                        url: `https://api.mapbox.com/styles/v1/mapbox/${action}/tiles/{z}/{x}/{y}?access_token=${this.props.user.company.mapBox}`,
                        attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>'
                    })
                )
                break;
            case 'osm':
                this.props.dispatch(
                    SetActiveLayer({
                        type: 'osm',
                        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
                    })
                );
                break;
            case 'ROADMAP':
            case 'SATELLITE':
            case 'HYBRID':
            case 'TERRAIN':
                this.props.dispatch(
                    SetActiveLayer({
                        type: 'google',
                        mapType: action,
                        attribution: 'google'
                    })
                );
                break;

        }
    }

    changeView(value) { this.props.dispatch(ChangeView(value.action));  }

    refreshPage() {
        this.props.active === 'vehicles' ?
            this.props.dispatch(GetVehiclesStart({})) :
            this.props.dispatch(GetEventsStart({}));
    }

    render() {
        const content =
            <>
                <div style={{marginBottom: '12px'}}>
                    <h5>MapBox</h5>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent:'flex-start', alignItems: 'center'}}>
                        <Layer
                            action="osm"
                            name="OSM"
                            img="/img/maps/osrm.png"
                            press={this.changeLayer.bind(this)}
                            active={this.props.activeTileLayer.type === 'osm'}
                        />


                        { this.props.user && this.props.user.company.mapBox && <>
                            <Layer
                                action="streets-v9"
                                name="Standard"
                                img="/img/maps/mNormal.png"
                                press={this.changeLayer.bind(this)}
                                active={this.props.activeTileLayer.type === 'streets-v9'}

                            />

                            <Layer
                                action="satellite-streets-v10"
                                name="Hybrid"
                                img="/img/maps/mHybrid.png"
                                press={this.changeLayer.bind(this)}
                                active={this.props.activeTileLayer.type === 'satellite-streets-v10'}

                            />
                         </>
                        }

                    </div>
                </div>

                {
                    this.props.user && this.props.user.company.googleMaps &&  <div style={{marginBottom: '12px'}}>
                        <h5>Google maps</h5>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between', alignItems: 'center'}}>
                            <Layer
                                action="ROADMAP"
                                name="Standard"
                                img="/img/maps/normal.png"
                                press={this.changeLayer.bind(this)}
                                active={this.props.activeTileLayer.mapType === 'ROADMAP'}
                            />

                            <Layer
                                action="SATELLITE"
                                name="Satellite"
                                img="/img/maps/satellite.png"
                                press={this.changeLayer.bind(this)}
                                active={this.props.activeTileLayer.mapType === 'SATELLITE'}
                            />

                            <Layer
                                action="HYBRID"
                                name="Hybrid"
                                img="/img/maps/hybrid.png"
                                press={this.changeLayer.bind(this)}
                                active={this.props.activeTileLayer.mapType === 'HYBRID'}

                            />

                            <Layer
                                action="TERRAIN"
                                name="Terrain"
                                img="/img/maps/terrain.png"
                                press={this.changeLayer.bind(this)}
                                active={this.props.activeTileLayer.mapType === 'TERRAIN'}

                            />
                        </div>
                    </div>
                }



            </>

        return (
            <>
                <div className="action-control">
                    <div className="actions">
                        <div className="zoom">
                            <Tooltip placement="left" title={"zoom in"}>
                                <Button type="primary" icon={<AiOutlineZoomIn />} onClick={() => this.action('zoomIn')}/>
                            </Tooltip>
                            <Tooltip placement="left" title={"zoom out"}>
                                <Button type="primary" icon={<AiOutlineZoomOut />} onClick={() => this.action('zoomOut')}/>
                            </Tooltip>
                        </div>
                        <Tooltip placement="left" title={"afficher/cacher véhicule"} onClick={() => this.action('toogleShow')}>
                            <Button className={this.props.show.hidden ? "active" : ""} type="primary" icon={<MdNearMe />}/>
                        </Tooltip>
                        <Tooltip placement="left" title={"afficher/cacher clusters"} onClick={() => this.action('cluster')}>
                            <Button className={this.props.show.isClustered ? "active" : ""} type="primary" icon={<MdDeviceHub />}/>
                        </Tooltip>
                        <Tooltip placement="left" title={"afficher tous les véhicules"}>
                            <Button type="primary" icon={<MdCenterFocusStrong />} onClick={() => this.action('showAll')}/>
                        </Tooltip>
                    </div>

                    {
                        !this.props.history && <Tooltip placement="left" title={"Type de la carte"}>
                            <Popover
                                overlayStyle={{zIndex:9999}}
                                placement="topLeft"
                                content={content}
                                trigger="click"
                            >
                                <Button type="primary" icon={<MdLayers />} style={{marginBottom: '12px'}}/>
                            </Popover>
                        </Tooltip>
                    }




                </div>
                <div id="map-section">
                    <div className="vehicles">
                        <LoadingComponent loading={this.props.loading} left={'-8px'}/>
                        <div className="header">
                            <label>{ this.props.active === 'vehicles' ?  'Véhicules' : 'Événements'}</label>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <HeaderAction
                                    value={{action: 'refresh'}}
                                    icon={IoMdRefresh}
                                    press={this.refreshPage.bind(this)}
                                />
                                <HeaderAction
                                    value={{action: 'vehicles'}}
                                    icon={AiOutlineCar}
                                    active={this.props.active === 'vehicles'}
                                    press={this.changeView.bind(this)}
                                />
                                <Badge dot={this.props.newEvent}>
                                    <HeaderAction
                                        value={{action: 'events'}}
                                        icon={IoMdNotificationsOutline}
                                        active={this.props.active === 'events'}
                                        press={this.changeView.bind(this)}
                                    />
                                </Badge>
                            </div>
                        </div>
                        {
                            this.props.active === 'vehicles' ?  <VehiclesMapPage/> : <EventsMapPage/>
                        }

                    </div>

                    {
                        !this.props.history && this.props.info &&
                        <>
                            <VehicleInfoMap />
                            <div style={{position: 'relative'}} onClick={()=>this.props.dispatch(SetInfo(false))}>
                                <div className="back">
                                    <img src="/img/back.svg"></img>
                                </div>
                                <div className="icon-back">
                                    <IoIosArrowBack/>
                                </div>
                            </div>
                        </>
                    }


                    {
                        !this.props.history && this.props.details &&
                        <>
                            <VehicleDetailsMap />
                            <div style={{position: 'relative'}} onClick={() => this.props.dispatch(SetDetails(false))}>
                                <div className="back">
                                    <img src="/img/back.svg"></img>
                                </div>
                                <div className="icon-back">
                                    <IoIosArrowBack/>
                                </div>
                            </div>
                        </>
                    }

                    {
                        !this.props.history && this.props.command &&
                        <>
                            <VehicleCommandsMap />
                            <div style={{position: 'relative'}} onClick={() => this.props.dispatch(SetCommand(false))}>
                                <div className="back">
                                    <img src="/img/back.svg"></img>
                                </div>
                                <div className="icon-back">
                                    <IoIosArrowBack/>
                                </div>
                            </div>
                        </>

                    }

                    {
                        this.props.history &&
                        <>
                            <VehicleHistory/>
                            <div style={{position: 'relative'}} onClick={() => this.props.dispatch(ToogleHistory(false))}>
                                <div className="back">
                                    <img src="/img/back.svg"></img>
                                </div>
                                <div className="icon-back">
                                    <IoIosArrowBack/>
                                </div>
                            </div>
                        </>
                    }

                </div>
            </>

        );
    }
}

const MapStateToProps = (state) => {
    return {
        active: state.vehiclesMapReducer.active,
        loading: state.vehiclesMapReducer.loading || state.eventsMapReducer.loading,
        show: state.vehiclesMapReducer.show,
        info: state.vehiclesMapReducer.info,
        details: state.vehiclesMapReducer.details,
        command: state.vehiclesMapReducer.command,
        user: state.authReducer.user,
        activeTileLayer: state.vehiclesMapReducer.activeTileLayer,
        history: state.historyReducer._id,
        newEvent: state.eventsMapReducer.new
    }
}
export default connect(MapStateToProps)(MapPagePage);