export const GET_START = '[REPORTS_MAP] GET_START';
export const GET_END = '[REPORTS_MAP] GET_END';

export const START_DOWLOAD = '[REPORTS_MAP] START_DOWLOAD';
export const END_DOWLOAD = '[REPORTS_MAP] END_DOWLOAD';

export const SET_TIMES = '[REPORTS_MAP] SET_TIMES';

export const SAVE_START = '[REPORTS_MAP] SAVE_START';
export const SAVE_END = '[REPORTS_MAP] SAVE_END';
export const VIRTUAL_PAGINATE = '[REPORTS_MAP] VIRTUAL_PAGINATE';
export const CLEAR_REPORT = '[REPORTS_MAP] CLEAR_REPORT';


export const GET_REPORT_START = '[REPORTS_MAP] GET_REPORT_START';
export const GET_REPORT_END = '[REPORTS_MAP] GET_REPORT_END';
export const EMPTY_REPORT = '[REPORTS_MAP] EMPTY_REPORT';

export const UPDATE_STATUS = '[REPORTS_MAP] UPDATE_STATUS';




export const TOOGLE_STEP = '[REPORTS_MAP] TOOGLE_STEP';
export const SET_STEPS = '[REPORTS_MAP] SET_STEPS';
export const SET_STEP_DATA = '[REPORTS_MAP] SET_STEP_DATA';
export const RESET_STEP = '[GROUP] RESET_STEP';

export const SET_ACTIVE_CHOICE = '[REPORTS_MAP] SET_ACTIVE_CHOICE';

export const SELECT_VEHICLE = '[REPORTS_MAP] SELECT_VEHICLE';
export const SELECT_GROUP = '[REPORTS_MAP] SELECT_GROUP';
export const SELECT_ZONE = '[REPORTS_MAP] SELECT_ZONE';

export const CLEAR_REPORTS = '[REPORTS_MAP] CLEAR_REPORTS';

export const ERROR = '[REPORTS_MAP] ERROR';

export const SET_ACTIVE = '[REPORTS_MAP] SET_ACTIVE';

export const GET_GENERATED_START = '[REPORTS_MAP] GET_GENERATED_START';
export const GET_GENERATED_END = '[REPORTS_MAP] GET_GENERATED_END';
export const PUSH_TO_GENERATED = '[REPORTS_MAP] PUSH_TO_GENERATED';
export const SET_SHOW_LIST_REPORT_TYPE = '[REPORTS_MAP] SET_SHOW_LIST_REPORT_TYPE';


export function GetGeneratedStart(payload) {
    return { type: GET_GENERATED_START, payload }
}

export function GetGeneratedEnd(payload) {
    return { type: GET_GENERATED_END, payload }
}

export function PushToGenerated(payload) {
    return { type: PUSH_TO_GENERATED, payload }
}

export function ResetStep() {
    return { type: RESET_STEP }
}

export function ClearReport() {
    return { type: CLEAR_REPORT }
}

export function EmptyReport() {
    return { type: EMPTY_REPORT }
}



export function SelectVehicleReport(payload){
    return { type: SELECT_VEHICLE, payload }
}

export function VirtualPaginate(payload){
    return { type: VIRTUAL_PAGINATE, payload }
}

export function SelectGroupReport(payload){
    return { type: SELECT_GROUP, payload }
}

export function GetReportsStart(payload) {
    return { type: GET_START, payload }
}

export function GetReportsEnd(payload) {
    return { type: GET_END, payload }
}

export function GetReportStart(payload) {
    return { type: GET_REPORT_START, payload }
}

export function GetReportEnd(payload) {
    // console.log(payload);
    return { type: GET_REPORT_END, payload }
}
export function Error(msg) {
    return { type: ERROR, msg }
}

export function SetActive(payload) {
    return { type: SET_ACTIVE, payload }
}

export function ToogleStep() {
    return { type: TOOGLE_STEP }
}


export function ClearReports() {
    return { type: CLEAR_REPORTS }
}
export function SetShowListReportType(payload) {
    return { type: SET_SHOW_LIST_REPORT_TYPE, payload }
}

export function StartDownload(payload) {
    return { type: START_DOWLOAD, payload }
}

export function EndDownload(payload) {
    return { type: END_DOWLOAD, payload }
}

export function SetTime(payload) {
    return { type: SET_TIMES, payload }
}

export function SaveStart(payload) {
    return { type: SAVE_START, payload }
}

export function SaveEnd(payload) {
    return { type: SAVE_END, payload }
}

export function SetActiveChoice(payload) {
    return { type: SET_ACTIVE_CHOICE, payload }
}

export function SetStepData(payload) {
    return { type: SET_STEP_DATA, payload }
}

export function SetSteps(payload) {
    return { type: SET_STEPS, payload }
}

export function UpdateStatus(payload) {
    return { type: UPDATE_STATUS, payload }
}

