export const GET_START_IOS = '[IO] GET_START';
export const GET_END_IOS = '[IO] GET_END';

export const REMOVE_START_IO = '[IO] REMOVE_START';
export const REMOVE_END_IO = '[IO] REMOVE_END';

export const ERROR = '[ADMIN] ERROR';

export const CLEAR_DATA='[ADMIN] CLEAR_DATA';

export function GetStartIOS(payload) {
    return { type: GET_START_IOS, payload }
}

export function GetEndIOS(payload) {
    return { type: GET_END_IOS, payload }
}

export function RemoveStartIO(payload) {
    return { type: REMOVE_START_IO, payload }
}

export function RemoveEndIO(payload) {
    return { type: REMOVE_END_IO, payload }
}

export function ClearData(payload) {
    return { type: CLEAR_DATA, payload }
}

export function Error(msg) {
    return { type: ERROR, msg }
}