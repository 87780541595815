import React, {useState} from 'react';
import './step1.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useForm } from "react-hook-form";
import {Button} from "../../../../components/widgets/form/button/button";
import moment from 'moment-timezone';
import {useDispatch, useSelector} from "react-redux";
import {SetStepData, ToogleStep} from "../../../../store/accounts/accounts.actions";
import isEmail from "validator/lib/isEmail";
import isNumeric from "validator/lib/isNumeric";

import {AiOutlineInfoCircle, AiOutlineMail, AiOutlineUser} from "react-icons/ai";
import {FiPhone} from "react-icons/fi";
import {fetchAccountByUserName} from "../../accountService";
import {GiWireframeGlobe} from "react-icons/all";


export default function Step1() {
    // console.log(moment.tz.names())
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {token, account, timezone} = useSelector(state => {
        return {
            token: state.authReducer.token,
            timezone: state.authReducer.user.timezone,
            account: state.accountsReducer.addAccount.data
        }
    });
    const { register, handleSubmit, setError, errors, formState  } = useForm(
        { mode: "onChange",
            defaultValues: {
                id: account.id, name: account.name,
                username: account.username, email: account.email,
                phone: account.phone,
                timezone
            }
        },
    );

    const onSubmit = async(data) => {
        setLoading(true);
        let exist = false;

        if(!account.id) {
            exist = await fetchAccountByUserName(data.username, token);
        }

        if(exist && exist.error){
            setError(exist.for, {message: exist.msg, type:'isUnique'});
        } else {
            dispatch(SetStepData({step: 2, data}));
        }
        setLoading(false);
    };

    const previous = () => { dispatch(ToogleStep()); };


    const styleName = !errors.name ? 'input-form' : 'input-form input-form-error';
    const styleEmail = !errors.email ? 'input-form' : 'input-form input-form-error';
    const styleUsername = !errors.username ? 'input-form' : 'input-form input-form-error';
    const stylePhone = !errors.phone ? 'input-form' : 'input-form input-form-error';
    const validateUsername = !account.id ? {
        required: {value: true, message: 'Ce champs est obligatoire'},
        minLength: {value: 4, message: 'La taille minimum est: 4'},
        maxLength: {value: 20, message: 'La taille maximum est: 20'},
    } : {required: {value: true, message: 'Ce champs est obligatoire'}}

    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={styleUsername}>
                    <input
                        name="username" placeholder="Nom  d'utilisateur"
                        ref={register(validateUsername)}
                    />
                    <AiOutlineUser/>
                    {errors.username && <p className="error-input">{errors.username.message}</p>}
                </div>
                <div className={styleName}>
                    <input
                        name="id" hidden={true}
                        ref={register}
                    />
                    <input
                        name="name" placeholder="Nom complet de l'utilisateur"
                        ref={register(
                            {
                                required: {value: true, message: 'Ce champs est obligatoire'},
                                maxLength: {value: 20, message: 'La taille maximum est: 20'},
                            }
                        )}
                    />
                    <AiOutlineInfoCircle/>
                    {errors.name && <p className="error-input">{errors.name.message}</p>}
                </div>
                <div className={styleEmail}>
                    <input name="email" placeholder="Email"
                           ref={register(
                               {
                                   required: {
                                       value: true,
                                       message: 'Ce champs est obligatoire'
                                   },
                                   validate: {
                                       isEmail: (input) => isEmail(input) || "Ce champs doit être un email : example@provider.com"
                                   }
                               }
                           )}
                    />
                    <AiOutlineMail/>
                    {errors.email && <p className="error-input">{errors.email.message}</p>}
                </div>
                <div className={stylePhone}>
                    <input name="phone" placeholder="Numèros de téléphone"
                           ref={register(
                               { required: {value: true, message: 'Ce champs est obligatoire'},
                                   validate: {
                                       isNumber: (input) => isNumeric(input) || "Ce champs doit contenir que des nombres"
                                   }}
                           )}
                    />
                    <FiPhone/>
                    {errors.phone && <p className="error-input">{errors.phone.message}</p>}
                </div>

                <div className='input-form'>
                    <input name="timezone" placeholder="fuseau horaire"
                           ref={register(
                               { required: {value: true, message: 'Ce champs est obligatoire'},}
                           )}
                    />
                    <GiWireframeGlobe/>
                </div>

            </div>

            <div className="actions">
                <Button type='btn-accent' press={previous} name='Fermer'/>
                <Button type='btn-primary' name='Suivant' loading={loading}/>
            </div>
        </form>
    );
}
