import React, { Component }  from 'react';
import './black-list.css';
import {AiOutlineDelete} from "react-icons/ai";
import {BsBuilding, FaRegAddressCard, MdDescription} from "react-icons/all";

export class BlackListInfo extends Component {

    constructor(props) { super(props); }

    componentDidMount() { }

    render() {
        const item = this.props.item;
        const classStyle = item.active ? "card-user-info active-user" : "card-user-info deactive-user";

        return (
            <div className={classStyle}>
                {
                    this.props.company._id === item.companyId ?
                        <div className="more-options delete" onClick={() => this.props.press && this.props.press({action: 'del', name: item.identification ,id: item._id})}>
                        <AiOutlineDelete style={{fontSize: '1em'}}/>
                    </div> : <></>
                }


                <div className="main">
                    <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                        <img className="avatar-item" style={{height: '3em'}} src="/img/avatar.svg"/>
                        <h5 className="name">{this.props.item.name}</h5>
                        <div className="identification">
                            <FaRegAddressCard />
                            <label>{this.props.item.identification.toUpperCase()}</label>
                        </div>
                        <div className="description">
                            <div className="descWidget">
                                <MdDescription />
                                <label>{this.props.item.description}</label>
                            </div>
                            <div className="descWidget">
                                <BsBuilding />
                                <label>Ajouté par: {this.props.item.company}</label>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}