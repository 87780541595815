import React, {Component, PureComponent} from 'react';
import {AiOutlineLeft, AiOutlineRight} from "react-icons/ai";
import './paginator.css'

export class Paginator extends PureComponent {

    constructor(props) {
        super(props);
        this.state = { page: this.getPage()}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({ page: this.getPage()});
        }
    }

    getPage() {
        const pageIndex = Math.ceil(this.props.skip / this.props.limit);
        return pageIndex+1;
    }

    next() {
        const skip = this.props.skip + this.props.limit;
        if(this.props.paginate)
            this.props.paginate(skip, this.props.limit);
    }


    back() {
        const skip = this.props.skip - this.props.limit ;
        if(this.props.paginate)
            this.props.paginate(skip, this.props.limit);
    }

    render() {
        return (
            <>
                {
                    this.props.total !== 0 && <div className="paginator" style={this.props.noMargin ? {margin: 0} : {}}>

                        {
                            <span>Page {this.state.page}/{Math.ceil(this.props.total / this.props.limit)}</span>
                        }
                        <div>
                            {
                                this.state.page !== 1 && <button className="btn-paginate"
                                                                 onClick={() => { this.back() }}
                                >
                                    <AiOutlineLeft/>
                                </button>
                            }


                            {
                                this.state.page >= 1 && this.props.skip + this.props.limit < this.props.total &&
                                <button className="btn-paginate" onClick={() => { this.next() }}
                                >
                                    <AiOutlineRight />
                                </button>
                            }
                        </div>

                    </div>
                }
            </>

        );
    }
}
