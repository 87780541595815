import * as maintenanceActions from './maintenance.actions';


const INITIAL_STATE = {
    addMaintenance: {
        show: false,
        step: 0,
        data: {
            km: {active: false, actualMielage: 0},
            day: {active: false},
            notification: { email: { items: [], active: false } }
        },
        loading: false
    },
    maintenances: [],
    loading: false,
    maintenanceError: null
}


export function maintenanceReducer(state = INITIAL_STATE, action){

    switch (action.type) {

        case maintenanceActions.SET_STEP_DATA:
            return {
                ...state,
                addMaintenance: {
                    ...state.addMaintenance,
                    step: action.payload.step,
                    data: {
                        ...state.addMaintenance.data,
                        ...action.payload.data,
                    }
                }
            }

        case maintenanceActions.SET_UPDATE:
            const {_id, name, description, emails, km, day} = action.payload;
            const data = {
                _id,
                name, description,
                km: {active: false, actualMielage: state.km},
                day: {active: false},
                notification: { email: { items: [], active: false } }
            };
            if(km) { data.km = {...km, active: true, actualMielage: state.km}; }
            if(day) { data.day = { active: true, intervalDay: day.intervalDay, lastServiceDay: day.lastServiceDay}}
            if(emails) {
                data.notification = {
                    email: { items: emails.map(email => {
                        if(email) return {email}
                    }), active: true }
                }
            }

             return {
                ...state,
                addMaintenance: {
                    ...state.addMaintenance,
                    step: 0,
                    show: true,
                    data: data
                }
            }

        case maintenanceActions.TOOGLE_ACTIVE:
            const show = !state.addMaintenance.show;
            return {
                ...state,
                addMaintenance: {
                    ...state.addMaintenance,
                    show,
                    data: !show ? INITIAL_STATE.addMaintenance.data : state.addMaintenance.data
                }
            }

        case maintenanceActions.SAVE_START:
            return {
                ...state,
                addMaintenance: {
                    ...state.addMaintenance,
                    loading: true
                }
            }

        case maintenanceActions.SAVE_END:
            return {
                ...state,
                addMaintenance: INITIAL_STATE.addMaintenance
            }

        case maintenanceActions.GET_START:
        case maintenanceActions.DELETE_START:
            return {
                ...state,
                loading: true
            }

        case maintenanceActions.GET_END:
            if(action.payload.km) {
                return {
                    ...state,
                    loading: false,
                    maintenances: action.payload.maintenance && action.payload.maintenance.length > 0 ? action.payload.maintenance : [],
                    km: action.payload.km,
                    addMaintenance: {
                        ...state.addMaintenance,
                        data: {
                            ...state.addMaintenance.data,
                            km: {
                                actualMielage: action.payload.km
                            },
                        }

                    }
                }
            }
            return  INITIAL_STATE;

        case maintenanceActions.DELETE_END:
            const {response, id} = action.payload;
            if(response.modifiedCount) {
                const findv = state.maintenances.findIndex(el => el._id === id);
                if(findv !== -1) {
                    state.maintenances.splice(findv, 1);
                    return {
                        ...state,
                        loading: false,
                        maintenances: [...state.maintenances],
                    }
                }
            }
            return { ...state, loading: false }

        case maintenanceActions.CLEAR_DATA:
            return INITIAL_STATE


        default:
            return state;
    }
}
