import React, { Component }  from 'react';
import './tracer-info.css';
import {AiOutlineMail, AiOutlineUser} from "react-icons/ai";
import {FiPhone, FiMoreVertical} from "react-icons/fi";
import { Popover } from 'antd';
import {ActionPopover} from "../widgets/action-popover/action-popover";
import {AiOutlineEdit} from "react-icons/ai";
import {AiOutlineDelete} from "react-icons/ai";
import {AiOutlineCar, FcElectronics, GoPrimitiveDot, VscFileBinary} from "react-icons/all";

export class TracerInfo extends Component {

    constructor(props) { super(props); }

    componentDidMount() { }

    status() {
        if(this.props.tracer && this.props.tracer.online) {
            return <GoPrimitiveDot color="rgb(59, 206, 133)"/>
        }
        return <GoPrimitiveDot color="red"/>
    }

    render() {
        const tracer = this.props.tracer;
        const classStyle = tracer.vehicle ? "card-user-info active-user" : "card-user-info deactive-tracer";
        const text = <span>Title</span>;
        const content = (
            <>
                <ActionPopover icon={AiOutlineEdit} label="Modifier" press={this.props.press} value={{action: 'edit', tracer: this.props.tracer}}/>
                {
                    !tracer.vehicle && <ActionPopover icon={AiOutlineDelete} press={this.props.press} label="Supprimer" value={{action: 'del', name: this.props.tracer.name, id: this.props.tracer._id}}/>
                }
            </>
        );
        return (
            <div className={classStyle}>
                <div className="more-options">
                    <Popover placement="rightTop" content={content} text={text} trigger="hover">
                        <FiMoreVertical />
                    </Popover>
                </div>

                    <div className="main">
                        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                                <FcElectronics className="avatar-tracer"/>
                                <h5 className="name">{this.props.tracer.name}

                                    { <span style={{verticalAlign: 'middle'}}> {this.status()}</span> }
                                </h5>
                                <div className="description">
                                    <div className="descWidget">
                                        <VscFileBinary />
                                        <label>{tracer && tracer.uid ? tracer.uid : '-'}</label>
                                    </div>
                                    <div className="descWidget">
                                        <FiPhone />
                                        <label>{tracer && tracer.sim ? tracer.sim : '-'}</label>
                                    </div>
                                    <div className="descWidget">
                                        <AiOutlineCar />
                                        <label>{tracer && tracer.vehicle ? tracer.vehicle : '-'}</label>
                                    </div>
                            </div>
                        </div>
                </div>
            </div>
        );
    }
}