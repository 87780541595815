export const GET_START = '[TRACERS] GET_START';
export const GET_END = '[TRACERS] GET_END';
export const SET_ACTIVE = '[TRACERS] SET_ACTIVE';
export const ERROR = '[TRACERS] ERROR';

export const GET_TRACER_START = '[TRACER] GET_START';
export const GET_TRACER_END = '[TRACER] GET_END';

export const UPDATE_TRACER_START = '[TRACER] UPDATE_TRACER_START';
export const CREATE_TRACER_START = '[TRACER] CREATE_TRACER_START';
export const CREATE_TRACER_END = '[TRACER] CREATE_TRACER_END';

export const DELETE_START = '[TRACER] DELETE_START';
export const DELETE_END = '[TRACER] DELETE_END';

export const TOOGLE_MANAGE_TRACER = '[TRACER] TOOGLE_MANAGE_TRACER';

export const SET_ADD_DATA = '[TRACER] SET_ADD_DATA';



export function GetTracersStart(payload) { return { type: GET_START, payload }; }
export function GetTracersEnd(payload) { return { type: GET_END, payload }; }

export function SetActive(payload) { return { type: SET_ACTIVE, payload }; }
export function ToogleManageTracer() { return { type: TOOGLE_MANAGE_TRACER }; }

export function GetTracerStart(payload) { return { type: GET_TRACER_START, payload }; }
export function GetTracerEnd(payload) { return { type: GET_TRACER_END, payload }; }

export function DeleteStart(payload) { return { type: DELETE_START, payload }; }
export function DeleteEnd(payload) { return { type: DELETE_END, payload }; }

export function SetAddData(payload) { return { type: SET_ADD_DATA, payload }; }

export function UpdateTracerStart(payload) { return { type: UPDATE_TRACER_START, payload }; }
export function CreateTracerStart(payload) { return { type: CREATE_TRACER_START, payload }; }
export function CreateTracerEnd(payload) { return { type: CREATE_TRACER_END, payload }; }



export function Error(payload) { return { type: ERROR, payload }; }