import React, {Component} from "react";
import {connect} from "react-redux";
import './init-report.css';
import {ChoiceItem} from "../../../../components/choice-item/choice-item";
import {AiFillFileAdd, AiOutlineCar, BiBox, BsBuilding, MdAddAlert} from "react-icons/all";
import {Button} from "../../../../components/widgets/form/button/button";
import PerfectScrollbar from "react-perfect-scrollbar";
import {SetActive, SetActiveChoice, SetSteps, ToogleStep} from "../../../../store/reports/report.actions";

class InitReport extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 110}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);

    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 110}px` });
    }

    selectChoice(choice) {this.props.dispatch(SetActiveChoice(choice));}
    toogleStep(){ this.props.dispatch(SetActive(false)); }
    next(){ this.props.dispatch(SetSteps()); }

    render() {
        return (
            <PerfectScrollbar style={{ height: this.state.height}}>
                <div id="choice-item-alarm">
                    <div  className="header background-color-primary">
                        <div className="main-title">
                            <div className="title">
                                <AiFillFileAdd/>
                                <label >Création d'un rapport</label>
                            </div>
                        </div>
                    </div>
                    <div className="items-alarms">
                        <div className="items-alarms-choices">
                            <ChoiceItem
                                icon={BsBuilding}
                                description="Enterprise"
                                active={this.props.active === 0}
                                press={() => this.selectChoice(0)}
                            />
                            <ChoiceItem icon={BiBox} description="Groupes" active={this.props.active === 1}
                                press={() => this.selectChoice(1)}
                            />
                            <ChoiceItem icon={AiOutlineCar} description="Véhicule" active={this.props.active === 2}
                                press={() => this.selectChoice(2)}
                            />
                        </div>
                        <div className="description">
                            <img src="/img/alarms/choice.svg"/>
                        </div>
                    </div>
                    <div className="actions">
                        <Button type='btn-accent' name='Annuler' press={()=>this.toogleStep()}/>
                        <Button type='btn-primary' name='Suivant' disabled={this.props.active === -1}
                                press={()=>this.next()}/>
                    </div>

                </div>
            </PerfectScrollbar>
        );
    }
}

const VehiclesStateToProps = (state) => {
    return {
        active: state.reportsReducer.addReport.active
    }
}
export default connect(VehiclesStateToProps)(InitReport);
