import React, {Component, createRef} from 'react';
import {connect} from "react-redux";

import './history-details-info.css';
import 'swiper/swiper.scss';
import {OnTraject} from "../../../../../components/history/on-traject/on-traject";
import {OffTraject} from "../../../../../components/history/off-traject/off-traject";
import {SET_ACTIVE_END, SetActiveEnd, SetActiveStart} from "../../../../../store/history/history.actions";
import {BlankComponent} from "../../../../../components/blank/blank";
import PerfectScrollbar from "react-perfect-scrollbar";


class HistoryDetailsInfo extends Component {

    constructor(props) {
        super(props);
        this.scrollBar = createRef();
    }

    onPressTraject(index, id, on) {
        if(on) {
            this.props.dispatch(SetActiveStart({index, data: id}));
        } else {
           this.props.dispatch(SetActiveEnd({index, traject: id, on}));
        }

    }

    render() {
        return (
            <PerfectScrollbar ref={this.scrollBar} className="items-details-vehicle" style={{ height: this.props.height, zIndex: 992}}>
                <div className="history-timeline">
                    {
                        this.props.trips.length > 0 ?
                            this.props.trips.map((el, i) => {
                                const isLast = i === this.props.trips.length - 1;
                                switch (true) {
                                    case el.on:
                                        return <OnTraject key={i}  data={el} active={this.props.active} index={i} onPress={this.onPressTraject.bind(this)}/>
                                    case el.on === false:
                                        return <OffTraject key={i}  data={el} active={this.props.active} index={i} onPress={this.onPressTraject.bind(this)}/>

                                }
                        }) : !this.props.loading && <BlankComponent/>

                    }
                </div>
            </PerfectScrollbar>
        );
    }
}

const VehicleHistoryStateToProps = (state) => {
    return {
        loading: state.historyReducer.loading,
        error: state.historyReducer.error,
        trips: state.historyReducer.trips,
        active: state.historyReducer.traject.active
    }
}
export default connect(VehicleHistoryStateToProps)(HistoryDetailsInfo);
