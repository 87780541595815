const axios = require('axios');

export function getAdress(payload) {

    const url = `http://nominatim.openstreetmap.org/reverse.php?format=json&lat=${payload.lat}&lon=${payload.lng}&zoom=17`;
    return axios.get(url);
}

export async function getAddress(longitude, latitude) {

    try{
        const url = `http://192.168.0.104/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}&zoom=18&accept-language=fr`;
        const config = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }
        const response = await axios.get(url, config);
        if(response.status === 200 && response.data) {
            const {display_name} = response.data;

            return display_name;
        }
    }catch(e) {}


    return '...';
}
