import React, { Component }  from 'react';
import './section.css';

export class SectionComponent extends Component {

    constructor(props) {super(props); }

    render() {
        return(
            < div className="section-data">
                <img src={this.props.img}/>
                <label>{this.props.description}</label>
            </div>

        );
    }
}
