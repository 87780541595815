import * as accountActions from './accounts.actions';
import {LIMIT} from "../../env";


const INITIAL_STATE = {
    addAccount: {
        steps:[
            {current: 'Information de base', next: 'Information sensible'},
            {current: 'Information sensible', next: 'Fin'},
        ],
        step: 0,
        data: {type: 'user', active: true},
        loading: false
    },
    stats: {active: 0, deactive: 0, withOutGroup: 0, total: 0},
    accounts: [],
    filters: {
        data: [
            {filter: 'all', name: 'Tous'},
            {filter: 'active', name: 'Activé'},
            {filter: 'deactive', name: 'Désactivé'}],
        params: {skip: 0, limit: LIMIT, filter: 'all'}
    },
    loading: false,
    accountError: null
}


export function accountsReducer(state = INITIAL_STATE, action){
    switch (action.type) {
        case accountActions.GET_START:
            const params = {...state.filters.params, ...action.payload};
            return {
                ...state, loading: true,
                filters: {
                    ...state.filters,
                    params
                }
            }

        case accountActions.GET_END:
            return Object.assign({}, state, {
                accounts: action.payload.list,
                stats: action.payload.stats,
                total: action.payload.total,
                loading: false
            });


        case accountActions.SAVE_START:
            return {
                ...state,
                addAccount: {
                    ...state.addAccount,
                    loading: true
                }
            };

        case accountActions.SAVE_END:
            const account = action.payload;
            if(account.hasOwnProperty('group')) {
                account.groups = account.group ? [account.group] : false;
            }
            let {active, deactive, total} = state.stats;
            const findAccount = state.accounts.findIndex(el => el._id === account.id);
            if(findAccount === -1) {
                state.accounts.unshift(account);
                account.choices.indexOf('activateAccount') !== -1 ? active++ : deactive++;
                total++;
            } else {
                state.accounts[findAccount] = {...state.accounts[findAccount], ...action.payload};
            }
            return {
                ...state,
                addAccount: INITIAL_STATE.addAccount,
                accounts: [...state.accounts],
                stats: {
                    ...state.stats,
                    total,
                    active,
                    deactive
                }
            };

        case accountActions.TOOGLE_STEP:
            if(state.addAccount.step) {
                return {
                    ...state,
                    addAccount: {
                        ...state.addAccount,
                        step: false,
                        data: INITIAL_STATE.addAccount.data,
                        steps: INITIAL_STATE.addAccount.steps
                    }
                }
            }
            return {
                ...state,
                addAccount: {
                    ...state.addAccount,
                    step: 1
                }
            }

        case accountActions.SET_STEP_DATA:
            return {
                ...state,
                addAccount: {
                    ...state.addAccount,
                    step: action.payload.step,
                    data: {
                        ...state.addAccount.data,
                       ...action.payload.data
                    }
                }
            }

        case accountActions.SET_DATE:
            return {
                ...state,
                addAccount: {
                    ...state.addAccount,
                    data: {
                        ...state.addAccount.data,
                        subscription: action.payload
                    }
                }
            }

        case accountActions.SET_STEP:
            return {
                ...state,
                addAccount: {
                    ...state.addAccount,
                    step: action.payload.step,
                    steps: INITIAL_STATE.addAccount.steps
                }
            }

        case accountActions.TOOGLE_ACTIVE:
            return {
                ...state,
                addAccount: {
                    ...state.addAccount,
                    data: {
                        ...state.addAccount.data,
                        active: action.payload
                    }
                }
            }

        case accountActions.DELETE_START:
            return {
                ...state,
                loading: true
            }

        case accountActions.DELETE_END:
            const {response, id} = action.payload;
            if(response.deletedCount) {
                const findv = state.accounts.findIndex(el => el.username === id);
                if(findv !== -1) {
                    let {total} = state.stats;
                    total--;
                    state.accounts.splice(findv, 1);
                    return {
                        ...state,
                        loading: false,
                        companies: state.companies,
                        stats: {
                            ...state.stats,
                            total
                        }
                    }
                }
            }
            return { ...state, loading: false }

        case accountActions.SET_STEP_UPDATE:
            return {
                ...state,
                addAccount: {
                    ...state.addAccount,
                    steps:[{current: 'Modifier mot de passe', next: 'Fin'}],
                    step: action.payload.step,
                    data: action.payload.data,
                    loading: false
                },
            };

        case accountActions.SAVE_PASSWORD_START:
            return {
                ...state,
                addAccount: {
                    ...state.addAccount,
                    loading: false
                },
            };

        case accountActions.SAVE_PASSWORD_END:
            return {
                ...state,
                addAccount: INITIAL_STATE.addAccount,
            };


        case accountActions.ERROR:
            return Object.assign({}, state, {
                loading: false
            })

        default:
            return state;
    }
}
