export const GET_START = '[COMPANIES] GET_START';
export const GET_END = '[COMPANIES] GET_END';
export const ERROR = '[COMPANIES] ERROR';

export const TOOGLE_STEP = '[COMPANIES] TOOGLE_STEP';
export const TOOGLE_ACTIVE = '[COMPANIES] TOOGLE_ACTIVE';
export const TOOGLE_LOCATION = '[COMPANIES] TOOGLE_LOCATION';


export const SET_STEP_DATA = '[COMPANIES] SET_STEP_DATA';
export const SET_STEP = '[COMPANIES] SET_STEP';
export const SET_DATE = '[COMPANIES] SET_DATE';
export const SET_SUBSCRIPTION = '[COMPANIES] SET_SUBSCRIPTION';



export const SAVE_START = '[COMPANIES] SAVE_START';
export const SAVE_END = '[COMPANIES] SAVE_END';

export const DELETE_START = '[COMPANIES] DELETE_START';
export const DELETE_END = '[COMPANIES] DELETE_END';

export const SWITCH_START = '[COMPANIES] SWITCH_START';
export const SWITCH_END = '[COMPANIES] SWITCH_END';

export function SwitchCompanyStart(payload) {
    return { type: SWITCH_START, payload }
}

export function SetSubscription(payload) {
    return { type: SET_SUBSCRIPTION, payload }
}

export function SwitchCompanyEnd(payload) {
    return { type: SWITCH_END, payload }
}

export function SaveCompanyStart(payload) {
    return { type: SAVE_START, payload }
}

export function SaveCompanyEnd(payload) {
    return { type: SAVE_END, payload }
}

export function GetCompaniesStart(payload) {
    return { type: GET_START, payload }
}

export function ToogleStep() {
    return { type: TOOGLE_STEP }
}

export function ToogleActive(payload) {
    return { type: TOOGLE_ACTIVE, payload }
}
export function ToogleLocation(payload) {
    return { type: TOOGLE_LOCATION, payload }
}



export function SetStepData(payload) {
    return { type: SET_STEP_DATA, payload }
}

export function SetStep(payload) {
    return { type: SET_STEP, payload }
}

export function SetDate(payload) {
    return { type: SET_DATE, payload }
}

export function getCompaniesEnd(payload) {
    return { type: GET_END, payload }
}

export function deleteCompanyStart(payload) {
    return { type: DELETE_START, payload }
}

export function deleteCompanyEnd(payload) {
    return { type: DELETE_END, payload }
}



export function Error(msg) {
    return { type: ERROR, msg }
}