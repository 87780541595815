import React, {PureComponent} from 'react';
import './layer.css';


export class Layer extends PureComponent {

    constructor(props) { super(props); }

    componentDidMount() { }

    press() {
        if(this.props.press && this.props.action) {
            this.props.press(this.props.action);
        }
    }


    render() {
        const active = !this.props.active ? "layer-item" : "layer-item layer-item-active";
        return (
            <div className={active}
                 onClick={() => { this.press() }}
            >
                <img className="layer-img" src={this.props.img}/>
                <label className="layer-name">{this.props.name}</label>
            </div>
        );
    }
}