import * as eventsActions from "./events.actions";
import {LIMIT} from "../../../env";

const INITIAL_STATE = {
    events: {
        list: [],
        total:0,
    },
    active: false,
    new: false,
    filters: {
        params: {skip: 0, limit: LIMIT}
    },
    loading: false
}

export function eventsMapReducer(state = INITIAL_STATE, action){
    switch (action.type) {
        case eventsActions.GET_START:
            const params = {...state.filters.params, ...action.payload};
            return Object.assign({}, state, {
                loading: true,
                filters: { ...state.filters, params }
            })
        case eventsActions.GET_END:
            return Object.assign({}, state, {
                events: {list: action.payload.list, total: action.payload.total},
                loading: false,
                new: false
            })
        case  eventsActions.PUSH_EVENTS:
            const list = [...action.payload.data, ...state.events.list];
            const size = list.length;
            const limit = LIMIT;
            if (size >= limit) {
                list.splice(limit, size - limit);
            }
            return {
                ...state,
                loading: false,
                events: {
                    ...state.events,
                    list,
                    total: state.events.total + action.payload.data.length
                },
                new: true
            };
        case eventsActions.ERROR:
            return Object.assign({}, state, {
                loading: false
            })


        case eventsActions.SET_ACTIVE:
            return { ...state, active: action.payload };

        default:
            return state;
    }
}
