import {
    AiOutlineQuestionCircle,
    FaCarCrash, FaMapMarkerAlt, GiNinjaMask,
    IoMdFlashOff,
    IoMdSpeedometer, MdPanTool,
    MdSignalCellular0Bar,
    MdSignalCellular1Bar,
    MdSignalCellular2Bar,
    MdSignalCellular3Bar,
    MdSignalCellular4Bar
} from "react-icons/all";
import React from "react";
import {MdTrackChanges} from "react-icons/md";
import {toTimezone} from "./date";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export function convertMS( milliseconds ) {
    let day, hour, minute, seconds;
    seconds = Math.floor(milliseconds / 1000);
    minute = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hour = Math.floor(minute / 60);
    minute = minute % 60;
    day = Math.floor(hour / 24);
    hour = hour % 24;
    return {
        day: day,
        hour: hour,
        minute: minute,
        seconds: seconds
    };
}
export function formatTimeStatus(start, end) {
    const e = new Date(end).getTime();
    const s = new Date(start).getTime();
    return convertMS(e-s);

}
export function parseDataReport(column, value, record, timezone) {
    switch (column) {
        case 'duration': {
            let date;
            if(value) {date = convertMS(value);}
            else if(record.start && record.end) {
                date = formatTimeStatus(record.start, record.end);
            } else { return '-'; }
            const { day, hour, minute, seconds} = date;
            if(day === 0) return `${hour}h:${minute}min:${seconds}s`;
            return `${day}j, ${hour}h:${minute}min:${seconds}s`;
        }
        case 'km':
            return km && value ? `${value.toFixed(2)} km` : '-'
        case 'maxSpeed':
        case 'minSpeed':
        case 'speed':
            return `${value} km/h`
        case 'fuel':
        case 'newF':
        case 'oldF':
        case 'fill':
            return value && `${value.toFixed(2)} Litres`;
        case 'start':
        case 'end':
        case 'date':
            return toTimezone(value, timezone)
        case 'zone': {
            if(value && value.length > 0) return value[0]
            return '-';
        }
        case 'position':
            return value.lng && value.lat ? `[${value.lng}, ${value.lat}]` : '-';
        case 'posStart':
        case 'posEnd':
            return value ? value.toString() : '-';
        case 'type': {
            switch (value) {
                case 'speed':
                    return 'Excès de vitesse';
                case 'notAuthorized':
                    return 'Démarrage non authorisé'
                case 'inZone':
                    return 'Entrée de zone';
                case 'outZone':
                    return 'Sortie de zone';
                case 'noElectricity':
                    return 'Traceur débranché';
            }
        }
        default:
            return value ? value : '-';

    }
}

export function parseTitleReport(column) {
    switch (column) {
        case 'duration':
            return 'Durée';
        case 'name':
            return `Nom`;
        case 'speed':
            return 'Vitesse';
        case 'maxSpeed':
            return 'Vitesse maximal';
        case 'minSpeed':
            return 'Vitesse minimal';
        case 'fuel':
            return `Consomation en litre`;
        case 'start':
            return `Début`;
        case 'end':
            return `Fin`;
        case 'date':
            return `Date`;
        case 'price':
            return `Prix`;
        case 'inZone':
            return 'Entrée de zone';
        case 'outZone':
            return 'Sortie de zone';
        case 'posStart':
            return 'position début';
        case 'posEnd':
            return 'position fin';
        case 'fill':
            return 'Remplie';
        case 'oldF':
            return 'Carburant initiale';
        case 'newF':
            return 'Carburant final';
        default:
            return column;

    }
}


export function exportToCSV(headers, csvData, fileName, timezone) {
    const header = headers.map(el => el.title);
    const jsonData = csvData.map(el => {
        const item = {};
        for (const [key, value] of Object.entries(el)) {
            const newKey = parseTitleReport(key);
            item[newKey]=parseDataReport(key, value, el, timezone)
        }
        return item;
    })
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(jsonData, { header });
    ws["!cols"] =headers.map(el => {return {wpx: 150, font: {sz: 10}}});

    const wb = { Sheets: { 'rapport': ws }, SheetNames: ['rapport'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}


export function km(km, unit) {
    if(unit === 'km') { return km; }
    return (km/1000).toFixed(2);
}

export function speed(speed) { return speed.toFixed(2); }

export function getSignal(signal) {
    switch (signal) {
        case 20:
            return <MdSignalCellular1Bar />
        case 40:
            return <MdSignalCellular2Bar  />
        case 60:
            return <MdSignalCellular3Bar />
        case 80:
        case 100:
            return <MdSignalCellular4Bar />
        default:
            return <MdSignalCellular0Bar />
    }
}

export function getSignalName(signal) {
    switch (signal) {
        case 20:
            return 'Faible'
        case 40:
            return 'Moyen'
        case 60:
            return 'Bien'
        case 80:
        case 100:
            return 'Excellent'
        default:
            return 'Très faible'
    }
}

export function getData(event) {
    if( event){
        const {vehicle, zone, type, date, max, speed, position, poi } = event;
        const data = {vehicle, date: new Date(date), position};
        if(zone) { data.zone = `(${zone})`; }
        switch (type) {
            case 'speed':
                return {...{ name: 'Excès de vitesse', ICON: IoMdSpeedometer, max, speed }, ...data};
            case 'inZone':
                return !poi ? {...{ name: 'Détéction entrée de zone',  ICON: MdTrackChanges }, ...data}:  {...{ name: 'Détéction entrée au POI',  ICON: FaMapMarkerAlt }, ...data};
            case 'outZone':
                return !poi ? {...{ name: 'Détéction sortie de zone',  ICON: MdTrackChanges }, ...data}:  {...{ name: 'Détéction sortie du POI',  ICON: FaMapMarkerAlt }, ...data};
            case 'notAuthorized':
                return {...{  name: 'Démarage non autorisé', ICON: MdPanTool }, ...data};
            case 'noElectricity':
                return {...{ name:  'Traceur débranché', ICON: IoMdFlashOff }, ...data};
            case 'crash':
                return {...{ name:  'Accident de voiture', ICON: FaCarCrash }, ...data};
            case 'towing':
                return {...{ name:  'Détéction dépannage', ICON: AiOutlineQuestionCircle }, ...data};
            case 'jamming':
                return {...{ name:  'Détection de brouillage réseau', ICON: GiNinjaMask }, ...data};
            default:
                return {...{ name:  'Non Connue', ICON: AiOutlineQuestionCircle }, ...data};
        }
    }
}
