import {BASE_URL} from "../../env";
const axios = require('axios');

export function fetchCommands(payload, token) {
    let params = '';
    for (let param in payload) {
        params+=`${param}=${payload[param]}&`
    }
    const url = `${BASE_URL}/api/command/all?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export async function deleteCommand(payload, token) {
    const url = `${BASE_URL}/api/command/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.delete(url, config);
}


export function addCommand(payload, token) {
    const url = `${BASE_URL}/api/command`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.post(url, payload, config);
}


export function UpdateCommand(payload, token) {
    const data = Object.assign({}, payload);
    const url = `${BASE_URL}/api/command/${data.id}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    delete data.id;
    return axios.patch(url, data, config);
}




