import {BASE_URL} from "../../env";

const axios = require('axios');

export function fetchVehicles(payload, token) {
    let params = `skip=${payload.skip}&limit=${payload.limit}`;
    if (payload.name) {
        params+=`&filter[item][name]=${payload.name}`
    }
    const url = `${BASE_URL}/api/vehicle/map?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export function sendCommand(payload, token) {
    const {commandID, vehicleID} = payload;
    const url = `${BASE_URL}/api/command/${commandID}/send/${vehicleID}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.post(url, {}, config);
}

