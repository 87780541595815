import * as actions from "./group.actions";
import {catchError, map, switchMap} from 'rxjs/operators'
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {AutoLogout} from "../auth/auth.actions";
import {Error} from "../comapny/company.actions";
import {AddGroup, deleteGroup, fetchGroups, UpdateGroup} from "../../pages/groups/groupService";
import {addVehicle, updateVehicle} from "../../pages/vehicles/vehicleService";
import {NotificationManager} from "react-notifications";
import {deleteCompany} from "../../pages/companies/companyService";


export const FetchGroupsEpic = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        const params = {...state$.value.groupReducer.filters.params, ...action.payload};
        return from(fetchGroups(params, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.getGroupsEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const SaveGroupEpic = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_START),
    switchMap((action) => {
        const group = {...state$.value.groupReducer.addGroup.data, ...action.payload};
        if(group.id) {
            return from(UpdateGroup(group, state$.value.authReducer.token)).pipe(
                map(res => {
                    NotificationManager.success('le groupe est modifiée avec succès', 'Modification' , 2000);
                    return actions.SaveGroupEnd(group);
                }),
                catchError(err => {
                    let msg = 'une erreur s\'est produite !';
                    if (err.response.status === 401) {
                        return of(AutoLogout(), Error(''));
                    }
                    return of(Error(msg))
                })
            )
        }
        delete group.id;
        return from(AddGroup(group, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('Le groupe est créé avec succès', 'Création', 2000);
                const newGroup = {
                    ...{
                        _id: res.data.insertedId,
                        id: res.data.insertedId,
                    },
                    ...group

                }
                return actions.SaveGroupEnd(newGroup);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const DeleteGroupEpic = (action$, state$) => action$.pipe(
    ofType(actions.DELETE_START),
    switchMap((action) => {
        return from(deleteGroup(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('le groupe est supprimée avec succès', 'Suppression', 2000);
                return actions.deleteGroupEnd({response: res.data, id: action.payload});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, 'Suppression', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Suppression', 2000);
        return of(Error('Problem unknow'))
    })
);
