import React, {useEffect, useState} from 'react';
import "react-perfect-scrollbar/dist/css/styles.css";
import {ReactApexChart} from "../components/apex-chart";

export const FuelGraph = ({data}) => {
    const [series, setSeries] = useState([]);
    const [options] = useState({
        series: [{ data: [] }],
        chart: {
            id: 'fuel',
            group: 'social',
            type: 'line',
            height: 160,
            animations: {
                enabled: false,},
            dataLabels: {
                enabled: false
            }
        },
        tooltip: {
            x: { format: "HH:mm:ss" },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left'
        },
        xaxis: {  type: 'datetime' },
        yaxis: {
            title: {
                text: 'Fuel',
            },
        },
        stroke: {
            width: 2,
            curve: 'smooth'
        },
    });

    useEffect(()=> {
        setSeries([{
            data: data.map(info => [new Date(info.gpsDate), info.fuel !== undefined ?  info.fuel.toFixed(1) : null])
        }]);
    }, [data]);

    return <ReactApexChart id="fuel" series={series} options={options}/>;

}

