import {BASE_URL} from "../../env";
const axios = require('axios');

export function AddRole(payload, token) {
    const url = `${BASE_URL}/api/role`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }

    return axios.post(url, {name: payload.name, canDelete: payload.canDelete}, config);
}

export function updateRole(payload, token) {
    const {_id, authorizations} = payload
    const url = `${BASE_URL}/api/role`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.patch(url, {_id, authorizations}, config);
}



export function fetchRoles(payload, token) {
    const url = `${BASE_URL}/api/role`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export function fetchRolesForUser(token) {
    const url = `${BASE_URL}/api/role/users`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}


export function deleteRole(payload, token) {
    const url = `${BASE_URL}/api/role/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.delete(url, config);
}
