import React, {useEffect, useState} from 'react';
import "react-perfect-scrollbar/dist/css/styles.css";
import {ReactApexChart} from "../components/apex-chart";
import moment from "moment-timezone";

export const TimeGraph = ({data}) => {
    const [series, setSeries] = useState([]);
    const [options] = useState({
        series: [{ data: [] }],
        chart: {
            id: 'time',
            group: 'social',
            type: 'line',
            height: 160,
            animations: {
                enabled: false,},
            dataLabels: {
                enabled: false
            }
        },
        tooltip: {
            x: { format: "HH:mm:ss" },
            y: {
                formatter: (value) => { return value ? moment(value).format('HH:mm:ss') : '-'},
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left'
        },
        yaxis: {
            show: false,
            type: 'datetime',
            labels: {
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                }
            },
            title: {
                text: 'Time series',
            },
        },
        xaxis: {
            type: 'datetime',
            title: {
                text: 'Time series',
            },
        },
        stroke: {
            width: 2,
            curve: 'smooth'
        },
    });

    useEffect(()=> {
        setSeries([
            {
                name: 'gpsDate',
                data: data.map(info => {
                    return [new Date(info.gpsDate).getTime(), new Date(info.gpsDate).getTime()]
                })
            },
            {
                name: 'gpsServer',
                data: data.map(info => {
                    return [new Date(info.gpsDate).getTime(), new Date(info.serverDate).getTime()]
                })
            },
        ]);
    }, [data]);

    return <ReactApexChart id="time" series={series} options={options}/>;

}

