import * as actions from "./blacklist.actions";
import {catchError, map, switchMap} from 'rxjs/operators'
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {AutoLogout} from "../auth/auth.actions";
import {Error} from "../comapny/company.actions";
import {AddGroup, deleteGroup, UpdateGroup} from "../../pages/groups/groupService";
import {NotificationManager} from "react-notifications";
import {addBlackList, deleteBlacklist, fetchchBlackList} from "../../pages/blacklist/blackListService";



export const FetchBlackListEpic = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        const params = {...state$.value.blacklistReducer.filters.params, ...action.payload};
        return from(fetchchBlackList(params, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.getBlackListsEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const SaveBlackListEpic = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_START),
    switchMap((action) => {
        const account = {...state$.value.blacklistReducer.addblacklist.data, ...action.payload};
        return from(addBlackList(account, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('Le compte est créé avec succès', 'Création', 2000);
                const newAccount = {
                    ...{
                        _id: res.data.insertedId,
                        id: res.data.insertedId,
                    },
                    ...account
                }
                return actions.SaveBlackListEnd(newAccount);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const DeleteBlackListEpic = (action$, state$) => action$.pipe(
    ofType(actions.DELETE_START),
    switchMap((action) => {
        return from(deleteBlacklist(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('le compte est supprimée avec succès', 'Suppression', 2000);
                return actions.deleteBlackListEnd({response: res.data, id: action.payload});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, 'Suppression', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Suppression', 2000);
        return of(Error('Problem unknow'))
    })
);
