import React, { Component }  from 'react';
import './step2.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import 'react-day-picker/lib/style.css';
import {Button} from "../../../../components/widgets/form/button/button";
import DayPicker from "react-day-picker";
import {useDispatch} from "react-redux";
import {SetDate, SetStep} from "../../../../store/comapny/company.actions";


export default function Step2(props) {
    const dispatch = useDispatch();
    const MONTHS = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septtembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ];
    const WEEKDAYS_LONG = [
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
    ];
    const WEEKDAYS_SHORT = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];

    const handleDayClick = (day, { selected }) => {
        dispatch(SetDate(day.getTime())); }
    const previous = () => { dispatch(SetStep({step: 2})); };
    const next = () => { dispatch(SetStep({step: 4})); }

    return (
        <>
            <DayPicker
                locale="fr"
                months={MONTHS}
                weekdaysLong={WEEKDAYS_LONG}
                weekdaysShort={WEEKDAYS_SHORT}
                initialMonth={new Date(props.data.subscription)}
                selectedDays={new Date(props.data.subscription)}
                disabledDays={{ before: new Date() }}
                onDayClick={handleDayClick}
            />

            <div className="actions">
                <Button type='btn-accent' press={previous} name='Précédent'/>
                <Button type='btn-primary' press={next} name='Suivant'/>
            </div>
        </>

    );
}
