import React, {useEffect, useState} from 'react';
import "react-perfect-scrollbar/dist/css/styles.css";
import {ReactApexChart} from "../components/apex-chart";

export const IngintionGraph = ({data}) => {
    const [series, setSeries] = useState([]);
    const [options] = useState({
        series: [{ data: [] }],

        chart: {
            id: 'ingintion',
            group: 'social',
            type: 'line',
            height: 160,
            animations: {
                enabled: false,},
            dataLabels: {
                enabled: false
            }
        },
        stroke: {
            width: 2,
            curve: 'stepline',

        },
        xaxis: {  type: 'datetime', },
        yaxis: {
            title: {
                text: 'Ingintion',
            },
        }
    });

    useEffect(()=> {
        setSeries([{
            name: 'ingintion',
            data: data.map(info => {
                if(info.ingintion !== undefined) {
                    return [new Date(info.gpsDate), info.ingintion ? 1 : 0]
                }
                return [new Date(info.gpsDate), null]
            })
        },
            {
                name: 'movement',
                data: data.map(info => {
                    if(info.movement !== undefined) {
                        return [new Date(info.gpsDate), info.movement ? 1 : 0]
                    }
                    return [new Date(info.gpsDate), null]
                })
            },
        ]);
    }, [data]);

    return <ReactApexChart id="ingintion" series={series} options={options}/>;

}

