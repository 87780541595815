import {FiCheck, IoMdClose, IoMdCloseCircleOutline} from "react-icons/all";
import React from "react";
import {SetRole} from "./store/role.actions";

export const columns = (canEdit, dispatch) => {
    const press = (role, key) => {
        if(canEdit){
            const newRole = {...role, ...{[key]: !role[key]}};
            dispatch(SetRole(newRole))
        }
    };

    return [
        {
            title: '#',
            dataIndex: 'role',
            key: 'role',
            width: 100
        },
        {
            title: 'Lecture seul',
            dataIndex: 'read',
            key: 'read',
            align: 'center',
            render: (text, role) => {
                const style = {fontSize: '18px'};
                const styleCell = {};
                if(role.read === undefined) {
                    styleCell.background = 'rgb(239, 239, 239) none repeat scroll 0% 0%';
                    styleCell.cursor = 'not-allowed';
                } else if(!canEdit) {
                    styleCell.cursor = 'not-allowed';
                } else {
                    styleCell.cursor = 'pointer';
                }

                switch (true) {
                    case role.read: {
                        return {
                            props: { style: styleCell },
                            children:  <div onClick={()=>press(role, 'read')}><FiCheck style={style} color="rgb(59, 206, 133)"/></div>
                        }
                    }
                    case role.read !== undefined: {
                        return {
                            props: { style: styleCell },
                            children:  <div onClick={()=>press(role, 'read')}><IoMdClose style={style} color="red"/></div>
                        }
                    }
                    default:
                        return {
                            props: { style: styleCell },
                            children:  <></>
                        }
                };
            }
        },
        {
            title: 'Ajout/Modifica...',
            dataIndex: 'write',
            align: 'center',
            key: 'write',
            render: (text, role) => {
                const style = {fontSize: '18px'}
                const styleCell = {};
                if(role.write === undefined) {
                    styleCell.background = 'rgb(239, 239, 239) none repeat scroll 0% 0%';
                    styleCell.cursor = 'not-allowed';
                } else if(!canEdit) {
                    styleCell.cursor = 'not-allowed';
                } else {
                    styleCell.cursor = 'pointer';
                }

                switch (true) {
                    case role.write: {
                        return {
                            props: { style: styleCell },
                            children:  <div onClick={()=>press(role, 'write')}><FiCheck style={style} color="rgb(59, 206, 133)"/></div>
                        }
                    }
                    case role.write !== undefined: {
                        return {
                            props: { style: styleCell },
                            children:  <div onClick={()=>press(role, 'write')}><IoMdClose style={style} color="red"/></div>
                        }}
                    default:
                        return {
                            props: { style: styleCell },
                            children:  <></>
                        }
                };
            }
        },
        {
            title: 'Suppression',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            render: (text, role) => {
                const style = {fontSize: '18px'}
                const styleCell = {};
                if(role.delete === undefined) {
                    styleCell.background = 'rgb(239, 239, 239) none repeat scroll 0% 0%';
                    styleCell.cursor = 'not-allowed';
                } else if(!canEdit) {
                    styleCell.cursor = 'not-allowed';
                } else {
                    styleCell.cursor = 'pointer';
                }

                switch (true) {
                    case role.delete: {
                        return {
                            props: { style: styleCell },
                            children:  <div onClick={()=>press(role, 'delete')}><FiCheck style={style} color="rgb(59, 206, 133)"/></div>
                        }
                    }
                    case role.delete !== undefined: {
                        return {
                            props: { style: styleCell },
                            children:  <div onClick={()=>press(role, 'delete')}><IoMdClose style={style} color="red"/></div>
                        }}
                    default:
                        return {
                            props: { style: styleCell },
                            children:  <></>
                        }
                };
            }
        },
    ]
};

