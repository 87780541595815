import React, {Component, useState} from 'react';
import './step3.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useForm } from "react-hook-form";
import {BsBuilding} from "react-icons/bs";
import {FiPhone} from "react-icons/fi";
import {AiOutlineMail, AiOutlineHome} from "react-icons/ai";
import {Button} from "../../../../components/widgets/form/button/button";
import isEmail from "validator/lib/isEmail";
import {fetchCompanyByName} from "../../companyService";
import {useDispatch, useSelector} from "react-redux";
import {
    SaveCompanyStart,
    SetStep,
    SetStepData,
    ToogleActive,
    ToogleStep
} from "../../../../store/comapny/company.actions";
import {Checkbox} from "../../../../components/widgets/form/checkbox/checkbox";
import isNumeric from "validator/lib/isNumeric";
import {SiGooglemaps, SiMapbox} from "react-icons/all";

export default function Step3(props) {
    const dispatch = useDispatch();
    const token = useSelector(state => state.authReducer.token);
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setError, errors, formState  } = useForm(
        { mode: "onChange",
            defaultValues: {
                googleMaps: props.data.googleMaps,
                mapBox: props.data.mapBox
            }
        },
    );

    const onSubmit = async(data) => {
        dispatch(SaveCompanyStart(data));
    };

    const previous = () => { dispatch(SetStep({step: 3})); };

    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className="input-form">
                    <input name="googleMaps" placeholder="Api key google maps"
                           ref={register()}
                    />
                    <SiGooglemaps/>
                </div>
                <div className="input-form">
                    <input name="mapBox" placeholder="Api key mapBox"
                           ref={register()}
                    />
                    <SiMapbox/>
                </div>
            </div>

            <div className="actions">
                <Button type='btn-accent' press={previous} name='Précédent'/>
                <Button type='btn-primary' name='Terminer' loading={loading}/>
            </div>
        </form>
    );
}
