import * as actions from "./command.actions";
import {catchError, map, switchMap} from 'rxjs/operators'
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {AutoLogout} from "../auth/auth.actions";
import {Error} from "../comapny/company.actions";
import {addCommand, deleteCommand, fetchCommands, UpdateCommand} from "../../pages/commands/commandService";
import {AddGroup, deleteGroup, UpdateGroup} from "../../pages/groups/groupService";
import {NotificationManager} from "react-notifications";


export const FetchCommandsEpic = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        const params = {...state$.value.commandReducer.filters.params, ...action.payload};
        return from(fetchCommands(params, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.getCommandsEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const DeleteCommandEpic = (action$, state$) => action$.pipe(
    ofType(actions.DELETE_START),
    switchMap((action) => {
        return from(deleteCommand(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('la commande est supprimée avec succès', 'Suppression', 2000);
                return actions.deletecommandEnd({response: res.data, id: action.payload});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, 'Suppression', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Suppression', 2000);
        return of(Error('Problem unknow'))
    })
);

export const SaveCommandEpic = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_START),
    switchMap((action) => {
        const command = {...state$.value.commandReducer.addCommand.data, ...action.payload};
       if(command.id) {
            return from(UpdateCommand(command, state$.value.authReducer.token)).pipe(
                map(res => {
                    NotificationManager.success('le groupe est modifiée avec succès', 'Modification' , 2000);
                    return actions.SaveCommandEnd(command);
                }),
                catchError(err => {
                    let msg = 'une erreur s\'est produite !';
                    if (err.response.status === 401) {
                        return of(AutoLogout(), Error(''));
                    }
                    return of(Error(msg))
                })
            )
        }
        delete command.id;
        return from(addCommand(command, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('La commande est créé avec succès', 'Création', 2000);
                const newCommand = {
                    ...{
                        _id: res.data.insertedId,
                        id: res.data.insertedId,
                    },
                    ...command

                }
                return actions.SaveCommandEnd(newCommand);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

