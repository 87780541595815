import React, { Component }  from 'react';
import {connect} from "react-redux";

import './vehicle-history-component.css';
import 'swiper/swiper.scss';
import {
    FaCarSide, FaGasPump,
    IoIosSpeedometer,
    MdTimeline
} from "react-icons/all";


class HistoryInfoComponent extends Component {

    constructor(props) { super(props); }

    getSpeedOrIdling() {
        if(this.props.idling) {
            return (<div className="item">
                <div style={{display: "flex", flexDirection: "row"}}>
                    <FaCarSide size={16} color="#edb52f"/>
                </div>
                <label className="value">{!this.props.loading ? this.props.idling || '-' : '-'}</label>
            </div>);
        }
        return (<div className="item">
            <div style={{display: "flex", flexDirection: "row"}}>
                <IoIosSpeedometer size={16} color="#81D8EB"/>
            </div>
            <label className="value">{
                !this.props.loading ?
                    this.props.avgSpeed || '-' : '-'} km/h</label>
        </div>);
    }
    getGazoil() {
        if(this.props.fuel) {
            return(
                    <div className="item">
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <FaGasPump size={16} color="rgb(182, 180, 18)"/>
                        </div>
                        <label className="value">{!this.props.loading ? this.props.fuel || '-' : '-'} L</label>
                    </div>
            );

        }
        return <></>;
    }
    render() {
        return (
            <div className="history-details">

                {this.getSpeedOrIdling()}
                <div className="item">
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <FaCarSide size={16} color="#3BA06E"/>
                    </div>
                    <label className="value">{!this.props.loading ? this.props.spentTime : '-'}</label>
                </div>
                {
                    /*
                    * <div className="item">
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <FaCarSide size={16} color="#E45D61"/>
                    </div>
                    <label className="value">{!this.props.loading ? this.props.spentTimeOff : '-'}</label>
                </div>
                    * */
                }

                <div className="item">
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <MdTimeline size={16} color="#ffefbe"/>
                    </div>
                    <label className="value">{!this.props.loading ? this.props.km || '-' : '-'} km</label>
                </div>
                {this.getGazoil()}
            </div>
        );
    }
}

const VehicleHistoryStateToProps = (state) => {
    return {
        spentTime: state.historyReducer.spentTime,
        spentTimeOff: state.historyReducer.spentTimeOff,
        avgSpeed: state.historyReducer.avgSpeed,
        km: state.historyReducer.km,
        idling: state.historyReducer.idling,
        fuel: state.historyReducer.fuel,
        loading: state.historyReducer.loading,
        error: state.historyReducer.error
    }
}
export default connect(VehicleHistoryStateToProps)(HistoryInfoComponent);
