import React, {Component} from "react";
import {connect} from "react-redux";
import { GetAlarmUpdateStart } from "../../../store/alarms/alarms.actions";
import PerfectScrollbar from "react-perfect-scrollbar";
import {BlankComponent} from "../../../components/blank/blank";
import {HeaderAction} from "../../../components/header/header-action/header-action";
import {AiOutlinePlus, AiOutlineReload, AiOutlineSearch} from "react-icons/ai";
import {SearchAction} from "../../../components/header/search-action/search-action";
import {Paginator} from "../../../components/pagination/paginator/Paginator";
import {ConfirmationDialog} from "../../../components/confirmation-dialog/confirmation-dialog";
import {confirmAlert} from "react-confirm-alert";
import {GetTracerStart, ToogleManageTracer, DeleteStart, SetAddData} from "../../../store/tracers/tracers.actions";
import {TracerInfo} from "../../../components/tracer/tracer-info";
import AddTracerPage from "../add-tracer/add-tracer-page";

class ListTracersPage extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 210}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(GetTracerStart({}));
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.id !== this.props.id) {
            this.props.dispatch(GetTracerStart({}));
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        // this.props.dispatch(ClearVehicles());

    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 210}px` });
    }

    changed(e) {
        if(e.keyCode === 13) {
            this.props.dispatch(GetTracerStart({
                name: `${e.target.value}.*`,
                skip: 0
            }));
        }
    }

    paginate(skip, limit) { this.props.dispatch(GetTracerStart({skip: skip}));}

    tracerAction(action) {
        switch (action.action) {
            case 'add':
                this.props.dispatch(ToogleManageTracer());
                break;
            case 'del':
                const options = {
                    customUI: ({ onClose }) =>
                        <ConfirmationDialog
                            close={onClose}
                            action={this.deleteAction.bind(this)}
                            id={action.id}
                            keyword={action.name}
                            ActionName={"Supprimer"}/>,
                    closeOnEscape: false,
                    closeOnClickOutside: false
                };
                confirmAlert(options);
                break;
            case 'edit':
                this.props.dispatch(SetAddData(action.tracer));
                break;
            case 'reload':
                this.props.dispatch(GetTracerStart({}));
                break;

        }
    }

    deleteAction(id) {
        this.props.dispatch(DeleteStart(id));
    }


    filterAction(action) {
        switch (action.key) {
            case 'name':
                this.props.dispatch(GetTracerStart({
                    name: action.value,
                    skip: 0
                }))
                break;

            case 'filter':
                this.props.dispatch(GetTracerStart({
                    filter: action.value,
                    skip: 0
                }))
                break;
        };
    }

    render() {
        const params = {limit: this.props.limit, skip: this.props.skip};
        if(!this.props.active)
            return (
                <>
                    <div className="header-section">
                        <div className="action-right">
                            <HeaderAction
                                value={{action: 'reload'}}
                                press={this.tracerAction.bind(this)}
                                icon={AiOutlineReload}
                            />
                            <HeaderAction
                                value={{action: 'add'}}
                                press={this.tracerAction.bind(this)}
                                icon={AiOutlinePlus}
                            />
                            <SearchAction
                                icon={AiOutlineSearch}
                                indentifier="name"
                                onChange={this.filterAction.bind(this)}
                            />
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                    <PerfectScrollbar className="scrollbar-container-alarm" style={{ height: this.state.height }}>
                        {
                            this.props.tracers && this.props.tracers.list.length > 0 ?
                                this.props.tracers.list.map((tracer, i) =>
                                    <TracerInfo tracer={tracer} press={this.tracerAction.bind(this)}/>):
                            <BlankComponent/>

                        }
                    </PerfectScrollbar>
                    <Paginator
                        noMargin={true}
                        paginate={this.paginate.bind(this)}
                        limit={params.limit}
                        skip={params.skip}
                        total={ this.props.tracers.total}/>
                </div>
                </>
            );
        else{
            return <AddTracerPage press={this.tracerAction.bind(this)}/>;
        }

    }
}

const VehiclesStateToProps = (state) => {
    return {
        tracer: state.tracersReducer.active,
        tracers: state.tracersReducer.tracer,
        limit: state.tracersReducer.tracer.limit,
        skip: state.tracersReducer.tracer.skip,
        active: state.tracersReducer.addTracer.active,
        /*total: state.alarmsReducer.alarm.total,
        id: state.alarmsReducer.active._id,
        step: state.alarmsReducer.addAlarm.step,*/
    }
}
export default connect(VehiclesStateToProps)(ListTracersPage);