import React from "react";
import * as reportsActions from "./deviceManager.actions";

const INITIAL_STATE = {
    data: {
        list: [],
        total: 0,
        loading: false,
        skip: 0,
        limit: 100,
    },
}

export function deviceManagerReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case reportsActions.ERROR:
            return Object.assign({}, state, {
                loading: false
            })

        case reportsActions.GET_START:
            return {
                ...state,
                data: {
                    ...state.data,
                    loading: true,
                }
            }

        case reportsActions.GET_END:
            return {
                ...state,
                data: {
                    ...state.report,
                    list: action.payload.list,
                    total: action.payload.total,
                    loading: false,
                }
            }

        case reportsActions.CLEAR:
            console.log('cleaaaaaaaar')
            return INITIAL_STATE;

        default:
            return state;
    }
}

