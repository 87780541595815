import './vehicle.css';
import React, { Component }  from 'react';
import {confirmAlert} from "react-confirm-alert";
import {AiOutlinePlus, AiOutlineReload, AiOutlineSearch} from "react-icons/ai";
import CountUp from "react-countup";
import PerfectScrollbar from 'react-perfect-scrollbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import {connect} from "react-redux";
import {
    SetStepData,
    ToogleStep,
    GetVehiclesStart,
    DeleteVehicleStart,
    StepCommandStart, ClearData
} from "../../store/vehicles/vehicles.actions";
import {MdWifiTethering, MdPortableWifiOff} from "react-icons/md";
import {GiGps} from "react-icons/gi";
import {FaBoxOpen} from "react-icons/fa";
import {HeaderAction} from "../../components/header/header-action/header-action";
import {SearchAction} from "../../components/header/search-action/search-action";
import {ShapeDetails} from "../../components/widgets/shape-details/shape-details";
import {VehicleInfo} from "../../components/vehicle/vehicle-info";
import {LoadingComponent} from "../../components/loading/loading";
import {ShapeElementInfo} from "../../components/widgets/shape-element-info/shape-element-info";
import {Paginator} from "../../components/pagination/paginator/Paginator";
import {FilterAction} from "../../components/header/filter-action/filter-action";
import {SideBar} from "../../components/sideBar/sideBar";
import Step1 from "./add-vehicle/step1/step1";
import Step2 from "./add-vehicle/step2/step2";
import {BlankComponent} from "../../components/blank/blank";
import {ConfirmationDialog} from "../../components/confirmation-dialog/confirmation-dialog";
import ManageCommand from "./add-vehicle/manage-command/manage-command";
import generatePath from "react-router-dom/es/generatePath";


class VehiclePage extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 176}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    componentDidMount() {
        if(this.props.vehicles && this.props.vehicles.length===0) {
            this.props.dispatch(GetVehiclesStart({}));
        }

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        this.props.dispatch(ClearData({all: true}));
    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 176}px` });
    }

    findVehicleById(id){
        return this.props.vehicles.find(el => id === el._id);
    }

    deleteAction(id) {
        this.props.dispatch(DeleteVehicleStart(id));
    }
    /*
     * Events
     * */

    vehicleAction(action) {
        switch (action.action) {
            case 'reload':
                this.props.dispatch(GetVehiclesStart({}))
                break;
            case 'add':
                this.props.dispatch(ToogleStep());
                break;
            case 'del':
                const options = {
                    customUI: ({ onClose }) =>
                        <ConfirmationDialog
                            close={onClose}
                            action={this.deleteAction.bind(this)}
                            id={action.id}
                            keyword={action.name}
                            ActionName={"Supprimer"}/>,
                    closeOnEscape: false,
                    closeOnClickOutside: false
                };

                confirmAlert(options);
                break;
            case 'edit':
                const vehicle = this.findVehicleById(action.id);
                if(vehicle) {
                    this.props.dispatch(SetStepData({
                        step: 1,
                        data: {
                            id: vehicle._id,
                            tracer: vehicle.tracer && vehicle.tracer.uid ? vehicle.tracer.uid : '',
                            group: vehicle.group,
                            name: vehicle.name,
                            plate: vehicle.plate,
                            vin: vehicle.vin,
                            model: vehicle.model,
                            type: vehicle.type,
                            unitSpeed: vehicle.unitSpeed,
                            hasCan: vehicle.hasCan,
                            active: vehicle.active
                        }
                    }));
                }
                break;
            case 'command':
                this.props.dispatch(StepCommandStart(action.id));
                break;
            case 'debug':{
                const path = generatePath("/vehicles/:id/manage", {
                    id: action.id,
                });
                this.props.history.push({pathname: path})
                break;
            }

            case 'accessory':{
                const path = generatePath("/vehicles/:id/accessory", {
                    id: action.id,
                });
                this.props.history.push({pathname: path})
                break;
            }

            case 'io':{
                const path = generatePath("/vehicles/:id/io", {
                    id: action.id,
                });
                this.props.history.push({pathname: path})
                break;
            }


        }
    }

    filterAction(action) {
        switch (action.key) {
            case 'name':
                this.props.dispatch(GetVehiclesStart({
                    name: action.value,
                    skip: 0
                }))
                break;

            case 'filter':
                this.props.dispatch(GetVehiclesStart({
                    filter: action.value,
                    skip: 0
                }))
                break;
        };
    }

    paginate(skip, limit) {
        this.props.dispatch(GetVehiclesStart({skip: skip}));
    }
    render() {
        const filters= this.props.filters.data;
        const params= this.props.filters.params;
        return (
            <section id="vehicles">
                <LoadingComponent loading={this.props.loading}/>
                <div className="header-section">
                    <div className="filter">
                        {
                            filters && filters.map((el, i)=> {
                               return <FilterAction
                                   key={i}
                                   active={el.filter === params.filter}
                                   label={el.name}
                                   value={el.filter}
                                   press={this.filterAction.bind(this)}
                               />
                            })
                        }
                    </div>
                    <div className="action-right">
                        <HeaderAction
                            value={{action: 'reload'}}
                            press={this.vehicleAction.bind(this)}
                            icon={AiOutlineReload}
                        />
                        <HeaderAction
                            value={{action: 'add'}}
                            press={this.vehicleAction.bind(this)}
                            icon={AiOutlinePlus}
                        />
                        <SearchAction
                            icon={AiOutlineSearch}
                            indentifier="name"
                            onChange={this.filterAction.bind(this)}
                        />
                    </div>

                </div>
                <div style={{display: 'flex'}}>
                    <PerfectScrollbar className="scrollbar-container-vehicle animmation-scroll" style={{ height: this.state.height }}>
                        <div className="body">
                            {
                                this.props.vehicles.length > 0  ? this.props.vehicles.map((vehicle,i) => {
                                    return <VehicleInfo
                                        key={i}
                                        vehicle={vehicle}
                                        press={this.vehicleAction.bind(this)}
                                    />
                                }) : !this.props.loading && <BlankComponent/>
                            }
                        </div>

                        <Paginator paginate={this.paginate.bind(this)} limit={params.limit} skip={params.skip} total={this.props.total}/>

                    </PerfectScrollbar>
                    <ShapeDetails>
                        <div className="circle-chart">
                            <div className="chart-value">

                                <span className="description">Véhicules</span>
                            </div>
                        </div>
                        <div className="description">
                            <ShapeElementInfo
                                name="Connecté"
                                icon={MdWifiTethering}
                                value={this.props.stats.connected}
                                first
                            />
                            <ShapeElementInfo
                                name="Déconnecté"
                                icon={MdPortableWifiOff}
                                value={this.props.stats.disconnected}
                            />
                            <ShapeElementInfo
                                name="Avec traceur"
                                icon={GiGps}
                                value={this.props.stats.withGps}
                            />
                            <ShapeElementInfo
                                name="Avec Groupe"
                                icon={FaBoxOpen}
                                value={this.props.stats.inGrp}
                            />
                        </div>
                    </ShapeDetails>

                    {
                        this.props.addVehicle.step && this.props.addVehicle.step<3 ?
                            <SideBar
                                height={window.innerHeight}
                                countSteps={this.props.addVehicle.steps.length}
                            step={this.props.addVehicle.step}
                            stepsText={this.props.addVehicle.steps}
                        >
                            { this.props.addVehicle.step === 1 && <Step1
                                data={this.props.addVehicle.data}
                            /> }
                            { this.props.addVehicle.step === 2 && <Step2
                                data={this.props.addVehicle.data}
                            /> }
                        </SideBar> : <></>
                    }

                    {
                        this.props.addVehicle.step && this.props.addVehicle.step===3 ?
                            <SideBar
                                height={window.innerHeight}
                                countSteps={1}
                                step={1}
                                stepsText={[{next:'fin', current: 'Choix des commandes'}]}
                            >
                                <ManageCommand data={this.props.addVehicle.data}/>
                            </SideBar> : <></>
                    }
                </div>
            </section>
        );
    }
}

const companiesStateToProps = (state) => {
    return {
        vehicles: state.vehiclesReducer.vehicles,
        filters: state.vehiclesReducer.filters,
        stats: state.vehiclesReducer.stats,
        loading: state.vehiclesReducer.loading,
        addVehicle: state.vehiclesReducer.addVehicle,
        total: state.vehiclesReducer.total
    }
}
export default connect(companiesStateToProps)(VehiclePage);
