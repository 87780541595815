const findRole = (name, where) => {
    return where.findIndex(r => r === name) !== -1;
}

export const canShowZone = (userType, roles) => {
    return userType === 'admin'
        || findRole('zone:rd', roles)
        || findRole('zone:wt', roles)
        || findRole('zone:dt', roles)
        || findRole('poi:rd', roles)
        || findRole('poi:wt', roles)
        || findRole('poi:dt', roles)
}

export const canManageZone = (userType, roles) => {
    return userType === 'admin'
        || findRole('zone:wt', roles)
        || findRole('poi:wt', roles);
}

export const canDeleteZone = (userType, roles) => {
    return userType === 'admin'
        || findRole('poi:dt', roles)
        || findRole('zone:dt', roles)
}
