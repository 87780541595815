import React, { Component }  from 'react';
import {connect} from "react-redux";
import {AiOutlineSearch} from "react-icons/ai";
import PerfectScrollbar from "react-perfect-scrollbar";
import VehicleMapInfo from "../../../components/map/vehicle-map/vehicle-map-info";
import {Paginator} from "../../../components/pagination/paginator/Paginator";
import {
    ClearVehicles,
    GetVehiclesStart,
    Locate, SetCommand,
    SetDetails,
    SetInfo,
    TrackVehicle
} from "../../../store/map/vehicles/vehicles.actions";
import {ToogleHistory} from "../../../store/history/history.actions";
import {BlankComponent} from "../../../components/blank/blank";
import {canShowMaintain} from "../../../common/maintenance-role";

class VehiclesMapPage extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 246}px`, scroll: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        if(this.props.vehicles.list.length === 0) {
            this.props.dispatch(GetVehiclesStart({}));
        }
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);

    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 246}px` });
    }

    changed(e) {
        if(e.keyCode === 13) {
            this.props.dispatch(GetVehiclesStart({
                name: `${e.target.value}.*`
            }));
        }
    }

    paginate(skip, limit) {
        this.props.dispatch(GetVehiclesStart({skip: skip}));
    }

    actionMenu(action) {
        switch (action.action) {
            case 'locate':
                this.props.dispatch(Locate(action));
                break;
            case 'track':
                this.props.dispatch(TrackVehicle(action));
                break;
            case 'info':
                this.props.dispatch(SetInfo(action.uid));
                break;
            case 'detail':
                this.props.dispatch(SetDetails(action._id));
                break;
            case 'command':
                this.props.dispatch(SetCommand(action._id));
                break;
            case 'history':
                this.props.dispatch(ToogleHistory(action._id));
                break;

        }
    }

    render() {
        const params= this.props.filters.params;

        return (
            <>
                <div className="action-search-map">
                    <input type="text" placeholder="Chercher..." onKeyUp={this.changed.bind(this)}/>
                    <AiOutlineSearch/>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                    <PerfectScrollbar
                        style={{ height: this.state.height, padding:'0px 3px', marginBottom: '12px' }}
                        onScrollY={container => this.setState({scroll: container.scrollTop})}
                    >
                        {
                            this.props.vehicles.list.length > 0 ?this.props.vehicles.list.map((el, i) => {
                                return <VehicleMapInfo
                                    index={i}
                                    key={i}
                                    trackVehicle={this.props.trackVehicle}
                                    vehicle={el}
                                    press={this.actionMenu.bind(this)}
                                    scroll={this.state.scroll}
                                    hasMaintenance={canShowMaintain(this.props.type, this.props.roleAuthorization)}
                                />
                            }): <BlankComponent/>
                        }
                    </PerfectScrollbar>
                    <Paginator
                        noMargin={true}
                        paginate={this.paginate.bind(this)}
                        limit={params.limit}
                        skip={params.skip}
                        total={this.props.vehicles.total}/>
                </div>
            </>
        );
    }
}

const VehiclesStateToProps = (state) => {
    return {
        filters: state.vehiclesMapReducer.filters,
        vehicles: state.vehiclesMapReducer.vehicles,
        trackVehicle: state.vehiclesMapReducer.trackVehicle,
        type: state.authReducer.user.type,
        roleAuthorization: state.authReducer.user.role
    }
}
export default connect(VehiclesStateToProps)(VehiclesMapPage);
