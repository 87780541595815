import React, { Component }  from 'react';
import './command-info.css';
import {AiOutlineEdit, AiOutlineDelete, AiOutlineCar} from "react-icons/ai";
import "react-perfect-scrollbar/dist/css/styles.css";
import {HeaderAction} from "../header/header-action/header-action";
import {AiOutlineSend, AiOutlineUser, RiAdminLine} from "react-icons/all";

export class CommandInfo extends Component {

    constructor(props) { super(props); }

    componentDidMount() { }

    render() {
        return (
            <div className="card-command-info">
                <div>
                    <div className="main">
                        <div style={{display: 'flex', alignItems:'center'}}>
                            <div>
                                <h4 className="name">{this.props.command.name}</h4>
                            </div>
                        </div>
                        <div className="action">
                            <HeaderAction icon={AiOutlineEdit} press={this.props.press} value={{action: 'edit', id: this.props.command._id}}/>
                            <HeaderAction icon={AiOutlineDelete} press={this.props.press} value={{action: 'del', name: this.props.command.name, id: this.props.command._id}}/>
                        </div>

                    </div>
                    <div className="details" style={{marginTop: '12px'}}>
                        <div className="widget-value">
                            <AiOutlineSend />
                            <label className="type"> {this.props.command.command}</label>
                        </div>
                    </div>
                    <div className="details">
                        <div className="widget-value">
                            <RiAdminLine />
                            <label className="type">Administrateur: {this.props.command.active ? 'Oui' : 'Non'}</label>
                        </div>
                    </div>
                    <div className="details">
                        <div className="widget-value">
                            <AiOutlineUser />
                            <label className="type">Utilisateur: {this.props.command.all && this.props.command.active ? 'Oui' : 'Non'}</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}