import React, { PureComponent }  from 'react';
import './bar.css';

export class Bar extends PureComponent{

    days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

    constructor(props) {
        super(props);
        this.state = { d0: 0 }
    }

    componentDidMount() {
        this.setState({d0: this.props.value});
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.d0 !== this.props.value) {
            this.setState({d0: this.props.value});
        }
    }


    render() {
        return (
            <div style={{marginRight: 5, justifyContent: 'center', alignItems: 'center'}}>
                <div className="big-bar">
                    <div  className="small-bar" style={{ flex: this.state.d0 }}/>
                </div>
                <label className="day">{this.props.label}</label>
            </div>
        );
    }
}