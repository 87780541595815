export const GET_START = '[HISTORY] GET_START';
export const GET_END = '[HISTORY] GET_END';
export const ERROR = '[HISTORY] ERROR';
export const TOOGLE_HISTORY = '[HISTORY] TOOGLE_HISTORY';

export const SET_ACTIVE_START = '[HISTORY] SET_ACTIVE_START';
export const SET_ACTIVE_END = '[HISTORY] SET_ACTIVE_END';
export const SET_ACTIVE_TRAJECT = '[HISTORY] SET_ACTIVE_TRAJECT';

export const SET_TIMELINE = '[HISTORY] SET_TIMELINE';


export const CLEAR_HISTORY = 'CLEAR_HISTORY';

export function GetHistoryStart(payload) {
    return { type: GET_START, payload }
}

export function GetHistoryEnd(payload) {
    return { type: GET_END, payload }
}

export function SetTimeLine(payload) {
    return { type: SET_TIMELINE, payload }
}

export function ToogleHistory(payload) {
    return { type: TOOGLE_HISTORY, payload }
}


export function SetActiveStart(payload) {
    return { type: SET_ACTIVE_START, payload }
}

export function SetActiveEnd(payload) {
    return { type: SET_ACTIVE_END, payload }
}

export function SetActiveTraject(payload) {
    return { type: SET_ACTIVE_TRAJECT, payload }
}

export function ClearHistory() {
    return { type: CLEAR_HISTORY }
}


export function Error(msg) {
    return { type: ERROR, msg }
}