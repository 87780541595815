import React, { Component }  from 'react';
import {connect} from "react-redux";
import {AiOutlineSearch} from "react-icons/ai";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Paginator} from "../../../components/pagination/paginator/Paginator";
import './events.css';
import {EventMapInfo} from "../../../components/map/event-map/event-info";
import {GetEventsStart, SetActive} from "../../../store/map/events/events.actions";
import {BlankComponent} from "../../../components/blank/blank";
import {EventDetails} from "../../../components/map/event-details/event-details";

class EventsMapPage extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 246}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        if(this.props.events.list.length === 0) {
            this.props.dispatch(GetEventsStart({}));
        }
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);

    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 246}px` });
    }

    changed(e) {
        if(e.keyCode === 13) {
            this.props.dispatch(GetEventsStart({
                name: `${e.target.value}.*`
            }));
        }
    }

    paginate(skip, limit) {
        this.props.dispatch(GetEventsStart({skip: skip}));
    }

    actionMenu(event) {
        this.props.dispatch(SetActive(event));
    }

    render() {
        const params= this.props.filters.params;
        return (
            <>
                {
                    this.props.active && <EventDetails event={this.props.active} press={this.actionMenu.bind(this)} timezone={this.props.timezone}/>
                }
                <div className="action-search-map">
                    <input type="text" placeholder="Chercher..." onKeyUp={this.changed.bind(this)}/>
                    <AiOutlineSearch/>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                    <PerfectScrollbar style={{ height: this.state.height, padding:'0px 3px', marginBottom: '12px' }}>
                        {
                            this.props.events.list.length > 0 ? this.props.events.list.map((event, i) => <EventMapInfo
                                        key={i}
                                        timezone={this.props.timezone}
                                        event={event}
                                        active={event.date === this.props.active.date
                                            && event.type === this.props.active.type}
                                        press={this.actionMenu.bind(this)}
                                    />
                            )
                                : <BlankComponent/>
                        }
                    </PerfectScrollbar>
                    <Paginator
                        noMargin={true}
                        paginate={this.paginate.bind(this)}
                        limit={params.limit}
                        skip={params.skip}
                        total={this.props.events.total}/>
                </div>
            </>
        );
    }
}

const VehiclesStateToProps = (state) => {
    return {
        timezone: state.authReducer.user.timezone,
        filters: state.eventsMapReducer.filters,
        events: state.eventsMapReducer.events,
        active: state.eventsMapReducer.active
    }
}
export default connect(VehiclesStateToProps)(EventsMapPage);