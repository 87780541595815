import React, {Component} from 'react';
import './vehicle-info-alarm.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import {AiOutlineCar, AiOutlineCheck} from "react-icons/all";

export class VehicleInfoAlarm extends Component {

    constructor(props) { super(props); }

    componentDidMount() { }

    press() {
        if(this.props.press && !this.props.active){
            this.props.press({action: 'select', value: this.props.vehicle._id});
        }
    }
    render() {
        const css = !this.props.active ? "card-group-info" : "card-group-info card-group-info-active"
        return (
            <div className={css} onClick={() => this.press() }>
                { !this.props.active ?   <AiOutlineCar/> : <AiOutlineCheck/> }
                <h5 className="name">{this.props.vehicle.name}</h5>
            </div>

        );
    }
}