import React, { Component }  from 'react';
import './sideBar.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import {CircleProgressBar} from "../../components/chart/circles/circle-progress-bar/circleProgressBar";
import PerfectScrollbar from "react-perfect-scrollbar";

export class SideBar extends Component {

    constructor(props) { super(props); }

    render() {
        const {current, next } = this.props.stepsText[this.props.step - 1];
        return (
            <div className="action-sideBar">
                <div className="aside-part">
                    <div className="header-action-sideBar">
                        <div className="percent">
                            <CircleProgressBar size={70} percent={this.props.step / this.props.countSteps * 100} color="primary">
                                <label className="info">{this.props.step} de {this.props.countSteps}</label>
                            </CircleProgressBar>
                            <div className="details-info">
                                <label>{current}</label>
                                <label>Suivant: {next}</label>
                            </div>
                        </div>
                    </div>
                    <div></div>
                    <PerfectScrollbar className="content" style={{height: this.props.height- 190, zIndex: 0}}>
                        { this.props.children }
                    </PerfectScrollbar>
                </div>
            </div>
        );
    }
}