import * as historyActions from './history.actions';
import {dateDiff, getHours, getMinutes, getSeconds, UNITTIME} from "../../utils/date";
// import {km, speed} from "../../utils/parser";
import moment from "moment-timezone";


const INITIAL_STATE = {
    _id: null,
    loading: false,
    timeLine: null,
    active: null,
    spentTime: '00h : 00m',
    spentTimeOff: '00h : 00m',
    avgSpeed: 0,
    km: 0,
    fuel: false,
    trips: [],
    traject: {
        loading: false,
        on: false,
        data: []
    },
}

export function historyReducer(state = INITIAL_STATE, action){
    switch (action.type) {
        case historyActions.TOOGLE_HISTORY:
            return Object.assign({}, state, {
                _id: action.payload
            });
        case historyActions.GET_START:
            return{
                ...state,
                loading: true,
                traject: {
                    ...state.traject,
                    data: [],
                    active : -1,
                    loading: false
                }
            }


        case historyActions.GET_END:
            const {stats, trips, firstTrip, lastTrip} = action.payload.data;
            let fuel = 0;
            let avgSpeed = 0;
            let km = 0;
            let timeOn = 0;
            let timeOff = 0;
            if(stats) {
                fuel = stats?.fuel?.toFixed(2);
                km = stats?.km?.toFixed(2);
                avgSpeed = stats?.avgSpeed?.toFixed(2);
            }
            const newTrips = [];
            const size = trips.length;
            if( size> 0) {
                if(trips[0].previousTrip) {
                    trips.unshift({
                        on: false,
                        start: trips[0].start,
                        end: trips[0].start,
                        posEnd: trips[0].posStart,
                        first: true
                    })
                }
            }



            trips.forEach((trip, i) => {
                const isLast = i === trips.length - 1;
                const newTrip = setTripTime(trip, i, trips.length, action.payload.from, action.payload.timezone);
                switch (true) {
                    case newTrip.on === undefined:
                        newTrips.push({...newTrip, on: true});
                        timeOn += newTrip.duration;
                        if(!isLast && !newTrip.notEnd) {
                                const start =  newTrip.end;
                                const end = moment(trips[i+1].start).tz(action.payload.timezone);
                                const duration = end.diff(start);
                                newTrips.push({posEnd: newTrip.posEnd, start, duration, end ,on: false});

                        } else if(!newTrip.sliced && !newTrip.notEnd) {
                            const start = newTrip.end;
                            const end = newTrip.sameDay ? moment().tz(action.payload.timezone)
                                :  moment(start).tz(action.payload.timezone).set({hour: 23, minute: 59, second: 59, millisecond: 59});
                            const duration = end.diff(start);
                            newTrips.push({
                                on: false, start, end, duration, posEnd: newTrip.posEnd});
                            break;
                        }
                        break;
                    default:
                        newTrips.push(newTrip);

                }
            });


            return{
                ...state,
                loading: false,
                spentTime: `${getHours(timeOn)}h : ${getMinutes(timeOn)}m`,
                spentTimeOff: `${getHours(timeOff)}h : ${getMinutes(timeOff)}m`,
                fuel, km, avgSpeed,
                timeLine: {
                    ...state.timeLine,
                    active: action.payload.index || 1
                },
                trips: newTrips
            }
            return INITIAL_STATE;



        case historyActions.SET_ACTIVE_START:
            return {
                ...state,
                traject: {
                    ...state.traject,
                    loading: true,
                    data: [],
                    active : -1
                }
            }

        case historyActions.SET_ACTIVE_END:
            return{
                ...state,
                traject: {
                    ...state.traject,
                    loading: false,
                    on: action.payload.on,
                    data: action.payload.traject,
                    active : action.payload.index
                }
            }


        case historyActions.SET_ACTIVE_TRAJECT:
            return Object.assign({}, state, {
                timeLine: Object.assign({}, state.timeLine, {
                    active: 15 - action.payload
                })
            });

        case historyActions.CLEAR_HISTORY:
            return INITIAL_STATE

        case historyActions.SET_TIMELINE:
            const timeLine = [];
            const limit = 15;
            for ( let i = limit; i > 0; i--) {
                const nowFrom = action.payload.from.clone();
                const nowTo = action.payload.from.clone();
                const dayFrom =  nowFrom.subtract(i-1, 'd');
                const dayTo =  nowTo.subtract(i-1, 'd');
                const from = dayFrom.set({hour:0,minute:0,second:0,millisecond:0});
                const to = dayTo.set({hour:23,minute:59,second:59,millisecond:0});
                timeLine.push({index: i, from, to});
            }
            return Object.assign({}, state, {
                timeLine: {
                    active: 1,
                    times: timeLine
                }
            });

        case historyActions.ERROR:
            return Object.assign({}, state, {
                loading: false
            })

        default:
            return state;
    }
}


function setTripTime(trip, i, size, from, timezone) {
    switch (true) {
        case trip.first: {
            const today = moment().tz(timezone);
            trip.start = moment(trip.start).tz(timezone).set({hour: 0, minute: 0, second: 0, millisecond: 0});
            if(trip.end) {
                const sameDay = today.isSame(trip.end, 'day');
                trip.end = moment(trip.end).tz(timezone);
                trip.duration = trip.end.diff(trip.start);
                trip.sameDay= sameDay;
            }
        }
        default:{
            const today = moment().tz(timezone);
            trip.start = moment(trip.start).tz(timezone);
            if(trip.end) {
                const sameDay = today.isSame(trip.end, 'day');
                trip.end = moment(trip.end).tz(timezone);
                trip.sameDay = sameDay;
            }
            else {
                const sameDay = today.isSame(trip.end, 'day');
                if(sameDay) {
                    trip.end = moment().tz(timezone);
                    trip.duration = trip.end.diff(trip.start);
                    trip.notEnd = true;
                    trip.sameDay = sameDay;
                }
            }
            return trip;
        }
    }

}

