import React, { Component }  from 'react';
import './blank.css';

export class BlankComponent extends Component {
    constructor(props) {super(props); }
    render() {
        return(
            <img className="blank-data" src="/img/empty.svg"/>
        );
    }
}
