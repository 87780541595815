import { combineReducers } from 'redux';
import {authReducer} from "./auth/auth.reducer";
import * as mainActions from "./main.actions";
import {companyReducer} from "./comapny/company.reducer";
import {vehiclesReducer} from "./vehicles/vehicles.reducer";
import {groupReducer} from "./group/group.reducer";
import {accountsReducer} from "./accounts/accounts.reducer";
import {vehiclesMapReducer} from "./map/vehicles/vehicles.reducer";
import {detailsReducer} from "./details/details.reducer";
import {commandReducer} from "./commands/command.reducer";
import {historyReducer} from "./history/history.reducer";
import {zonesReducer} from "./zones/zones.reducer";
import {eventsMapReducer} from "./map/events/events.reducer";
import {alarmsReducer} from "./alarms/alarms.reducer";
import {reportsReducer} from "./reports/reports.reducer";
import {blacklistReducer} from "./blacklist/blacklist.reducer";
import {driversReducer} from "./driver/drivers.reducer";
import {adminReducer} from "./admin/admin.reducer";
import {tracersReducer} from "./tracers/tracers.reducer";
import {maintenanceReducer} from "./maintenance/maintenance.reducer";
import {deviceManagerReducer} from "./deviceManager/deviceManager.reducer";
import {roleReducer} from "../pages/role/store/role.reducer";

const INITIAL_STATE = {
    activePage: '/dashboard',
    sizeMenu: 'full' // short
}


export function mainReducer(state = INITIAL_STATE, action){
    switch (action.type) {
        case mainActions.ACTIVE_MENU:
            return Object.assign({}, state, {
                activePage: action.payload
            });
        case mainActions.SET_SIZE_MENU:
            return Object.assign({}, state, {
                sizeMenu: state.sizeMenu === 'full' ? 'short' : 'full'
            });
        case mainActions.MIN_SIZE_MENU:
            return Object.assign({}, state, {
                sizeMenu:'short'
            });
        default:
            return state;
    }
}

const reducers  = combineReducers({
        mainReducer,
        authReducer,
        companyReducer,
        vehiclesReducer,
        groupReducer,
        accountsReducer,
        vehiclesMapReducer,
        detailsReducer,
        commandReducer,
        historyReducer,
        zonesReducer,
        eventsMapReducer,
        alarmsReducer,
        reportsReducer,
        blacklistReducer,
        driversReducer,
        adminReducer,
        tracersReducer,
        maintenanceReducer,
        deviceManagerReducer,
        roleReducer
    }
);

export default reducers;
