export const GET_START = '[DRIVER] GET_START';
export const GET_END = '[DRIVER] GET_END';
export const ERROR = '[DRIVER] ERROR';

export const TOOGLE_STEP = '[DRIVER] TOOGLE_STEP';

export const TOOGLE_ACTIVE = '[DRIVER] TOOGLE_ACTIVE';

export const SET_STEP_DATA = '[DRIVER] SET_STEP_DATA';
export const SET_STEP = '[DRIVER] SET_STEP';
export const SET_DATE = '[DRIVER] SET_DATE';

export const SAVE_START = '[DRIVER] SAVE_START';
export const SAVE_END = '[DRIVER] SAVE_END';

export const DELETE_START = '[DRIVER] DELETE_START';
export const DELETE_END = '[DRIVER] DELETE_END';





export function SaveDriverStart(payload) {
    return { type: SAVE_START, payload }
}

export function SaveDriverEnd(payload) {
    return { type: SAVE_END, payload }
}

export function GetDriversStart(payload) {
    return { type: GET_START, payload }
}

export function ToogleStep() {
    return { type: TOOGLE_STEP }
}

export function ToogleActive(payload) {
    return { type: TOOGLE_ACTIVE, payload }
}

export function SetStepData(payload) {
    return { type: SET_STEP_DATA, payload }
}

export function getDriversEnd(payload) {
    return { type: GET_END, payload }
}

export function deleteDriverStart(payload) {
    return { type: DELETE_START, payload }
}

export function deleteDriverEnd(payload) {
    return { type: DELETE_END, payload }
}



export function Error(msg) {
    return { type: ERROR, msg }
}