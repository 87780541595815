import React, {Component} from 'react';
import {connect} from "react-redux";

import {Icon} from "leaflet";
import {changeView, fitBoundsPositions} from "../../../../store/map/vehicles/leaflet-provider";
import RotatedMarker from "../../../../components/leaflet/rotated-marker"
import 'leaflet-editable'
import AntPath from "react-leaflet-ant-path";
import {Marker} from "react-leaflet";


class History extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.duration = 2000;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.moveToRegion();
    }

    moveToRegion() {
        const traject = this.props.traject;
        const loading = this.props.loading
        if (traject.length > 0 && !loading) {
            if (this.props.on) {
                const polyline = traject.map((el) => {
                    return {lat: el.location[1], lng: el.location[0]}
                });
                fitBoundsPositions(this.props.mapRef.current, polyline);
            } else {
                changeView(this.props.mapRef.current, {lat: traject[1], lng: traject[0]}, 17)
            }
        }
    }

    getIcon(status) {
        let iconUrl = '/img/vehicles/car.png';
        if (!status) {
            iconUrl = '/img/vehicles/car-offline.png';
        }
        return new Icon({
            iconUrl, iconSize: [16.5, 30], iconAnchor: [8.25, 15], popupAnchor: [0, -32]
        });
    }

    getStart() {
        const iconUrl = '/img/vehicles/start.png';
        return new Icon({
            iconUrl, iconSize: [20, 20], iconAnchor: [18, 18], popupAnchor: [0, -32]
        });
    }


    getStop() {
        const iconUrl = '/img/vehicles/stop.png';
        return new Icon({
            iconUrl, iconSize: [20, 20], iconAnchor: [18, 18], popupAnchor: [0, -32]
        });
    }



    historyPolylines() {
        const antPathOptions = {
            "className": 'history-line',
            "delay": 900,
            "dashArray": [
                32,
                59
            ],
            "weight": 6,
            "color": "#1976D2",
            "pulseColor": "rgba(255, 255, 255, .9)",
            "paused": false,
            "reverse": false,
            "hardwareAccelerated": true
        };

        const antIdlingOptions = {
            "className": 'history-line',
            "delay": 900,
            "dashArray": [
                32,
                59
            ],
            "weight": 6,
            "color": "orange",
            "pulseColor": "rgba(255, 255, 255, .9)",
            "paused": false,
            "reverse": false,
            "hardwareAccelerated": true
        };
        if (this.props.traject.length > 0 && this.props.active !== null) {
            const traject = this.props.traject;
           if (!this.props.on) {
               return <Marker
                   position={{lat: traject[1], lng: traject[0]}}
                   icon={this.getIcon(false)}
               />

            }

            const polyline = traject.map((el) => {
                return {lat: el.location[1], lng: el.location[0]}
            });
            return (
                <>
                    <Marker
                        position={{lat: traject[0].location[1], lng: traject[0].location[0]}}
                        icon={this.getStart()}
                    />
                    <Marker
                        position={{
                            lat: traject[traject.length-1].location[1],
                            lng: traject[traject.length-1].location[0]
                        }}
                        icon={this.getStop()}
                    />
                    <AntPath positions={polyline} options={antPathOptions}/>

                </>

            );
        }
        return <></>
    }





    render() {
        return (
            <> {this.historyPolylines()} </>
        );
    }
}

const MapStateToProps = (state) => {
    return {
        traject: state.historyReducer.traject.data,
        on: state.historyReducer.traject.on,
        loading: state.historyReducer.traject.loading,
        active: state.historyReducer.traject.active,
        timeLine: state.historyReducer.timeLine,

    }
}
export default connect(MapStateToProps)(History);