const findRole = (name, where) => {
    return where.findIndex(r => r === name) !== -1;
}

export const canShowUser = (userType, roles) => {
    return userType === 'admin' || findRole('user:rd', roles) || findRole('user:wt', roles) || findRole('user:dt', roles)
}

export const canManageUser = (userType, roles) => {
    return userType === 'admin' || findRole('user:wt', roles);
}

export const canDeleteUser = (userType, roles) => {
    return userType === 'admin' || findRole('user:dt', roles)
}
