import * as actions from "./deviceManager.actions";
import {ofType} from "redux-observable";
import {catchError, map, switchMap} from 'rxjs/operators'
import {from, of} from "rxjs";
import {AutoLogout} from "../auth/auth.actions";
import {fetchData} from "../../pages/admin/device-manager/deviceManagerService";
import {GetEnd} from "./deviceManager.actions";



export const FetchDataEpics = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        return from(fetchData(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                return GetEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);



