import {BASE_URL} from "../../env";

const axios = require('axios');


export async function fetchAccountByUserName(payload, token) {
    const url = `${BASE_URL}/api/account?projection[username]=1&filter[username]=${payload}&projection[_id]=0`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }

    try{
        const res = await axios.get(url, config);
        if(res.data === false) {
            return { for: 'username', error: false }
        }
    }catch (e) { }
    return {
        for: 'username',
        error: true,
        msg: 'Nom d\'utilisateur déjà utilisé !'
    }
}

export function fetchAccounts(payload, token) {
    let params = '';
    for (let param in payload) {
        params+=`${param}=${payload[param]}&`
    }
    const url = `${BASE_URL}/api/account/all?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}


export async function fetchTracerByUid(uid, token) {
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    const url =`${BASE_URL}/api/account/tracer/${uid}`;
    try{
        const res = await axios.get(url, config);
        if(res.data) {
            return {
                for: 'tracer',
                error: true,
                msg: 'Traceur déjà utilisé !'
            }
        }
        return { for: 'tracer', error: false };
    }catch (e) {
        return {
            for: 'tracer',
            error: true,
            msg: 'Traceur inéxistant !'
        }
    }
}

export async function fetchGroupByUName(name, token) {
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    const url =`${BASE_URL}/api/group/${name}`;
    try{
        const res = await axios.get(url, config);
        if(res.data) {
            return {
                for: 'group',
                error: false
            }
        }
        return {
            for: 'group',
            error: true,
            msg: 'Groupe inéxistant !'
        }
    }catch (e) {
        return {
            for: 'group',
            error: true,
            msg: 'Groupe inéxistant !'
        }
    }
}

export async function addAccount(payload, token) {
    const url = `${BASE_URL}/api/account`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.post(url, payload, config);
}

export async function updateAccount(payload, token) {
    const url = `${BASE_URL}/api/account`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    const id = payload.id;
    payload.role = payload.role;
    delete payload.id;
    delete payload._id;
    delete payload.username;
    payload = {
        filter: {id},
        update: payload
    }
    return axios.patch(url, payload, config);
}


export async function updateAccountCompany(payload, token) {
    const url = `${BASE_URL}/api/account`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.patch(url, payload, config);
}

export async function deleteAccount(payload, token) {
    const url = `${BASE_URL}/api/account/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.delete(url, config);
}

export async function updatePassword(payload, token) {
    delete payload.confirmPassword;
    const url = `${BASE_URL}/api/account/password`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.patch(url, payload, config);
}
