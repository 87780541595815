import React, {useEffect, useState} from 'react';
import "react-perfect-scrollbar/dist/css/styles.css";
import {ReactApexChart} from "../components/apex-chart";

export const VoltageGraph = ({data}) => {
    const [series, setSeries] = useState([]);
    const [options] = useState({
        series: [{ data: [] }],

        chart: {
            id: 'voltage',
            group: 'social',
            type: 'line',
            height: 160,
            animations: {
                enabled: false,},
            dataLabels: {
                enabled: false
            }
        },
        stroke: {
            width: 2
        },
        xaxis: {  type: 'datetime', },
        yaxis: {
            title: {
                text: 'Tension en Volt',
            },
        }
    });

    useEffect(()=> {
        setSeries([{
            name: 'Voltage',
            data: data.map(info => [new Date(info.gpsDate), info.gpsV !== undefined ? info.gpsV/1000 : null])
        },
            {
                name: 'Batterie voltage',
                data: data.map(info => [new Date(info.gpsDate),   info.gpsBatteryV !== undefined ? info.gpsBatteryV/1000 : null])
            },
        ]);
    }, [data]);

    return <ReactApexChart id="voltage" series={series} options={options}/>;

}

