import * as actions from "./alarms.actions";
import {Error, GetAlarmEnd, GetAlarmsEnd, SaveEnd} from "./alarms.actions";
import {ofType} from "redux-observable";
import {catchError, map, switchMap} from 'rxjs/operators'
import {from, of} from "rxjs";
import {AutoLogout} from "../auth/auth.actions";
import {deleteAlarm, fetchAlarms, fetchAlarmsType, getAlarm, SaveAlarm} from "../../pages/alarms/alarmsService";
import {NotificationManager} from "react-notifications";

export const FetchAlarmsType = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        const params = {...state$.value.alarmsReducer.filters.params, ...action.payload};
        return from(fetchAlarmsType(params, state$.value.authReducer.token)).pipe(
            map(res => {
                return GetAlarmsEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const SaveAlarmsEpic = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_START),
    switchMap((action) => {
        let { zones, vehicle, group, times } = state$.value.alarmsReducer.addAlarm;
        const alarm = state$.value.alarmsReducer.active._id;
        const config = state$.value.alarmsReducer.addAlarm.data;
        zones = zones || [];
        const vehicles = vehicle ? [vehicle] : [];
        const groups = group ? [group] : [];

        const params = {alarm, config, zones, vehicles, groups, times};
        return from(SaveAlarm(params, state$.value.authReducer.token)).pipe(
            map(res => {
                params.name = params.config.name;
                params.active = true;
                if(state$.value.alarmsReducer.addAlarm.data._id) {
                    params._id = state$.value.alarmsReducer.addAlarm.data._id;
                }
                delete params.config;
                NotificationManager.success('Alarme Créee avec succès', 'Création' , 2000);
                return SaveEnd({data: params, res: res.data});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const FetchAlarmType = (action$, state$) => action$.pipe(
    ofType(actions.GET_ALARM_START),
    switchMap((action) => {
        const id = state$.value.alarmsReducer.active._id;
        const params = {...{id}, ...state$.value.alarmsReducer.alarm.filters.params, ...action.payload};
        return from(fetchAlarms(params, state$.value.authReducer.token)).pipe(
            map(res => {
                return GetAlarmEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const DeleteAlarmEpic = (action$, state$) => action$.pipe(
    ofType(actions.DELETE_START),
    switchMap((action) => {
        return from(deleteAlarm(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('Alarme supprimée avec succès', 'Suppression', 2000);
                return actions.DeleteEnd({response: res.data, id: action.payload});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, 'Suppression', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Suppression', 2000);
        return of(Error('Problem unknow'))
    })
);

export const GetUpdateAlarmEpic = (action$, state$) => action$.pipe(
    ofType(actions.GET_UPDATE_START),
    switchMap((action) => {
        return from(getAlarm(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.GetAlarmUpdateEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, 'Suppression', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Suppression', 2000);
        return of(Error('Problem unknow'))
    })
);

