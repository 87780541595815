import * as actions from "./role.actions";
import {catchError, map, switchMap} from 'rxjs/operators'
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {NotificationManager} from "react-notifications";
import {AddRole, deleteRole, fetchRoles, updateRole} from "../roleService";
import {AutoLogout} from "../../../store/auth/auth.actions";
import {Error} from "../../../store/comapny/company.actions";


export const SaveRoleEpic = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_START),
    switchMap((action) => {
        return from(AddRole({...action.payload, canDelete: state$.value.roleReducer.add.canDelete}, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('Le rôle est créé avec succès', 'Création', 2000);
                return actions.SaveEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), actions.Error(''));
                }
                return of(actions.Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const FetchRolesEpic = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {

        return from(fetchRoles({}, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.GetEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const UpdateRolesEpic = (action$, state$) => action$.pipe(
    ofType(actions.UPDATE_START),
    switchMap((action) => {
        const data = state$.value.roleReducer.roles[action.payload];
        return from(updateRole(data, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('le rôle est modifié avec succès', 'Modification' , 2000);
                return  actions.UpdateEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);


export const DeleteRoleEpic = (action$, state$) => action$.pipe(
    ofType(actions.DELETE_START),
    switchMap((action) => {
        return from(deleteRole(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('le rôle est supprimée avec succès', 'Suppression', 2000);
                return actions.DeleteEnd({response: res.data, _id: action.payload});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, 'Suppression', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Suppression', 2000);
        return of(Error('Problem unknow'))
    })
);
