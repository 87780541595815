import {Button} from "../../../../components/widgets/form/button/button";
import React, {useEffect} from "react";
import {BiMailSend, RiMailAddLine} from "react-icons/all";
import './notification.css'
import {useFieldArray, useForm} from "react-hook-form";
import {resetInput, validateEmail} from "../../../../validation";
import {Checkbox, Button as BTN} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {SaveMaintenanceStart, SetStepData} from "../../../../store/maintenance/maintenance.actions";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";

export const NotificationCompoment = ({id}) => {

    const dispatch = useDispatch();
    const { checkedEmails, emails, step } = useSelector(state => {
        return {
        step: state.maintenanceReducer.addMaintenance.step,
        checkedEmails: state.maintenanceReducer.addMaintenance.data.notification.email.active,
        emails: state.maintenanceReducer.addMaintenance.data.notification.email.items,
        timezone: state.authReducer.user.timezone,
    } });


    const onChangeCheckBox = (e) =>   {
        dispatch(SetStepData({_id: '', data: {notification: {email: {active: e.target.checked, ...emails}}}, step}));
    }
    const back = () => dispatch(SetStepData({data: {}, step: step - 1}));

    const { register, handleSubmit, setError, errors, reset, control  } = useForm(
        {
            // mode: "onChange",
            defaultValues: {
                items: emails
            }
        },
    );
    const { fields, append, remove } = useFieldArray({
        control,
        name: "items"
    });

    useEffect(() => {
            if(checkedEmails === false) {
                resetInput(reset, {});
            }
        }, [checkedEmails, reset]
    );

    useEffect(() => {
        if(checkedEmails === true) {
            append({})
        }}, [append, checkedEmails]
    );

    const onSubmit = async(data) => {
        const emails = data.items ? data.items.map( el => el.email) : null;
        dispatch(SaveMaintenanceStart({id, emails}));
    };

    const hasError = (index) => errors && errors.items && errors.items.length > index && errors.items[index];


    const styleKm = {display: 'flex', flexDirection: 'row', margin: '10px 0px'};
    if(!checkedEmails) {styleKm.opacity = .4} else {styleKm.opacity = 1}

    return(
        <div className="notification-maintenance">
            <form className="form-maintenance" onSubmit={handleSubmit(onSubmit)}>
                <h4>
                    <Checkbox
                        checked={checkedEmails}
                        onChange={onChangeCheckBox}
                    >Notification par email</Checkbox>
                </h4>

                <div style={{display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start', alignItems: 'center', marginTop: '8px'}}>
                    {
                        fields.map(({ id, email }, index) => {
                            return(
                                <div key={id} style={styleKm}>
                                        <div defaultValue={email} style={{margin: '0px 8px 0px 0px', width: '266px'}} className={!hasError(index) ? 'input-form' : 'input-form input-form-error'}>
                                            <input defaultValue={email} name={`items[${index}].email`} placeholder="Email" ref={register(validateEmail(true))} disabled={!checkedEmails} />
                                            <BiMailSend/>
                                            {hasError(index) && <p className="error-input">{errors.items[index].email.message}</p>}
                                        </div>

                                    <div className="remove-email" onClick={() => remove(index)}>
                                        <DeleteOutlined />
                                    </div>

                                </div>
                            )
                        })
                    }
                    <BTN style={{display: "flex", alignItems:'center', justifyContent: 'center',marginTop: '4px'}} type="dashed" onClick={() => append({})}>
                        <RiMailAddLine style={{fontSize: '18px'}}/>
                        <span style={{marginLeft: "8px"}}>Ajouter un nouveau champs Email</span>
                    </BTN>
                </div>

                <div className="actions">
                    <Button type='btn-accent' name='Précédent' press={back}/>
                    <Button type='btn-primary' name='Terminer'/>
                </div>
            </form>
        </div>
    );
}
