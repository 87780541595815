import React, { Component }  from 'react';
import './report-data.css';
import {
    RiFileExcel2Line
} from "react-icons/all";
import {Table, Tooltip} from "antd";
import {PaginatorReport} from "../../pagination/paginator-report/Paginator-report";
import {connect} from "react-redux";
import {ClearReport, VirtualPaginate} from "../../../store/reports/report.actions";
import {exportToCSV} from "../../../utils/parser";

class ReportData extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 300}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 300}px` });
    }

    paginate(virtualSkip, virtualLimit) {
        this.props.dispatch(VirtualPaginate({virtualSkip, virtualLimit}));
    }

    generateExcel() {
        exportToCSV(this.props.report.header, this.props.report.list, 'rapport', this.props.timezone);
    }

    render() {
        const {list, total} = this.props.report;
        const {virtualSkip, virtualLimit} = this.props.report;
        const fullSize = list.length < total;
        return (
            <div style={{height: this.state.height}}>
                <div className="report-data-action">
                    <PaginatorReport
                        limit={virtualLimit} skip={virtualSkip}
                        total={this.props.report.list.length}
                        limitFix={this.props.report.limit}
                        paginate={this.paginate.bind(this)}
                        fullSize={!fullSize}
                    />

                    {
                        fullSize ?
                        <div className="loading-report">
                            <span>Chargement...</span>
                        </div> :
                            <div className="document" onClick={this.generateExcel.bind(this)}>
                                {
                                    /*<Tooltip placement="topRight" title="Pdf">
                                        <AiOutlineFilePdf color="#EC3A1C"/>
                                    </Tooltip>*/
                                }

                                <Tooltip placement="topRight" title="Excel" >
                                    <RiFileExcel2Line color="#316F45"/>
                                </Tooltip>
                            </div>

                    }


                    <button className="clear" onClick={()=>this.props.dispatch(ClearReport())}>
                        Nettoyer
                    </button>
                </div>
                <Table scroll={{ y: window.innerHeight - 170 }}
                       className="table-report"
                       dataSource={this.props.report.list.slice(this.props.report.virtualSkip, this.props.report.virtualLimit)}
                       columns={this.props.report.header}
                       pagination={false} size="small"
                />
            </div>
        );
    }
}

const MapStateToProps = (state) => { return {
    timezone: state.authReducer.user.timezone,
} }
export default connect(MapStateToProps)(ReportData);
