export const GET_START = '[COMMAND] GET_START';
export const GET_END = '[COMMAND] GET_END';
export const ERROR = '[COMMAND] ERROR';

export const TOOGLE_STEP = '[COMMAND] TOOGLE_STEP';
export const TOOGLE_ACTIVE = '[COMMAND] TOOGLE_ACTIVE';
export const TOOGLE_ALL = '[COMMAND] TOOGLE_ALL';

export const SET_STEP_DATA = '[COMMAND] SET_STEP_DATA';
export const SET_STEP = '[COMMAND] SET_STEP';
export const SET_DATE = '[COMMAND] SET_DATE';

export const SAVE_START = '[COMMAND] SAVE_START';
export const SAVE_END = '[COMMAND] SAVE_END';

export const DELETE_START = '[COMMAND] DELETE_START';
export const DELETE_END = '[COMMAND] DELETE_END';


export function SaveCommandStart(payload) {
    return { type: SAVE_START, payload }
}

export function SaveCommandEnd(payload) {
    return { type: SAVE_END, payload }
}

export function GetCommandsStart(payload) {
    return { type: GET_START, payload }
}

export function getCommandsEnd(payload) {
    return { type: GET_END, payload }
}

export function ToogleStep() {
    return { type: TOOGLE_STEP }
}

export function ToogleActive(payload) {
    return { type: TOOGLE_ACTIVE, payload }
}
export function ToogleAll(payload) {
    return { type: TOOGLE_ALL, payload }
}


export function SetStepData(payload) {
    return { type: SET_STEP_DATA, payload }
}



export function deleteCommandStart(payload) {
    return { type: DELETE_START, payload }
}

export function deletecommandEnd(payload) {
    return { type: DELETE_END, payload }
}



export function Error(msg) {
    return { type: ERROR, msg }
}