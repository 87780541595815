import * as actions from "./company.actions";
import {catchError, map, switchMap} from 'rxjs/operators'
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {addCompany, deleteCompany, fetchCompanies, updateCompany} from "../../pages/companies/companyService";
import {NotificationManager} from "react-notifications";
import {AutoLogout} from "../auth/auth.actions";
import {Error} from "../comapny/company.actions";
import {updateAccount, updateAccountCompany} from "../../pages/accounts/accountService";
import {remmoveAuthToCookies} from "../../pages/login/loginService";
import {SwitchCompanyEnd} from "./company.actions";


export const FetchCompaniesEpic = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        const params = {...state$.value.companyReducer.filters.params, ...action.payload};
        return from(fetchCompanies(params, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.getCompaniesEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const SaveCompanyEpic = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_START),
    switchMap((action) => {
        if(!action.payload.googleMaps) {
            delete action.payload.googleMaps;
            delete state$.value.companyReducer.addCompany.data.googleMaps; }
        if(!action.payload.mapBox) {
            delete action.payload.mapBox;
            delete state$.value.companyReducer.addCompany.data.mapBox
        }

        const company = Object.assign(state$.value.companyReducer.addCompany.data, action.payload);
        const active = state$.value.companyReducer.addCompany.data.active ? "active" : "";
        const location = state$.value.companyReducer.addCompany.data.location ? "location" : "";
        company.choices = [active, location];
        delete company.active;
        delete company.location;
        if (company.id) {
            return from(updateCompany(company, state$.value.authReducer.token)).pipe(
                map(res => {
                    NotificationManager.success('l\'entreprise est modifiée avec succès', 'Modification', 2000);
                    return actions.SaveCompanyEnd(company);
                }),
                catchError(err => {
                    let msg = 'une erreur s\'est produite !';
                    if (err.response.status === 401) {
                        return of(AutoLogout(), Error(''));
                    }
                    return of(Error('Problem unknow'))
                })
            )
        }

        delete company.id;
        return from(addCompany(company, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('l\'entreprise est créée avec succès', 'Création', 2000);

                const newCompany = {
                    ...{
                        _id: res.data.insertedId,
                        vehicles: 0,
                        accounts: 0,
                        groups: 0,
                        alarms: 0,
                        zones: 0
                    },
                    ...company
                }

                return actions.SaveCompanyEnd(newCompany);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);


export const DeleteCompanyEpic = (action$, state$) => action$.pipe(
    ofType(actions.DELETE_START),
    switchMap((action) => {
        return from(deleteCompany(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                NotificationManager.success('l\'entreprise est supprimée avec succès', 'Suppression', 2000);
                return actions.deleteCompanyEnd({response: res.data, id: action.payload});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, 'Suppression', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Suppression', 2000);
        return of(Error('Problem unknow'))
    })
);

export const SwitchCompanyEpic = (action$, state$) => action$.pipe(
    ofType(actions.SWITCH_START),
    switchMap((action) => {
        action.payload.filter = {username: state$.value.authReducer.user.username};
        return from(updateAccountCompany(action.payload, state$.value.authReducer.token)).pipe(
            switchMap(res => {
                NotificationManager.success('l\'entreprise est affecté avec succès', 'Affectation', 2000);
                remmoveAuthToCookies();
                return of(AutoLogout(), SwitchCompanyEnd());
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                NotificationManager.error(msg, 'Suppression', 2000);
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        NotificationManager.error('une erreur s\'est produite !', 'Suppression', 2000);
        return of(Error('Problem unknow'))
    })
);

