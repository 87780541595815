import * as commandActions from './command.actions';
import {LIMIT} from "../../env";


const INITIAL_STATE = {
    addCommand: {
        steps:[
            {current: 'Information de base', next: 'Fin d\'abonnement'},
        ],
        step: 0,
        data: {active: true, all: false},
        loading: false
    },
    commands: [],
    total: 0,
    filters: {
        params: {skip: 0, limit: LIMIT, filter: 'all'},
        data: [
            {filter: 'all', name: 'Tous'},
            {filter: 'active', name: 'Activé'},
            {filter: 'deactive', name: 'Désactivé'},
            {filter: 'admin', name: 'Administrateur'},
            {filter: 'user', name: 'Utilisateur'}],
    },
    loading: false,
    commandError: null
}


export function commandReducer(state = INITIAL_STATE, action){
    switch (action.type) {
        case commandActions.GET_START:
            const params = {...state.filters.params, ...action.payload};
            return {
                ...state, loading: true,
                filters: {
                    ...state.filters,
                    params
                }
            }

        case commandActions.GET_END:
            return Object.assign({}, state, {
                commands: action.payload.list,
                total: action.payload.total,
                loading: false
            });


        case commandActions.SAVE_START:
            return {
                ...state,
                addCommand: {
                    ...state.addCommand,
                    loading: true
                }
            };

        case commandActions.SAVE_END:
            const command = action.payload;
            const findCommand = state.commands.findIndex(el => el._id === command.id);

            if(findCommand === -1) {
                state.commands.unshift(command);
            } else {
                state.commands[findCommand] = {...state.commands[findCommand], ...command};
            }
            return {
                ...state,
                addCommand: INITIAL_STATE.addCommand,
                commands: state.commands,
            };

        case commandActions.TOOGLE_STEP:
            if(state.addCommand.step) {
                return {
                    ...state,
                    addCommand: {
                        ...state.addCommand,
                        step: false,
                        data: INITIAL_STATE.addCommand.data
                    }
                }
            }
            return {
                ...state,
                addCommand: {
                    ...state.addCommand,
                    step: 1
                }
            }

        case commandActions.SET_STEP_DATA:
            return {
                ...state,
                addCommand: {
                    ...state.addCommand,
                    step: action.payload.step,
                    data: {
                        ...state.addCommand.data,
                       ...action.payload.data
                    }
                }
            }

        case commandActions.SET_DATE:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    data: {
                        ...state.addCompany.data,
                        subscription: action.payload
                    }
                }
            }

        case commandActions.SET_STEP:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    step: action.payload.step
                }
            }

        case commandActions.TOOGLE_ACTIVE:
            return {
                ...state,
                addCommand: {
                    ...state.addCommand,
                    data: {
                        ...state.addCommand.data,
                        active: action.payload
                    }
                }
            }

        case commandActions.TOOGLE_ALL:
            return {
                ...state,
                addCommand: {
                    ...state.addCommand,
                    data: {
                        ...state.addCommand.data,
                        all: action.payload
                    }
                }
            }

        case commandActions.DELETE_START:
            return {
                ...state,
                loading: true
            }

        case commandActions.DELETE_END:
            const {response, id} = action.payload;
            if(response.deletedCount) {
                const findGroup = state.commands.findIndex(el => el._id === id);
                if(findGroup !== -1) {
                    state.commands.splice(findGroup, 1);
                    return { ...state, loading: false, companies: state.commands }
                }
            }
            return { ...state, loading: false }

        case commandActions.ERROR:
            return Object.assign({}, state, {
                loading: false
            })

        default:
            return state;
    }
}