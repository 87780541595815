import * as driverActions from './drivers.actions';
import {LIMIT} from "../../env";


const INITIAL_STATE = {
    addDriver: {
        steps:[
            {current: 'Information de base', next: 'Fin'},
        ],
        step: 0,
        data: {},
        loading: false
    },
    driver: [],
    total: 0,
    filters: {
        params: {skip: 0, limit: LIMIT}
    },
    loading: false,
    companyError: null
}


export function driversReducer(state = INITIAL_STATE, action){
    switch (action.type) {

        case driverActions.GET_START:
            const params = {...state.filters.params, ...action.payload};
            return {
                ...state, loading: true,
                filters: {
                    ...state.filters,
                    params
                }
            }

        case driverActions.GET_END:
            return Object.assign({}, state, {
                driver: action.payload.list,
                total: action.payload.total,
                loading: false
            });


        case driverActions.SAVE_START:
            return {
                ...state,
                addDriver: {
                    ...state.addDriver,
                    loading: true
                }
            };

        case driverActions.SAVE_END:
            const driver = action.payload;
            const findDriver = state.driver.findIndex(el => el._id === driver.id);

            if(findDriver === -1) {
                state.driver.unshift(driver);
            } else {
                driver._id = driver.id;
                state.driver[findDriver] = driver;
            }
            return {
                ...state,
                addDriver: INITIAL_STATE.addDriver,
                driver: state.driver,
            };

        case driverActions.TOOGLE_STEP:
            if(state.addDriver.step) {
                return {
                    ...state,
                    addDriver: {
                        ...state.addDriver,
                        step: false,
                        data: INITIAL_STATE.addDriver.data
                    }
                }
            }
            return {
                ...state,
                addDriver: {
                    ...state.addDriver,
                    step: 1
                }
            }

        case driverActions.SET_STEP_DATA:
            return {
                ...state,
                addDriver: {
                    ...state.addDriver,
                    step: action.payload.step,
                    data: {
                        ...state.addDriver.data,
                       ...action.payload.data
                    }
                }
            }

        case driverActions.SET_DATE:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    data: {
                        ...state.addCompany.data,
                        subscription: action.payload
                    }
                }
            }

        case driverActions.SET_STEP:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    step: action.payload.step
                }
            }

        case driverActions.TOOGLE_ACTIVE:
            return {
                ...state,
                addCompany: {
                    ...state.addCompany,
                    data: {
                        ...state.addCompany.data,
                        active: action.payload
                    }
                }
            }

        case driverActions.DELETE_START:
            return {
                ...state,
                loading: true
            }

        case driverActions.DELETE_END:
            const {response, id} = action.payload;
            if(response.deletedCount) {
                const findGroup = state.driver.findIndex(el => el._id === id);
                if(findGroup !== -1) {
                    state.driver.splice(findGroup, 1);
                    return { ...state, loading: false, driver: state.driver }
                }
            }
            return { ...state, loading: false }

        case driverActions.ERROR:
            return Object.assign({}, state, {
                loading: false
            })

        default:
            return state;
    }
}