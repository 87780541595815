import React from "react"
import {Steps} from "antd";
import {useSelector} from "react-redux";
import '../generate-report.css';
import Step1Alarm from "../../../reports/add-alarm/step1/step1";
import GroupsPage from "../../../reports/add-alarm/group-choose/groupsPage";
import VehiclePage from "../../../reports/add-alarm/vehicle-choose/vehiclesPage";
import ChooseTimeRange from "./choose-time-range";

export const MainReportStep1 = () => {

    const {timezone, addActive} = useSelector(state => {
        const reports = state.reportsReducer;
        return {
            timezone: state.authReducer.user.timezone,
            addActive: reports.addReport.active,
        };
    });


    switch (true) {
        case addActive === 0:
            return <ChooseTimeRange timezone={timezone} />;
        case addActive === 1:
            return <GroupsPage/>;
        default:
            return <VehiclePage/>;
    }

}
