import {BASE_URL} from "../../env";

const axios = require('axios');


export function fetchDriver(payload, token) {
    let params = '';
    for (let param in payload) {
        params+=`${param}=${payload[param]}&`
    }
    const url = `${BASE_URL}/api/driver?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export async function fetchOneIdentification(payload, token) {

    const url = `${BASE_URL}/api/driver/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }

    try{
        const res = await axios.get(url, config);
        return true;
    }catch (e) {
        return false;
    }

}

export async function addDriver(payload, token) {
    const url = `${BASE_URL}/api/driver`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.post(url, payload, config);
}

export async function updateDriver(payload, token) {
    const url = `${BASE_URL}/api/driver`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    const data = Object.assign({}, payload);
    const id = data.id;
    delete data.id;
    payload = {
        filter: {id},
        update: data
    }
    return axios.patch(url, payload, config);
}



export async function deleteDriver(payload, token) {
    const url = `${BASE_URL}/api/driver/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.delete(url, config);
}
