import React, {Component, createRef} from 'react';
import {connect} from "react-redux";
import {DivIcon} from "leaflet";
import {Marker} from "react-leaflet";
import {
    MdPanTool,
    AiOutlineQuestionCircle,
    IoMdFlashOff,
    IoMdSpeedometer,
    FaCarCrash,
    GiNinjaMask
} from "react-icons/all";

import './event.css';
import {changeView} from "../../../store/map/vehicles/leaflet-provider";
import {getAddress, getAdress} from "../../../components/adress/adress.service";

class EventMap extends Component {

    constructor(props) {
        super(props);


    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        // Show zone
        if (this.props.event && this.props.event !== prevProps.event) {
            const {lat, lng } = this.props.event.position;
            changeView(this.props.mapRef.current, [lat, lng], 18);

        }
    }

    iconEvent(type, label) {
        let html;
        switch (label) {
            case 'inZone':
            case 'outZone':
                html = `<div class="main">
                            <svg class="mat-icon ${label}" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19.07 4.93l-1.41 1.41C19.1 7.79 20 9.79 20 12c0 4.42-3.58 8-8 8s-8-3.58-8-8c0-4.08 3.05-7.44 7-7.93v2.02C8.16 6.57 6 9.03 6 12c0 3.31 2.69 6 6 6s6-2.69 6-6c0-1.66-.67-3.16-1.76-4.24l-1.41 1.41C15.55 9.9 16 10.9 16 12c0 2.21-1.79 4-4 4s-4-1.79-4-4c0-1.86 1.28-3.41 3-3.86v2.14c-.6.35-1 .98-1 1.72 0 1.1.9 2 2 2s2-.9 2-2c0-.74-.4-1.38-1-1.72V2h-1C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-2.76-1.12-5.26-2.93-7.07z"></path></svg>
                            <div class="pin ${type}"></div>
                            <div class="pulse pulse-${type}"></div>
                        </div>`;
                break;
            case 'speed':
                html = `<div class="main">
                            <svg class="mat-icon ${label}" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M128 416h256v48H128zM256 288c17.7 0 32-14.3 32-32s-14.3-32-32-32c-3 0-6 .4-8.8 1.2l-66.7-48.7-4 3.5 48.9 66.7c-.9 2.9-1.4 6-1.4 9.3 0 17.7 14.3 32 32 32z"></path><path d="M256 48C141.1 48 48 141.1 48 256c0 48.3 16.5 92.7 44.1 128h58.8l4-4 22.1-22.1-22.9-22.9-22.1 22c-19.9-24.3-32.1-54-35.2-85H128v-32H96.8c3.1-31 15.3-60.7 35.2-85l22.1 22 22.9-22.9-22-22.1c24.3-19.9 54-32.1 85-35.2V128h32V96.8c31 3.1 60.7 15.3 85 35.2l-22 22.1 22.9 22.9 22.1-22c19.9 24.3 32.1 54 35.2 85H384v32h31.2c-3.1 31-15.3 60.7-35.2 85l-22.1-22-22.9 22.9 22.1 22.1 4 4h58.8c27.6-35.3 44.1-79.7 44.1-128 0-114.9-93.1-208-208-208z"></path></svg>
                            <div class="pin ${type}"></div>
                            <div class="pulse pulse-${type}"></div>
                        </div>`;
                break;
            case 'notAuthorized':
                html = `<div class="main">
                            <svg class="mat-icon ${label}" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M23 5.5V20c0 2.2-1.8 4-4 4h-7.3c-1.08 0-2.1-.43-2.85-1.19L1 14.83s1.26-1.23 1.3-1.25c.22-.19.49-.29.79-.29.22 0 .42.06.6.16.04.01 4.31 2.46 4.31 2.46V4c0-.83.67-1.5 1.5-1.5S11 3.17 11 4v7h1V1.5c0-.83.67-1.5 1.5-1.5S15 .67 15 1.5V11h1V2.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5V11h1V5.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5z"></path></svg>                            <div class="pin ${type}"></div>
                            <div class="pulse pulse-${type}"></div>
                        </div>`;
            case 'noElectricity':
                html = `<div class="main">
                            <svg class="mat-icon ${label}" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M459.9 435.5L76.1 52.5 51.9 76.6 160 184.3V272h64v192l72-144 139.9 139.5zM352 208h-64l64-160H160v40.3l168 167.6z"></path></svg>                            <div class="pin ${type}"></div>
                            <div class="pulse pulse-${type}"></div>
                        </div>`;
                break;
            case 'crash':
                html = `<div class="main">
                            <div class="pulse pulse-${type}"></div>
                        </div>`;
                break;
            case 'towing':
                html = `<div class="main">
                            <div class="pulse pulse-${type}"></div>
                        </div>`;
            case 'jamming':
                html = `<div class="main">
                            <div class="pulse pulse-${type}"></div>
                        </div>`;
            default:
                html = `<div class="main">
                            <div class="pulse pulse-${type}"></div>
                        </div>`;
        }

        return new DivIcon({
            html,
            iconSize: [0, 0],
            iconAnchor: [8, 16],
            popupAnchor: [0, -32]
        });
    }

    render() {
        if(this.props.event){
            const {lat, lng } = this.props.event.position;
            return (
                <Marker
                    icon={this.iconEvent('event-map', this.props.event.type)}
                    position={[lat, lng]}
                />
            );

        }

        return (
            <>

            </>
        );
    }
}

const MapStateToProps = (state) => {
    return {
        event: state.eventsMapReducer.active,
    }
}
export default connect(MapStateToProps)(EventMap);
