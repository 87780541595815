export const GET_START = '[ACCOUNTS] GET_START';
export const GET_END = '[ACCOUNTS] GET_END';
export const ERROR = '[ACCOUNTS] ERROR';

export const TOOGLE_STEP = '[ACCOUNTS] TOOGLE_STEP';
export const TOOGLE_ACTIVE = '[ACCOUNTS] TOOGLE_ACTIVE';

export const SET_STEP_DATA = '[ACCOUNTS] SET_STEP_DATA';
export const SET_STEP = '[ACCOUNTS] SET_STEP';
export const SET_DATE = '[ACCOUNTS] SET_DATE';

export const SET_STEP_UPDATE = '[ACCOUNTS] SET_STEP_PASSWORD';
export const SAVE_PASSWORD_START = '[ACCOUNTS] SAVE_PASSWORD_START';
export const SAVE_PASSWORD_END = '[ACCOUNTS] SAVE_PASSWORD_END';

export const SAVE_START = '[ACCOUNTS] SAVE_START';
export const SAVE_END = '[ACCOUNTS] SAVE_END';

export const DELETE_START = '[ACCOUNTS] DELETE_START';
export const DELETE_END = '[ACCOUNTS] DELETE_END';

export function SaveAccountStart(payload) {
    return { type: SAVE_START, payload }
}

export function SaveAccountEnd(payload) {
    return { type: SAVE_END, payload }
}

export function UpdatePasswordStart(payload) {
    return { type: SAVE_PASSWORD_START, payload }
}

export function UpdatePasswordEnd(payload) {
    return { type: SAVE_PASSWORD_END, payload }
}

export function DeleteAccountStart(payload) {
    return { type: DELETE_START, payload }
}

export function DeleteAccountEnd(payload) {
    return { type: DELETE_END, payload }
}

export function GetAccountsStart(payload) {
    return { type: GET_START, payload }
}

export function ToogleStep() {
    return { type: TOOGLE_STEP }
}

export function ToogleActive(payload) {
    return { type: TOOGLE_ACTIVE, payload }
}

export function SetStepData(payload) {
    return { type: SET_STEP_DATA, payload }
}

export function SetStep(payload) {
    return { type: SET_STEP, payload }
}


export function getAccountsEnd(payload) {
    return { type: GET_END, payload }
}

export function SetStepUpdate(payload) {
    return { type: SET_STEP_UPDATE, payload }
}

export function Error(msg) {
    return { type: ERROR, msg }
}