import React, {Component, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import './step1.css';
import {AiOutlineUser, AiOutlineMail, BiMailSend, GiSandsOfTime, MdTimeline} from "react-icons/all";
import {Button} from "../../../../components/widgets/form/button/button";
import {useForm} from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import {ResetStep, SetStepData} from "../../../../store/alarms/alarms.actions";
import PerfectScrollbar from "react-perfect-scrollbar";



export default function Step1Alarm() {
    const dispatch = useDispatch();
    const {token, active, step, activeStep, data} = useSelector(state => {
        return {
            token: state.authReducer.token,
            active: state.alarmsReducer.active,
            step: state.alarmsReducer.addAlarm.step,
            activeStep: state.alarmsReducer.addAlarm.step,
            data: state.alarmsReducer.addAlarm.data
        }
    });
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setError, errors, watch  } = useForm(
        { mode: "onChange",
            defaultValues: {
                name: data.name || active.name,
                interval: data.interval || active.interval,
                speed: data.speed || active.speed,
                msg: data.msg || active.msg,
                msgIn: data.msgIn || active.msgIn,
                msgOut: data.msgOut ||active.msgOut,
                email: data.email ||active.email,
            }
        },
    );

    const onSubmit = async(data) => {
        dispatch(SetStepData({data, step: step+1}));
    };

    const previous = () => {
        if(activeStep === 1 && step === 1) {
            dispatch(ResetStep());
        } else {
            dispatch(SetStepData({data: {}, step: 1}));
        }
    };

    const styleMsg = !errors.msg ? 'input-form' : 'input-form input-form-error';
    const styleMsgIn = !errors.msgIn ? 'input-form' : 'input-form input-form-error';
    const styleMsgOut = !errors.msgOut ? 'input-form' : 'input-form input-form-error';
    const validateName = {
        required: {value: true, message: 'Ce champs est obligatoire'},
        minLength: {value: 3, message: 'La taille minimum est: 3'},
        maxLength: {value: 35, message: 'La taille maximum est: 20'},
    }

    const getForm = () => {
        if(active.icon === 'track_changes') {
            return (<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div className={styleMsgIn}>
                    <input placeholder="Message entré de la zone"  name="msgIn" ref={register(validateName)}/>

                    <AiOutlineMail/>
                    {errors.msgIn && <p className="error-input">{errors.msgIn.message}</p>}
                </div>
                {
                    <div className={styleMsgOut} style={{marginLeft: '12px'}}>
                        <input placeholder="Message sortie de la zone"  name="msgOut" ref={register(validateName)}/>
                        <AiOutlineMail/>
                        {errors.msgOut && <p className="error-input">{errors.msgOut.message}</p>}
                    </div>
                }

            </div>)
        } else {
            return (
                <div className={styleMsg}>
                    <input
                        name="msg" placeholder="Text du message"
                        ref={register(validateName)}
                    />
                    <AiOutlineMail/>
                    {errors.msg && <p className="error-input">{errors.msg.message}</p>}
                </div>
            );
        }
    }



    const styleName = !errors.name ? 'input-form' : 'input-form input-form-error';

    const validateInterval = {
        required: {value: true, message: 'Ce champs est obligatoire'},
        min: {value: 2, message: 'Minimum est: 2 minutes'},
        max: {value: 1440, message: 'Maximum est: 1440 minutes'},
    }
    const styleInterval = !errors.interval ? 'input-form' : 'input-form input-form-error';

    const validateSpeed = {
        required: {value: true, message: 'Ce champs est obligatoire'},
        min: {value: 50, message: 'Minimum est: 50 km'},
        max: {value: 180, message: 'Maximum est: 180 Km'},
    }
    const styleSpeed = !errors.speed ? 'input-form' : 'input-form input-form-error';

    const validateEmail = {
        required: {
            value: true,
            message: 'Ce champs est obligatoire'
        },
        validate: {
            isEmail: (input) => isEmail(input) || "Ce champs doit être un email : example@provider.com"}
    }
    const styleEmail = !errors.email ? 'input-form' : 'input-form input-form-error';
    const styleType = !errors.name ? 'input-form' : 'input-form input-form-error';

    return(
        <PerfectScrollbar style={{ height: `${window.innerHeight - 210}px`}}>
            <div className="step1-alarm">
                <form className="form-alarm" onSubmit={handleSubmit(onSubmit)}>
                    <h4>Nom de l'alarme</h4>
                    <div className={styleName}>
                        <input
                            name="name" placeholder="Nom  de l'alarme"
                            ref={register(validateName)}
                        />
                        <AiOutlineUser/>
                        {errors.name && <p className="error-input">{errors.name.message}</p>}
                    </div>

                    {
                        active.icon !== 'track_changes' && active.icon !== 'security' && <>
                            <h4>Options de la régle</h4>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <div className={styleInterval}>
                                    <input
                                        placeholder="Intérvale" type="number" min="2" name="interval"
                                        ref={register(validateInterval)}
                                    />
                                    <GiSandsOfTime/>
                                    {errors.interval && <p className="error-input">{errors.interval.message}</p>}
                                </div>
                                {
                                    active.icon === 'speed' && <div className={styleSpeed} style={{marginLeft: '12px'}}>
                                        <input
                                            placeholder="Vitésse" type="number" min="50" name="speed"
                                            ref={register(validateSpeed)}
                                        />
                                        <MdTimeline/>
                                        {errors.speed && <p className="error-input">{errors.speed.message}</p>}
                                    </div>
                                }

                            </div>
                        </>
                    }


                    <h4>Notifications</h4>
                    { getForm() }

                    <h4>Envoie par email</h4>
                    <div className={styleEmail}>
                        <input
                            name="email" placeholder="E-mail" ref={register(validateEmail)}
                        />
                        <BiMailSend/>
                        {errors.email && <p className="error-input">{errors.email.message}</p>}
                    </div>
                    <div className="actions">
                        <Button type='btn-accent'  name='Précédent' press={previous}/>
                        <Button type='btn-primary' name='Suivant'/>
                    </div>
                </form>
                <div className="description">
                    <img src="/img/alarms/notify.svg"/>
                </div>
            </div>
        </PerfectScrollbar>
    )
}
