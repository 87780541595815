import React, { Component }  from 'react';
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {FaRegAddressCard, FiPhone} from "react-icons/all";
import {Popover} from "antd";
import {FiMoreVertical} from "react-icons/fi";
import {ActionPopover} from "../widgets/action-popover/action-popover";

export class DriversInfo extends Component {

    constructor(props) { super(props); }

    componentDidMount() { }

    render() {
        const item = this.props.item;
        const classStyle = item.active ? "card-user-info active-user" : "card-user-info deactive-user";
        const text = <span>Title</span>;
        const content = (
            <>

                <ActionPopover icon={AiOutlineEdit} label="Modifier" press={this.props.press} value={{action: 'edit', id: item._id}}/>
                <ActionPopover icon={AiOutlineDelete} press={this.props.press} label="Supprimer" value={{action: 'del', name: item.name, id: item._id}}/>
            </>
        );
        return (
            <div className={classStyle} style={{height: '7.5em'}}>
                <div className="more-options">
                    <Popover placement="rightTop" content={content} text={text} trigger="click">
                        <FiMoreVertical />
                    </Popover>
                </div>
                {
                    this.props.company._id === item.companyId ?
                        <div className="more-options delete" onClick={() => this.props.press && this.props.press({action: 'del', name: item.identification ,id: item._id})}>
                        <AiOutlineDelete style={{fontSize: '1em'}}/>
                    </div> : <></>
                }


                <div className="main">
                    <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                        <img className="avatar-item" style={{height: '3em'}} src="/img/avatar.svg"/>
                        <h5 className="name">{this.props.item.name}</h5>

                        {
                            this.props.item.identification &&  <div className="identification">
                                <FaRegAddressCard />
                                <label>{this.props.item.identification.toUpperCase()}</label>
                            </div>
                        }
                        {
                            this.props.item.phone &&  <div className="description">
                                <div className="descWidget">
                                    <FiPhone />
                                    <label>{this.props.item.phone}</label>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        );
    }
}