import React, {Component} from "react";
import {connect} from "react-redux";
import './planning-step.css';
import PerfectScrollbar from "react-perfect-scrollbar";
import {Button} from "../../../../components/widgets/form/button/button";
import { Button as AntBtn } from 'antd';
import {SaveStart, SetStepData, SetTimes} from "../../../../store/alarms/alarms.actions";

class PlanningStep extends Component {

    constructor(props) {
        super(props);
        this.state = {height: `${window.innerHeight - 210}px`};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.times = [
            {
                name: 'Lu', data: [
                    {name: '00h', description: '00:00', class: 'cell'},
                    {name: '01h', description: '01:00', class: 'cell'},
                    {name: '02h', description: '02:00', class: 'cell'},
                    {name: '03h', description: '03:00', class: 'cell'},
                    {name: '04h', description: '04:00', class: 'cell'},
                    {name: '05h', description: '05:00', class: 'cell'},
                    {name: '06h', description: '06:00', class: 'cell'},
                    {name: '07h', description: '07:00', class: 'cell'},
                    {name: '08h', description: '08:00', class: 'cell'},
                    {name: '09h', description: '09:00', class: 'cell'},
                    {name: '10h', description: '10:00', class: 'cell'},
                    {name: '11h', description: '11:00', class: 'cell'},
                    {name: '12h', description: '12:00', class: 'cell'},
                    {name: '13h', description: '13:00', class: 'cell'},
                    {name: '14h', description: '14:00', class: 'cell'},
                    {name: '15h', description: '15:00', class: 'cell'},
                    {name: '16h', description: '16:00', class: 'cell'},
                    {name: '17h', description: '17:00', class: 'cell'},
                    {name: '18h', description: '18:00', class: 'cell'},
                    {name: '19h', description: '19:00', class: 'cell'},
                    {name: '20h', description: '20:00', class: 'cell'},
                    {name: '21h', description: '21:00', class: 'cell'},
                    {name: '22h', description: '22:00', class: 'cell'},
                    {name: '23h', description: '23:00', class: 'cell'}
                ]
            },
            {
                name: 'Mar', data: [
                    {name: '00h', description: '00:00', class: 'cell'},
                    {name: '01h', description: '01:00', class: 'cell'},
                    {name: '02h', description: '02:00', class: 'cell'},
                    {name: '03h', description: '03:00', class: 'cell'},
                    {name: '04h', description: '04:00', class: 'cell'},
                    {name: '05h', description: '05:00', class: 'cell'},
                    {name: '06h', description: '06:00', class: 'cell'},
                    {name: '07h', description: '07:00', class: 'cell'},
                    {name: '08h', description: '08:00', class: 'cell'},
                    {name: '09h', description: '09:00', class: 'cell'},
                    {name: '10h', description: '10:00', class: 'cell'},
                    {name: '11h', description: '11:00', class: 'cell'},
                    {name: '12h', description: '12:00', class: 'cell'},
                    {name: '13h', description: '13:00', class: 'cell'},
                    {name: '14h', description: '14:00', class: 'cell'},
                    {name: '15h', description: '15:00', class: 'cell'},
                    {name: '16h', description: '16:00', class: 'cell'},
                    {name: '17h', description: '17:00', class: 'cell'},
                    {name: '18h', description: '18:00', class: 'cell'},
                    {name: '19h', description: '19:00', class: 'cell'},
                    {name: '20h', description: '20:00', class: 'cell'},
                    {name: '21h', description: '21:00', class: 'cell'},
                    {name: '22h', description: '22:00', class: 'cell'},
                    {name: '23h', description: '23:00', class: 'cell'}
                ]
            },
            {
                name: 'Mer', data: [
                    {name: '00h', description: '00:00', class: 'cell'},
                    {name: '01h', description: '01:00', class: 'cell'},
                    {name: '02h', description: '02:00', class: 'cell'},
                    {name: '03h', description: '03:00', class: 'cell'},
                    {name: '04h', description: '04:00', class: 'cell'},
                    {name: '05h', description: '05:00', class: 'cell'},
                    {name: '06h', description: '06:00', class: 'cell'},
                    {name: '07h', description: '07:00', class: 'cell'},
                    {name: '08h', description: '08:00', class: 'cell'},
                    {name: '09h', description: '09:00', class: 'cell'},
                    {name: '10h', description: '10:00', class: 'cell'},
                    {name: '11h', description: '11:00', class: 'cell'},
                    {name: '12h', description: '12:00', class: 'cell'},
                    {name: '13h', description: '13:00', class: 'cell'},
                    {name: '14h', description: '14:00', class: 'cell'},
                    {name: '15h', description: '15:00', class: 'cell'},
                    {name: '16h', description: '16:00', class: 'cell'},
                    {name: '17h', description: '17:00', class: 'cell'},
                    {name: '18h', description: '18:00', class: 'cell'},
                    {name: '19h', description: '19:00', class: 'cell'},
                    {name: '20h', description: '20:00', class: 'cell'},
                    {name: '21h', description: '21:00', class: 'cell'},
                    {name: '22h', description: '22:00', class: 'cell'},
                    {name: '23h', description: '23:00', class: 'cell'}
                ]
            },
            {
                name: 'Je', data: [
                    {name: '00h', description: '00:00', class: 'cell'},
                    {name: '01h', description: '01:00', class: 'cell'},
                    {name: '02h', description: '02:00', class: 'cell'},
                    {name: '03h', description: '03:00', class: 'cell'},
                    {name: '04h', description: '04:00', class: 'cell'},
                    {name: '05h', description: '05:00', class: 'cell'},
                    {name: '06h', description: '06:00', class: 'cell'},
                    {name: '07h', description: '07:00', class: 'cell'},
                    {name: '08h', description: '08:00', class: 'cell'},
                    {name: '09h', description: '09:00', class: 'cell'},
                    {name: '10h', description: '10:00', class: 'cell'},
                    {name: '11h', description: '11:00', class: 'cell'},
                    {name: '12h', description: '12:00', class: 'cell'},
                    {name: '13h', description: '13:00', class: 'cell'},
                    {name: '14h', description: '14:00', class: 'cell'},
                    {name: '15h', description: '15:00', class: 'cell'},
                    {name: '16h', description: '16:00', class: 'cell'},
                    {name: '17h', description: '17:00', class: 'cell'},
                    {name: '18h', description: '18:00', class: 'cell'},
                    {name: '19h', description: '19:00', class: 'cell'},
                    {name: '20h', description: '20:00', class: 'cell'},
                    {name: '21h', description: '21:00', class: 'cell'},
                    {name: '22h', description: '22:00', class: 'cell'},
                    {name: '23h', description: '23:00', class: 'cell'}
                ]
            },
            {
                name: 'Ve', data: [
                    {name: '00h', description: '00:00', class: 'cell'},
                    {name: '01h', description: '01:00', class: 'cell'},
                    {name: '02h', description: '02:00', class: 'cell'},
                    {name: '03h', description: '03:00', class: 'cell'},
                    {name: '04h', description: '04:00', class: 'cell'},
                    {name: '05h', description: '05:00', class: 'cell'},
                    {name: '06h', description: '06:00', class: 'cell'},
                    {name: '07h', description: '07:00', class: 'cell'},
                    {name: '08h', description: '08:00', class: 'cell'},
                    {name: '09h', description: '09:00', class: 'cell'},
                    {name: '10h', description: '10:00', class: 'cell'},
                    {name: '11h', description: '11:00', class: 'cell'},
                    {name: '12h', description: '12:00', class: 'cell'},
                    {name: '13h', description: '13:00', class: 'cell'},
                    {name: '14h', description: '14:00', class: 'cell'},
                    {name: '15h', description: '15:00', class: 'cell'},
                    {name: '16h', description: '16:00', class: 'cell'},
                    {name: '17h', description: '17:00', class: 'cell'},
                    {name: '18h', description: '18:00', class: 'cell'},
                    {name: '19h', description: '19:00', class: 'cell'},
                    {name: '20h', description: '20:00', class: 'cell'},
                    {name: '21h', description: '21:00', class: 'cell'},
                    {name: '22h', description: '22:00', class: 'cell'},
                    {name: '23h', description: '23:00', class: 'cell'}
                ]
            },
            {
                name: 'Sa', data: [
                    {name: '00h', description: '00:00', class: 'cell'},
                    {name: '01h', description: '01:00', class: 'cell'},
                    {name: '02h', description: '02:00', class: 'cell'},
                    {name: '03h', description: '03:00', class: 'cell'},
                    {name: '04h', description: '04:00', class: 'cell'},
                    {name: '05h', description: '05:00', class: 'cell'},
                    {name: '06h', description: '06:00', class: 'cell'},
                    {name: '07h', description: '07:00', class: 'cell'},
                    {name: '08h', description: '08:00', class: 'cell'},
                    {name: '09h', description: '09:00', class: 'cell'},
                    {name: '10h', description: '10:00', class: 'cell'},
                    {name: '11h', description: '11:00', class: 'cell'},
                    {name: '12h', description: '12:00', class: 'cell'},
                    {name: '13h', description: '13:00', class: 'cell'},
                    {name: '14h', description: '14:00', class: 'cell'},
                    {name: '15h', description: '15:00', class: 'cell'},
                    {name: '16h', description: '16:00', class: 'cell'},
                    {name: '17h', description: '17:00', class: 'cell'},
                    {name: '18h', description: '18:00', class: 'cell'},
                    {name: '19h', description: '19:00', class: 'cell'},
                    {name: '20h', description: '20:00', class: 'cell'},
                    {name: '21h', description: '21:00', class: 'cell'},
                    {name: '22h', description: '22:00', class: 'cell'},
                    {name: '23h', description: '23:00', class: 'cell'}
                ]
            },
            {
                name: 'Di', data: [
                    {name: '00h', description: '00:00', class: 'cell'},
                    {name: '01h', description: '01:00', class: 'cell'},
                    {name: '02h', description: '02:00', class: 'cell'},
                    {name: '03h', description: '03:00', class: 'cell'},
                    {name: '04h', description: '04:00', class: 'cell'},
                    {name: '05h', description: '05:00', class: 'cell'},
                    {name: '06h', description: '06:00', class: 'cell'},
                    {name: '07h', description: '07:00', class: 'cell'},
                    {name: '08h', description: '08:00', class: 'cell'},
                    {name: '09h', description: '09:00', class: 'cell'},
                    {name: '10h', description: '10:00', class: 'cell'},
                    {name: '11h', description: '11:00', class: 'cell'},
                    {name: '12h', description: '12:00', class: 'cell'},
                    {name: '13h', description: '13:00', class: 'cell'},
                    {name: '14h', description: '14:00', class: 'cell'},
                    {name: '15h', description: '15:00', class: 'cell'},
                    {name: '16h', description: '16:00', class: 'cell'},
                    {name: '17h', description: '17:00', class: 'cell'},
                    {name: '18h', description: '18:00', class: 'cell'},
                    {name: '19h', description: '19:00', class: 'cell'},
                    {name: '20h', description: '20:00', class: 'cell'},
                    {name: '21h', description: '21:00', class: 'cell'},
                    {name: '22h', description: '22:00', class: 'cell'},
                    {name: '23h', description: '23:00', class: 'cell'}
                ]
            }
        ];
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        const times = this.props.times;
        if (!times) {
            this.always();
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({height: `${window.innerHeight - 210}px`});
    }

    always() {
        const alarmTimes = [
            [
                '00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h',
                '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'
            ],
            [
                '00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h',
                '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'
            ],
            [
                '00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h',
                '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'
            ],
            [
                '00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h',
                '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'
            ],
            [
                '00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h',
                '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'
            ],
            [
                '00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h',
                '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'
            ],
            [
                '00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h',
                '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'
            ],
        ];
        this.props.dispatch(SetTimes(alarmTimes));
    }

    weekend() {
        const alarmTimes = [
            [],
            [],
            [],
            [],
            [],
            [
                '00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h',
                '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'
            ],
            [
                '00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h',
                '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'
            ],
        ];
        this.props.dispatch(SetTimes(alarmTimes));
    }

    worksDay() {
        const alarmTimes = [
            [
                '00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h',
                '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'
            ],
            [
                '00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h',
                '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'
            ],
            [
                '00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h',
                '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'
            ],
            [
                '00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h',
                '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'
            ],
            [
                '00h', '01h', '02h', '03h', '04h', '05h', '06h', '07h', '08h', '09h', '10h', '11h',
                '12h', '13h', '14h', '15h', '16h', '17h', '18h', '19h', '20h', '21h', '22h', '23h'
            ],
            [],
            [],
        ];
        this.props.dispatch(SetTimes(alarmTimes));
    }

    downClickTable(ev) {
        ev.preventDefault();
        this.clicked = true;
    }

    upClickTable(ev) {
        ev.preventDefault();
        this.clicked = false;
    }

    select(ev, day, hour) {
        if (this.clicked && ev.type === 'mouseenter' || ev.type === 'mousedown') {
            const dayHour = this.times[day].data[hour];
            const times = [...this.props.times];
            const i = this.props.times[day].indexOf(dayHour.name);

            if (i === -1) {
                times[day].push(dayHour.name);
            } else {
                times[day].splice(i, 1);
            }
            this.props.dispatch(SetTimes(times));
        }
    }

    cancel() {  this.props.dispatch(SetStepData({step: this.props.step-1, data: {} })); }
    next() {
        if(this.props.active.zone === false) {
            this.props.dispatch(SaveStart());
            return;
        }
        this.props.dispatch(SetStepData({step: this.props.step+1 }));
    }

    render() {

        return (
            <PerfectScrollbar className="plannifier-step" style={{height: this.state.height}}>
                <div className="planifier">
                    <table cellSpacing="0" cellPadding="0" onMouseDown={event => this.downClickTable(event)}
                           onMouseUp={event => this.upClickTable(event)}>
                        <colgroup>
                            <col className="day"></col>
                        </colgroup>
                        <thead>
                        <tr className="fixed-row">
                            <th></th>
                            <th collapse="6" className="hour">00h</th>
                            <th collapse="6" className="hour">01h</th>
                            <th collapse="6" className="hour">02h</th>
                            <th collapse="6" className="hour">03h</th>
                            <th collapse="6" className="hour">04h</th>
                            <th collapse="6" className="hour">05h</th>
                            <th collapse="6" className="hour">06h</th>
                            <th collapse="6" className="hour">07h</th>
                            <th collapse="6" className="hour">08h</th>
                            <th collapse="6" className="hour">09h</th>
                            <th collapse="6" className="hour">10h</th>
                            <th collapse="6" className="hour">11h</th>
                            <th collapse="6" className="hour">12h</th>
                            <th collapse="6" className="hour">13h</th>
                            <th collapse="6" className="hour">14h</th>
                            <th collapse="6" className="hour">15h</th>
                            <th collapse="6" className="hour">16h</th>
                            <th collapse="6" className="hour">17h</th>
                            <th collapse="6" className="hour">18h</th>
                            <th collapse="6" className="hour">19h</th>
                            <th collapse="6" className="hour">20h</th>
                            <th collapse="6" className="hour">21h</th>
                            <th collapse="6" className="hour">22h</th>
                            <th collapse="6" className="hour">23h</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.times.map((day, i) => <tr>
                                <th key={i} className="day">{day.name}</th>
                                {
                                    day.data.map((time, j) => {
                                        const dayTime = this.props.times && this.props.times[i];
                                        const classSelection = dayTime && dayTime.indexOf(time.name) !== -1 ? `${time.class} selected` : time.class;
                                        return <td key={j} className={classSelection}
                                                   onMouseEnter={(event) => this.select(event, i, j)}
                                                   onMouseDown={event => this.select(event, i, j)}></td>
                                    })
                                }
                            </tr>)
                        }

                        </tbody>
                    </table>
                </div>
                <div className="details">
                    <AntBtn type="text" onClick={() => this.always()}>Toujours</AntBtn>
                    <AntBtn type="text" onClick={() => this.worksDay()}>Jours ouvrables</AntBtn>
                    <AntBtn type="text" onClick={() => this.weekend()}>Weekend</AntBtn>
                </div>
                <div className="actions">
                    <Button type='btn-accent' name='Précedent' press={this.cancel.bind(this)}/>
                    <Button type='btn-primary'
                            name={this.props.active.zone === false ? 'Terminer' : 'Suivant'}
                            press={this.next.bind(this)}/>
                </div>
            </PerfectScrollbar>
        )
    }
}

const VehiclesStateToProps = (state) => {
    return {
        times: state.alarmsReducer.addAlarm.times,
        step: state.alarmsReducer.addAlarm.step,
        active: state.alarmsReducer.active
    }
}
export default connect(VehiclesStateToProps)(PlanningStep);