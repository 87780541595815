import React, {useEffect, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useDispatch, useSelector} from "react-redux";
import '../tracer.css';
import {AiOutlineUser, RiCpuLine} from "react-icons/all";
import {Button} from "../../../components/widgets/form/button/button";
import {CreateTracerStart, ToogleManageTracer, UpdateTracerStart} from "../../../store/tracers/tracers.actions";
import {useForm} from "react-hook-form";
import isNumeric from "validator/lib/isNumeric";

export default function AddTracerPage() {
    const dispatch = useDispatch();
    const {loading, data, error} = useSelector(state => {
        return {
            loading: state.tracersReducer.addTracer.loading,
            data: state.tracersReducer.addTracer.data,
            error: state.tracersReducer.error
        }
    });

    const { register, handleSubmit, setError, errors, watch  } = useForm(
        { mode: "onChange",
            defaultValues: {
                _id: data._id,
                name: data.name,
                sim: data.sim,
                uid: data.uid,
            }
        },
    );
    const updateWindowDimensions = () => { setHeight(`${window.innerHeight - 110}px`); }
    window.addEventListener('resize', updateWindowDimensions);

    const [height, setHeight] = useState(`${window.innerHeight - 110}px`);
    const onSubmit = async(data) => {
        if(data._id) {
            console.log("update")
            dispatch(UpdateTracerStart(data));
        } else {
            console.log("insert")
            dispatch(CreateTracerStart(data));
        }

    };

    useEffect(() => {
        updateWindowDimensions();
        return () =>{
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, [height]);

    useEffect(() => {
        if(error === 'exist') {
            setError('uid', {message: 'Cet uid éxiste déjà !', type:'isUnique'});
        }
    }, [error, setError]);



    const validateName = {
        required: {
            value: true,
            message: 'Ce champs est obligatoire'
        },
        minLength: {value: 3, message: 'La taille minimum est: 3'},
        maxLength: {value: 20, message: 'La taille maximum est: 20'},
    }

    const validateSim = {
        required: {
            value: true,
            message: 'Ce champs est obligatoire'
        },
        minLength: {value: 9, message: 'La taille minimum est: 9'},
        maxLength: {value: 20, message: 'La taille maximum est: 20'},
        validate: {
            isNumber: (input) => isNumeric(input) || "Ce champs doit être un nombre"}
    }

    const validateUid = {
        required: {
            value: true,
            message: 'Ce champs est obligatoire'
        },
        minLength: {value: 9, message: 'La taille minimum est: 9'},
        maxLength: {value: 20, message: 'La taille maximum est: 20'},
    }

    const styleName = !errors.name ? 'input-form' : 'input-form input-form-error';
    const styleUid = !errors.uid ? 'input-form' : 'input-form input-form-error';
    const styleSim = !errors.sim ? 'input-form' : 'input-form input-form-error';

    return (
        <PerfectScrollbar style={{ height: height}}>
            <form id="choice-item-alarm" className="form-alarm" onSubmit={handleSubmit(onSubmit)}>
                <div className="header background-color-primary">
                    <div className="main-title">
                        <div className="title">
                            <RiCpuLine/>
                            <label >Ajouter un traceur</label>
                        </div>
                    </div>
                </div>
                <div className="items-alarms">
                    <div className="items-alarms-choices">


                        {
                            !data._id ? (
                                <>
                                    <h4>Nom du traceur</h4>
                                    <div className={styleName}>
                                        <input
                                            name="name" placeholder="Nom du traceur"
                                            ref={register(validateName)}
                                        />
                                        <AiOutlineUser/>
                                        {errors.name && <p className="error-input">{errors.name.message}</p>}
                                    </div>

                                    <h4>UID du traceur</h4>
                                    <div className={styleUid}>
                                    <input
                                        name="uid" placeholder="UID du traceur"
                                        ref={register(validateUid)}
                                    />
                                    <AiOutlineUser/>
                                    {errors.uid && <p className="error-input">{errors.uid.message}</p>}
                                    </div>
                                </> ) :
                                <input
                                    name="_id"
                                    ref={register()}
                                    hidden={true}
                                />
                        }


                        <h4>Sim du traceur</h4>
                        <div className={styleSim} >
                            <input
                                name="sim" placeholder="SIM du traceur"
                                ref={register(validateSim)}
                            />
                            <AiOutlineUser/>
                            {errors.sim && <p className="error-input">{errors.sim.message}</p>}
                        </div>
                    </div>


                    <div className="description">
                        <img src="/img/alarms/choice.svg"/>
                    </div>
                </div>
                <div className="actions">
                    <Button type='btn-accent' name='Annuler' press={()=>dispatch(ToogleManageTracer())}/>
                    <Button type='btn-primary' name='Sauvegarder' loading={loading}/>
                </div>

            </form>
        </PerfectScrollbar>
    );
}
