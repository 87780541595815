import React, {Component} from 'react';
import {connect} from "react-redux";
import D3Timeline from "../../../../components/d3-timeline/timeline";

class TimeLine extends Component {

    constructor(props) { super(props); }


    render() {
        if(!this.props.historyLoading && this.props.trips.length > 0)
            return (<D3Timeline w={window.innerWidth - 660}/>);
        else
            return <></>
    }
}

const MapStateToProps = (state) => {
    return {
        trips: state.historyReducer.trips,
        historyLoading: state.historyReducer.loading,
        active: state.historyReducer.active,
    }
}
export default connect(MapStateToProps)(TimeLine);