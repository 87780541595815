import React from 'react';
import { Polyline as LeafletPolyline } from 'leaflet';
import {Corridor}  from './lib/corridor';
import { LeafletProvider, withLeaflet, MapLayer } from 'react-leaflet';
import './lib/corridor';
import {from} from "rxjs";

class CorridorPolyline extends MapLayer {


    createLeafletElement(props) {
        const el = new Corridor([props.positions], this.getOptions(props));
        this.contextValue = { ...props.leaflet, popupContainer: el };
        return el;
    }

    updateLeafletElement(fromProps, toProps) {
        this.leafletElement.setLatLngs(toProps.positions);
        this.leafletElement.setStyle({color: toProps.color});
        this.leafletElement.setRadius(null, toProps.corridor);

    }

    render() {
        const { children } = this.props;
        return children == null || this.contextValue == null ? null : (
            <LeafletProvider value={this.contextValue}>{children}</LeafletProvider>
        );
    }
}

export default withLeaflet(CorridorPolyline);