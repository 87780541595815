import React, {Component} from 'react';
import './groups.css';
import {FilterAction} from "../../../../components/header/filter-action/filter-action";
import {HeaderAction} from "../../../../components/header/header-action/header-action";
import {AiOutlinePlus, AiOutlineReload, AiOutlineSearch} from "react-icons/ai";
import {SearchAction} from "../../../../components/header/search-action/search-action";
import {connect} from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import {BlankComponent} from "../../../../components/blank/blank";
import {Paginator} from "../../../../components/pagination/paginator/Paginator";
import {Button} from "../../../../components/widgets/form/button/button";
import {GetGroupsStart, ToogleStep} from "../../../../store/group/group.actions";
import {GroupInfoAlarm} from "../../../../components/group/group-info-alarm/group-info-alarm";
import {LoadingComponent} from "../../../../components/loading/loading";
import {ResetStep, SelectGroupAlarm, SetStepData} from "../../../../store/alarms/alarms.actions";


class GroupsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {height: `${window.innerHeight - 213}px`};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(GetGroupsStart({}));
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({height: `${window.innerHeight - 213}px`});
    }

    groupAction(action) {
        switch (action.action) {
            case 'reload':
                this.props.dispatch(GetGroupsStart({}));
                break;
            case 'select':
                this.props.dispatch(SelectGroupAlarm(action.value));
                break;
        }
    }


    filterAction(action) {

        switch (action.key) {
            case 'name':
                this.props.dispatch(GetGroupsStart({
                    name: action.value,
                    skip: 0
                }))
                break;

            case 'filter':
                this.props.dispatch(GetGroupsStart({
                    filter: action.value,
                    skip: 0
                }))
                break;
        }
        ;
    }

    paginate(skip, limit) {
        this.props.dispatch(GetGroupsStart({skip: skip}));
    }

    cancel() {  this.props.dispatch(ResetStep()); }
    next() {   this.props.dispatch(SetStepData({step: this.props.step+1 }));}

    render() {
        const filters = this.props.filters.data;
        const params = this.props.filters.params;
        const groupsId = this.props.selected;
        return (
            <PerfectScrollbar style={{height: this.state.height}}>
                <LoadingComponent loading={this.props.loading}/>
                <section id="zones-step">
                    <div className="header-section">
                        <div className="filter">
                            {
                                filters && filters.map((el, i) => {
                                    return <FilterAction
                                        key={i}
                                        active={el.filter === params.filter}
                                        label={el.name}
                                        value={el.filter}
                                        press={this.filterAction.bind(this)}
                                    />
                                })
                            }
                        </div>
                        <div className="action-right">
                            <HeaderAction
                                value={{action: 'reload'}}
                                press={this.groupAction.bind(this)}
                                icon={AiOutlineReload}
                            />
                            <SearchAction
                                icon={AiOutlineSearch}
                                indentifier="name"
                                onChange={this.filterAction.bind(this)}
                            />
                        </div>

                    </div>
                    <div className="groups-list">

                        <div className="body">
                            {
                                this.props.groups.length > 0 ? this.props.groups.map((group, i) => {
                                    return <GroupInfoAlarm
                                        active={this.props.group === group._id}
                                        key={i}
                                        group={group}
                                        press={this.groupAction.bind(this)}
                                    />
                                }) : !this.props.loading && <BlankComponent/>
                            }
                        </div>
                        <Paginator paginate={this.paginate.bind(this)} limit={params.limit} skip={params.skip}
                                   total={this.props.total}/>


                        <div className="actions">
                            <Button type='btn-accent' name='Annuler' press={this.cancel.bind(this)}/>
                            <Button type='btn-primary' name='Suivant' press={this.next.bind(this)} disabled={!this.props.group}/>
                        </div>
                    </div>
                </section>
            </PerfectScrollbar>

        );
    }
}

const groupsStateToProps = (state) => {
    return {
        groups: state.groupReducer.groups,
        filters: state.groupReducer.filters,
        loading: state.groupReducer.loading,
        total: state.groupReducer.total,

        group: state.alarmsReducer.addAlarm.group,
        step: state.alarmsReducer.addAlarm.step,

    }
}
export default connect(groupsStateToProps)(GroupsPage);