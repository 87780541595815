import React, {PureComponent, useEffect, useState} from "react";
import './event-details.css'
import {AiOutlineQuestionCircle} from "react-icons/all";

import {getData} from "../../../utils/parser";
import moment from "moment-timezone";
import {getAddress} from "../../adress/adress.service";


export const EventDetails = ({timezone, event, press}) => {
    const {name, zone, vehicle, date, max, speed, position} = getData(event);
    const [address, setAddress] = useState('...');

    useEffect(() => {
        const {lat, lng } = position;
        getAddress(lng, lat).then(res => setAddress(res));
    }, [position]);

    const close = () =>{ if(press) { press(false); } }
    return(
        <div  className="event-details">
            <div  className="details">
                <div className="header">
                    <label className="title">{vehicle} : {name}
                        { zone ? <span className="zone">{zone}</span> : <></> }
                    </label>
                    <br/>
                    <label className="close" onClick={close}>Fermer</label>
                </div>
                {
                    speed && max && <div className="speed-group">
                        <span className="maxSpeed">Max vitesse: {max} km/h</span>
                        <span className="speed">Vitesse: {speed} km/h</span>
                    </div>
                }

                {
                    <label className="address">{address}</label>
                }
                <div className="position">
                    <span className="time">{moment(date).tz(timezone).format('DD/MM/YYYY HH:mm:ss')}</span>
                    <span className="latlng">{position.lat}, {position.lng}</span>
                </div>
            </div>
        </div>
    );
}
