import React, { Component }  from 'react';
import './statNumber.css';
import CountUp from "react-countup";

export class StatNumber extends Component {
    render() {
        return (
            <div className="statNumber">
                <img className="speed" src={this.props.img}/>
                <div className="details">
                    <span className="title">{this.props.name}</span>
                    <div className="values">
                        <CountUp delay={1} end={this.props.value} duration={4}/>
                        { this.props.unit && <span style={{fontSize: '12px', marginLeft: '3px'}}>{this.props.unit}</span>}
                    </div>
                </div>
            </div>
        );
    }
}