import * as actions from "./admin.actions";
import {catchError, map, switchMap} from 'rxjs/operators'
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {AutoLogout} from "../auth/auth.actions";
import {Error} from "../vehicles/vehicles.actions";
import {notification} from "antd";
import {deleteIo, getIOActive} from "../../pages/admin/io/ioService";


const openNotificationWithIcon = (type, msg) => {
    notification[type]({
        message: "Succès",
        description:
            msg,
    });
};

export const FetchIOSEpic = (action$, state$) => action$.pipe(
    ofType(actions.GET_START_IOS),
    switchMap((action) => {
        return from(getIOActive(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                return actions.GetEndIOS(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const DeleteIOSEpic = (action$, state$) => action$.pipe(
    ofType(actions.REMOVE_START_IO),
    switchMap((action) => {
        const {vehicleId, io} = action.payload;
        return from(deleteIo(vehicleId, io, state$.value.authReducer.token)).pipe(
            map(res => {
                openNotificationWithIcon('success', 'Capteur supprimé')
                return actions.RemoveEndIO({res: res.data, io});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);