import React, {Component} from "react";
import './io.css'
import {Button} from "../widgets/form/button/button";
import {LoadingComponent} from "../loading/loading";
import {CircleProgressBar} from "../chart/circles/circle-progress-bar/circleProgressBar";
import {Step1} from "./step1/step1";
import {Step2} from "./step2/step2";
import Coeff from "./step3/coeff";
import Percent from "./step3/percentage";
import {addSensorIOVehicle, getIOVehicle} from "../../pages/vehicles/vehicleService";

export class AddIO extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disable: true,
            loading: false,
            steps: [
                {current: 'Choix du type du capteur', next: 'Choix du capteur'},
                {current: 'Choix du capteur', next: 'Calcule'},
                {current: 'Calcule', next: 'Fin'},
            ],
            step: 0,
            data: {}
        };
    }

    onClose() {
        const {step, data}= this.state;
        if(step === 0) {
            this.props.close && this.props.close(); return;
        } else if(step === 2) {
            delete data.coeff;
            this.setState({step: step-1, data});
            return;
        }
        this.setState({step: step-1});
    }

    async chooseIO() {
        const {step, data}= this.state;
        if(step <2) {
            this.setState({step: step+1});
        } else {
            this.setState({loading: true});
            const {vehicleId} = this.props;
            const {sensor, coeff, full, group} = data;
            const payload = {id: sensor.key, io: group.key};
            if(coeff) { payload.coeff = coeff; }
            else if(full) { payload.full = full; }
            const updated = await addSensorIOVehicle(vehicleId, sensor._id, payload, this.props.token);
            if(updated.status === 200) {
                this.props.close && this.props.close(); return;
            }
            this.setState({loading: false});

        }
    }

    step() {
        const {step, data}= this.state;
        switch (step) {
            case 0: {
                return <Step1
                    vehicleId={this.props.vehicleId}
                    token={this.props.token}
                    select={this.select.bind(this)}
                    selected={data.group}
                />
            }


            case 1: {
                return <Step2
                    vehicleId={this.props.vehicleId}
                    token={this.props.token}
                    select={this.select.bind(this)}
                    groupId={data.group._id}
                    selected={data.sensor}
                />
            }

            case 2: {
                if(data.sensor.unit==='coeff') {
                    return <Coeff
                        select={this.select.bind(this)}
                    />
                } else if(data.sensor.unit==='percent') {
                    return <Percent
                        select={this.select.bind(this)}
                        placeHolder={'Plein résérvoir carburant en Litre'}
                    />
                }

            }

        }

    }

    canPass() {
        const {step, data}= this.state;
        switch (step) {
            case 0:
                return(data && data.group && data.group._id);
            case 1:
                return(data && data.sensor && data.sensor._id);
            case 2:
                return (data &&
                    ((data.coeff && data.sensor.unit === 'coeff') || (data.full && data.sensor.unit === 'percent') || (!data.coeff && !data.full ))
                );
        }
    }

    select(action) {
        const {data}= this.state;
        switch (action.key) {
            case 'group':{
                const {value} = action;
                this.setState({
                    data: {...data, ...{group: value}}
                });
                break;
            }
            case 'sensor':{
                const {value} = action;
                this.setState({
                    data: {...data, ...{sensor: value}}
                });
                break;
            }

            case 'coeff':{
                const {value} = action;
                this.setState({
                    data: {...data, ...{coeff: value}}
                });
                break;
            }

            case 'full':{
                const {value} = action;
                this.setState({
                    data: {...data, ...{full: value}}
                });
                break;
            }
          }
    }

    render() {
        const {steps, step, loading} = this.state;
        return (
            <div className="confirmation">
                <LoadingComponent loading={loading}/>
                <div className="header-action-sideBar">
                    <div className="percent">
                        <CircleProgressBar size={70} percent={(step+1) / 3 * 100} color="primary">
                            <label className="info">{step+1} de 3</label>
                        </CircleProgressBar>
                        <div className="details-info">
                            <label>{steps[step].current}</label>
                            <label>Suivant: {steps[step].next}</label>
                        </div>
                    </div>
                </div>
                <div className="content" style={{fontWeight: 'lighter'}}>
                    <div className="items-accessory-choices">
                        {this.step()}
                    </div>
                </div>
                <div className="footer">
                    <Button name="Précédent" type="btn-accent" press={this.onClose.bind(this)}/>
                    <Button name={step !== 2 ? 'Suivant' : 'Terminer'} press={this.chooseIO.bind(this)}
                            disabled={!this.canPass()}
                            type="btn-primary"/>
                </div>
            </div>
        );
    }
}
