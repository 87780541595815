import React, {Component, createRef} from 'react';
import {connect} from "react-redux";

import L from "leaflet";
import 'polyline-encoded';
import 'leaflet-editable'
import {Polyline, Circle, Polygon, FeatureGroup, GeoJSON} from "react-leaflet";
import {fitBoundsPositions} from "../../../../store/map/vehicles/leaflet-provider";
import {EditControl} from "react-leaflet-draw";
import {FaCircle, FaDrawPolygon} from "react-icons/all";
import {StartZoneDraw} from "../../../../store/zones/zones.actions";
import CorridorPolyline from "../../../../components/leaflet/corridor-polyline";

class EditZone extends Component {

    constructor(props) {
        super(props);
        this.geoRef = createRef();
        this.featureRef = createRef();

        this.state = {
            rectangle: false,
            circlemarker: false,
            marker: false,
            polyline: true,
            circle: true,
            polygon: true,
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const zoneTmp = this.props.zone;

        if(zoneTmp.type !== prevProps.zone.type) {
            const isEdit = this.props.step === 1 && this.props.steps.length === 1;
            switch (zoneTmp.type) {
                case undefined:
                    break;
                case 'Point':
                case 'POI':
                    if(isEdit) {
                        this.setState({polyline: false, circle: false, polygon: false});
                    } else {
                        this.setState({polyline: false, circle: true, polygon: false});                    }

                    break;
                case 'LineString':
                    if(isEdit) {
                        this.editZone();
                        this.setState({polyline: false, circle: false, polygon: false});
                    } else {
                        this.setState({polyline: true, circle: false, polygon: false});
                    }
                    break;
                case 'Polygon':
                    if(isEdit) {
                        this.editZone();
                        this.setState({polyline: false, circle: false, polygon: false});
                    } else {
                        this.setState({polyline: false, circle: false, polygon: true});
                    }
                    break;
            }
        }
    }


    getCorridor(isEdit) {
        const zone = this.props.zone;
        if(isEdit && zone && zone.type === 'LineString') {
            const positions = zone.zone.geometry.coordinates.map(el => [el[1], el[0]]);
            return <CorridorPolyline
                positions={positions}
                className={'corridor'}
                color={zone.color} opacity={0.7} corridor={zone.radius}
            />
        }

    }
    editCircleZone() {
        const zone = this.props.zone;
        if(zone && zone.location) {
            const coordinates = zone.location.coordinates;
            return <Circle center={[coordinates[1], coordinates[0]]} radius={zone.radius}
                                  color={zone.color}/>;
        }
    }
    editZone(){
        const zone = this.props.zone;
        if(zone && zone.location) {
            const data = {type: 'Feature', properties: {}, geometry: zone.location};
            const geoJson = new L.GeoJSON(data, {color: zone.color});
            const layer = geoJson.getLayers()[0];
            fitBoundsPositions(this.props.mapRef.current, layer.getBounds());
            this.featureRef.current.leafletElement.addLayer(layer);
        }
    }

    editControlInit(drawControl){
        this.drawControl = drawControl;
        const zone = this.props.zone;
        const isEdit = this.props.step === 1 && this.props.steps.length === 1;
        if(!isEdit) {
            switch (zone.type) {
                case 'Point':
                case 'POI':
                    this.drawControl._toolbars.draw._modes.circle.handler._initialLabelText = 'Cliquer et glissez pour déssiner le cercle.';
                    this.drawControl._toolbars.draw._modes.circle.handler.enable();
                    break;
                case 'LineString':
                    this.drawControl._toolbars.draw._modes.polyline.handler.enable();
                    break;
                case 'Polygon':
                    this.drawControl._toolbars.draw._modes.polygon.handler.enable();
                    break;
            }
        }

    }


    created(e) {
        this.setState({polyline: false, circle: false, polygon: false});
        const zone = {zone: e.layer.toGeoJSON()};
        if(e.layer._mRadius) { zone.radius = Math.round(e.layer._mRadius); }
        this.props.dispatch(StartZoneDraw(zone));
    }

    edited(e) {
        this.setState({polyline: false, circle: false, polygon: false});
        const zone = {zone: e.layers.toGeoJSON().features[0]};
        const hasLayer = Object.keys(e.layers._layers).length > 0;
        if(hasLayer){
            const radius = Math.round(e.layers._layers[Object.keys(e.layers._layers)]._mRadius);
            if(radius) { zone.radius = radius; }
        }
        this.props.dispatch(StartZoneDraw(zone));
    }

    deleted(e) {
        const zone = this.props.zone;
        switch (zone.type) {
            case 'Point':
            case 'POI':
                this.setState({polyline: false, circle: true, polygon: false});
                break;
            case 'LineString':
                this.setState({polyline: true, circle: false, polygon: false});
                break;
            case 'Polygon':
                this.setState({polyline: false, circle: false, polygon: true});
                break;
        }
        this.props.dispatch(StartZoneDraw({zone: false, radius: 2}));
    }

    render() {
        const isEdit = this.props.step === 1 && this.props.steps.length === 1;
        if(this.props.step === 2 || isEdit) {
            return (
                <>
                    <FeatureGroup ref={this.featureRef}>
                        <EditControl
                            onMounted={this.editControlInit.bind(this)}
                            cancel={false}
                            edit={{
                                edit: {   // this property shouldn't be needed
                                    selectedPathOptions: { // this property should be one level up
                                        color: this.props.zone.color,
                                        fillColor: this.props.zone.color
                                    }
                                }
                            }}

                            position='topright'
                            draw={this.state}
                            onCreated={this.created.bind(this)}
                            onEdited={this.edited.bind(this)}
                            onDeleted={this.deleted.bind(this)}
                        />
                        {
                            isEdit && this.props.zone && this.props.zone.type === 'Point' && this.editCircleZone()
                        }
                    </FeatureGroup>
                    { this.getCorridor(isEdit)}
                </>

            );
        }
        return <></>
    }
}

const MapStateToProps = (state) => {
    return {
        zone: state.zonesReducer.addZone.data,
        step: state.zonesReducer.addZone.step,
        steps: state.zonesReducer.addZone.steps,
    }
}
export default connect(MapStateToProps)(EditZone);

