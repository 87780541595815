import React, {PureComponent} from "react";
import {NavLink} from "react-router-dom";
import './aisdeMenu.css';
import {Logo} from "../components/logo/logo";
import {BsMap, BsFileEarmarkText} from "react-icons/bs";
import {IoIosNotificationsOutline} from "react-icons/io";
import {FiUsers} from "react-icons/fi";
import {BiBox} from "react-icons/bi"
import {AiOutlineSend, AiOutlineCar} from "react-icons/ai";
import {GiCaptainHatProfile} from "react-icons/gi";
import {MdTrackChanges} from "react-icons/all";
import {canShowUser} from "../common/users-role";
import {useSelector} from "react-redux";
import {canShowGrp} from "../common/groups-role";
import {canShowDriver} from "../common/driver-role";
import {canShowVehicle} from "../common/vehicle-role";
import {canShowZone} from "../common/zone-poi-role";
import {canShowAlarm} from "../common/alarm-role";
import {canShowReport} from "../common/report-role";

export const AsideMenu = (props) => {
    const {sizeMenu, active, user} = props;
    const {type, role} = useSelector(state => {
        const { user } = state.authReducer;
        return {
            type: user.type,
            role: user?.role
        };
    });
    return (
        <aside className={`${sizeMenu}`}>
            <Logo
                hideText={sizeMenu === 'full'}
                logo={"/img/logo-white.svg"}
                primary={"Telematics"}
                secondary={"Services"}
            />
            <img
                className={`menuActive ${active}`}
                src="/img/corner.svg"
            />
            <div className="menu">
                {
                    /*
                    * <NavLink activeClassName='active' to="/dashboard" className="item">
                        <GoGraph className="icon"/>
                        { this.props.sizeMenu === 'full' && <span>Tableau de bord</span> }
                    </NavLink>*/
                }

                <NavLink activeClassName='active'  to="/map" className="item">
                    <BsMap className="icon"/>
                    { sizeMenu === 'full' && <span>Carte</span> }
                </NavLink>

                {
                    canShowZone(type, role) && <NavLink activeClassName='active'  to="/zones" className="item">
                        <MdTrackChanges className="icon"/>
                        { sizeMenu === 'full' && <span>Zones</span> }
                    </NavLink>
                }

                {
                    canShowReport(type, role) && <NavLink activeClassName='active'  to="/reports" className="item">
                        <BsFileEarmarkText className="icon"/>
                        { sizeMenu === 'full' && <span>Rapports</span> }
                    </NavLink>
                }


                {
                    canShowAlarm(type, role) && <NavLink activeClassName='active'  to="/alarms" className="item">
                        <IoIosNotificationsOutline className="icon"/>
                        { sizeMenu === 'full' && <span>Alarmes</span> }
                    </NavLink>
                }

                {
                    /*<NavLink activeClassName='active'  to="/alarms" className="item">
                        <SiOpenstreetmap className="icon"/>
                        { this.props.sizeMenu === 'full' && <span>Trajets</span> }
                    </NavLink>*/
                }
            </div>
            <>
                    <div className="divier"/>
                    <div className="menuAdmin">
                        {
                            canShowUser(type, role) && <NavLink activeClassName='active'  to="/users" className="item">
                                <FiUsers className="icon"/>
                                { sizeMenu === 'full' && <span>Utilisateurs</span> }
                            </NavLink>
                        }

                        {
                            canShowGrp(type, role) && <NavLink activeClassName='active'  to="/groups" className="item">
                                <BiBox className="icon"/>
                                { sizeMenu === 'full' && <span>Groupes</span> }
                            </NavLink>
                        }

                        {
                            canShowDriver(type, role) && <NavLink activeClassName='active'  to="/drivers" className="item">
                                <GiCaptainHatProfile className="icon"/>
                                { sizeMenu === 'full' && <span>Conducteurs</span> }
                            </NavLink>
                        }
                        {
                            canShowVehicle(type, role) && <NavLink activeClassName='active'  to="/vehicles" className="item">
                                <AiOutlineCar className="icon"/>
                                { sizeMenu === 'full' && <span>Véhicules</span> }
                            </NavLink>
                        }
                        {
                            type === 'admin' && <NavLink activeClassName='active'  to="/commands" className="item">
                                <AiOutlineSend className="icon"/>
                                { sizeMenu === 'full' && <span>Commandes</span> }
                            </NavLink>
                        }

                    </div>
                </>
        </aside>
    );
}
