import {BASE_URL} from "../../env";

const axios = require('axios');

export function fetchVehicles(payload, token) {
    let params = '';
    for (let param in payload) {
        params+=`${param}=${payload[param]}&`
    }
    const url = `${BASE_URL}/api/vehicle/all?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export function fetchAccessory(payload, token) {
    const url = `${BASE_URL}/api/vehicle/${payload}/accessory`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export async function fetchTracerByUid(uid, token) {
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    const url =`${BASE_URL}/api/vehicle/tracer/${uid}`;
    try{
        const res = await axios.get(url, config);
        if(res.data) {
            return {
                for: 'tracer',
                error: true,
                msg: 'Traceur déjà utilisé !'
            }
        }
        return { for: 'tracer', error: false };
    }catch (e) {
        return {
            for: 'tracer',
            error: true,
            msg: 'Traceur inéxistant !'
        }
    }
}

export async function fetchGroupByUName(name, token) {
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    const url =`${BASE_URL}/api/group/${name}`;
    try{
        const res = await axios.get(url, config);
        if(res.data) {
            return {
                for: 'group',
                error: false
            }
        }
        return {
            for: 'group',
            error: true,
            msg: 'Groupe inéxistant !'
        }
    }catch (e) {
        return {
            for: 'group',
            error: true,
            msg: 'Groupe inéxistant !'
        }
    }
}

export async function addVehicle(payload, token) {
    const url = `${BASE_URL}/api/vehicle`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.post(url, payload, config);
}

export async function updateVehicle(payload, token, path) {
    const url = `${BASE_URL}/api/vehicle${path}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.patch(url, payload, config);
}

export async function deleteVehicle(payload, token) {
    const url = `${BASE_URL}/api/vehicle/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.delete(url, config);
}

export async function getCommandVehicle(payload, token) {
    const url = `${BASE_URL}/api/vehicle/${payload}/commands`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export async function getSensorVehicle(payload, token) {
    const url = `${BASE_URL}/api/tracer_grp/sensors/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export async function getIOVehicle(payload, token) {
    const url = `${BASE_URL}/api/tracer_grp/io/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export async function addSensorIOVehicle(vehicleId, sensorId, payload, token) {
    const url = `${BASE_URL}/api/vehicle/${vehicleId}/io/${sensorId}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.patch(url, payload, config);
}

export async function sensorByGroup(payload, token) {
    const url = `${BASE_URL}/api/tracer_grp/${payload.group}/vehicle/${payload.vehicleId}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export async function manageCommandVehicle(payload, token) {
    const url = `${BASE_URL}/api/vehicle/commands`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.patch(url, payload, config);
}

export async function addAccessory(_id, payload, token) {
    const url = `${BASE_URL}/api/vehicle/${_id}/accessory`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.post(url, payload, config);
}




/**========== DETAILS ==========**/
export async function fetchSpeed(payload, token) {
    const params = `?from=${payload.from.getTime()}&to=${payload.to.getTime()}`;
    const url = `${BASE_URL}/api/vehicle/${payload._id}/details/speed${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export async function fetchSpeeds(payload, token) {
    const params = `?from=${payload.from.getTime()}&to=${payload.to.getTime()}`;
    const url = `${BASE_URL}/api/vehicle/${payload._id}/details/speeds${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export async function fetchKm(payload, token) {
    const params = `?from=${payload.from.getTime()}&to=${payload.to.getTime()}`;
    const url = `${BASE_URL}/api/vehicle/${payload._id}/details/km${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);

}
export async function fetchCountAlarms(payload, token) {
    const params = `?from=${payload.from.getTime()}&to=${payload.to.getTime()}`;
    const url = `${BASE_URL}/api/vehicle/${payload._id}/details/alarm${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export async function fetchCountTrips(payload, token) {
    const params = `?from=${payload.from.getTime()}&to=${payload.to.getTime()}`;
    const url = `${BASE_URL}/api/vehicle/${payload._id}/details/trip${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}
