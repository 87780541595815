import React, { Component }  from 'react';
import './on-traject.css';
import 'swiper/swiper.scss';
import {
    AiOutlineCar, BiGasPump, FaGasPump, GiButtonFinger, GiCaptainHatProfile,
    GiPathDistance,
    IoMdSpeedometer,
    MdTimer, RiRoadsterLine
} from "react-icons/all";
import moment from "moment-timezone";
import {Tooltip} from "antd";



export class OnTraject extends Component {

    constructor(props) { super(props); }

    pointing() {
            const {pointing} = this.props.data;
            const pointings = [];
            if(pointing && pointing.length > 0) {
                for(let i=0;i<pointing.length;i++) {
                    const {name, dateTime} = pointing[i];
                    pointings.push(
                        <Tooltip placement="right" title={`Pointage: ${dateTime}`}>
                            <div className="history-timeline-data-info">
                                <GiButtonFinger/>
                                <label>{ name }</label>
                            </div>
                        </Tooltip>

                    );
                }
            }
            return pointings;
        }

    render() {
        const {data, active, index, onPress} = this.props;
        const diff = moment.utc(data.duration ?? 0);

        const classItem = active === index ? `history-timeline-item history-timeline-item-active id${index}` : `history-timeline-item id${index}`;
        const item = data.idling ? 'history-timeline-time history-timeline-time-on idling' : 'history-timeline-time history-timeline-time-on';
        return (
            <div className={classItem}  onClick={() => onPress(index, data, true)}>
                <div className={item}>
                    <label>{data.start.format('HH')}:{data.start.format('mm')}</label>
                    <AiOutlineCar/>
                </div>
                <div className="history-timeline-line"/>
                <div className="history-timeline-data">
                    <label className="type">En marche</label>
                    <div className="history-timeline-data-info first">
                        <MdTimer/>
                        <label>{diff.format("HH")}h {diff.format("mm")}min {diff.format("ss")}sec</label>
                    </div>
                    {
                        data.idling ? <div className="history-timeline-data-info">
                            <RiRoadsterLine/>
                            <label>{data.idling}</label>
                        </div> : <></>
                    }

                    <div className="history-timeline-data-info">
                        <GiPathDistance/>
                        <label>{data.km ? data.km.toFixed(2) : 0} km</label>
                    </div>
                    {
                        data.fuel && data.fuel !== 'NaN' ?  <div className="history-timeline-data-info">
                                <FaGasPump/>
                                <label>{data.fuel.toFixed(2) } L</label>
                            </div>
                            :<></>
                    }

                    <div className="history-timeline-data-info">
                        <IoMdSpeedometer/>
                        <label>{data.avgSpeed ? data.avgSpeed.toFixed(2) : 0} km/h</label>
                    </div>
                    { this.pointing()}
                    {
                        data.driver ?  <div className="history-timeline-data-info">
                                <GiCaptainHatProfile/>
                                <label>{data.driver.name }</label>
                            </div>
                            :<></>
                    }

                </div>
            </div>
        );
    }
}
