import React, {Component, useState} from 'react';
import './step1.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useForm } from "react-hook-form";
import {BsBuilding} from "react-icons/bs";
import {FiPhone} from "react-icons/fi";
import {AiOutlineMail, AiOutlineHome} from "react-icons/ai";
import {Button} from "../../../../components/widgets/form/button/button";
import isEmail from "validator/lib/isEmail";
import {fetchCompanyByName} from "../../companyService";
import {useDispatch, useSelector} from "react-redux";
import {
    SetStep,
    SetStepData,
    ToogleActive,
    ToogleLocation,
    ToogleStep
} from "../../../../store/comapny/company.actions";
import {Checkbox} from "../../../../components/widgets/form/checkbox/checkbox";
import isNumeric from "validator/lib/isNumeric";

export default function Step1(props) {
    const dispatch = useDispatch();
    const token = useSelector(state => state.authReducer.token);
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setError, errors, formState  } = useForm(
        { mode: "onChange",
            defaultValues: {
                id: props.data.id,
                name: props.data.name,
                email: props.data.email,
                phone: props.data.phone,
                address: props.data.address
            }
        },
    );

    const onSubmit = async(data) => {
        if(!data.id){
            setLoading(true);
            const exist = await fetchCompanyByName(data.name, token);
            if(exist) {
                setError('name', {message: `le nom de l'entreprise doit être unique`, type:'isUnique'});
            } else {
                dispatch(SetStepData({step: 3, data}));
            }
            setLoading(false);
        } else {
            dispatch(SetStepData({step: 3, data}));
        }

    };

    const previous = () => {
        dispatch(SetStep({step: 1}));
    };

    const toogleCheckbox = (value) => {
        dispatch(ToogleActive(value));
    };
    const toogleCheckboxLocation = (value) => {
        dispatch(ToogleLocation(value));
    };

    const styleName = !errors.name ? (formState.touched.name ? 'input-form input-form-valid' : 'input-form') : 'input-form input-form-error';
    const stylePhone = !errors.phone ? 'input-form' : 'input-form input-form-error';
    const styleEmail = !errors.email ? 'input-form' : 'input-form input-form-error';
    const styleAddress = !errors.address ? 'input-form' : 'input-form input-form-error';

    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={styleName}>
                    <input
                        name="id" hidden={true}
                        ref={register}
                    />
                    <input
                        name="name" placeholder="Nom de l'entreprise"
                        ref={register(
                            {
                                required: {value: true, message: 'Ce champs est obligatoire'},
                                minLength: {value: 4, message: 'La taille minimum est: 4'},
                                maxLength: {value: 20, message: 'La taille maximal est: 20'}
                            }

                        )}
                    />
                    <BsBuilding/>
                    {errors.name && <p className="error-input">{errors.name.message}</p>}
                </div>
                <div className={stylePhone}>
                    <input name="phone" placeholder="Numèros de téléphone"
                           ref={register(
                               {
                                   required: {value: true, message: 'Ce champs est obligatoire'},
                                   isNumber: (input) => isNumeric(input) || "Ce champs doit contenir que des nombres"
                               }
                           )}
                    />
                    <FiPhone/>
                    {errors.phone && <p className="error-input">{errors.phone.message}</p>}
                </div>
                <div className={styleEmail}>
                    <input name="email" placeholder="Email"
                           ref={register(
                               {
                                   required: {
                                       value: true,
                                       message: 'Ce champs est obligatoire'
                                   },
                                   validate: {
                                       isEmail: (input) => isEmail(input) || "Ce champs doit être un email : example@provider.com"}
                               }
                           )}
                    />
                    <AiOutlineMail/>
                    {errors.email && <p className="error-input">{errors.email.message}</p>}

                </div>

                <div className={styleAddress}>
                    <input name="address" placeholder="Adresse"
                           ref={register(
                                   {
                                       required: {value: true, message: 'Ce champs est obligatoire'},
                                       minLength: {value: 4, message: 'La taille minimum est: 4'},
                                       maxLength: {value: 20, message: 'La taille maximal est: 20'}
                                   }
                           )}
                    />
                    <AiOutlineHome/>
                    {errors.address && <p className="error-input">{errors.address.message}</p>}
                </div>
                <Checkbox name="location" press={toogleCheckboxLocation} checked={props.data.location}/>
                <Checkbox name="active" press={toogleCheckbox} checked={props.data.active}/>



            </div>

            <div className="actions">
                <Button type='btn-accent' press={previous} name='Précédent'/>
                <Button type='btn-primary' name='Suivant' loading={loading}/>
            </div>
        </form>
    );
}
