import React from "react"
import {useSelector} from "react-redux";
import {InitReport} from "./init-report";
import {StepsReport} from "./steps";
export const GenerateReport = () => {

    const {step} = useSelector(state => {
        const reports = state.reportsReducer.addReport;
        return { step: reports.step };
    });

    if(step === 0) {
        return(
            <InitReport/>
        );
    }
    return <StepsReport/>

}
