import React, {Component, createRef} from 'react';
import {connect} from "react-redux";

import L, {Icon, Point} from "leaflet";
import 'polyline-encoded';

import 'react-leaflet-markercluster/dist/styles.min.css';
import './map.css'
import {InitMap} from "../../../store/map/vehicles/vehicles.actions";
import {changeView} from "../../../store/map/vehicles/leaflet-provider";
import RotatedMarker from "../../../components/leaflet/rotated-marker";
import MarkerClusterGroup from "react-leaflet-markercluster";
import ReactLeafletEditable from 'react-leaflet-editable';
import 'leaflet-editable'
import {TileLayer, Map, Tooltip} from "react-leaflet";

import {GoogleSatellite} from "../../../components/leaflet/satelitte";
import {GoogleRoadmap} from "../../../components/leaflet/RoadMap";
import {GoogleTerrain} from "../../../components/leaflet/terrain";
import {GoogleHybrid} from "../../../components/leaflet/hybrid";
import EditZone from "./edit-zone/EditZone";
import History from "./history/history";
import TimeLine from "./history/timeline";
import ShowZone from "./show-zone/ShowZone";
import EventMap from "./event";
import ReportData from "../../../components/reports/report-data/report-data";

class MapLeaflet extends Component {

    constructor(props) {
        super(props);
        this.editRef = createRef();
        this.mapRef = createRef();
        this.geoRef = createRef();
        this.state = {first: true};
    }

    componentDidMount() {
        this.duration = 2000;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.first) {
            this.props.dispatch(InitMap({map: this.mapRef.current, editRef: this.editRef.current}));
            this.setState({first: false})
        }
        setTimeout(() => {
            this.mapRef.current.leafletElement.invalidateSize();
        }, 300);

    }


    getIcon(status) {
        let iconUrl = '/img/vehicles/car.png';
        if (!status) {
            iconUrl = '/img/vehicles/car-offline.png';
        }
        return new Icon({
            iconUrl, iconSize: [16.5, 30], iconAnchor: [8.25, 15], popupAnchor: [0, -32]
        });
    }


    handleClick = (e) => {
        changeView(this.mapRef.current, e.latlng, 16);
    }

    drawVehicles() {
        return this.props.vehicles.map((vehicle, i) => {
            if (vehicle.dataVehicle && vehicle.dataVehicle.location && vehicle.dataVehicle.location.coordinates.length === 2) {
                const track = this.props.trackVehicle === vehicle.uid && this.props.activeRoot === 'map';
                const position = [ vehicle.dataVehicle.location.coordinates[1], vehicle.dataVehicle.location.coordinates[0]]
                return (
                    <RotatedMarker
                        key={i}
                        rotationAngle={vehicle.dataVehicle.angle}
                        duration={this.duration}
                        keepAtCenter={track}
                        icon={this.getIcon(vehicle.dataVehicle.ingintion)}
                        position={position}
                        onClick={this.handleClick}
                    >
                        <Tooltip
                            className="tooltip-vehicle"
                            permanent={true}
                            offset={new Point(0, 0)}
                            direction={'right'}
                        >{vehicle.name}</Tooltip>
                    </RotatedMarker>

                )
            }
            return <></>
        })
    }

    getMarkers() {
        if (!this.props._id)
            return (
                !this.props.show.hidden ? (
                        !this.props.show.isClustered ?
                            <>
                                {this.drawVehicles()}
                            </> :
                            <MarkerClusterGroup showCoverageOnHover={false} onClick={this.hover}>
                                {this.drawVehicles()}
                            </MarkerClusterGroup>
                    ) :
                    <></>
            );
    }

    hover(e) {
    }

    getTileLayer() {
        const tileLayer = this.props.activeTileLayer;
        if (tileLayer.type === 'streets-v9' || tileLayer.type === 'satellite-streets-v10') {
            return <TileLayer url={tileLayer.url} attribution={tileLayer.attribution}/>;
        } else if (tileLayer.type === 'osm') {
            return <TileLayer url={tileLayer.url} attribution={tileLayer.attribution}/>;
        } else if (tileLayer.type === 'google') {
            switch (tileLayer.mapType) {
                case 'SATELLITE':
                    return <GoogleSatellite
                        googlekey={this.props.user.type === 'user' ? this.props.user.company.googleMaps : ''}/>
                case 'ROADMAP':
                    return <GoogleRoadmap
                        googlekey={this.props.user.type === 'user' ? this.props.user.company.googleMaps : ''}/>
                case 'TERRAIN':
                    return <GoogleTerrain
                        googlekey={this.props.user.type === 'user' ? this.props.user.company.googleMaps : ''}/>
                case 'HYBRID':
                    return <GoogleHybrid
                        googlekey={this.props.user.type === 'user' ? this.props.user.company.googleMaps : ''}/>
            }
        }
    }


    render() {
        return (
            <div style={this.props.containerStyle}>
                <ReactLeafletEditable
                    ref={this.editRef}>
                    <Map
                        ref={this.mapRef}
                        editable={true}
                        id="map"
                        className={this.props.mapClass}
                        center={[34.52050666666666, -5.946033333333332]}
                        zoom={8}
                        maxZoom={18}
                        minZoom={3}
                        zoomControl={false}

                    >
                        {this.getTileLayer()}
                        {this.getMarkers()}
                        <History mapRef={this.mapRef}/>
                        <ShowZone mapRef={this.mapRef}/>
                        <EditZone mapRef={this.mapRef}/>
                        <EventMap mapRef={this.mapRef}/>

                    </Map>

                </ReactLeafletEditable>

                <TimeLine/>

                {
                    this.props.report.total !== 0
                    && <ReportData report={this.props.report}/>
                }

            </div>

        );
    }
}

const MapStateToProps = (state) => {
    return {
        trackVehicle: state.vehiclesMapReducer.trackVehicle,
        loading: state.vehiclesMapReducer.loading,

        vehicles: state.vehiclesMapReducer.vehicles.list,
        polylines: state.vehiclesMapReducer.polylines,
        show: state.vehiclesMapReducer.show,
        activeTileLayer: state.vehiclesMapReducer.activeTileLayer,
        user: state.authReducer.user,

        _id: state.historyReducer._id,
    }
}
export default connect(MapStateToProps)(MapLeaflet);
