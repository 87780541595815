import * as reportsActions from "./report.actions";
import {LIMIT} from "../../env";
import {
    AiFillFileText,
    AiOutlineCar,
    BiBox,
} from "react-icons/all";
import React from "react";

const INITIAL_STATE = {
    generated: [],
    reports: {list: [], total: 0},
    showListReportType: false,

    active: false,
    filters: {params: {skip: 0, limit: LIMIT}},
    loading: false,

    report: {
        list: [],
        total: 0,
        loading: false,
        skip: 0,
        limit: 100,
        virtualLimit: 100,
        virtualSkip: 0
    },

    addReport: {
        active: -1,
        steps: [],
        step: 0,
        data: {},
        loading: false
    },
}

export function reportsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case reportsActions.SET_SHOW_LIST_REPORT_TYPE:
            return {
                ...state,
                showListReportType: action.payload
            }
        case reportsActions.SET_TIMES:
            return {
                ...state,
                addReport: {
                    ...state.addReport,
                    data: {
                        ...state.addReport.data,
                        start: action.payload.state,
                        end: action.payload.end
                    }
                }
            }

        case reportsActions.GET_START:
            const params = {...state.filters.params, ...action.payload};
            return Object.assign({}, state, {
                loading: true,
                filters: {...state.filters, params}
            });

        case reportsActions.GET_END:
            return {
                ...state,
                loading: false,
                new: false,
                addReport: INITIAL_STATE.addReport,
                active: INITIAL_STATE.active,
                reports: {
                    ...state.reports,
                    list: action.payload,
                    total: action.payload.length
                }
            }

        case reportsActions.START_DOWLOAD:
            return {
                ...state,
                loading: true
            }
        case reportsActions.END_DOWLOAD:
            return {
                ...state,
                loading: false
            }
        case reportsActions.ERROR:
            return Object.assign({}, state, {
                loading: false
            })


        case reportsActions.SET_ACTIVE:
            const active = action.payload;
            if(!active) {
                return {
                    ...state,
                    active: action.payload,
                    addReport: INITIAL_STATE.addReport
                }
            }
            return {
                ...state,
                active: action.payload
            };

        case reportsActions.GET_REPORT_START:
            const paramReport = {...state.report.filters.params, ...action.payload};
            return {
                ...state,
                report: {
                    ...state.report,
                    loading: true,
                    filters: {...state.filters, params: paramReport}
                }
            }

        case reportsActions.GET_GENERATED_START:
            return {
                ...state,
                loading: true
            }

        case reportsActions.GET_GENERATED_END:
            return {
                ...state,
                loading: false,
                generated: action.payload.reverse()
            }

        case reportsActions.GET_REPORT_END:
            return {
                ...state,
                report: {
                    ...state.report,
                    list: action.payload.list,
                    total: action.payload.total,
                    loading: false,
                },
                addReport: INITIAL_STATE.addReport,
            }

        case reportsActions.SET_TIMES:
            return {
                ...state,
                addReport: {
                    ...state.addReport,
                    times: action.payload
                }
            }

        case reportsActions.SET_STEPS:
            return {
                ...state,
                addReport: {
                    ...state.addReport,
                    step: 1
                }
            }

        case reportsActions.SET_ACTIVE_CHOICE:
            let steps = INITIAL_STATE.addReport.steps;
            switch (action.payload) {
                case 0:
                    steps = [
                        {icon: AiFillFileText, name: 'Rapport'},
                    ];
                    break;
                case 1:
                    steps = [
                        {icon: BiBox, name: 'Groupes'},
                        {icon: AiFillFileText, name: 'Rapport'},
                    ];
                    if (state.active.zone === false) {
                        delete steps.splice(3, 1);
                    }
                    break;
                case 2:
                    steps = [
                        {icon: AiOutlineCar, name: 'Véhicules'},
                        {icon: AiFillFileText, name: 'Rapport'},
                    ];
                    if (state.active.zone === false) {
                        delete steps.splice(3, 1);
                    }
                    break
            }
            return {
                ...state,
                addReport: {
                    ...state.addReport,
                    active: action.payload,
                    steps
                }
            }

        case reportsActions.TOOGLE_STEP:
            if (state.addReport.step !== -1) {
                return {
                    ...state,
                    addReport: INITIAL_STATE.addReport
                }
            }
            return {
                ...state,
                addReport: {
                    ...state.addReport,
                    step: 0
                }
            }

        case reportsActions.SELECT_VEHICLE:
            return {
                ...state,
                addReport: {
                    ...state.addReport,
                    vehicle: action.payload
                }
            }

        case reportsActions.SELECT_GROUP:
            return {
                ...state,
                addReport: {
                    ...state.addReport,
                    group: action.payload
                }
            }

        case reportsActions.SELECT_ZONE:
            const id = action.payload;
            let zones = state.addReport.zones || [];
            zones = [...zones];
            const i = zones.indexOf(id);
            if (i === -1) {
                zones.push(id);
            } else {
                zones.splice(i, 1);
            }
            return {
                ...state,
                addReport: {
                    ...state.addReport,
                    zones
                }
            }

        case reportsActions.RESET_STEP:
            const addReport = {
                ...INITIAL_STATE.addReport,
                step: state.addReport.data._id ? -1 : 0,
                active: state.addReport.active
            }
            return {
                ...state,
                addReport
            }

        case reportsActions.SET_STEP_DATA:
            const dataa = {...state.addReport.data, ...action.payload.data};
            return {
                ...state,
                addReport: {
                    ...state.addReport,
                    step: action.payload.step,
                    data: dataa
                }
            }

        case reportsActions.VIRTUAL_PAGINATE:
            const {virtualSkip, virtualLimit} = action.payload;
            return {
                ...state,
                report: {
                    ...state.report,
                    virtualSkip, virtualLimit
                }
            }


        case reportsActions.SAVE_END:
            const {_id, name, createdAt} = action.payload.res;
            const arr = [...state.generated];
            arr.unshift({_id, name, createdAt, done: false})
            return {
                ...state,
                loading: false,
                generated: arr,
                showListReportType: false
            }

        case reportsActions.SAVE_START:
            return {
                ...state,
                loading: state.report.skip === 0
            }
        case reportsActions.CLEAR_REPORTS:
            return INITIAL_STATE;

        case reportsActions.CLEAR_REPORT:
            return {
                ...state,
                report: INITIAL_STATE.report,
                addReport: INITIAL_STATE.addReport
            };

        case reportsActions.EMPTY_REPORT:
            return {
                ...state,
                report: INITIAL_STATE.report,
                addReport: INITIAL_STATE.addReport,
                loading: false
            };

        case reportsActions.UPDATE_STATUS: {
            const {progress, _id} = action.payload;
            const newArray  = [...state.generated];
            const generatedIndex = newArray.findIndex(el => el._id === _id);

            if(generatedIndex !== -1) {
                newArray[generatedIndex].progress = progress;
                return {
                    ...state,
                    generated: newArray
                }
            }

            return {
                ...state,
            }
        }


        default:
            return state;
    }
}

