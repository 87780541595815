import React, {Component} from 'react';
import './group-info-alarm.css';
import {BiBox} from "react-icons/bi"
import "react-perfect-scrollbar/dist/css/styles.css";
import {AiOutlineCar, AiOutlineCheck} from "react-icons/all";

export class GroupInfoAlarm extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    press() {
        if(this.props.press){
            this.props.press({action: 'select', value: this.props.group._id});
        }
    }

    render() {
        const css = !this.props.active ? "card-group-info" : "card-group-info card-group-info-active"
        return (
            <div className={css} onClick={()=>this.press()}>
                { !this.props.active ?   <BiBox/> : <AiOutlineCheck/> }
                <h5 className="name">{this.props.group.name}</h5>
            </div>

        );
    }
}