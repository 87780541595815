import * as roleActions from './role.actions';
import * as groupActions from "../../../store/group/group.actions";

const defaultRole =  [
    { key: 'role', role: 'Rôles', write: false, delete: false },
    { key: 'user', role: 'Utilisateurs', read: false, write: false, delete: false },
    { key: 'zone', role: 'Zones', read: true, write: false, delete: false },
    { key: 'poi', role: 'POIs', read: true, write: false, delete: false },
    { key: 'report', role: 'Rapports', read: true },
    { key: 'plan_report', role: 'Plannification rapports', write: false, delete: false },
    { key: 'group', role: 'Groupes', read: false, write: false, delete: false },
    { key: 'alarm', role: 'Alarmes', read: false, write: false, delete: false },
    { key: 'driver', role: 'Conducteurs', read: false, write: false, delete: false },
    { key: 'maintain', role: 'Maintenances', read: true, write: false, delete: false },
    { key: 'command', role: 'Commandes', read: true },
];

const INITIAL_STATE = {
    add: {
        loading: false,
        active: false
    },
    roles: [{
        name: 'administrateur',
        users: 2,
        authorizations: defaultRole,
        edit: false
    }],
    active: -1,
    loading: false,
    roleError: null
}


export function roleReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case roleActions.SET_ACTIVE: {
            const index = state.roles.findIndex(el=>el.edit);
            const result = {...state, active: action.payload};
            if(index !== -1) {
                const roles = [...state.roles];
                roles[index].edit = false;
                result.roles = roles;
            }
            return result;
        }


        case roleActions.SET_ADD:
            return {
                ...state,
                add: {
                    ...state.add,
                    active: action.payload
                }
            };

        case roleActions.CAN_DELETE:
            return {
                ...state,
                add: {
                    ...state.add,
                    canDelete: !state.add.canDelete
                }
            };

        case roleActions.SET_EDIT:
            const roles = [...state.roles];
            const index = roles.findIndex(el=>el.edit);
            if(index !== -1) {roles[index].edit = false;}
            roles[action.payload.index].edit = action.payload.edit;
            return {
                ...state,
                roles,
                active: action.payload.index
            };

        case roleActions.GET_END:
            return {
                ...state,
                loading: false,
                roles: action.payload
            }

        case roleActions.GET_START:
        case roleActions.DELETE_START:
        case roleActions.SAVE_START:
        case roleActions.UPDATE_START:
            return {
                ...state,
                add: {
                    ...state.add,
                    loading: false
                },
                loading: true
            }

        case roleActions.UPDATE_END:{
            const index = state.roles.findIndex(el=>el.edit);
            const result = {...state, loading: false};
            if(index !== -1) {
                const roles = [...state.roles];
                roles[index].edit = false;
                result.roles = roles;
            }
            return result;
        }

        case roleActions.SAVE_END: {
            const roles = [...state.roles];
            roles.unshift(action.payload)
            return {
                ...state,
                add: {
                    active: false,
                    loading: false
                },
                roles,
                loading: false
            }
        }

        case roleActions.DELETE_END:
            const {response, _id} = action.payload;
            if(response.deletedCount) {
                const find = state.roles.findIndex(el => el._id === _id);
                if(find !== -1) {
                    state.roles.splice(find, 1);
                    return { ...state, loading: false, roles: state.roles }
                }
            }
            return { ...state, loading: false }

        case roleActions.SET_ROLE: {
            if(state.active>=0) {
                const roles = [...state.roles];
                const {key} = action.payload;
                const i = roles[state.active].authorizations.findIndex(r=>r.key === key);
                if(i !== -1) {
                    const list = [
                        ...roles[state.active].authorizations.slice(0, i),
                        action.payload,
                        ...roles[state.active].authorizations.slice(i + 1)
                    ];

                    roles[state.active].authorizations = list;
                    return {...state, roles}
                }

            }
            return state;
        }

    }
    return state;
}
