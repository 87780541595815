import React, {Component, useState} from 'react';
import './command.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useForm } from "react-hook-form";
import {Button} from "../../../components/widgets/form/button/button";
import {useDispatch, useSelector} from "react-redux";
import {GiGps} from "react-icons/gi";
import {Checkbox} from "../../../components/widgets/form/checkbox/checkbox";
import {SaveCommandStart, ToogleActive, ToogleAll, ToogleStep} from "../../../store/commands/command.actions";
import {AiOutlineSend, BiRename, MdDescription} from "react-icons/all";

function checkTracer(tracer, vehicle) {
    if(vehicle.id && (!tracer || vehicle.tracer && vehicle.tracer === tracer)){
        return false;
    }
    return true;
}
export default function Command(props) {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.groupReducer.addGroup.loading);
    const { register, handleSubmit, setError, errors, formState  } = useForm(
        { mode: "onChange",
            defaultValues: {
                id: props.data.id,
                name: props.data.name,
                description: props.data.description,
                command: props.data.command

            }
        },
    );

    const onSubmit = async(data) => {
        dispatch(SaveCommandStart(data));
    };

    const previous = () => { dispatch(ToogleStep()); };


    const toogleActive = (value) => {
        dispatch(ToogleActive(value));
    };


    const toogleAll = (value) => {
        dispatch(ToogleAll(value));
    };

    const styleName = !errors.name ? 'input-form' : 'input-form input-form-error';
    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={styleName}>
                    <input
                        name="id" hidden={true}
                        ref={register}
                    />
                    <input
                        name="name" placeholder="Nom de la commande"
                        ref={register(
                            {
                                required: {value: true, message: 'Ce champs est obligatoire'},
                                minLength: {value: 4, message: 'La taille minimum est: 4'}
                            }

                        )}
                    />
                    <BiRename/>
                    {errors.name && <p className="error-input">{errors.name.message}</p>}
                </div>

                <div className={styleName}>
                    <input
                        name="description" placeholder="Déscription de la commande"
                        ref={register()}
                    />
                    <MdDescription/>
                    {errors.name && <p className="error-input">{errors.name.message}</p>}
                </div>

                <div className={styleName}>
                    <input
                        name="command" placeholder="Commande"
                        ref={register()}
                    />
                    <AiOutlineSend/>
                    {errors.name && <p className="error-input">{errors.name.message}</p>}
                </div>

                <Checkbox name="Active"  press={toogleActive} checked={props.data.active}/>
                <Checkbox name="Utilisateurs standard" press={toogleAll} checked={props.data.all}/>

            </div>

            <div className="actions">
                <Button type='btn-accent' press={previous} name='Fermer'/>
                <Button type='btn-primary' name='Terminer' loading={loading}/>
            </div>
        </form>
    );
}
