import React, { Component }  from 'react';
import './filter-action.css';

export class FilterAction extends Component {

    click() {
        if(this.props.press && this.props.value && !this.props.active) {
            this.props.press({key: 'filter', value: this.props.value});
        }
    }

    render() {
        const style = !this.props.active ? 'filter-span' : 'filter-span-active';
        return (
            <span
                className={style}
                onClick={this.click.bind(this)}
            >{this.props.label}</span>
        );
    }
}