import React, {useEffect} from 'react';
import './users.css';
import {AiOutlineDelete, AiOutlineEdit, AiOutlinePlus, AiOutlineReload, AiOutlineSearch} from "react-icons/ai";
import {HeaderAction} from "../../components/header/header-action/header-action";
import {SearchAction} from "../../components/header/search-action/search-action";
import "react-perfect-scrollbar/dist/css/styles.css";
import {FaBoxOpen} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {DeleteAccountStart, GetAccountsStart, SetStepUpdate, ToogleStep} from "../../store/accounts/accounts.actions";
import {LoadingComponent} from "../../components/loading/loading";
import {SideBar} from "../../components/sideBar/sideBar";
import Step1 from "../accounts/add-account/step1/step1";
import Step2 from "../accounts/add-account/step2/step2";
import {SetStepData} from "../../store/accounts/accounts.actions";
import {ConfirmationDialog} from "../../components/confirmation-dialog/confirmation-dialog";
import {confirmAlert} from "react-confirm-alert";
import UpdatePassword from "./add-account/update-password/updatePassword";
import UpdateGrp from "./add-account/update-grp/updateGroup";
import {Popover, Table} from "antd";
import {FiMoreVertical} from "react-icons/fi";
import {FilterAction} from "../../components/header/filter-action/filter-action";
import {FiUserCheck, FiUserX} from "react-icons/all";
import {ActionPopover} from "../../components/widgets/action-popover/action-popover";
import {RiLockPasswordLine} from "react-icons/ri";

export const UsersPage = () => {
    const content = (account) =>{
        return(
            <>

                <ActionPopover icon={AiOutlineEdit} label="Modifier" press={accountAction} value={{action: 'edit', id: account._id}}/>
                <ActionPopover icon={RiLockPasswordLine} label="Modifier le mot de passe" press={accountAction} value={{action: 'editPass', username: account.username}}/>
                <ActionPopover icon={FaBoxOpen} label="Affecter/Retirer le groupe" press={accountAction} value={{action: 'grp', username: account.username}}/>
                <ActionPopover icon={AiOutlineDelete} press={accountAction} label="Supprimer" value={{action: 'del', name: account.name, username: account.username, id: account._id}}/>
            </>
        )
    };
    const columns = [
        {
            title: '#',
            dataIndex: 'actions',
            key: 'actions',
            width: 40,
            fixed: 'left',
            render: (text, maitenance) => {
                return(
                    <div className="more-options">
                        {
                            <Popover placement="rightTop" content={()=>content(maitenance)} text={text} trigger="hover">
                            <FiMoreVertical />
                        </Popover>
                        }

                    </div>

                )
            }
        },
        {
            title: 'Actif',
            dataIndex: 'active',
            key: 'active',
            width: 60,
            align: 'center',
            render: (text, user) => {
                const style = {fontSize: '18px'}
                return(
                    user.active ?
                        <FiUserCheck style={style} color="rgb(59, 206, 133)"/> :
                        <FiUserX style={style} color="red"/>
                )
            }
        },
        {
            title: 'Rôle',
            dataIndex: 'role',
            key: 'role',
            shouldCellUpdate: (record, prevRecord) => record.role !== prevRecord.role,
            render: (text, user) => {
                const style = {fontSize: '18px'}
                return user.role && user.role.name
            }
        },
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            shouldCellUpdate: (record, prevRecord) => record.name !== prevRecord.name
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            shouldCellUpdate: (record, prevRecord) => record.type !== prevRecord.type
        },
        {
            title: 'Nom d\'utilisateur',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            shouldCellUpdate: (record, prevRecord) => record.email !== prevRecord.email
        },
        {
            title: 'Mobile',
            dataIndex: 'phone',
            key: 'phone',
            shouldCellUpdate: (record, prevRecord) => {
                return record.phone !== prevRecord.phone
            }
        },
        {
            title: 'Fuseaux horaire',
            dataIndex: 'timezone',
            key: 'timezone',
            shouldCellUpdate: (record, prevRecord) => record.timezone !== prevRecord.timezone

        },


    ];
    const dispatch = useDispatch();

    const {filters, params, accounts, loading, addAccount} = useSelector(state => {
        const account = state.accountsReducer;
        return {
            filters: account.filters.data,
            params: account.filters.params,
            accounts: account.accounts,
            addAccount: account.addAccount,
            loading: account.loading
        }
    });

    useEffect(()=> {
        dispatch(GetAccountsStart({}));
    }, []);

    const filterAction = (action) => {
        switch (action.key) {
            case 'name':
                dispatch(GetAccountsStart({
                    name: action.value,
                    skip: 0
                }))
                break;

            case 'filter':
                dispatch(GetAccountsStart({
                    filter: action.value,
                    skip: 0
                }))
                break;
        };
    }
    const findAccountById = (id) => {
        return accounts.find(el => id === el._id);
    }
    const findAccountByUsername = (username) => {
        return accounts.find(el => username === el.username);
    }
    const deleteAction = (id) => {
        dispatch(DeleteAccountStart(id));
    }
    const accountAction = (action) =>{
        switch (action.action) {
            case 'reload':
                dispatch(GetAccountsStart({}))
                break;
            case 'add':
                dispatch(ToogleStep());
                break;
            case 'edit':
                const account = findAccountById(action.id);
                if(account) {
                    dispatch(SetStepData({
                        step: 1,
                        data: {
                            ...{id: account._id},
                            ...account
                        }
                    }));
                }
                break;
            case 'editPass':
                const accountPassword = findAccountByUsername(action.username);
                if(accountPassword) {
                    dispatch(SetStepUpdate({
                        step: 3,
                        data: {
                            ...{id: accountPassword._id},
                            ...accountPassword
                        }
                    }));
                }
                break;
            case 'grp':
                const accountGrp = findAccountByUsername(action.username);
                if(accountGrp) {
                    dispatch(SetStepUpdate({
                        step: 4,
                        data: {...accountGrp}
                    }));
                }
                break;
            case 'del':
                const options = {
                    customUI: ({ onClose }) =>
                        <ConfirmationDialog
                            close={onClose}
                            action={deleteAction.bind(this)}
                            id={action.username}
                            keyword={action.name}
                            ActionName={"Supprimer"}/>,
                    closeOnEscape: false,
                    closeOnClickOutside: false
                };
                confirmAlert(options);
                break;
        }
    }


    return(
        <section id="users">
            <LoadingComponent loading={loading}/>
            <div className="header-section">
                <div className="filter">
                    {
                        filters && filters.map((el, i)=> {
                            return <FilterAction
                                key={i}
                                active={el.filter === params.filter}
                                label={el.name}
                                value={el.filter}
                                press={filterAction}
                            />
                        })
                    }
                </div>
                <div className="action-right">
                    <HeaderAction
                        value={{action: 'reload'}}
                        icon={AiOutlineReload}
                        press={accountAction}
                    />
                    <HeaderAction
                        value={{action: 'add'}}
                        icon={AiOutlinePlus}
                        press={accountAction}
                    />
                    <SearchAction
                        icon={AiOutlineSearch}
                        indentifier="name"
                        onChange={filterAction}
                    />
                </div>

            </div>

            <div className="body">
                <Table scroll={{ y: window.innerHeight - 170 }}
                       className="table-report"
                       dataSource={accounts}
                       columns={columns}
                       pagination={false} size="small"
                />
            </div>
            {
                addAccount.step && addAccount.step < 3?
                    <SideBar
                        height={window.innerHeight}
                        countSteps={addAccount.steps.length}
                        step={addAccount.step}
                        stepsText={addAccount.steps}
                    >
                        { addAccount.step === 1 && <Step1 /> }
                        { addAccount.step === 2 && <Step2
                            data={addAccount.data}
                        /> }
                    </SideBar> : <></>
            }

            {
                addAccount.step && addAccount.step === 3 ?
                    <SideBar
                        height={window.innerHeight}
                        countSteps={1}
                        step={1}
                        stepsText={addAccount.steps}
                    >
                        <UpdatePassword />
                    </SideBar> : <></>
            }

            {
                addAccount.step && addAccount.step === 4 ?
                    <SideBar
                        height={window.innerHeight}
                        countSteps={1}
                        step={1}
                        stepsText={addAccount.steps}
                    >
                        <UpdateGrp />
                    </SideBar> : <></>
            }
        </section>
    );
};
/*
class UsersPage extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 176}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(GetAccountsStart({}));
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 176}px` });
    }

    deleteAction(id) {
        this.props.dispatch(DeleteAccountStart(id));
    }

    filterAction(action) {
        switch (action.key) {
            case 'name':
                this.props.dispatch(GetAccountsStart({
                    name: action.value,
                    skip: 0
                }))
                break;

            case 'filter':
                this.props.dispatch(GetAccountsStart({
                    filter: action.value,
                    skip: 0
                }))
                break;
        };
    }

    findAccountById(id){
        return this.props.accounts.find(el => id === el._id);
    }
    findAccountByUsername(username){
        return this.props.accounts.find(el => username === el.username);
    }


    accountAction(action) {
        switch (action.action) {
            case 'reload':
                this.props.dispatch(GetAccountsStart({}))
                break;
            case 'add':
                this.props.dispatch(ToogleStep());
                break;
            case 'edit':
                const account = this.findAccountById(action.id);
                if(account) {
                    this.props.dispatch(SetStepData({
                        step: 1,
                        data: {
                            ...{id: account._id},
                            ...account
                        }
                    }));
                }
                break;
            case 'editPass':
                const accountPassword = this.findAccountByUsername(action.username);
                if(accountPassword) {
                    this.props.dispatch(SetStepUpdate({
                        step: 3,
                        data: {
                            ...{id: accountPassword._id},
                            ...accountPassword
                        }
                    }));
                }
                break;
            case 'grp':
                const accountGrp = this.findAccountByUsername(action.username);
                if(accountGrp) {
                    this.props.dispatch(SetStepUpdate({
                        step: 4,
                        data: {...accountGrp}
                    }));
                }
                break;
            case 'del':
                const options = {
                    customUI: ({ onClose }) =>
                        <ConfirmationDialog
                            close={onClose}
                            action={this.deleteAction.bind(this)}
                            id={action.username}
                            keyword={action.name}
                            ActionName={"Supprimer"}/>,
                    closeOnEscape: false,
                    closeOnClickOutside: false
                };
                confirmAlert(options);
                break;
        }
    }

    paginate(skip, limit) {
        this.props.dispatch(GetAccountsStart({skip: skip}));
    }

    render() {
        const filters= this.props.filters.data;
        const params= this.props.filters.params;
        return (
            <section id="users">
                <LoadingComponent loading={this.props.loading}/>
                <div className="header-section">
                    <div className="filter">
                        {
                            filters && filters.map((el, i)=> {
                                return <FilterAction
                                    key={i}
                                    active={el.filter === params.filter}
                                    label={el.name}
                                    value={el.filter}
                                    press={this.filterAction.bind(this)}
                                />
                            })
                        }
                    </div>
                    <div className="action-right">
                        <HeaderAction
                            value={{action: 'reload'}}
                            press={this.accountAction.bind(this)}
                            icon={AiOutlineReload}
                        />
                        <HeaderAction
                            value={{action: 'add'}}
                            press={this.accountAction.bind(this)}
                            icon={AiOutlinePlus}
                        />
                        <SearchAction
                            icon={AiOutlineSearch}
                            indentifier="name"
                            onChange={this.filterAction.bind(this)}
                        />
                    </div>

                </div>
                <div style={{display: 'flex'}}>
                    <PerfectScrollbar className="scrollbar-container-account" style={{ height: this.state.height }}>
                        <div className="body">
                            {
                                this.props.accounts.length > 0  ? this.props.accounts.map((account,i) => {
                                    return <AccountInfo
                                        key={i}
                                        account={account}
                                        press={this.accountAction.bind(this)}
                                    />
                                }) : !this.props.loading && <BlankComponent/>
                            }
                        </div>
                        <Paginator paginate={this.paginate.bind(this)} limit={params.limit} skip={params.skip} total={this.props.total}/>

                    </PerfectScrollbar>

                    {
                        this.props.addAccount.step && this.props.addAccount.step < 3?
                            <SideBar
                                height={window.innerHeight}
                                countSteps={this.props.addAccount.steps.length}
                                step={this.props.addAccount.step}
                                stepsText={this.props.addAccount.steps}
                            >
                                { this.props.addAccount.step === 1 && <Step1 /> }
                                { this.props.addAccount.step === 2 && <Step2
                                    data={this.props.addAccount.data}
                                /> }
                            </SideBar> : <></>
                    }

                    {
                        this.props.addAccount.step && this.props.addAccount.step === 3 ?
                            <SideBar
                                height={window.innerHeight}
                                countSteps={1}
                                step={1}
                                stepsText={this.props.addAccount.steps}
                            >
                                <UpdatePassword />
                            </SideBar> : <></>
                    }

                    {
                        this.props.addAccount.step && this.props.addAccount.step === 4 ?
                            <SideBar
                                height={window.innerHeight}
                                countSteps={1}
                                step={1}
                                stepsText={this.props.addAccount.steps}
                            >
                                <UpdateGrp />
                            </SideBar> : <></>
                    }
                </div>
            </section>
        );
    }
}

const accountsStateToProps = (state) => {
    return {
        accounts: state.accountsReducer.accounts,
        filters: state.accountsReducer.filters,
        stats: state.accountsReducer.stats,
        loading: state.accountsReducer.loading,
        addAccount: state.accountsReducer.addAccount,
        total: state.accountsReducer.total
    }
}
export default connect(accountsStateToProps)(UsersPage);*/
