import React from "react";
import {InfoGeneralCompoment} from "./general-info";
import {Steps} from "antd";
import {AiFillFileText} from "react-icons/all";
import {useSelector} from "react-redux";
import {NotificationCompoment} from "./notification";
import {IntervalCompoment} from "./interval";
import './add-maintenance.css';

export const AddMaintenanceCompoment = ({id}) => {

    const steps = [
        { icon: AiFillFileText, name: 'Information général' },
        { icon: AiFillFileText, name: 'Intérval' },
        { icon: AiFillFileText, name: 'Notification' },
    ];
    const { step } = useSelector(state => {
        return {
            step: state.maintenanceReducer.addMaintenance.step,
        }
    });
    const {Step} = Steps;

    return(
        <div>
            <Steps size={'small'} className="stepper-maintenance" current={step}>
                {
                    steps.map(step => {
                        const ICON = step.icon;
                        return <Step title={step.name} icon={<ICON/>}/>
                    })
                }
            </Steps>

            { step === 0 && <InfoGeneralCompoment /> }
            { step === 1 && <IntervalCompoment /> }
            { step === 2 && <NotificationCompoment id={id}/> }

        </div>

    );
}
