import * as zoneActions from './zones.actions';
import {LIMIT} from "../../env";


const INITIAL_STATE = {
    addZone: {
        steps:[
            {current: 'Type de la zone', next: 'Déssiner la zone'},
            {current: 'Déssiner la zone', next: 'Fin'},
        ],
        step: 0,
        data: {color: '#1976D2', radius: 2},
        loading: false
    },
    zones: [],
    total: 0,
    zone: null,
    filters: {
        data: [
            {filter: 'all', name: 'Tous'},
            {filter: 'Point', name: 'Cércle'},
            {filter: 'LineString', name: 'Ligne'},
            {filter: 'Polygon', name: 'Polygone'},
            {filter: 'Poi', name: 'POI'}],
        params: {skip: 0, limit: LIMIT, filter: 'all'}
    },
    loading: false,
    zoneError: null
}


export function zonesReducer(state = INITIAL_STATE, action){
    switch (action.type) {
        case zoneActions.GET_START:
            const params = {...state.filters.params, ...action.payload};
            return {
                ...state, loading: true,
                filters: {
                    ...state.filters,
                    params
                }
            }

        case zoneActions.GET_END:
            return Object.assign({}, state, {
                zones: action.payload.list,
                stats: action.payload.stats,
                total: action.payload.total,
                loading: false
            });

        case zoneActions.SET_STEP:
            if(action.payload === 1 && state.addZone.step === 2) {
                const addZone = {...INITIAL_STATE.addZone, ...{step: action.payload}}
                return { ...state, addZone, zone: null }
            }
            return {
                ...state,
                addZone: {
                    ...state.addZone,
                    step: action.payload,
                    steps: INITIAL_STATE.addZone.steps
                }
            }

        case zoneActions.TOOGLE_STEP:
            if(state.addZone.step) {
                return {
                    ...state,
                    addZone: {
                        ...state.addZone,
                        step: false,
                        data: INITIAL_STATE.addZone.data,
                        steps: INITIAL_STATE.addZone.steps
                    }
                }
            }
            const addZone = {...INITIAL_STATE.addZone, ...{step: 1}}
            return {
                ...state,
                addZone,
                zone: null
            }
        case zoneActions.SET_TYPE_ZONE:
            return {
                ...state,
                addZone: {
                    ...state.addZone,
                    data: {
                        ...state.addZone.data,
                        type: action.payload
                    }
                }
            }

        case zoneActions.ZONE_DRAW:
            return {
                ...state,
                addZone: {
                    ...state.addZone,
                    data: {
                        ...state.addZone.data,
                        ...action.payload
                        // radius: INITIAL_STATE.addZone.data.radius
                    }
                }
            }

        case zoneActions.SET_COLOR:
            return {
                ...state,
                addZone: {
                    ...state.addZone,
                    data: {
                        ...state.addZone.data,
                        color: action.payload
                    }
                }
            }
        case zoneActions.SET_RADIUS:
            return {
                ...state,
                addZone: {
                    ...state.addZone,
                    data: {
                        ...state.addZone.data,
                        radius: Math.round(action.payload)
                    }
                }
            }
        case zoneActions.SET_CORRIDOR:
            return {
                ...state,
                addZone: {
                    ...state.addZone,
                    data: {
                        ...state.addZone.data,
                        latLngs: action.payload
                    }
                }
            }

        case zoneActions.SAVE_START:
            return {
                ...state,
                addZone: {
                    ...state.addZone,
                    loading: true
                }
            }
        case zoneActions.SAVE_END:
            const zone = action.payload;
            const findZone = state.zones.findIndex(el => el._id === zone.id);
            if(findZone === -1) {
                state.zones.unshift(zone);
            } else {
                state.zones[findZone] = {...state.zones[findZone], ...action.payload};
            }
            return {
                ...state,
                addZone: INITIAL_STATE.addZone,
                zones: state.zones,
            }

        case zoneActions.FETCH_ONE_START:
            return Object.assign({}, state, {
                loading: true, zone: null
            });

        case zoneActions.START_ZONE_EDIT:
            return {
                ...state,
                loading: true,
                addZone: {
                    ...state.addZone,
                    data:{
                        ...state.addZone.data,
                        location: null
                    },

                },
                zone: INITIAL_STATE.zone
            }

        case zoneActions.END_ZONE_EDIT:
            const zoneDraw = {zone: {type: 'Feature', properties: {}, geometry: action.payload.location}}
            const data = {...state.addZone.data, ...action.payload, ...{type: action.payload.location.type}, ...zoneDraw};
            return{
                ...state,
                addZone: {
                    ...state.addZone,
                    data,
                    steps:[
                        {current: 'Déssiner la zone', next: 'Fin'},
                    ],
                    step: 1,
                },
                loading: false
            }

        case zoneActions.FETCH_ONE_END:
            return Object.assign({}, state, {
                loading: false,
                zone: action.payload
            });

        case zoneActions.DELETE_START:
            return {
                ...state,
                loading: true
            }

        case zoneActions.DELETE_END:
            const {response, id} = action.payload;
            if(response.deletedCount) {
                const find = state.zones.findIndex(el => el.id === id || el._id === id);
                if(find !== -1) {
                    let total = state.total;
                    total--;
                    state.zones.splice(find, 1);
                    return {
                        ...state,
                        loading: false,
                        total
                    }
                }
            }
            return {
                ...state,
                loading: false
            }

        case zoneActions.ERROR:
            return Object.assign({}, state, {
                loading: false
            });

        case zoneActions.CLEAR_ZONE:
            return INITIAL_STATE;

        default:
            return state;
    }
}
