import React, {PureComponent} from "react";
import './avatar.css';
import {Popover} from "antd";
import {ActionPopover} from "../widgets/action-popover/action-popover";
import {AiOutlineLogout, RiExchangeBoxLine} from "react-icons/all";

export class Avatar extends PureComponent{
    constructor(props) {
        super(props);
        this.state = { visible: false };
    }

    press(value) {
        if(value) {
            this.props.press(value);
            this.setState({ visible: false });
        }
    }

    handleVisibleChange = visible => {
        this.setState({ visible });
    };

    render() {
        const content =
            <>
                <ActionPopover icon={RiExchangeBoxLine} label="Changer d'utilisateur" press={this.press.bind(this)}
                               value={{action: 'changeUser'}}/>
                <ActionPopover icon={AiOutlineLogout} label="Se déconnecter" press={this.press.bind(this)}
                               value={{action: 'disconnection'}}/>
            </>;
        return (
            <Popover
                placement="bottomLeft"
                content={content}
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
            >
                <div className="avatar">
                    <img src={this.props.img}/>
                    <span>{ this.props.name }</span>
                </div>
            </Popover>

        );
    }
}