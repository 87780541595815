import * as detailsActions from './details.actions';


const INITIAL_STATE = {
    lSpeed: true,
    lKms: false,
    lAlarms: false,
    lTrips: false,
    speed: { max: undefined, min: undefined },
    speeds: [],
    kms: {
        stats: [], max: undefined, min: undefined, today: undefined
    },
    alarms: 0,
    trips: 0
}

export function detailsReducer(state = INITIAL_STATE, action){
    switch (action.type) {

        case detailsActions.GET_START_SPEED:
            return Object.assign({}, state, {
                lSpeed: true
            });

        case detailsActions.GET_END_SPEED:
            return Object.assign({}, state, {
                lSpeed: false,
                speed: action.payload
            });



        case detailsActions.GET_START_KM:
            return Object.assign({}, state, {
                lKms: true
            });

        case detailsActions.GET_END_KM:
            return Object.assign({}, state, {
                lKms: false,
                kms: action.payload
            });

        case detailsActions.GET_START_ALARMS:
            return Object.assign({}, state, {
                lAlarms: true
            });
        case detailsActions.GET_END_ALARMS:
            return Object.assign({}, state, {
                lAlarms: false,
                alarms: action.payload,
            });

        case detailsActions.GET_START_TRIPS:
            return Object.assign({}, state, {
                lTrips: true,
            });
        case detailsActions.GET_END_TRIPS:
            return Object.assign({}, state, {
                lTrips: false,
                trips: action.payload,
            });

        case detailsActions.CLEAR:
            return INITIAL_STATE;


        case detailsActions.ERROR:
            return Object.assign({}, state, {
                lSpeed: false,
                lSpeeds: false,
                lKms: false
            });

        default:
            return state;
    }
}