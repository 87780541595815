import React, {Component} from "react";
import {connect} from "react-redux";
import {Steps} from 'antd';
import './add-report.css';
import Step1Alarm from "./step1/step1";
import GroupsPage from "./group-choose/groupsPage";
import VehiclePage from "./vehicle-choose/vehiclesPage";
import InitReport from "./init-report/init-report";

class AddReportPage extends Component {

    constructor(props) {
        super(props);
        this.state = {height: `${window.innerHeight - 210}px`};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({height: `${window.innerHeight - 210}px`});
    }

    getStep1() {
        if(this.props.addActive === 0) {return <Step1Alarm timezone={this.props.timezone}/>;}
        else if(this.props.addActive === 1) {return <GroupsPage/>;}
        else {return <VehiclePage/>;}
    }

    getStep2() {
        if(this.props.addActive === 0) {return <></>;}
        else {return <Step1Alarm timezone={this.props.timezone}/>;}
    }

    getStep() {
        const step = this.props.step;
        switch (step) {
            case 1:
                return this.getStep1();
            case 2:
                return this.getStep2();
            default:
                return <></>
        }
    }

    render() {
        const {Step} = Steps;
        if (this.props.step !== 0) {
            return (
                <div className="add-alarm">
                    <Steps size={'small'} className="stepper-alarm" current={this.props.step - 1}>
                        { this.props.steps.map(step => {
                            const ICON = step.icon;
                            return <Step title={step.name} icon={<ICON/>}/>
                        }) }
                    </Steps>
                    {this.getStep()}
                </div>
            );

        }
        return (
            <div className="add-alarm">
                <InitReport/>
            </div>
        )
    }
}

const VehiclesStateToProps = (state) => {
    return {
        timezone: state.authReducer.timezone,
        active: state.reportsReducer.active,
        addActive: state.reportsReducer.addReport.active,
        step: state.reportsReducer.addReport.step,
        steps: state.reportsReducer.addReport.steps,
    }
}
export default connect(VehiclesStateToProps)(AddReportPage);