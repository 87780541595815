import * as actions from "./events.actions";
import {Error, GetEventsEnd} from "./events.actions";
import {ofType} from "redux-observable";
import {catchError, map, switchMap} from 'rxjs/operators'
import {from, of} from "rxjs";
import {fetchEvents} from "../../../pages/map/events/eventService";
import {AutoLogout} from "../../auth/auth.actions";

export const FetchEventsMap = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        const params = {...state$.value.eventsMapReducer.filters.params, ...action.payload};
        return from(fetchEvents(params, state$.value.authReducer.token)).pipe(
            map(res => {
                return GetEventsEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

