const findRole = (name, where) => {
    return where.findIndex(r => r === name) !== -1;
}

export const canShowMaintain = (userType, roles) => {
    return userType === 'admin' || findRole('maintain:rd', roles) || findRole('maintain:wt', roles) || findRole('maintain:dt', roles)
}

export const canManageMaintain = (userType, roles) => {
    return userType === 'admin' || findRole('maintain:wt', roles);
}

export const canDeleteMaintain = (userType, roles) => {
    return userType === 'admin' || findRole('maintain:dt', roles)
}
