import React, {Component, useEffect, useRef, useState} from 'react';
import "react-perfect-scrollbar/dist/css/styles.css";
import ApexCharts from "apexcharts";
import {ReactApexChart} from "../components/apex-chart";
import moment from "moment-timezone";

export const KmGraph = ({data}) => {

    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({
        series: [{ data: [] }],
        chart: {
            id: 'km',
            group: 'social',
            type: 'line',
            height: 160,
            animations: {
                enabled: false,},
            dataLabels: {
                enabled: false
            }
        },
        tooltip: {
            y: {
                formatter: (value) => { return `${value} Km`},
            },
        },
        xaxis: {  type: 'datetime', },
        yaxis: {
            title: {
                text: 'Km',
            },
        },
        stroke: {
            width: 2
        },
    });

    useEffect(()=> {
        setSeries([{
            data: data.map(info => {
                return([new Date(info.gpsDate), info.km !== undefined ? info.km.toFixed(1) : null])
            })
        }]);
        setOptions({
            ...options, ...{}})
    }, [data]);

    return <ReactApexChart id="km" series={series} options={options}/>;

}

