import React from "react"
import {useSelector} from "react-redux";
import '../generate-report.css';

import ChooseTimeRange from "./choose-time-range";

export const MainReportStep2 = () => {

    const {timezone, addActive} = useSelector(state => {
        const reports = state.reportsReducer;
        return {
            timezone: state.authReducer.user.timezone,
            addActive: reports.addReport.active,
        };
    });


    if(addActive === 0) {
        return <></>;
    }
    return <ChooseTimeRange timezone={timezone}/>
}
