import React from 'react';
import { useForm } from "react-hook-form";
import isNumeric from "validator/lib/isNumeric";

import {AiOutlineInfoCircle} from "react-icons/ai";
import {Button} from "../../widgets/form/button/button";


export default function Percent(props) {
    const { register, handleSubmit, errors  } = useForm(
        { mode: "onChange"},
    );

    const onSubmit = async(data) => {
        data.full = Number(data.full);
        if(props.select) {
            props.select({key: 'full', value: data.full})
        }
    };


    const stylePercent = !errors.percent ? 'input-form' : 'input-form input-form-error';
    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={stylePercent}>
                    <input
                        name="full" placeholder={props.placeHolder ? props.placeHolder : 'Maximum valeur'}
                        ref={register(
                            {
                                required: {value: true, message: 'Ce champs est obligatoire'},
                                validate: {
                                    isNumber: (input) => isNumeric(input) || "Ce champs doit contenir que des nombres"
                                }}
                        )}
                    />
                    <AiOutlineInfoCircle/>
                    {errors.percent && <p className="error-input">{errors.percent.message}</p>}
                </div>

            </div>
            <Button name="Confirmer" type="btn-primary"/>
        </form>
    );
}
