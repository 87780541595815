import React, {useState, useEffect } from 'react';
import './step2.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useForm } from "react-hook-form";
import {Button} from "../../../../components/widgets/form/button/button";
import {useDispatch, useSelector} from "react-redux";
import { HuePicker } from 'react-color';
import {AiOutlineInfoCircle} from "react-icons/ai";
import {SaveZoneStart, SetColor, SetCorridor, SetRadius, SetStep, ToogleStep} from "../../../../store/zones/zones.actions";
import {GiRadarSweep} from "react-icons/all";


export default function Step2() {
    const dispatch = useDispatch();
    const {token, account, zone, loading, editRef} = useSelector(state => {
        return {
            token: state.authReducer.token,
            account: state.accountsReducer.addAccount.data,
            zone: state.zonesReducer.addZone.data,
            editRef: state.vehiclesMapReducer.editRef,
            loading: state.zonesReducer.addZone.loading,

        }
    });



    const { register, handleSubmit, errors  } = useForm(
        { mode: "onChange",
            defaultValues: {
                id: zone._id || zone.id,
                name: zone.name,
                radius: zone.radius
            }
        },
    );

    const onSubmit = async(data) => {
        dispatch(SaveZoneStart(data));
    };
    const previous = () => {
        if(zone.location){
            dispatch(ToogleStep());
            return;
        }
        dispatch(SetStep(1));
    };
    const handleChangeComplete = (color) => {dispatch(SetColor(color.hex)); };
    const radius = () => {
        switch (zone.type) {
            case 'LineString':
                return (
                    <div className={styleRadius}>
                        <input
                            name="id" hidden={true}
                            ref={register}
                        />
                        <input
                            placeholder="Rayon en mètre" type="number"
                            min="2" name="radius"
                            ref={register({
                                required: {value: zone.radius, message: 'Ce champs est obligatoire'}
                            })}
                            onChange={(el)=>dispatch(SetRadius(el.currentTarget.value))}
                        />
                        <GiRadarSweep/>
                        {errors.radius && <p className="error-input">{errors.radius.message}</p>}
                    </div>
                )
            case 'Point':
            case 'POI':
                return(
                    <div className={styleRadius}>
                        <label>{zone.radius} m</label>
                        <GiRadarSweep/>
                    </div>
                )
            default:
                return <></>
        }
    }


    const styleName = !errors.name ? 'input-form' : 'input-form input-form-error';
    const styleRadius = !errors.radius ? 'input-form' : 'input-form input-form-error';
    return (
        <form className="form-aside" onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className={styleName}>
                    <input
                        name="id" hidden={true}
                        ref={register}
                    />
                    <input
                        name="name" placeholder="Nom  de la zone"
                        ref={register({required: {value: true, message: 'Ce champs est obligatoire'}})}
                    />
                    <AiOutlineInfoCircle/>
                    {errors.name && <p className="error-input">{errors.name.message}</p>}
                </div>

                { radius() }

                <HuePicker
                    color={ zone.color }
                    onChangeComplete={ (color) => handleChangeComplete(color) }
                />
            </div>

            <div className="actions">
                <Button type='btn-accent' press={previous} name='Précédent'/>
                <Button type='btn-primary' name='Suivant' disabled={!zone.zone}/>
            </div>
        </form>
    );
}
