import {ofType} from "redux-observable";
import * as actions from "./tracers.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {from, of} from "rxjs";
import {AutoLogout} from "../auth/auth.actions";
import {createTracer, deleteTracer, fetchTracersByType, updateTracer} from "../../pages/tracer/tracersService";
import {GetTracerEnd, Error, DeleteEnd, CreateTracerEnd} from "./tracers.actions";
import {NotificationManager} from "react-notifications";

export const GetTracer = (action$, state$) => action$.pipe(
    ofType(actions.GET_TRACER_START),
    switchMap((action) => {
        const params = {...{type: state$.value.tracersReducer.active}, ...state$.value.tracersReducer.filters.params, ...action.payload};
        return from(fetchTracersByType(params, state$.value.authReducer.token)).pipe(
            map(res => {

                return GetTracerEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(msg));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const DeleteTracer = (action$, state$) => action$.pipe(
    ofType(actions.DELETE_START),
    switchMap((action) => {
        return from(deleteTracer(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                const payload = {response: res.data, id: action.payload}
                NotificationManager.success('le traceur est supprimé avec succès', 'Suppression' , 2000);
                return DeleteEnd(payload);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(msg));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const CreateTracer = (action$, state$) => action$.pipe(
    ofType(actions.CREATE_TRACER_START),
    switchMap((action) => {
        return from(createTracer(state$.value.tracersReducer.active, action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                const data = {...{_id: res.data.insertedId, online: false}, ...action.payload};
                return CreateTracerEnd(data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if( err.response.status === 417) {
                    return of(Error('exist'));
                } else if (err.response.status === 401) {
                    return of(AutoLogout(), Error(msg));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const UpdateTracer = (action$, state$) => action$.pipe(
    ofType(actions.UPDATE_TRACER_START),
    switchMap((action) => {
        return from(updateTracer(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                return CreateTracerEnd(action.payload);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(msg));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);