import {BASE_URL} from "../../env";

const axios = require('axios');


export function fetchchBlackList(payload, token) {
    let params = '';
    for (let param in payload) {
        params+=`${param}=${payload[param]}&`
    }
    const url = `${BASE_URL}/api/blacklist?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export async function fetchOneIdentification(payload, token) {

    const url = `${BASE_URL}/api/blacklist/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }

    try{
        const res = await axios.get(url, config);
        return true;
    }catch (e) {
        return false;
    }

}

export async function addBlackList(payload, token) {
    const url = `${BASE_URL}/api/blacklist`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.post(url, payload, config);
}

export async function deleteBlacklist(payload, token) {
    const url = `${BASE_URL}/api/blacklist/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.delete(url, config);
}
