import React, { Component }  from 'react';
import './alarm-info.css';
import {FiMoreVertical} from "react-icons/fi";
import { Popover } from 'antd';
import {ActionPopover} from "../../widgets/action-popover/action-popover";
import {AiOutlineEdit} from "react-icons/ai";
import {RiLockPasswordLine} from "react-icons/ri";
import {AiOutlineDelete} from "react-icons/ai";
import {FaBoxOpen} from "react-icons/fa";
import {AiOutlineCar, BiBox, BsBuilding, MdTrackChanges} from "react-icons/all";
import group from "../../../pages/groups/add-group/group";

export class AlarmInfo extends Component {

    constructor(props) { super(props); }

    componentDidMount() { }

    getIcon(hasVehicles, hasGroups) {
        if(hasGroups){ return BiBox;}
        if(hasVehicles) { return AiOutlineCar;}
        return BsBuilding;
    }

    render() {
       const alarm = this.props.alarm;
        const classStyle = alarm.active ? "card-alarm-info active-alarm" : "card-alarm-info deactive-alarm";
        const text = <span>Title</span>;
        const content = (
            <>
                <ActionPopover icon={AiOutlineEdit} label="Modifier" press={this.props.press} value={{action: 'edit', id: alarm._id}}/>
                <ActionPopover icon={AiOutlineDelete} press={this.props.press} label="Supprimer" value={{action: 'del', name: alarm.name, id: alarm._id}}/>
            </>
        );
        const hasVehicles = alarm.hasOwnProperty('vehicles') && alarm.vehicles.length > 0;
        const hasGroups = alarm.hasOwnProperty('groups') && alarm.groups.length > 0;
        const ICON = this.getIcon(hasVehicles, hasGroups);
        return (
            <div className={classStyle}>
                <div className="more-options">
                    <Popover placement="rightTop" content={content} text={text} trigger="hover">
                        <FiMoreVertical />
                    </Popover>
                </div>

                <div className="main">
                    <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                        <ICON className="avatar-alarm"/>
                        <h5 className="name">{alarm.name}</h5>
                        <div className="description">
                            {
                                alarm.hasOwnProperty('zones') && alarm.zones.length>0&&
                                <div className="descWidget">
                                    <MdTrackChanges />
                                    <label>Zones: {alarm.zones.length}</label>
                                </div>
                            }
                            {
                                hasVehicles && alarm.vehicles.length> 0&&
                                <div className="descWidget">
                                    <AiOutlineCar />
                                    <label>Véhicules: {alarm.vehicles.length}</label>
                                </div>
                            }

                            {
                                hasGroups && alarm.groups.length> 0&&
                                <div className="descWidget">
                                    <BiBox />
                                    <label>Groupes: {alarm.groups.length}</label>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}