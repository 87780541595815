import React, {Component, useState} from 'react';
import './step0.css';
import "react-perfect-scrollbar/dist/css/styles.css";
import { useForm } from "react-hook-form";
import {BsBuilding} from "react-icons/bs";
import {FiPhone} from "react-icons/fi";
import {AiOutlineMail, AiOutlineHome} from "react-icons/ai";
import {Button} from "../../../../components/widgets/form/button/button";
import isEmail from "validator/lib/isEmail";
import {fetchCompanyByName} from "../../companyService";
import {useDispatch, useSelector} from "react-redux";
import {
    SET_SUBSCRIPTION,
    SetStep,
    SetStepData, SetSubscription,
    ToogleActive,
    ToogleStep
} from "../../../../store/comapny/company.actions";
import {Checkbox} from "../../../../components/widgets/form/checkbox/checkbox";
import isNumeric from "validator/lib/isNumeric";
import {
    FaCircle,
    FaDrawPolygon,
    FaRegMoneyBillAlt,
    GiMoneyStack,
    GiPayMoney,
    GiTakeMyMoney,
    MdTimeline
} from "react-icons/all";

export default function Step0(props) {
    const dispatch = useDispatch();

    const {type} = useSelector(state => {
        return {
            type: state.companyReducer.addCompany.data.type,
        }
    });


    const onSubmit = async() => {
        dispatch(SetStep({step: 2}));
    };

    const previous = () => {
        dispatch(ToogleStep());
    };

    const toogleCheckbox = (value) => {
        dispatch(ToogleActive(value));
    };

    const chooseSubscription= (value) => {
        dispatch(SetSubscription(value));
    }

    return (
        <form className="form-aside" onSubmit={onSubmit}>
            <div className="options-subscription">
                <div className={type === 'eco' ? 'option-subscription option-subscription-active' : 'option-subscription' }
                     onClick={() => chooseSubscription('eco')}>
                    <GiPayMoney/>
                    <label>Eco</label>
                </div>
                <div className={type === 'eco+' ? 'option-subscription option-subscription-active' : 'option-subscription' }
                     onClick={() => chooseSubscription('eco+')}
                >
                    <FaRegMoneyBillAlt/>
                    <label>Eco+</label>
                </div>
                <div
                    className={type === 'confort' ? 'option-subscription option-subscription-active' : 'option-subscription' }
                    onClick={() => chooseSubscription('confort')}
                >
                    <GiTakeMyMoney/>
                    <label>Confort</label>
                </div>
                <div
                    className={type === 'premium' ? 'option-subscription option-subscription-active' : 'option-subscription' }
                    onClick={() => chooseSubscription('premium')}
                >
                    <GiMoneyStack/>
                    <label>Premium</label>
                </div>
            </div>

            <div className="actions">
                <Button type='btn-accent' press={previous} name='Fermer'/>
                <Button type='btn-primary' name='Suivant' disabled={!type}/>
            </div>
        </form>
    );
}
