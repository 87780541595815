import React, { Component }  from 'react';
import './account-info.css';
import {AiOutlineMail, AiOutlinePlus, AiOutlineMore, AiOutlineUser} from "react-icons/ai";
import {FiPhone, FiMoreVertical} from "react-icons/fi";
import { Popover } from 'antd';
import {ActionPopover} from "../widgets/action-popover/action-popover";
import {AiOutlineEdit} from "react-icons/ai";
import {RiLockPasswordLine} from "react-icons/ri";
import {AiOutlineDelete} from "react-icons/ai";
import {FaBoxOpen} from "react-icons/fa";

export class AccountInfo extends Component {

    constructor(props) { super(props); }

    componentDidMount() { }

    status() {
        if(this.props.account.tracer) {
            return this.props.account.tracer.online ? "Connecté" : "Non connecté";
        }
        return '-'
    }

    render() {
        const account = this.props.account;
        const classStyle = account.active ? "card-user-info active-user" : "card-user-info deactive-user";
        const text = <span>Title</span>;
        const content = (
            <>

                <ActionPopover icon={AiOutlineEdit} label="Modifier" press={this.props.press} value={{action: 'edit', id: this.props.account._id}}/>
                <ActionPopover icon={RiLockPasswordLine} label="Modifier le mot de passe" press={this.props.press} value={{action: 'editPass', username: this.props.account.username}}/>
                <ActionPopover icon={FaBoxOpen} label="Affecter/Retirer le groupe" press={this.props.press} value={{action: 'grp', username: this.props.account.username}}/>
                <ActionPopover icon={AiOutlineDelete} press={this.props.press} label="Supprimer" value={{action: 'del', name: this.props.account.name, username: this.props.account.username, id: this.props.account._id}}/>
            </>
        );
        return (
            <div className={classStyle}>
                <div className="more-options">
                    <Popover placement="rightTop" content={content} text={text} trigger="hover">
                        <FiMoreVertical />
                    </Popover>
                </div>

                    <div className="main">
                        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                                <img className="avatar-account" src="/img/avatar.svg"/>
                                <h5 className="name">{this.props.account.name}

                                    {
                                        account && account.groups && account.groups.length > 0 &&
                                        <span> ({account.groups[0]})</span>
                                    }
                                </h5>
                                <div className="description">
                                    <div className="descWidget">
                                        <AiOutlineMail />
                                        <label>{account && account.email ? account.email : '-'}</label>
                                    </div>
                                    <div className="descWidget">
                                        <AiOutlineUser />
                                        <label>{account && account.username ? account.username : '-'}</label>
                                    </div>
                                    <div className="descWidget">
                                        <FiPhone />
                                        <label>{account && account.phone ? account.phone : '-'}</label>
                                    </div>
                            </div>
                        </div>
                </div>
            </div>
        );
    }
}