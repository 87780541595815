import {BASE_URL} from "../../../env";
const axios = require('axios');

export function fetchEvents(payload, token) {
    let params = `skip=${payload.skip}&limit=${payload.limit}`;
    if (payload.name) {
        params+=`&filter[item][name]=${payload.name}`
    }
    const url = `${BASE_URL}/api/event/map?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

