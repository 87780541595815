import * as actions from "./history.actions";
import {catchError, delay, map, switchMap} from 'rxjs/operators'
import {ofType} from "redux-observable";
import {from, of} from "rxjs";
import {Error, GET_START, GetHistoryEnd, SetActiveEnd} from "./history.actions";
import {fetchHistoy, fetchTraject} from "../../pages/map/vehicles/vehicle-history/historyService";



export const FetchHistory = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        return from(fetchHistoy(action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                const list = state$.value.vehiclesMapReducer.vehicles.list;
                const timezone = state$.value.authReducer.user.timezone
                const index = list.findIndex(el => el._id === action.payload._id);
                let unitSpeed = undefined;
                if(index !== -1) {
                    unitSpeed = list[index].unitSpeed;
                }
                return GetHistoryEnd({
                    data: res.data, _id: action.payload._id, index: action.payload.index,
                    timezone,
                    unitSpeed,
                    from: action.payload.from
                });
            }),
            catchError(err => {
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const FetchTrajects = (action$, state$) => action$.pipe(
    ofType(actions.SET_ACTIVE_START),
    switchMap((action) => {
        const vehicle = state$.value.historyReducer._id;
        return from(fetchTraject(vehicle, action.payload, state$.value.authReducer.token)).pipe(
            map(res => {
                return SetActiveEnd({
                    index: action.payload.index,
                    traject: res.data,
                    on: true
                });
            }),
            catchError(err => {
                return of(Error('Problem unknow'))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);