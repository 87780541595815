import * as actions from "./report.actions";
import {
    EmptyReport,
    EndDownload,
    Error,
    GetGeneratedEnd,
    GetReportEnd,
    GetReportsEnd,
    SaveEnd,
    SaveStart, SetActive
} from "./report.actions";
import {ofType} from "redux-observable";
import {catchError, delay, map, switchMap} from 'rxjs/operators'
import {from, of} from "rxjs";
import {AutoLogout} from "../auth/auth.actions";
import {NotificationManager} from "react-notifications";
import {
    deleteReport, fetchReports, fetchReportsType, getReport, saveReport,
    download, fetchGeneratedReports
} from "../../pages/reports/reportsService";
import fileDownload from 'js-file-download';
import {notification} from "antd";

const openNotificationWithIcon = type => {
    notification[type]({
        message: 'Pas de donnée!',
        description:
            'Pas de donnée pour la période séléctonnée',
    });
};

export const FetchReportsType = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        const params = {...state$.value.reportsReducer.filters.params, ...action.payload};
        return from(fetchReportsType(params, state$.value.authReducer.token)).pipe(
            map(res => {
                return GetReportsEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);


export const StartDownloadEpic = (action$, state$) => action$.pipe(
    ofType(actions.START_DOWLOAD),
    switchMap((action) => {
            return from(download(action.payload, state$.value.authReducer.token)).pipe(
                map(res => {
                    fileDownload(res.data, `${action.payload.name}.xlsx`);
                    return EndDownload(res.data);
                }),
                catchError(err => {
                    let msg = 'une erreur s\'est produite !';
                    if (err.response.status === 401) {
                        return of(AutoLogout(), Error(''));
                    }
                    return of(Error(msg))
                })
            )
       // }
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const SaveReportsEpic = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_START),
    switchMap((action) => {
        const params = {
            ...state$.value.reportsReducer.addReport.data,
            ...action.payload,
            ...{vehicle: state$.value.reportsReducer.addReport.vehicle},
            ...{group: state$.value.reportsReducer.addReport.group}
        };

        const filters = {
            ...{limit: state$.value.reportsReducer.report.limit},
            ...{skip: state$.value.reportsReducer.report.skip},
        };

        if(params.vehicle) { params.vehicles = [params.vehicle]; delete params.vehicle; }
        else { params.vehicles= []; }
        if(params.group) { params.groups = [params.group]; delete params.group; }
        else { params.groups= []; }
        const type = state$.value.reportsReducer.active.type;
        const timezone = state$.value.authReducer.user.timezone;
        return from(saveReport(filters, params, type, state$.value.authReducer.token)).pipe(
            map(res => {
                if(filters.skip === 0 && res.data.total === 0) {
                    openNotificationWithIcon('info');
                    return EmptyReport();
                }
                return SaveEnd({data: params, res: res.data, timezone});
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const SaveEndReportsEpic = (action$, state$) => action$.pipe(
    ofType(actions.SAVE_END),
    switchMap((action) => {
        NotificationManager.success('Rapport chargé avec succès', 'Chargement' , 2000);
        return of(SetActive(false));
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const FetchReportType = (action$, state$) => action$.pipe(
    ofType(actions.GET_REPORT_START),
    switchMap((action) => {
        const id = state$.value.reportsReducer.active._id;
        const params = {...{id}, ...state$.value.reportsReducer.report.filters.params, ...action.payload};
        return from(fetchReports(params, state$.value.authReducer.token)).pipe(
            map(res => {
                return GetReportEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const FetchReportGenerated = (action$, state$) => action$.pipe(
    ofType(actions.GET_GENERATED_START),
    switchMap((action) => {
       // const id = state$.value.reportsReducer.active._id;
        // const params = {...{id}, ...state$.value.reportsReducer.report.filters.params, ...action.payload};
        return from(fetchGeneratedReports({}, state$.value.authReducer.token)).pipe(
            map(res => {
                return GetGeneratedEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

