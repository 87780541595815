import * as actions from "./vehicles.actions";
import {fetchVehicles} from "../../../pages/map/vehicleService";
import {Error, getVehiclesEnd, LOCATE} from "./vehicles.actions";
import {ofType} from "redux-observable";
import {catchError, map, switchMap} from 'rxjs/operators'
import {from, of} from "rxjs";
import {changeView, fitBounds, zoomIn, zoomOut} from "./leaflet-provider";
import {AutoLogout} from "../../auth/auth.actions";


export const FetchVehiclesMap = (action$, state$) => action$.pipe(
    ofType(actions.GET_START),
    switchMap((action) => {
        const params = {...state$.value.vehiclesMapReducer.filters.params, ...action.payload};
        return from(fetchVehicles(params, state$.value.authReducer.token)).pipe(
            map(res => {
                return getVehiclesEnd(res.data);
            }),
            catchError(err => {
                let msg = 'une erreur s\'est produite !';
                if (err.response.status === 401) {
                    return of(AutoLogout(), Error(''));
                }
                return of(Error(msg))
            })
        )
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const VehiclesMapEnd = (action$, state$) => action$.pipe(
    ofType(actions.GET_END),
    map((action) => {
        const map = state$.value.vehiclesMapReducer.map;
        fitBounds(map, state$.value.vehiclesMapReducer.vehicles.list);
        return Error('')
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const Locate = (action$, state$) => action$.pipe(
    ofType(actions.LOCATE),
    map((action) => {
        const map = state$.value.vehiclesMapReducer.map;
        changeView(map, action.payload.position, 16);
        return Error('')
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const VehicleData = (action$, state$) => action$.pipe(
    ofType(actions.SET_VEHICLE_DATA),
    map((action) => {
        if(state$.value.vehiclesMapReducer.trackVehicle && action.payload.uid === state$.value.vehiclesMapReducer.trackVehicle) {
            const map = state$.value.vehiclesMapReducer.map;
            const pos = action.payload.data[action.payload.data.length-1].position
            // changeView(map, [pos.lat, pos.lng], 16);
        }
        return Error('')
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const ZoomInEpic = (action$, state$) => action$.pipe(
    ofType(actions.ZOOM_IN),
    map((action) => {
        const map = state$.value.vehiclesMapReducer.map;
        zoomIn(map);
        return Error('')
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const ZoomOutEpic = (action$, state$) => action$.pipe(
    ofType(actions.ZOOM_OUT),
    map((action) => {
        const map = state$.value.vehiclesMapReducer.map;
        zoomOut(map);
        return Error('')
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

export const ShowAllEpic = (action$, state$) => action$.pipe(
    ofType(actions.SHOW_ALL),
    map((action) => {
        const map = state$.value.vehiclesMapReducer.map;
        fitBounds(map, state$.value.vehiclesMapReducer.vehicles.list);
        return Error('')
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);

