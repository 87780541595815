import React, {useEffect} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {BlankComponent} from "../../components/blank/blank";
import {AiOutlineDoubleLeft} from "react-icons/all";
import {useDispatch, useSelector} from "react-redux";
import './report.css';
import {GetReportsStart, SetActive} from "../../store/reports/report.actions";
import {ReportTypeInfo} from "../../components/reports/report-type/report-type";

export const ReportListPage = ({show, toogleShow}) => {
    const dispatch = useDispatch();

    const {reports, active} = useSelector(state => {
        const reports = state.reportsReducer;
        return { reports: reports.reports.list, active : reports.active };
    });

    const reportSize = reports.length;

    useEffect(() => {
        dispatch(GetReportsStart({}));
    }, []);

    useEffect(() => {
        if(!show) {
            dispatch(SetActive(false))
        }
        dispatch(GetReportsStart({}));
    }, [show]);

    return (
        <div className={`report-list-type ${show ? 'show-report-list-type' : ''}`}>
            <div className="header">
                <label className="title">Rapport disponibles</label>
                <AiOutlineDoubleLeft className="icon-close" onClick={()=>toogleShow && toogleShow()}/>
            </div>
            <div className="content">
                <PerfectScrollbar style={{ height: `${window.innerHeight - 150}px`, padding:'1px 5px', marginBottom: '12px' }}>
                    {
                        reportSize > 0 ? reports.map((report, i) => {
                            return <ReportTypeInfo key={i}
                                       report={report}
                                       press={()=>dispatch(SetActive(report))}
                                       active={active}
                                       enable={reportSize !== 0}
                            />
                        }): <BlankComponent/>
                    }
                </PerfectScrollbar>
            </div>
            {
                /*
                <div style={{opacity: .7, padding: '9px 0px 9px 0px', display: 'flex', flexDirection: 'column', borderBottom: '1px solid rgb(163, 168, 166)', cursor: 'pointer'}}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <span style={{fontSize: '13px', fontWeight: 400}}>Rapport trajet</span>
                        <Progress percent={40} steps={4} size="default" strokeColor="#52c41a" />
                    </div>
                    <span style={{fontSize: '10px', color: 'rgba(0, 0, 0, 0.54)'}}>Date de création: 05/06/2021 à 12:34</span>
                </div>
                <div style={{padding: '9px 0px 9px 0px', display: 'flex', flexDirection: 'column', borderBottom: '1px solid rgb(163, 168, 166)', cursor: 'pointer'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <span style={{fontSize: '13px', fontWeight: 400}}>Rapport trajet</span>
                <Progress percent={100} steps={4} size="default" strokeColor="#52c41a" />
                </div>
                <span style={{fontSize: '10px', color: 'rgba(0, 0, 0, 0.54)'}}>Date de création: 05/06/2021 à 12:34</span>
                </div>*/
            }
        </div>

    );
}

