export const GET_START = '[VEHICLES] GET_START';
export const GET_END = '[VEHICLES] GET_END';

export const GET_START_ACCESSORY = '[VEHICLES] GET_START_ACCESSORY';
export const GET_END_ACCESSORY = '[VEHICLES] GET_END_ACCESSORY';
export const TOOGLE_ACCESSORY = '[VEHICLES] TOOGLE_ACCESSORY';
export const ADD_START_ACCESSORY = '[VEHICLES] ADD_START_ACCESSORY';
export const ADD_END_ACCESSORY = '[VEHICLES] ADD_END_ACCESSORY';




export const ERROR = '[VEHICLES] ERROR';

export const TOOGLE_STEP = '[VEHICLES] TOOGLE_STEP';
export const TOOGLE_ACTIVE = '[VEHICLES] TOOGLE_ACTIVE';

export const SET_STEP_DATA = '[VEHICLES] SET_STEP_DATA';
export const SET_STEP = '[VEHICLES] SET_STEP';
export const SET_DATE = '[VEHICLES] SET_DATE';

export const SAVE_START = '[VEHICLES] SAVE_START';
export const SAVE_END = '[VEHICLES] SAVE_END';

export const DELETE_START = '[VEHICLES] DELETE_START';
export const DELETE_END = '[VEHICLES] DELETE_END';

export const STEP_COMMAND_START = '[VEHICLES] STEP_COMMAND_START';
export const STEP_COMMAND_END = '[VEHICLES] STEP_COMMAND_END';
export const SAVE_COMMANDS_START = '[VEHICLES] SAVE_COMMANDS_START';
export const SAVE_COMMANDS_END = '[VEHICLES] SAVE_COMMANDS_END';

export const CLEAR_DATA='[VEHICLES] CLEAR_DATA';





export function AddAccessoryStart(payload) {
    return { type: ADD_START_ACCESSORY, payload }
}

export function AddAccessoryEnd(payload) {
    return { type: ADD_END_ACCESSORY, payload }
}

export function StepCommandStart(payload) {
    return { type: STEP_COMMAND_START, payload }
}

export function StepCommandEnd(payload) {
    return { type: STEP_COMMAND_END, payload }
}

export function SaveVehicleStart(payload) {
    return { type: SAVE_START, payload }
}

export function SaveVehicleEnd(payload) {
    return { type: SAVE_END, payload }
}

export function GetAccessoryStart(payload) {
    return { type: GET_START_ACCESSORY, payload }
}

export function GetAccessoryEnd(payload) {
    return { type: GET_END_ACCESSORY, payload }
}

export function ToogleAccessory(payload) {
    return { type: TOOGLE_ACCESSORY, payload }
}

export function DeleteVehicleStart(payload) {
    return { type: DELETE_START, payload }
}

export function DeleteVehicleEnd(payload) {
    return { type: DELETE_END, payload }
}

export function GetVehiclesStart(payload) {
    return { type: GET_START, payload }
}

export function ToogleStep() {
    return { type: TOOGLE_STEP }
}

export function ToogleActive(payload) {
    return { type: TOOGLE_ACTIVE, payload }
}

export function SetStepData(payload) {
    return { type: SET_STEP_DATA, payload }
}

export function SetStep(payload) {
    return { type: SET_STEP, payload }
}

export function getVehiclesEnd(payload) {
    return { type: GET_END, payload }
}

export function SaveCommandsStart(payload) {
    return { type: SAVE_COMMANDS_START, payload }
}

export function SaveCommandsEnd(payload) {
    return { type: SAVE_COMMANDS_END, payload }
}

export function ClearData(payload) {
    return { type: CLEAR_DATA, payload }
}

export function Error(msg) {
    return { type: ERROR, msg }
}