import React, { PureComponent} from "react";
import {AsideMenu} from "../../_menu/asideMenu";
import {Header} from "../../_header/header";
import 'leaflet/dist/leaflet.css';
import './index.css';
import {connect} from "react-redux";
import {SetSizeMenu} from "../../store/main.actions";
import {NotificationContainer} from "react-notifications";
import {AutoLogout, LogoutEnd, LogoutStart} from "../../store/auth/auth.actions";
import LoginPage from "../../pages/login/loginPage";
import io from "socket.io-client";
import {BASE_URL} from "../../env";
import MapLeaflet from "../../pages/map/MapComponents/map";
import {SetDriver, SetVehicleCnx, SetVehicleData} from "../../store/map/vehicles/vehicles.actions";
import {PushEvents} from "../../store/map/events/events.actions";
import {notification} from "antd";
import {getData} from "../../utils/parser";
import {UpdateStatus} from "../../store/reports/report.actions";
import moment from "moment-timezone";

class DefaultLayout extends PureComponent{

    constructor(props) { super(props); }

    componentDidMount() {
        if(this.props.token) { this.popLogin(); }
        // Socket IO
        this.socketIO();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.token && !prevProps.token) {
            this.socketIO();
            this.popLogin();
        }
    }

    componentWillUnmount() {
        if(this.timeOut) { clearTimeout(this.timeOut); }
    }

    popLogin() {
        if(this.timeOut) { clearTimeout(this.timeOut); }
        const expirationDuration = this.props.expiresIn - new Date().getTime();
        this.timeOut = setTimeout(() => {
            this.props.dispatch(AutoLogout());
        }, expirationDuration);
    }

    // SOCKET IO
    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            console.log('Disconnected...')
            this.socket = undefined;
        }
    }

    socketIO() {
        this.disconnect();
        this.socket = io(`${BASE_URL}/${this.props.company}`, { query: { token: this.props.token } });
        this.socket.on('connect', s => {
            console.log('connected....')
            this.ping();
            this.events();
            this.reports();
            this.drivers();
            this.disconnected();
        });
    }

    async ping() {
        this.socket.on('gps-data', data => {
            this.props.dispatch(SetVehicleData(data));
        });
    }

    async drivers() {
        this.socket.on('driver', data => {
            this.props.dispatch(SetDriver(data));
        });
    }

    async events() {
        this.socket.on('gps-alarms', data => {
            const end = [];
            data.data.forEach((el, i) => {
                if( el.vehicleName ) {
                    const vehicle = el.vehicleName;
                    const zone = el.zoneName;
                    el.vehicle = vehicle;
                    el.zone = zone;
                    delete el.vehicleName;
                    delete el.zoneName;

                    const {ICON, name, date} = getData(el);

                    notification.open({
                        className:'notification-popup',
                        duration: 5,
                        message: name,
                        description: `${el.vehicle}, ${el.msg} à ${moment(date).tz(this.props.user.timezone).format('DD/MM/YYYY HH:mm:ss')}`,
                        icon: <ICON />,
                    });
                   end.push(el);
                }

            });
            data.data = end;
            this.props.dispatch(new PushEvents(data));
        });
    }




    async reports() {
        this.socket.on('report', data => {
            if(data.user === this.props.user._id) {
                this.props.dispatch(UpdateStatus(data));
            }
        });
    }

    async disconnected() {
        this.socket.on('disconnected', data => {
            this.props.dispatch(SetVehicleCnx({uid: data.uid, status: false}));
        });
    }



    /* HTML */

    changeSizeMenu = (action) => {
        switch (action.action) {
            case 'menuSize':
                this.props.dispatch(SetSizeMenu());
                break;
            case 'changeUser':
                this.props.dispatch(AutoLogout());
                break;

            case 'disconnection':
                this.props.dispatch(LogoutEnd());
                break;

        }

    }
    render() {
        const activePath = this.props.active.split('/');
        const active = activePath[1];
        let mapClass = 'hide';
        let containerStyle = {};
        if(active === 'map' || active === 'zones' /*|| (active === 'reports' && this.props.report.total !== 0)*/) {
            mapClass = 'show';
            containerStyle = {flex: 1, display: 'flex', flexDirection: 'column'};
        }
        return (
            <>
                {
                    this.props.token === false &&
                    <div className="loginPOUP">
                        <LoginPage noBackground={true} notification="Votre session a expiré, merci de vous connecter."/>
                    </div>
                }
                <AsideMenu sizeMenu={this.props.sizeMenu} active={active} user={this.props.user}/>
                <div className={`container ${this.props.sizeMenu}-container`}>
                    {

                        !this.props.active.includes('manage') &&
                            <Header onPress={this.changeSizeMenu} sizeMenu={this.props.sizeMenu} user={this.props.user}/>

                    }
                    <div style={{height: '100%', display: 'flex', flexDirection: 'row', position: 'relative'}}>
                        {this.props.children}
                        <MapLeaflet
                            mapClass={mapClass}
                            containerStyle={containerStyle}
                            activeRoot={active}
                            report={this.props.report}
                        />
                    </div>

                </div>
                <NotificationContainer/>
            </>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        active: state.mainReducer.activePage,
        sizeMenu: state.mainReducer.sizeMenu,
        token: state.authReducer.token,
        company: state.authReducer.user && state.authReducer.user.company && state.authReducer.user.company._id,
        expiresIn: state.authReducer.expiresIn,
        report: state.reportsReducer.report
    }
}
export default connect(mapStateToProps)(DefaultLayout);
