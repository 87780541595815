import React, {Component} from "react";
import {AiOutlineSearch} from "react-icons/ai";
import PerfectScrollbar from "react-perfect-scrollbar";
import {BlankComponent} from "../../components/blank/blank";
import {Paginator} from "../../components/pagination/paginator/Paginator";
import {connect} from "react-redux";
import './alarms.css';
import {AlarmTypeInfo,} from "../../components/alarms/alarm-type/event-type";
import {ClearAlarms, GetAlarmsStart, SetActive} from "../../store/alarms/alarms.actions";
import {LoadingComponent} from "../../components/loading/loading";
import ListAlarmsPage from "./list-alarms/list-alarms";

class AlarmsPage extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 210}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.props.dispatch(GetAlarmsStart({}));
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        this.props.dispatch(ClearAlarms());

    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 210}px` });
    }

    changed(e) {
        if(e.keyCode === 13) {
            this.props.dispatch(GetAlarmsStart({
                name: `${e.target.value}.*`
            }));
        }
    }

    paginate(skip, limit) {
        this.props.dispatch(GetAlarmsStart({skip: skip}));
    }

    activeAlarm(alarm) { this.props.dispatch(SetActive(alarm)); }

    accountAction() {

    }

    filterAction() {

    }
    render() {
        const params= this.props.filters.params;
        return (
            <section id="alarm-section">
                <div className="alarm">
                    <LoadingComponent loading={this.props.loading}/>
                    <div className="action-search-alarm">
                        <input type="text" placeholder="Chercher..." onKeyUp={this.changed.bind(this)}/>
                        <AiOutlineSearch/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                        <PerfectScrollbar style={{ height: this.state.height, padding:'0px 3px', marginBottom: '12px' }}>

                            {
                                this.props.alarms.list.length > 0 ?this.props.alarms.list.map((el, i) => {
                                    return <AlarmTypeInfo key={i}
                                                          alarm={el}
                                                          press={this.activeAlarm.bind(this)}
                                                          active={this.props.active}
                                    />
                                }): <BlankComponent/>
                            }
                        </PerfectScrollbar>
                        <Paginator
                            noMargin={true}
                            paginate={this.paginate.bind(this)}
                            limit={params.limit}
                            skip={params.skip}
                            total={this.props.alarms.total}/>
                    </div>
                </div>
                <div className="content">
                    { this.props.active ? <ListAlarmsPage/>  : <BlankComponent/>}
                </div>
            </section>



        );
    }
}

const VehiclesStateToProps = (state) => {
    return {
        filters: state.alarmsReducer.filters,
        alarms: state.alarmsReducer.alarms,
        loading: state.alarmsReducer.loading || state.alarmsReducer.alarm.loading,
        active: state.alarmsReducer.active,
    }
}
export default connect(VehiclesStateToProps)(AlarmsPage);