import {BASE_URL} from "../../env";
const axios = require('axios');

export function fetchTracersByType(payload, token) {
    let params = `skip=${payload.skip}&limit=${payload.limit}`;
    if (payload.name) {
        params+=`&name=${payload.name}`
    }
    const url = `${BASE_URL}/api/tracer/${payload.type}?${params}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.get(url, config);
}

export function deleteTracer(payload, token) {
    const url = `${BASE_URL}/api/tracer/${payload}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.delete(url, config);
}

export function createTracer(type, payload, token) {
    const url = `${BASE_URL}/api/tracer/${type}`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.post(url, payload, config);
}

export function updateTracer(payload, token) {
    const url = `${BASE_URL}/api/tracer`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    }
    return axios.patch(url, payload, config);
}


