import React, { Component }  from 'react';
import './blackList.css';
import {AiOutlinePlus, AiOutlineReload, AiOutlineSearch} from "react-icons/ai";
import {HeaderAction} from "../../components/header/header-action/header-action";
import {SearchAction} from "../../components/header/search-action/search-action";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from 'react-perfect-scrollbar'
import {connect} from "react-redux";

import {BlankComponent} from "../../components/blank/blank";
import {LoadingComponent} from "../../components/loading/loading";
import {Paginator} from "../../components/pagination/paginator/Paginator";
import {SideBar} from "../../components/sideBar/sideBar";
import Step1 from "./add/step1/step1";
import {ConfirmationDialog} from "../../components/confirmation-dialog/confirmation-dialog";
import {confirmAlert} from "react-confirm-alert";
import {BlackListInfo} from "../../components/blacklist/black-list-info";
import {deleteBlackListStart, GetBlackListsStart, ToogleStep} from "../../store/blacklist/blacklist.actions";


class BlackListPage extends Component {

    constructor(props) {
        super(props);
        this.state = { height: `${window.innerHeight - 176}px` };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(GetBlackListsStart({}));
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({  height: `${window.innerHeight - 176}px` });
    }

    deleteAction(id) {
        this.props.dispatch(deleteBlackListStart(id));
    }

    filterAction(action) {
        switch (action.key) {
            case 'name':
                this.props.dispatch(GetBlackListsStart({
                    name: action.value,
                    skip: 0
                }))
                break;

            case 'filter':
                this.props.dispatch(GetBlackListsStart({
                    filter: action.value,
                    skip: 0
                }))
                break;
        };
    }

    findAccountById(id){
        return this.props.accounts.find(el => id === el._id);
    }
    findAccountByUsername(username){
        return this.props.accounts.find(el => username === el.username);
    }


    blackListAction(action) {
        switch (action.action) {
            case 'reload':
                this.props.dispatch(GetBlackListsStart({}))
                break;
            case 'add':
                this.props.dispatch(ToogleStep());
                break;
            case 'del':
                const options = {
                    customUI: ({ onClose }) =>
                        <ConfirmationDialog
                            close={onClose}
                            action={this.deleteAction.bind(this)}
                            id={action.id}
                            keyword={action.name}
                            ActionName={"Supprimer"}/>,
                    closeOnEscape: false,
                    closeOnClickOutside: false
                };
                confirmAlert(options);
                break;
        }
    }

    paginate(skip, limit) {
        this.props.dispatch(GetBlackListsStart({skip: skip}));
    }

    render() {
        const filters= this.props.filters.data;
        const params= this.props.filters.params;
        return (
            <section id="blackList">
                <LoadingComponent loading={this.props.loading}/>
                <div className="header-section">
                    <div className="action-right">
                        <HeaderAction
                            value={{action: 'reload'}}
                            press={this.blackListAction.bind(this)}
                            icon={AiOutlineReload}
                        />
                        <HeaderAction
                            value={{action: 'add'}}
                            press={this.blackListAction.bind(this)}
                            icon={AiOutlinePlus}
                        />
                        <SearchAction
                            icon={AiOutlineSearch}
                            indentifier="name"
                            onChange={this.filterAction.bind(this)}
                        />
                    </div>

                </div>
                <div style={{display: 'flex'}}>
                    <PerfectScrollbar className="scrollbar-container-account" style={{ height: this.state.height }}>
                        <div className="body">
                            {
                                this.props.blacklist.length > 0  ? this.props.blacklist.map((item,i) => {
                                    return <BlackListInfo
                                        key={i}
                                        item={item}
                                        company={this.props.user}
                                        press={this.blackListAction.bind(this)}
                                    />
                                }) : !this.props.loading && <BlankComponent/>
                            }
                        </div>
                        <Paginator paginate={this.paginate.bind(this)} limit={params.limit} skip={params.skip} total={this.props.total}/>

                    </PerfectScrollbar>

                    {
                        this.props.addblacklist.step &&  this.props.addblacklist.step > 0 ?
                            <SideBar
                                height={window.innerHeight}
                                countSteps={this.props.addblacklist.steps.length}
                                step={this.props.addblacklist.step}
                                stepsText={this.props.addblacklist.steps}
                            >
                                <Step1 />
                            </SideBar> : <></>
                    }


                </div>
            </section>
        );
    }
}

const accountsStateToProps = (state) => {
    return {
        user: state.authReducer.user.company,
        blacklist: state.blacklistReducer.blacklist,
        filters: state.blacklistReducer.filters,
        loading: state.blacklistReducer.loading,
        addblacklist: state.blacklistReducer.addblacklist,
        total: state.blacklistReducer.total
    }
}
export default connect(accountsStateToProps)(BlackListPage);